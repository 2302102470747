import { useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { filter, map } from 'rxjs';

import { useCabinet, useDeviceSettings, useHiddenEntryIds, useHiddenJournalIds, useHiddenMediaIds } from './components/DataServiceStateContext';
import { getAsPlaintext, getTitle } from './data/data-types/delta';
import { Entry, EntryId } from './data/data-types/entities/entry';
import { Journal, JournalId } from './data/data-types/entities/journal';
import { Media } from './data/data-types/entities/media';
import { Settings } from './data/data-types/entities/settings';
import { Timestamp } from './data/data-types/timestamp';
import { single } from './data/utils';

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export type PromiseState<T> =
  | { state: 'pending' }
  | { state: 'resolved', value: T }
  | { state: 'rejected', error: any };

export function usePromise<T>(
  promise: Promise<T>,
): PromiseState<T> {
  const [state, setState] = useState<PromiseState<T>>({ state: 'pending' });
  useEffect(() => {
    let cancel = false;
    setState({ state: 'pending' });
    promise.then(res => {
      if (cancel) return;
      setState({ state: 'resolved', value: res });
    }, error => {
      if (cancel) return;
      setState({ state: 'rejected', error: error });
    })
    return () => {
      cancel = true;
    };
  }, [promise]);

  return state;
}

export function useTimeout() {
  const timeouts = useRef<any[]>([]);

  const clearAll = useCallback(() => {
    timeouts.current.forEach((timeoutId) => clearTimeout(timeoutId));
    timeouts.current = [];
  }, []);

  useEffect(() => () => clearAll(), [clearAll]);

  const setLocalTimeout = useCallback((callback: () => void, milliseconds: number) => {
    const timeoutId = setTimeout(callback, milliseconds);
    timeouts.current.push(timeoutId);

    return () => {
      timeouts.current = timeouts.current.filter((x) => x !== timeoutId);
      clearTimeout(timeoutId);
    };
  }, []);

  return [setLocalTimeout, clearAll] as const;
}

export function useKeyDownCallback<T extends Element = Element>(
  { onEnter, onEscape, onDown, onRight, onUp, onLeft, onBackspace }: {
    onEnter?: (e: React.KeyboardEvent<T>) => void;
    onEscape?: (e: React.KeyboardEvent<T>) => void;
    onUp?: (e: React.KeyboardEvent<T>) => void;
    onLeft?: (e: React.KeyboardEvent<T>) => void;
    onDown?: (e: React.KeyboardEvent<T>) => void;
    onRight?: (e: React.KeyboardEvent<T>) => void;
    onBackspace?: (e: React.KeyboardEvent<T>) => void;
  }
) {
  const callback = useCallback((event: React.KeyboardEvent<T>) => {
    switch (event.key) {
      case 'Enter':
        if (onEnter) {
          onEnter(event);
        }
        break;
      case 'Escape':
        if (onEscape) {
          onEscape(event);
        }
        break;
      case 'Down':
      case 'ArrowDown':
        if (onDown) {
          onDown(event);
        }
        break;
      case 'Right':
      case 'ArrowRight':
        if (onRight) {
          onRight(event);
        }
        break;
      case 'Up':
      case 'ArrowUp':
        if (onUp) {
          onUp(event);
        }
        break;
      case 'Left':
      case 'ArrowLeft':
        if (onLeft) {
          onLeft(event);
        }
        break;
      case 'Backspace':
        if (onBackspace) {
          onBackspace(event);
        }
        break;
    }
  }, [onEnter, onEscape, onDown, onRight, onUp, onLeft, onBackspace]);

  return callback;
}

export function useBackground() {
  return useColorModeValue('white', 'gray.800');
}

export function useTextPrimary() {
  return useColorModeValue('black', 'white');
}

export function useTextSecondary() {
  return useColorModeValue('gray.500', 'gray.400');
}

export function useTextOptional() {
  return useColorModeValue('gray.400', 'gray.500');
}

export function useHighlight() {
  return useColorModeValue('gray.200', 'gray.600');
}

export function useButtonBackground() {
  return useColorModeValue('gray.100', 'whiteAlpha.200');
}

export function useRed() {
  return useColorModeValue('red', 'red.300');
}

export function useGrayBg() {
  return useColorModeValue('gray.50', '#242C3A'); // gray.750
}

export function useDelayed<T>(value: T, delay?: number): T {
  const [state, setState] = useStateDelay(value);

  useEffect(() => {
    setState(value, delay);
  }, [delay, setState, value]);

  return state;
}

export function useStateDelay<T>(initialState: T) {
  const [state, setState] = useState(initialState);

  const [setLocalTimeout, cancelLocalTimeout] = useTimeout();

  return [
    state,
    useCallback(
      (newState: T | ((prev: T) => T), delay?: number) => {
        cancelLocalTimeout();
        if (!delay) {
          setState(newState);
        } else {
          setLocalTimeout(() => setState(newState), delay);
        }
      },
      [cancelLocalTimeout, setLocalTimeout]
    ),
  ] as const;
}

export interface Observable<T> {
  subscribe: (listener: (value: T) => void) => {
    unsubscribe: () => void;
  };
}

export function useObservable<T>(observable: Observable<T>): T | undefined;
export function useObservable<T>(observable: Observable<T>, initialValue: T): T;
export function useObservable<T>(observable: Observable<T>, initialValue?: T): T | undefined {
  const valueRef = useRef(initialValue);
  const prevObservableRef = useRef(observable);

  if (prevObservableRef.current !== observable) {
    prevObservableRef.current = observable;
    valueRef.current = initialValue;
  }

  const [, triggerRedner] = useState(0);

  useEffect(() => {
    const subscription = observable.subscribe(value => {
      valueRef.current = value;
      triggerRedner(x => x + 1);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [observable]);

  return valueRef.current;
}

export function usePreventUnload(prevent: boolean) {
  useEffect(() => {
    const handler = (e: BeforeUnloadEvent) => {
      if (prevent) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handler);

    return () => {
      window.removeEventListener('beforeunload', handler);
    }
  }, [prevent]);
}

export function useActiveTheme(): 'light' | 'dark' {
  const settings = useSettings();
  const deviceSettings = useDeviceSettings();
  const [isDark] = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = settings.syncTheme ? settings.theme : deviceSettings.theme;

  if (theme.autoDark) {
    if (isDark) {
      return 'dark';
    }
  }

  return theme.type;
}

export function useJournal(journalId: JournalId): Journal | undefined {
  const cabinet = useCabinet();
  const journal$ = useMemo(
    () => cabinet.on({ journalIds: [journalId] }).pipe(map(() => cabinet.journal(journalId))),
    [cabinet, journalId],
  );
  const initial = useMemo(() => cabinet.journal(journalId), [cabinet, journalId]);

  return useObservable(journal$, initial);
}

export function useMedia(): readonly Media[] {
  function list(media: Media[]) {
    return media.sort((a, b) => b.createdAt - a.createdAt);
  }

  const cabinet = useCabinet();
  const journals$ = useMemo(
    () => cabinet
      .on({})
      .pipe(
        filter(x => x.mediaIds.length > 0),
        map(() => list(cabinet.media())),
      ),
    [cabinet],
  );
  const initial = useMemo(() => list(cabinet.media()), [cabinet]);

  const result = useObservable(journals$, initial);
  const [hiddenMediaIds] = useHiddenMediaIds();

  return useMemo(() => result.filter(x => !hiddenMediaIds.has(x.id)), [hiddenMediaIds, result]);
}

export function useJournals(): readonly Journal[] {
  function list(journals: Journal[]) {
    return journals.sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      } else if (a.order > b.order) {
        return 1;
      } else {
        return a.createdAt - b.createdAt;
      }
    });
  }

  const cabinet = useCabinet();
  const journals$ = useMemo(
    () => cabinet
      .on({})
      .pipe(
        filter(x => x.journalIds.length > 0),
        map(() => list(cabinet.journals())),
      ),
    [cabinet],
  );
  const initial = useMemo(() => list(cabinet.journals()), [cabinet]);

  const result = useObservable(journals$, initial);
  const [hiddenJournalIds] = useHiddenJournalIds();

  return useMemo(() => result.filter(x => !hiddenJournalIds.has(x.id)), [hiddenJournalIds, result]);
}

export function useEntries(options?: EntryListOptions): readonly Entry[] {
  const cabinet = useCabinet();
  const entries$ = useMemo(
    () => cabinet
      .on({})
      .pipe(
        filter(x => x.entryIds.length > 0),
        map(() => listEntries(cabinet.entries(), options)),
      ),
    [cabinet, options],
  );
  const initial = useMemo(() => listEntries(cabinet.entries(), options), [cabinet, options]);

  const result = useObservable(entries$, initial);
  const [hiddenEntryIds] = useHiddenEntryIds();

  return useMemo(() => result.filter(x => !hiddenEntryIds.has(x.id)), [hiddenEntryIds, result]);
}

export function usePagination<T>(items: readonly T[], pageSize = 20): {
  items: readonly T[];
  loadMore: () => void;
  hasMore: boolean;
  reset: () => void;
} {
  const [limit, setLimit] = useState(pageSize);

  const reset = useCallback(() => setLimit(pageSize), [pageSize]);

  return useMemo(() => ({
    items: items.slice(0, limit),
    hasMore: items.length > limit,
    loadMore: () => setLimit(prevLimit => prevLimit + pageSize),
    reset,
  }), [items, limit, pageSize, reset]);
}

export function useEntry(entryId: EntryId, timestamp?: Timestamp): Entry | undefined {
  const cabinet = useCabinet();
  const view = useMemo(
    () => timestamp === undefined ? cabinet : cabinet.view(timestamp, { entryIds: [entryId] }),
    [cabinet, entryId, timestamp],
  );

  const entry$ = useMemo(
    () => view.on({ entryIds: [entryId] }).pipe(map(() => view.entry(entryId))),
    [view, entryId],
  );
  const initial = useMemo(() => view.entry(entryId), [view, entryId]);

  return useObservable(entry$, initial);
}

export function useSettings(): Settings {
  const cabinet = useCabinet();
  const settings$ = useMemo(
    () => cabinet.on({ settings: true }).pipe(map(() => cabinet.settings())),
    [cabinet],
  );
  const initial = useMemo(() => cabinet.settings(), [cabinet]);

  return useObservable(settings$, initial);
}

export function useIsMobile() {
  return !single(useMediaQuery(`(min-width: ${48 * 16}px)`));
}

export function useScollBoxContainer() {
  const osRef = useRef<OverlayScrollbarsComponent>(null);
  const [scrollingContainer, setScrollingContainer] = useState<HTMLElement>();

  useLayoutEffect(() => {
    function getContainer() {
      const osViewport = osRef.current?.osInstance()?.getElements().viewport;
      if (scrollingContainer !== osViewport) {
        setScrollingContainer(osViewport ?? undefined);
      }

      if ((osViewport ?? undefined) === undefined) {
        return setTimeout(getContainer, 1000);
      }
    }

    const timeout = getContainer();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [scrollingContainer]);

  return [osRef, scrollingContainer] as const;
}

export function useScrollParentAccessor() {
  const [os, setOs] = useState<OverlayScrollbarsComponent | undefined>();
  const ref = useCallback((os: OverlayScrollbarsComponent) => setOs(os), []);
  const accessor = useCallback(() => os?.osInstance()?.getElements().viewport ?? null, [os])

  return [ref, accessor] as const;
}

export interface EntryListOptions {
  readonly bookmarked?: boolean;
  readonly before?: Timestamp;
  readonly after?: Timestamp;
  readonly text?: string;
  readonly journalIds?: readonly JournalId[];

  readonly sort?: 'asc' | 'desc';

  readonly offset?: number;
  readonly limit?: number;
}

function listEntries(entries: readonly Entry[], { before, after, bookmarked, journalIds, text, sort, offset, limit }: EntryListOptions = {}): Entry[] {
  offset = offset ?? 0;
  limit = limit ?? 1_000_000_000;

  return entries
    .filter(x => journalIds === undefined || journalIds.includes(x.journalId))
    .filter(x => bookmarked === undefined || x.bookmarked === bookmarked)
    .filter(x => before === undefined || x.timestamp <= before)
    .filter(x => after === undefined || x.timestamp >= after)
    .filter(x => text === undefined || text === '' || (
      getAsPlaintext(x.body).toUpperCase().indexOf(text.toUpperCase()) !== -1 ||
      (getTitle(x.body) || 'Untitled').toUpperCase().indexOf(text.toUpperCase()) !== -1
    ))
    .sort((a, b) => {
      if (sort === 'asc') {
        return a.timestamp - b.timestamp;
      } else {
        return b.timestamp - a.timestamp;
      }
    })
    .slice(offset, limit + offset);
}

export function useValue<T>(value: T) {
  const ref = useRef(value);
  ref.current = value;
  return ref;
}
