import { memo } from 'react';

import NotFoundFeedback from '../components/NotFoundFeedback';

function WebNotFountPage() {
  return (
    <NotFoundFeedback />
  );
}

export default memo(WebNotFountPage);
