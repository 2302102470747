import { Box, Flex, Image, Spinner, Tooltip, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { FiImage } from 'react-icons/fi';

import { useDataService } from '../../DataServiceContext';
import { useCabinet } from '../../DataServiceStateContext';
import { MediaId } from '../../../data/data-types/entities/media';
import WebAppSpinner from '../../../../web/components/WebAppSpinner';
import { useEntryMediaCache } from '../EntryMediaCacheContext';
import { createAppEmbed } from './createAppEmbed';

export interface MediaAppEmbedValue {
  readonly mediaId: MediaId;
}

export const MediaAppEmbed = createAppEmbed<MediaAppEmbedValue>(
  'media',
  ({ readOnly, value, isFocused, onFocus }) => <MediaAppEmbedComponent
    readOnly={readOnly}
    value={value}
    isFocused={isFocused}
    onFocus={onFocus}
  />
);

interface MediaAppEmbedComponentProps {
  readonly readOnly: boolean;
  readonly value: MediaAppEmbedValue;
  readonly isFocused: boolean;
  readonly onFocus: () => void;
}

type ImageState =
  | { readonly state: 'no_data' }
  | { readonly state: 'waiting' }
  | { readonly state: 'error' }
  | { readonly state: 'ready', readonly buffer: Uint8Array | undefined, readonly mimeType: string };

const MediaAppEmbedComponent = React.memo(({ value: media, isFocused, onFocus }: MediaAppEmbedComponentProps) => {
  const [image, setImage] = useState<ImageState>({ state: 'no_data' });

  const { value: cachedValue, uploading } = useEntryMediaCache(media.mediaId) ?? { uploading: false };
  const dataService = useDataService();
  const cabinet = useCabinet();

  useEffect(() => {
    (async () => {
      if (image.state === 'no_data') {
        if (cachedValue !== undefined) {
          setImage({ state: 'ready', buffer: cachedValue.buffer, mimeType: cachedValue.mimeType });
        } else {
          setImage({ state: 'waiting' });

          try {
            const imageMedia = cabinet.media(media.mediaId);
            if (imageMedia === undefined || imageMedia.type !== 'image') {
              setImage({ state: 'error' });
              return;
            }

            const buffer = await dataService.blob(imageMedia.blobKey);
            setImage({ state: 'ready', buffer, mimeType: imageMedia.mimeType });
          } catch (err) {
            console.warn(`Error while loading image ${media.mediaId}:`, err);
            setImage({ state: 'error' });
          }
        }
      }
    })();
  }, [media, image, cachedValue, cabinet, dataService]);

  const imageBlob = useMemo(
    () => image.state === 'ready' && image.buffer !== undefined
      ? new Blob([image.buffer], { type: image.mimeType })
      : undefined,
    [image],
  );

  const [dataUrl, setDataUrl] = useState<string>();
  useEffect(() => {
    if (imageBlob) {
      const url = URL.createObjectURL(imageBlob);
      setDataUrl(url);

      return () => {
        URL.revokeObjectURL(url);
      }
    }
  }, [imageBlob]);

  const focusBorderColor = useColorModeValue('blue.300', 'blue.200');
  const errorImageColor = useColorModeValue('gray.400', 'gray.600');

  // todo: add retry buttom and explanation if image.state === 'ready' and image.buffer === undefined
  // todo: handle when unknown media is used
  return (
    <Box onClick={onFocus}>
      {dataUrl && (
        <Tooltip label={uploading ? 'Uploading the image...' : undefined}>
          <Flex justify="center">
            <Box position="relative">
              <Image
                mx="auto"
                border="4px solid transparent"
                borderColor={isFocused ? focusBorderColor : 'transparent'}
                src={dataUrl}
                alt="decrypted"
              />
              {uploading && (
                <Box
                  px={3}
                  position="absolute"
                  right="4px"
                  bottom="4px"
                  bg="blackAlpha.600"
                  color="white"
                  borderRadius="md"
                >
                  <Spinner thickness="3px" size="sm" />
                </Box>
              )}
            </Box>
          </Flex>
        </Tooltip>
      )}
      {image.state === 'waiting' && (
        <Box
          border="4px solid transparent"
          borderColor={isFocused ? focusBorderColor : 'transparent'}
          py="50px"
        >
          <WebAppSpinner />
        </Box>
      )}
      {image.state === 'error' && (
        <Flex
          py={4}
          color={errorImageColor}
          direction="column"
          align="center"
          fontSize="6xl"
        >
          <Box
            border="4px solid transparent"
            borderColor={isFocused ? focusBorderColor : 'transparent'}
          >
            <FiImage />
          </Box>
        </Flex>
      )}
    </Box>
  )
});
