import { Timestamp } from './data-types/timestamp';
import { AppError } from './errors';
import { Result } from './result';
import { Brand } from './utils';

export class InvalidEncryptionKeyError extends AppError<'invalid_encryption_key'> {
  constructor(){
    super('invalid_encryption_key', 'Provided encryption key is not valid');
  }
}

export interface Passcode {
  readonly text: string;
  readonly metadata: PasscodeMetadata;
}

export type PasscodeId = Brand<string, 'PasscodeId'>;

export interface PasscodeMetadata {
  readonly id: PasscodeId;
  readonly hint?: string;
  readonly location?: string;
  readonly timestamp: Timestamp;
}

export type EncryptionKeyId = Brand<string, 'EncryptionKeyId'>;

export interface EncryptionKey {
  readonly id: EncryptionKeyId;
  readonly secret: string;
}

export type Ciphertext<TPlaintext> = Brand<Uint8Array, 'Ciphertext' & TPlaintext>;
export type PlaintextOf<TCiphertext extends Ciphertext<any>> = TCiphertext extends Ciphertext<infer TPlaintext>
  ? TPlaintext
  : never;

export interface Cryptography {
  encrypt<TPlaintext>(key: EncryptionKey, plaintext: TPlaintext): Promise<Ciphertext<TPlaintext>>;
  decrypt<TChiphertext extends Ciphertext<any>>(
    key: EncryptionKey,
    ciphertext: TChiphertext
  ): Promise<Result<InvalidEncryptionKeyError, PlaintextOf<TChiphertext>>>;
  createKey(passcode?: Passcode): Promise<EncryptionKey>;
}
