import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useBoolean,
} from '@chakra-ui/react';
import React from 'react';
import { useCallback, useRef, useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { useKeyDownCallback, useTextSecondary } from '../../../shared/hooks';
import { wait } from '../../../shared/data/utils';
import { useDataService } from '../../../shared/components/DataServiceContext';
import { useSyncedOnce } from '../../../shared/components/DataServiceStateContext';
import { withLazy } from '../../../shared/components/hoc/withLazy';

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

function WebResumeSyncDialog({ isOpen, onClose }: Props) {
  const [passcode, setPasscode] = useState('');
  const [isPasscodeInvalid, setIsPasscodeInvlid] = useBoolean();

  const passcodeInputRef = useRef<HTMLInputElement>(null);

  const handleClose = useCallback(() => {
    setPasscode('');
    setIsPasscodeInvlid.off();
    onClose();
  }, [setIsPasscodeInvlid, onClose]);

  const handleConfirmPasscodeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPasscode(e.target.value);
    setIsPasscodeInvlid.off();
  }, [setIsPasscodeInvlid]);

  const dataService = useDataService();
  const resume = useCallback(async () => {
    const result = await dataService.unblock(passcode);
    if (result.isSuccess()) {
      handleClose();
    } else {
      setIsPasscodeInvlid.on();
      wait(0).then(() => passcodeInputRef.current?.focus());
    }
  }, [dataService, passcode, handleClose, setIsPasscodeInvlid]);

  const handleKeyDown = useKeyDownCallback({
    onEnter: resume,
    onEscape: handleClose,
  });

  const syncedOnce = useSyncedOnce();

  const [showPasscode, setShowPasscode] = useBoolean();
  const textSecondary = useTextSecondary();

  return (
    <Modal
      isOpen={isOpen}
      initialFocusRef={passcodeInputRef}
      onClose={handleClose}
    >
      <ModalOverlay>
        <ModalContent mx={[4, null]}>
          <ModalBody pt={6}>
            <FormControl isInvalid={isPasscodeInvalid}>
              <FormLabel>Passcode</FormLabel>
              <InputGroup>
                <Input
                  ref={passcodeInputRef}
                  isInvalid={isPasscodeInvalid}
                  type={showPasscode ? 'text' : 'password'}
                  placeholder="Enter passcode..."
                  value={passcode}
                  onChange={handleConfirmPasscodeChange}
                  onKeyDown={handleKeyDown}
                />
                <InputRightElement>
                  <IconButton
                    tabIndex={-1}
                    variant="ghost"
                    onClick={setShowPasscode.toggle}
                    icon={showPasscode ? <FiEyeOff /> : <FiEye />}
                    aria-label="toggle passcode visibility"
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>Passcode doesn't match</FormErrorMessage>
            </FormControl>
            <Box fontSize="sm" color={textSecondary} mt={2}>
              {syncedOnce ? 'Your passcode was changed on a different device' : 'Enter the passcode to sync your journals'}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              disabled={isPasscodeInvalid || passcode === ''}
              onClick={resume}
              ml={3}
            >
              {syncedOnce ? 'Resume' : 'Turn on'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export default React.memo(withLazy(WebResumeSyncDialog, 200));
