import { Observable } from 'rxjs';

import { DeltaOperation } from './delta';
import { Entry, EntryId } from './entities/entry';
import { Icon, Journal, JournalId, EntryTemplate } from './entities/journal';
import { ImageMedia, Media, MediaId } from './entities/media';
import { ColorTheme, Settings } from './entities/settings';
import { StyledBackground, StyledEntity, StyledFont } from './entities/styled';
import { Duration, TimeRange, Timestamp } from './timestamp';

export interface TransactionDiff {
  readonly entryIds: readonly EntryId[];
  readonly journalIds: readonly JournalId[];
  readonly mediaIds: readonly MediaId[];
  readonly settings: boolean;
}

export function diffOverlaps({ entryIds, journalIds, mediaIds, settings }: TransactionDiff, filter: Partial<TransactionDiff>): boolean {
  return (
    (filter.settings === undefined || filter.settings === settings) &&
    (filter.entryIds === undefined || filter.entryIds.some(entryId => entryIds.includes(entryId))) &&
    (filter.journalIds === undefined || filter.journalIds.some(journalId => journalIds.includes(journalId))) &&
    (filter.mediaIds === undefined || filter.mediaIds.some(mediaId => mediaIds.includes(mediaId)))
  );
}

export function diffEqual(a: TransactionDiff, b: TransactionDiff): boolean {
  return a.entryIds.length === b.entryIds.length &&
    a.journalIds.length === b.journalIds.length &&
    a.mediaIds.length === b.mediaIds.length &&
    a.settings === b.settings &&
    a.entryIds.every(id => b.entryIds.includes(id)) &&
    a.journalIds.every(id => b.journalIds.includes(id)) &&
    a.mediaIds.every(id => b.mediaIds.includes(id));
}

export interface Cabinet {
  entry(entryId: EntryId): Entry | undefined;
  entries(): Entry[];

  journal(journalId: JournalId): Journal | undefined;
  journals(): Journal[];

  media(mediaId: MediaId): Media | undefined;
  media(): Media[];

  settings(): Settings;

  view(timestamp: Timestamp, diff: Partial<TransactionDiff>): Cabinet;
  history(diff: Partial<TransactionDiff>): TimeRange[];

  on(filter: Partial<TransactionDiff>): Observable<TransactionDiff>;
}

export type SettingsCabinetAction =
  | { readonly type: 'settings/init' }
  | { readonly type: 'settings/set_show_editor_help_web', value: boolean }
  | { readonly type: 'settings/set_sync_theme', value: boolean }
  | { readonly type: 'settings/set_theme', theme: ColorTheme }
  | { readonly type: 'settings/set_name', name: string }
  | { readonly type: 'settings/set_auto_lock', value: Duration | undefined };

export type JournalCabinetAction =
  | { readonly type: 'journal/put', readonly journal: Journal }
  | { readonly type: 'journal/delete', readonly journalId: JournalId }
  | { readonly type: 'journal/set_name', readonly journalId: JournalId, readonly name: string }
  | { readonly type: 'journal/set_icon', readonly journalId: JournalId, readonly icon: Icon | undefined }
  | { readonly type: 'journal/set_entry_style', readonly journalId: JournalId, readonly style: StyledEntity }
  | { readonly type: 'journal/set_entry_template', readonly journalId: JournalId, readonly template: EntryTemplate | undefined }
  | { readonly type: 'journal/set_order', readonly journalId: JournalId, readonly order: number };

export type EntryCabinetAction =
  | { readonly type: 'entry/put', readonly entry: Entry }
  | { readonly type: 'entry/delete', readonly entryId: EntryId }
  | { readonly type: 'entry/set_bookmarked', readonly entryId: EntryId, readonly value: boolean }
  | { readonly type: 'entry/set_timestamp', readonly entryId: EntryId, readonly timestamp: Timestamp }
  | { readonly type: 'entry/edit_body', readonly entryId: EntryId, readonly delta: readonly DeltaOperation[] }
  | { readonly type: 'entry/set_cover', readonly entryId: EntryId, readonly cover: StyledBackground | undefined }
  | { readonly type: 'entry/set_background', readonly entryId: EntryId, readonly background: StyledBackground | undefined }
  | { readonly type: 'entry/set_pad', readonly entryId: EntryId, readonly pad: StyledBackground | undefined }
  | { readonly type: 'entry/set_title_font', readonly entryId: EntryId, readonly font: StyledFont | undefined }
  | { readonly type: 'entry/set_text_font', readonly entryId: EntryId, readonly font: StyledFont | undefined };

export type MediaCabinetAction = never;

export type ImageCabinetAction =
  | { readonly type: 'image/put', readonly media: ImageMedia }
  | { readonly type: 'image/delete', readonly mediaId: MediaId };

export type CabinetAction =
  | JournalCabinetAction
  | EntryCabinetAction
  | MediaCabinetAction
  | ImageCabinetAction
  | SettingsCabinetAction;
