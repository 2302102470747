import { Box, Flex, IconButton, Kbd, Portal, SimpleGrid, Spacer, useBoolean, useColorModeValue } from '@chakra-ui/react';
import React, { ReactNode, useCallback, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { FiCamera, FiChevronLeft, FiChevronRight, FiX } from 'react-icons/fi';

import { useTextSecondary } from '../../shared/hooks';
import { useDataService } from '../../shared/components/DataServiceContext';
import { useCabinet } from '../../shared/components/DataServiceStateContext';
import WebHelpFabButton from './WebHelpFabButton';

interface Slide {
  readonly title: ReactNode;
  readonly content: () => ReactNode;
}

const SLIDES: readonly Slide[] = [
  {
    title: 'Select text to change formatting, add headers, or create links',
    content: () => (
      <img style={{ maxWidth: '60%' }} src="/toolbar-help.png" alt="Toolbar" />
    )
  },
  {
    // title: 'Add images and other media by starting a new line and clicking <image_here>',
    title: (
      <Flex justifyContent="center" alignItems="center">
        <Box mr={2} pb={1}>Add images by starting a new line and clicking</Box>
        <FiCamera />
      </Flex>
    ),
    content: () => (
      <img style={{ maxWidth: '60%', paddingRight: 30 }} src="/media-help.png" alt="Media upload" />
    )
  },
  {
    title: 'You can add a tag to your entry by typing # and then its name',
    content: () => (
      <img style={{ maxWidth: '60%' }} src="/tag-help.png" alt="Tag" />
    )
  },
  {
    title: 'Keyboard shortcuts',
    content: () => (
      <Flex>
        <SimpleGrid mr={4} columns={2} spacing={4}>
          <div>Bigger header or title</div>
          <div>
            <Kbd>Ctrl</Kbd> + <Kbd>Alt</Kbd> + <Kbd>1</Kbd>
          </div>

          <div>Smaller header or title</div>
          <div>
            <Kbd>Ctrl</Kbd> + <Kbd>Alt</Kbd> + <Kbd>2</Kbd>
          </div>

          <div>Quote</div>
          <div>
            <Kbd>Ctrl</Kbd> + <Kbd>Alt</Kbd> + <Kbd>5</Kbd>
          </div>

          <div >Bullet list</div>
          <div>
            <Kbd>*</Kbd> <Kbd>Space</Kbd>
          </div>

          <div>Ordered  list</div>
          <div>
            <Kbd>1</Kbd> <Kbd>.</Kbd> <Kbd>Space</Kbd>
          </div>
        </SimpleGrid>

        <SimpleGrid columns={2} spacing={3}>
          <div><b>Bold</b></div>
          <div>
            <Kbd>Ctrl</Kbd> + <Kbd>B</Kbd>
          </div>

          <div><i>Italic</i></div>
          <div>
            <Kbd>Ctrl</Kbd> + <Kbd>I</Kbd>
          </div>

          <div>&nbsp;</div><Box />

          <div>Undo</div>
          <div>
            <Kbd>Ctrl</Kbd> + <Kbd>Z</Kbd>
          </div>

          <div>Redo</div>
          <div>
            <Kbd>Ctrl</Kbd> + <Kbd>Shift</Kbd> + <Kbd>Z</Kbd>
          </div>
        </SimpleGrid>
      </Flex>
    )
  },
]

function WebEntryHelpFab() {
  const cabinet = useCabinet();
  const [isHelpOpen, setIsHelpOpen] = useBoolean(() => cabinet.settings().showEditorHelpWeb);

  const bg = useColorModeValue('white', '#242C3A'); // gray.750

  const [index, setIndex] = useState(0);
  const next = useCallback(() => setIndex(x => (x + 1) % SLIDES.length), []);
  const prev = useCallback(() => setIndex(x => (x - 1 + SLIDES.length) % SLIDES.length), []);

  const textSecondary = useTextSecondary();

  const dataService = useDataService();
  const hideHelp = useCallback(() => {
    if (cabinet.settings().showEditorHelpWeb) {
      dataService.execute({ type: 'settings/set_show_editor_help_web', value: false });
    }

    setIsHelpOpen.off();
  }, [cabinet, dataService, setIsHelpOpen]);

  useHotkeys('esc', hideHelp, [hideHelp]);

  return (
    <Portal>
      {isHelpOpen && (
        <Box zIndex="1" left="0" right="0" bottom="0" position="fixed">
          <Flex
            alignItems="center"
            minH="350px"
            bg={bg}
            w={['100%', '90%', '80%', '70%']}
            mx="auto"
            boxShadow="0 6px 20px 0 rgb(0 0 0 / 6%), 0 2px 6px 0 rgb(0 0 0 / 6%)"
            // boxShadow="xl"
            position="relative"
          >
            <IconButton
              icon={<FiX />}
              color={textSecondary}
              position="absolute"
              top="5px" right="5px"
              size="sm"
              fontSize="xl"
              aria-label="close"
              variant="ghost"
              onClick={hideHelp}
            />
            <IconButton
              color={textSecondary}
              icon={<FiChevronLeft />}
              aria-label="previous"
              variant="ghost"
              size="lg"
              fontSize="3xl"
              borderRadius="xl"
              ml={6}
              onClick={prev}
            />
            <Spacer />
            {SLIDES.map((slide, slideIndex) => (
              <Box px={6} py={10} key={slideIndex} display={slideIndex === index ? undefined : 'none'}>
                <Box textAlign="center" fontSize="2xl" mb={6}>
                  {slide.title}
                </Box>
                <Flex justifyContent="center">
                  {slide.content()}
                </Flex>
              </Box>
            ))}
            <Spacer />
            <IconButton
              color={textSecondary}
              icon={<FiChevronRight />}
              aria-label="next"
              variant="ghost"
              size="lg"
              fontSize="3xl"
              borderRadius="xl"
              mr={6}
              onClick={next}
            />
          </Flex>
        </Box>
      )}
      <WebHelpFabButton zIndex="1" onClick={isHelpOpen ? hideHelp : setIsHelpOpen.on} />
    </Portal>
  );
}

export default React.memo(WebEntryHelpFab);
