import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { useCallback, useRef } from 'react';

import { withLazy } from '../../../shared/components/hoc/withLazy';
import { ApiClient } from '../../../shared/services/ApiClient';
import { AuthService } from '../../../shared/services/AuthService';

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

function WebDeleteAccountDialog({isOpen, onClose}: Props) {
  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleDelete = useCallback(async () => {
    await ApiClient.deleteAccount();
    // todo: RemoteCloud shouldn't be allowed to change a user in the middle
    // todo: clear all local databases before logging out out
    AuthService.logOut(false);
    window.location.href = 'https://www.journalify.com/';
    onClose();
  }, [onClose]);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent mx={[4, null]}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete account
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to delete your account?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="ghost" ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDelete}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default React.memo(withLazy(WebDeleteAccountDialog, 200));
