import { Box } from '@chakra-ui/react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { useDataService } from '../../shared/components/DataServiceContext';
import { withJournal } from '../../shared/components/hoc/withJournal';
import { Journal } from '../../shared/data/data-types/entities/journal';
import { useKeyDownCallback } from '../../shared/hooks';
import WebInlineInput from './WebInlineInput';

interface Props {
  readonly journal: Journal;
  readonly fontSize: number;
  readonly fontWeight: 'bold' | 'normal';
}

function WebJournalNameInput({ journal, fontSize, fontWeight }: Props) {
  const [journalName, setJournalName] = useState(journal.name);

  useEffect(() => setJournalName(journal.name), [journal.name]);

  const dataService = useDataService();
  const updateJournalName = useCallback(async (name: string) => {
    setJournalName(name);
    await dataService.execute({ type: 'journal/set_name', journalId: journal.id, name });
  }, [journal, dataService]);

  const inputRef = useRef<HTMLInputElement>(null);
  const handleKeyDown = useKeyDownCallback({
    onEscape: useCallback(() => inputRef.current?.blur(), []),
    onEnter: useCallback(() => inputRef.current?.blur(), []),
  });

  return (
    <Box maxW="100%" overflow="hidden">
      <WebInlineInput
        ref={inputRef}
        value={journalName}
        fontSize={fontSize}
        fontWeight={fontWeight}
        onKeyDown={handleKeyDown}
        onChange={e => updateJournalName(e.target.value)}
      />
    </Box>
  )
}

export default memo(withJournal(WebJournalNameInput));
