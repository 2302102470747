import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { useDataService } from '../../../shared/components/DataServiceContext';
import { withLazy } from '../../../shared/components/hoc/withLazy';
import { EntryTemplate, Icon, JournalId } from '../../../shared/data/data-types/entities/journal';
import { StyledEntity } from '../../../shared/data/data-types/entities/styled';
import { getTimestamp } from '../../../shared/data/data-types/timestamp';
import { useJournals, useKeyDownCallback } from '../../../shared/hooks';
import { calcOrderBetween, generateId } from '../../../shared/utils';
import WebIconPickerButton from '../WebIconPickerButton';
import WebJournalEntryStylePickerButton from '../WebJournalEntryStylePickerButton';
import WebModal from '../WebModal';
import WebTextTemplatePickerButton from '../WebTextTemplatePickerButton';

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

function WebAddJournalDialog({ isOpen, onClose }: Props) {
  const initialRef = useRef<HTMLInputElement>(null);

  const [journalName, setJournalName] = useState("");
  const [icon, setIcon] = useState<Icon | undefined>();
  const [entryTemplate, setEntryTemplate] = useState<EntryTemplate | undefined>();
  const [entryStyle, setEntryStyle] = useState<StyledEntity>({
    background: undefined,
    cover: { type: "gray" },
    pad: undefined,
    textFont: undefined,
    titleFont: undefined,
  });

  const history = useHistory();
  const dataService = useDataService();

  const journals = useJournals();

  const handleCreate = useCallback(async () => {
    const journalId = generateId() as JournalId;
    await dataService.execute({
      type: "journal/put",
      journal: {
        id: journalId,
        name: journalName,
        createdAt: getTimestamp(),
        modifiedAt: getTimestamp(),
        icon,
        order: calcOrderBetween(
          journals[journals.length - 1]?.order ?? Number.MIN_VALUE,
          Number.MAX_VALUE
        ),
        entryStyle,
        entryTemplate,
      },
    });
    history.push(`/journals/${journalId}/timeline`);
    onClose();
  }, [dataService, journalName, icon, journals, entryStyle, entryTemplate, history, onClose]);

  const handleKeyDown = useKeyDownCallback({
    onEnter: useCallback(() => {
      if (journalName.length > 0) {
        handleCreate();
      }
    }, [handleCreate, journalName]),
  });

  return (
    <WebModal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
      title="Add journal"
      action="Add"
      onAction={handleCreate}
    >
      <FormControl>
        <FormLabel>Name</FormLabel>
        <Input
          onKeyDown={handleKeyDown}
          ref={initialRef}
          placeholder="Enter journal name..."
          value={journalName}
          onChange={(x) => setJournalName(x.target.value)}
        />
      </FormControl>

      <FormControl mt={3}>
        <FormLabel>Icon</FormLabel>
        <WebIconPickerButton value={icon} onChange={setIcon} />
      </FormControl>

      <FormControl mt={3}>
        <FormLabel>Entry style</FormLabel>
        <WebJournalEntryStylePickerButton
          value={entryStyle}
          onChange={setEntryStyle}
        />
      </FormControl>

      <FormControl mt={3}>
        <FormLabel>Entry template</FormLabel>
        <WebTextTemplatePickerButton value={entryTemplate} onChange={setEntryTemplate} />
      </FormControl>
    </WebModal>
  );
}

export default React.memo(withLazy(WebAddJournalDialog, 200));
