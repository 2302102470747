import { Popover, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { memo, ReactNode } from 'react';

import WebScrollBox from './WebScrollBox';

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly trigger: ReactNode;
  readonly children: ReactNode;
  readonly placement?: 'bottom-start';
  readonly w?: string;
}

function WebScrollableMenu({ w, placement, trigger, isOpen, onClose, children }: Props) {
  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      placement={placement}
      isLazy
    >
      <PopoverTrigger>
        {trigger}
      </PopoverTrigger>
      <PopoverContent w={w} boxShadow="none" _focus={{ boxShadow: 'none' }}>
        <PopoverBody px={0} py={2} sx={{
          '& button': {
            justifyContent: 'start',
            fontWeight: 'normal',
            width: '100%',
            borderRadius: 0,
            '&:focus': { boxShadow: 'none' },
          },
        }}>
          <WebScrollBox maxH="var(--vh-50)">
            {children}
          </WebScrollBox>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default memo(WebScrollableMenu);
