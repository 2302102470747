import { Box, Button, Flex, HStack, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';

import { withLazy } from '../../../shared/components/hoc/withLazy';
import { EntryId } from '../../../shared/data/data-types/entities/entry';
import { JournalId } from '../../../shared/data/data-types/entities/journal';
import { useHighlight } from '../../../shared/hooks';
import { useEntryHistory } from '../../../shared/hooks/useEntryHistory';
import WebEntryEditor from '../editor/WebEntryEditor';
import WebHistoryNavigation from '../editor/WebHistoryNavigation';
import WebScrollBox from '../WebScrollBox';

interface Props {
  readonly entryId: EntryId;
  readonly journalId: JournalId;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

function WebEntryHistoryDialog({ entryId, journalId, isOpen, onClose }: Props) {
  const { history, restore } = useEntryHistory(entryId);

  const [selected, setSelected] = useState(0);

  const highlightColor = useHighlight();

  const handleRestore = useCallback(() => {
    restore(history[selected][1]);
    onClose();
  }, [history, onClose, restore, selected]);

  return (
    <Modal
      isOpen={isOpen}
      size="6xl"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent h="var(--vh-80)">
        <ModalBody p={0}>
          <Flex>
            <Box minW={0} flex={1} display={['none', 'block']} overflow="hidden" bg="bg-main">
              <WebEntryEditor
                key={selected}
                readOnly
                h="var(--vh-80)"
                entryId={entryId}
                journalId={journalId}
                timestamp={history[selected][1]}
                topBar={
                  <Box bg="bg-main">
                    <WebHistoryNavigation
                      hasNext={history.length - 1 > selected}
                      hasPrev={selected > 0}
                      onNext={() => setSelected(selected + 1)}
                      onPrev={() => setSelected(selected - 1)}
                      timestamp={history[selected][1]}
                    />
                  </Box>
                }
              />
            </Box>
            <Box flex={[1, 0]} w={[null, '280px']} borderLeft="1px solid" borderLeftColor={highlightColor}>
              <WebScrollBox h="calc(var(--vh-80) - 56px)">
                <div>
                  {history.map(([, to], index) => (
                    <Box key={index}>
                      <Button
                        bg={index === selected ? highlightColor : undefined}
                        borderRadius={0}
                        w="100%"
                        variant="ghost"
                        fontWeight="normal"
                        onClick={() => setSelected(index)}
                        justifyContent="start"
                      >
                        {format(new Date(to), 'PPP p')}
                      </Button>
                    </Box>
                  ))}
                </div>
              </WebScrollBox>
              <Box h="56px" p={3} borderTop="1px solid" borderTopColor={highlightColor}>
                <HStack spacing={2}>
                  <Button size="sm" colorScheme="blue" onClick={handleRestore}>
                    Restore version
                  </Button>
                  <Button size="sm" variant="ghost" onClick={onClose}>
                    Cancel
                  </Button>
                </HStack>
              </Box>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(withLazy(WebEntryHistoryDialog, 200));
