import { Box, Button, HStack, Link, Select, useBoolean } from '@chakra-ui/react';
import { Fragment, memo, useCallback } from 'react';

import { useDataService } from '../../../shared/components/DataServiceContext';
import { usePasscode } from '../../../shared/components/DataServiceStateContext';
import { AUTO_LOCK_OPTIONS } from '../../../shared/constants';
import { useSettings, useTextSecondary } from '../../../shared/hooks';
import WebChangePasscodeDialog from '../dialogs/WebChangePasscodeDialog';
import WebTurnOffPasscodeLockDialog from '../dialogs/WebTurnOffPasscodeLockDialog';
import WebTurnOnPasscodeLockDialog from '../dialogs/WebTurnOnPasscodeLockDialog';
import WebSettingsSection from './WebSettingsSection';

function WebPasscodeSettings() {
  const passcode = usePasscode();

  const textSecondary = useTextSecondary();
  const [isTurnOnPrivacyLockOpen, setIsTurnOnPrivacyLockOpen] = useBoolean();
  const [isTurnOffPrivacyLockOpen, setIsTurnOffPrivacyLockOpen] = useBoolean();
  const [isChangePasscodeOpen, setIsChangePasscodeOpen] = useBoolean();

  const settings = useSettings();

  const dataService = useDataService();
  const setAutoLock = useCallback((value: string | undefined) => {
    dataService.execute({
      type: 'settings/set_auto_lock',
      value: AUTO_LOCK_OPTIONS.find(x => x.value === value)?.duration,
    })
  }, [dataService]);

  return (
    <div>
      <WebSettingsSection title="Passcode Lock">
        {passcode !== undefined && (
          <Fragment>
            <Box mb={2} fontSize="sm" color={textSecondary}>
              Your journals are end-to-end encrypted. Even we can't access them.
              <Link
                ml={1}
                color={textSecondary}
                textDecor="underline"
                _hover={{ textDecor: 'none' }}
                target="_blank"
                href="https://en.wikipedia.org/wiki/End-to-end_encryption"
                rel="noreferrer"
              >
                Learn more
              </Link>
            </Box>
            <HStack mb={4}>
              <Button variant="outline" size="sm" onClick={setIsChangePasscodeOpen.on}>
                Change passcode
              </Button>
              <Button colorScheme="red" variant="outline" size="sm" onClick={setIsTurnOffPrivacyLockOpen.on}>
                Turn off
              </Button>
            </HStack>

            <WebSettingsSection title="Auto-Lock">
              <Select
                value={AUTO_LOCK_OPTIONS.find(x => x.duration === settings.autoLock)?.value}
                onChange={e => setAutoLock(e.target.value)}
                size="sm"
                maxW="300px"
              >
                {AUTO_LOCK_OPTIONS.map(x => <option key={x.value} value={x.value}>{x.title}</option>)}
              </Select>
            </WebSettingsSection>
          </Fragment>
        )}

        {passcode === undefined && (
          <Fragment>
            <Box mb={2} fontSize="sm" color={textSecondary}>
              When you set up a passcode, all your journals will be end-to-end encrypted. Even we will not have access to them.
              <Link
                ml={1}
                color={textSecondary}
                textDecor="underline"
                _hover={{ textDecor: 'none' }}
                target="_blank"
                href="https://en.wikipedia.org/wiki/End-to-end_encryption"
                rel="noreferrer"
              >
                Learn more
              </Link>
            </Box>
            <Button variant="outline" size="sm" onClick={setIsTurnOnPrivacyLockOpen.on}>
              Turn passcode on
            </Button>
          </Fragment>
        )}
      </WebSettingsSection>

      <WebTurnOnPasscodeLockDialog isOpen={isTurnOnPrivacyLockOpen} onClose={setIsTurnOnPrivacyLockOpen.off} />
      <WebTurnOffPasscodeLockDialog isOpen={isTurnOffPrivacyLockOpen} onClose={setIsTurnOffPrivacyLockOpen.off} />
      <WebChangePasscodeDialog isOpen={isChangePasscodeOpen} onClose={setIsChangePasscodeOpen.off} />
    </div>
  )
}

export default memo(WebPasscodeSettings);
