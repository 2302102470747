import { Box, Button, useBoolean } from '@chakra-ui/react';
import React from 'react';
import { useCallback } from 'react';
import { match } from 'ts-pattern';

import { Icon } from '../../shared/data/data-types/entities/journal';
import { useTextOptional, useTextSecondary } from '../../shared/hooks';
import { getEmojiName } from '../../shared/utils';
import WebIconPickerPopover from './WebIconPickerPopover';
import IconView from '../../shared/components/IconView';

interface Props {
  readonly value: Icon | undefined;
  readonly onChange: (icon: Icon | undefined) => void;
}

function WebIconPickerButton({ value, onChange }: Props) {
  const [isIconPickerOpen, setIsIconPickerOpen] = useBoolean();

  const handleIconChange = useCallback((newIcon: Icon | undefined) => {
    onChange(newIcon);
    setIsIconPickerOpen.off();
  }, [onChange, setIsIconPickerOpen]);

  const textOptional = useTextOptional();
  const textSecondary = useTextSecondary();

  const selectIconText = match(value ?? { type: 'unknown' as const })
    .with({ type: 'emoji' }, ({ unicode }) => getEmojiName(unicode))
    .otherwise(() => 'Select journal icon...');

  return (
    <WebIconPickerPopover
      isOpen={isIconPickerOpen}
      onClose={setIsIconPickerOpen.off}
      value={value}
      onChange={handleIconChange}
    >
      <Button
        leftIcon={<IconView icon={value} />}
        aria-label="Icon picker"
        onClick={setIsIconPickerOpen.toggle}
        variant="outline"
        fontWeight="normal"
        w="100%"
      >
        <Box color={value === undefined ? textOptional : textSecondary} textAlign="left" w="100%">
          {selectIconText}
        </Box>
      </Button>
    </WebIconPickerPopover>
  )
}

export default React.memo(WebIconPickerButton);
