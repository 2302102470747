import { Alert, AlertIcon, Button, Spacer, useBoolean, useColorModeValue } from '@chakra-ui/react';
import React, { Fragment } from 'react';

import { useObstruction, useSyncedOnce } from '../../shared/components/DataServiceStateContext';
import WebResumeSyncDialog from './dialogs/WebResumeSyncDialog';

function WebSyncObstructionAlert() {
  const obstruction = useObstruction();
  const syncedOnce = useSyncedOnce();
  const [isResumeSyncDialogOpen, setIsResumeSyncDialogOpen] = useBoolean();

  const btnHoverBg = useColorModeValue('blackAlpha.200', 'whiteAlpha.200');
  const btnActiveBg = useColorModeValue('blackAlpha.400', 'whiteAlpha.400');

  if (obstruction === undefined || !syncedOnce) {
    return <Fragment />
  }

  return (
    <Alert
      zIndex="sticky"
      alignItems="center"
      position="fixed"
      display="flex"
      bottom={0}
      right={0}
      left={0}
      py={2}
      status='warning'
    >
      <Spacer />
      <AlertIcon />
      <div>
        Offline mode
      </div>
      <Button
        variant="ghost"
        onClick={setIsResumeSyncDialogOpen.on}
        size="sm"
        fontSize="md"
        ml={2}
        py={2}
        _hover={{ background: btnHoverBg }}
        _active={{ background: btnActiveBg }}
      >
        {'Resume sync'}
      </Button>
      <Spacer />
      <WebResumeSyncDialog isOpen={isResumeSyncDialogOpen} onClose={setIsResumeSyncDialogOpen.off} />
    </Alert>
  )
}

export default React.memo(WebSyncObstructionAlert);
