import { Component, memo, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { useScrollBox } from './WebScrollBox';

type ComponentProps<T extends Component<any>> = T extends Component<infer Props> ? Props : never;

type Props = Omit<ComponentProps<InfiniteScroll>, 'getScrollParent'>;

function WebInfiniteScrollBox(props: Props) {
  const scrollBox = useScrollBox();
  const getScrollParent = useCallback(() => scrollBox ?? null, [scrollBox]);

  return (
    <InfiniteScroll {...props} getScrollParent={getScrollParent} />
  );
}

export default memo(WebInfiniteScrollBox);
