import 'react-day-picker/lib/style.css';

import { Box, Input, Popover, PopoverContent, PopoverTrigger, useBoolean } from '@chakra-ui/react';
import { format, parse } from 'date-fns';
import { ReactNode, useCallback, useState } from 'react';
import React from 'react';
import DayPicker from 'react-day-picker';

import { useRed, useTextPrimary } from '../../shared/hooks';

interface Props {
  readonly value: Date | undefined;
  readonly onChange: (date: Date) => void;
  readonly children: ReactNode;
  readonly readOnly?: boolean;
}

function formatDate(date: Date) {
  return format(date, 'PP, p');
}

function WebDatePicker({ readOnly, value, onChange, children }: Props) {
  const textPrimary = useTextPrimary();
  const red = useRed();

  const [isDateStringInvalid, setIsDateStringInvalid] = useBoolean();

  const changeDate = useCallback((date: Date) => {
    onChange(date);
    setDateString(formatDate(date));
    setIsDateStringInvalid.off();
  }, [onChange, setIsDateStringInvalid]);

  const [dateString, setDateString] = useState(formatDate(value ?? new Date()));
  const handleDateStringBlur = useCallback(() => {
    const newDate = parse(dateString, 'PP, p', new Date());
    if (isNaN(newDate.getTime())) {
      setIsDateStringInvalid.on();
      return;
    }

    changeDate(newDate);
    setMonth(newDate);
  }, [dateString, changeDate, setIsDateStringInvalid]);

  const handleDateStringFocus = useCallback(() => {
    setMonth(value ?? new Date());
  }, [value]);

  const changeDateWithoutTime = useCallback((date: Date) => {
    const newDate = new Date(date.getTime());
    if (value !== undefined) {
      newDate.setHours(value.getHours());
      newDate.setMinutes(value.getMinutes());
      newDate.setSeconds(value.getSeconds());
      newDate.setMilliseconds(value.getMilliseconds());
    }
    changeDate(newDate);
  }, [changeDate, value]);

  const [month, setMonth] = useState(value ?? new Date());

  return (
    <Popover isOpen={readOnly === true ? false : undefined}>
      <PopoverTrigger>
        {children}
      </PopoverTrigger>
      <PopoverContent boxShadow="md" borderRadius="sm" sx={{
        '.DayPicker-Day--today': {
          color: red,
        },
        '.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
          backgroundColor: 'blue.300',
          color: 'white',
        },
        '.DayPicker-Day': {
          padding: '6px 12px',
          borderRadius: 'md',
        },
        '.DayPicker-Day:not(.DayPicker-Day--today), .DayPicker-Caption': {
          color: textPrimary,
        },
        '.DayPicker-Weekday, .DayPicker-Day.DayPicker-Day--outside': {
          color: 'gray.400',
        },
        '.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover': {
          backgroundColor: 'rgba(99,179,237, 0.3)'
        }
      }}>
        <Box pt={4} px={4}>
          <Input
            variant="filled"
            value={dateString}
            onChange={e => setDateString(e.target.value)}
            onBlur={handleDateStringBlur}
            onFocus={handleDateStringFocus}
            isInvalid={isDateStringInvalid}
          />
        </Box>
        <DayPicker
          showOutsideDays
          month={month}
          onMonthChange={setMonth}
          selectedDays={value}
          onDayClick={changeDateWithoutTime}
        />
      </PopoverContent>
    </Popover>
  );
}

export default React.memo(WebDatePicker);
