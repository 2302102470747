import { useColorMode } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { useActiveTheme } from '../../shared/hooks';

function ColorModeController()  {
  const { setColorMode } = useColorMode();

  const activeTheme = useActiveTheme();

  useEffect(() => setColorMode(activeTheme), [setColorMode, activeTheme]);

  return <React.Fragment />
}

export default React.memo(ColorModeController);
