import React from 'react';

import { Journal } from '../data/data-types/entities/journal';
import { withJournal } from './hoc/withJournal';
import IconView from './IconView';

interface Props {
  readonly journal: Journal;
}

function JournalIcon({ journal }: Props) {
  return <IconView icon={journal.icon} />;
}

export default React.memo(withJournal(JournalIcon));
