import { AspectRatio, Box, Button, Flex, Spacer, useBoolean } from '@chakra-ui/react';
import { format } from 'date-fns';
import { Fragment, memo } from 'react';
import { match } from 'ts-pattern';

import { ImageMedia, Media } from '../../../shared/data/data-types/entities/media';
import { useGrayBg, useMedia } from '../../../shared/hooks';
import WebDeleteImageDialog from '../dialogs/WebDeleteImageDialog';
import WebAppFigure from '../WebAppFigure';

function WebMediaSettings() {
  const media = useMedia();

  function renderRow(item: Media) {
    return match(item)
      .with({ type: 'image' }, (image) => <ImageRow image={image} />)
      .otherwise(() => <Fragment />);
  }

  const grayBg = useGrayBg();

  return (
    <div>
      {media.map(item => (
        <Box px={2} ml={-2} py={2} _hover={{ backgroundColor: grayBg }} transition="background-color 0.2s" key={item.id}>
          {renderRow(item)}
        </Box>
      ))}
      {media.length === 0 && (
        <Box textAlign="center" py={16} px={2}>
          <Box fontWeight="semibold" fontSize="lg" color="text-secondary">
            No media
          </Box>
          <Box fontSize="md" color="text-optional">
            Upload images to see them here
          </Box>
        </Box>
      )}
    </div>
  );
}

export default memo(WebMediaSettings);

interface ImageRowProps {
  readonly image: ImageMedia;
}

function ImageRow({ image }: ImageRowProps) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useBoolean();

  return (
    <Flex alignItems="center" gap={4}>
      <AspectRatio ratio={1} w="50px">
        <WebAppFigure media={image} />
      </AspectRatio>
      <Box>
        {format(new Date(image.createdAt), 'p, PPP')}
      </Box>
      <Spacer />
      <Button variant="link" fontWeight="normal" colorScheme="blue" onClick={setIsDeleteDialogOpen.on}>
        Delete
      </Button>

      <WebDeleteImageDialog image={image} isOpen={isDeleteDialogOpen} onClose={setIsDeleteDialogOpen.off} />
    </Flex>
  )
}
