import { CabinetCRDT, Transaction, TransactionBatch } from './cabinet-crdt';
import { diffEqual, TransactionDiff } from './data-types/cabinet';
import { bytesCount } from './encoding';

export interface Aggregate<T> {
  readonly value: T;
  readonly source: {
    readonly offset: number;
    readonly length: number;
  }
}

// DynamoDB document size limit is 400KB
const BATCH_SIZE_LIMIT = 64 * 1024;

export function aggregate(
  transactions: ReadonlyArray<TransactionBatch>,
): ReadonlyArray<Aggregate<TransactionBatch>> {
  const result: Array<Aggregate<TransactionBatch>> = [];
  let batch: TransactionBatch[] = [];
  let batchSize = 0;
  let batchOffset = 0;
  function flushBatch() {
    if (batch.length === 0) return;

    result.push({
      value: aggregateTransactions(batch.flat()),
      source: {
        offset: batchOffset,
        length: batch.length,
      },
    });
    batchOffset += batch.length;
    batch = [];
    batchSize = 0;
  }

  for (const item of transactions) {
    const itemSize = bytesCount(item);
    if (batchSize + itemSize > BATCH_SIZE_LIMIT) {
      flushBatch();
    }

    batch.push(item);
    batchSize += itemSize;
  }

  flushBatch();
  return result;
}

function aggregateTransactions(
  transactions: ReadonlyArray<Transaction>,
): Transaction[] {
  const result: Transaction[] = [];
  let batch: Transaction[] = [];

  let batchClientId: undefined | number = undefined;
  let batchDeviceId: undefined | string = undefined;
  let batchDiff: undefined | TransactionDiff = undefined;

  let batchMinTimestamp = 1000 * 1000 * 1000 * 1000 * 1000;
  let batchMaxTimestamp = -1;

  function flushBatch() {
    if (batch.length > 0) {
      result.push(CabinetCRDT.merge(batch));

      batch = [];
      batchMinTimestamp = 1000 * 1000 * 1000 * 1000 * 1000;
      batchMaxTimestamp = -1;
      batchClientId = undefined;
      batchDeviceId = undefined;
      batchDiff = undefined;
    }
  }

  const MERGE_WINDOW_MILLISECONDS = 5 * 60 * 1000;

  for (const transaction of transactions.map(x => x)) {
    const { timeFrame, deviceId, diff, clientId } = transaction;
    const batchItem: Transaction | undefined = batch[0];
    const [fromTimestamp, toTimestamp] = timeFrame;
    if (batchItem) {
      if (
        (batchDeviceId !== undefined && batchDeviceId !== deviceId) ||
        (batchClientId !== undefined && batchClientId !== clientId) ||
        toTimestamp - batchMinTimestamp > MERGE_WINDOW_MILLISECONDS ||
        batchMaxTimestamp - fromTimestamp > MERGE_WINDOW_MILLISECONDS ||
        (batchDiff !== undefined && !diffEqual(batchDiff, diff))
      ) {
        flushBatch();
      }
    }

    batch.push(transaction);
    batchClientId = clientId;
    batchDeviceId = deviceId;
    batchDiff = diff;
    batchMinTimestamp = Math.min(batchMinTimestamp, fromTimestamp);
    batchMaxTimestamp = Math.max(batchMaxTimestamp, toTimestamp);
  }

  flushBatch();

  return result;
}

export function aggregateDummy(transactions: readonly TransactionBatch[]): ReadonlyArray<Aggregate<TransactionBatch>> {
  return transactions.map((transaction, offset) => ({
    value: transaction,
    source: { offset, length: 1 },
  }));
}
