import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useBoolean,
} from '@chakra-ui/react';
import React from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { withLazy } from '../../../shared/components/hoc/withLazy';
import { useKeyDownCallback } from '../../../shared/hooks';
import { useChangePasscode } from '../../../shared/hooks/useChangePasscode';
import WebModal from '../WebModal';

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

function WebChangePasscodeDialog({ isOpen, onClose }: Props) {
  const {
    handleRepeatPasscodeChange,
    handleNewPasscodeChange,
    handleOldPasscodeChange,
    isRepeatPasscodeInvalid,
    isOldPasscodeInvalid,
    newPasscode,
    oldPasscode,
    repeatPasscode,
    changePasscode,
    oldPasscodeInputRef,
    repeatPasscodeInputRef,
  } = useChangePasscode({ onClose });

  const handleKeyDown = useKeyDownCallback({
    onEnter: changePasscode,
    onEscape: onClose,
  });

  const [showOldPasscode, setShowOldPasscode] = useBoolean();
  const [showNewPasscode, setShowNewPasscode] = useBoolean();
  const [showConfirmPasscode, setShowConfirmPasscode] = useBoolean();

  return (
    <WebModal
      minimal
      isOpen={isOpen}
      initialFocusRef={oldPasscodeInputRef}
      onClose={onClose}
      action={['Change', 'Change Passcode']}
      title="Passcode"
      actionDisabled={isRepeatPasscodeInvalid || oldPasscode === '' || newPasscode === '' || repeatPasscode === ''}
      onAction={changePasscode}
    >
      <FormControl mb={4} isInvalid={isOldPasscodeInvalid}>
        <FormLabel>Old passcode</FormLabel>
        <InputGroup>
          <Input
            isInvalid={isOldPasscodeInvalid}
            type={showOldPasscode ? 'text' : 'password'}
            placeholder="Enter old passcode..."
            value={oldPasscode}
            onChange={e => handleOldPasscodeChange(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <InputRightElement>
            <IconButton
              tabIndex={-1}
              variant="ghost"
              onClick={setShowOldPasscode.toggle}
              icon={showOldPasscode ? <FiEyeOff /> : <FiEye />}
              aria-label="toggle old passcode visibility"
            />
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>Passcode doesn't match</FormErrorMessage>
      </FormControl>

      <FormControl mb={2}>
        <FormLabel>New passcode</FormLabel>
        <InputGroup>
          <Input
            type={showNewPasscode ? 'text' : 'password'}
            placeholder="Enter new passcode..."
            value={newPasscode}
            onChange={e => handleNewPasscodeChange(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <InputRightElement>
            <IconButton
              tabIndex={-1}
              variant="ghost"
              onClick={setShowNewPasscode.toggle}
              icon={showNewPasscode ? <FiEyeOff /> : <FiEye />}
              aria-label="toggle new passcode visibility"
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <FormControl isInvalid={isRepeatPasscodeInvalid}>
        <FormLabel>Repeat passcode</FormLabel>
        <InputGroup>
          <Input
            ref={repeatPasscodeInputRef}
            isInvalid={isRepeatPasscodeInvalid}
            type={showConfirmPasscode ? 'text' : 'password'}
            placeholder="Repeat new passcode..."
            value={repeatPasscode}
            onChange={e => handleRepeatPasscodeChange(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <InputRightElement>
            <IconButton
              tabIndex={-1}
              variant="ghost"
              onClick={setShowConfirmPasscode.toggle}
              icon={showConfirmPasscode ? <FiEyeOff /> : <FiEye />}
              aria-label="toggle repeat passcode visibility"
            />
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>Passcode doesn't match</FormErrorMessage>
      </FormControl>
    </WebModal>
  );
}

export default React.memo(withLazy(WebChangePasscodeDialog, 200));
