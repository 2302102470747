import { Box, Flex, HStack, Spacer } from '@chakra-ui/layout';
import { Button, IconButton, MenuButton, Tooltip, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { Fragment, memo } from 'react';
import { useCallback } from 'react';
import { FiArrowLeft, FiMoreHorizontal } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';

import { useDelayed, useIsMobile } from '../../../shared/hooks';
import { EntryId } from '../../../shared/data/data-types/entities/entry';
import { JournalId } from '../../../shared/data/data-types/entities/journal';
import { useEntry } from '../../../shared/hooks';
import { getBackground, wait } from '../../../shared/utils';
import WebBookmarkIcon from '../WebBookmarkIcon';
import WebEntryMenu from '../WebEntryMenu';

interface Props {
  readonly journalId: JournalId;
  readonly entryId?: EntryId;
  readonly onBookmarkedChange: (value: boolean) => void;
  readonly title: string;
  readonly showTitle: boolean;
}

function WebEntryTopBar({ title, showTitle, journalId, entryId, onBookmarkedChange }: Props) {
  const entry = useEntry(entryId ?? ('unknown' as EntryId));

  const { colorMode, setColorMode } = useColorMode();

  const handlePrint = useCallback(() => {
    if (colorMode === 'dark') {
      setColorMode('light');
      wait(300).then(() => {
        window.print();
        setColorMode('dark');
      });
    } else {
      window.print();
    }
  }, [colorMode, setColorMode]);

  const buttonColor = useColorModeValue('gray.600', 'gray.300');

  const bookmarked = entry?.bookmarked === true;
  const bookmarkedWithDelay = useDelayed(bookmarked, 200);

  const history = useHistory();
  const onDelete = useCallback(() => history.push(`/journals/${journalId}/timeline`), [history, journalId]);

  const isMobile = useIsMobile();

  const renderMenuButton = useCallback((onClick: () => void) => (
    <MenuButton
      fontSize="2xl"
      as={IconButton}
      aria-label="Options"
      icon={<FiMoreHorizontal />}
      variant="ghost"
      onClick={onClick}
    />
  ), []);

  return (
    <Flex px={4} align="center" height="56px">
      <HStack bg={getBackground(1, colorMode)} borderRadius="md">
        <Link to={`/journals/${journalId}/timeline`}>
          <Button transition="background-color 0.2s" px={[2, 4]} variant="ghost">
            <HStack alignItems="center" color={buttonColor}>
              <Box fontSize={['xl', 'md']}>
                <FiArrowLeft />
              </Box>
              {!isMobile && <div>Back to journal</div>}
            </HStack>
          </Button>
        </Link>
      </HStack>
      {isMobile && (
        <Box
          isTruncated
          fontWeight="semibold"
          minW={0}
          ml={1}
          opacity={showTitle ? 1 : 0}
          transition="opacity 0.2s"
        >
          {title}
        </Box>
      )}
      <Spacer />
      <HStack borderRadius="md" transition="background-color 0.2s" bg={getBackground(1, colorMode)}>
        {!isMobile && (
          <Fragment>
            <Tooltip openDelay={500} label={bookmarkedWithDelay ? 'Remove bookmark' : 'Bookmark'}>
              <IconButton

                icon={<WebBookmarkIcon fontSize="lg" withYellow value={bookmarked} />}
                aria-label={bookmarked ? 'Remove bookmark' : 'Bookmark'}
                onClick={() => onBookmarkedChange(!bookmarked)}
                variant="ghost"
              />
            </Tooltip>
          </Fragment>
        )}
        <div>
          <WebEntryMenu
            journalId={journalId}
            onBookmarkedChange={onBookmarkedChange}
            onPrint={handlePrint}
            entryId={entryId}
            onDelete={onDelete}
            button={renderMenuButton}
          />
        </div>
      </HStack>
    </Flex>
  )
}

export default memo(WebEntryTopBar);
