import { Box, useConst } from '@chakra-ui/react';
import { Fragment, memo, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import AutoLockController from '../shared/components/AutoLockController';
import ColorModeController from '../shared/components/ColorModeController';
import { DataServiceContextProvider } from '../shared/components/DataServiceContext';
import { DataServiceStateContextProvider } from '../shared/components/DataServiceStateContext';
import ErrorFeedback from '../shared/components/ErrorFeedback';
import LockController from '../shared/components/LockController';
import StorageQuotaController from '../shared/components/StorageQuotaController';
import { createDataService } from '../shared/dataServiceFactory';
import { useBackground, usePromise } from '../shared/hooks';
import { IndexedDBKeyValueStorage } from '../shared/indexeddb';
import UnloadController from '../shared/UnloadController';
import WebAppSpinner from './components/WebAppSpinner';
import { WebHotkeysDialogContextProvider } from './components/WebHotkeysDialogContext';
import { WebLockContextProvider } from './components/WebLockContext';
import WebPasscodeWall from './components/WebPasscodeWall';
import { WebSearchDialogContextProvider } from './components/WebSearchDialogContext';
import WebSyncObstructionAlert from './components/WebSyncObstructionAlert';
import WebCreateEntryPage from './pages/WebCreateEntryPage';
import WebEditEntryPage from './pages/WebEditEntryPage';
import WebJournalPage from './pages/WebJournalPage';
import WebNotFountPage from './pages/WebNotFoundPage';

function WebApp() {
  const bg = useBackground();
  const [dataServicePromise, dataServiceStatePromise] = useConst(() =>
    createDataService((name) => new IndexedDBKeyValueStorage(name))
  );
  const dataService = usePromise(dataServicePromise);
  const dataServiceState = usePromise(dataServiceStatePromise);

  useEffect(() => {
    if (dataService.state === "resolved") {
      dataService.value.startBackgroundSync();
    }
  }, [dataService]);

  return (
    <Fragment>
      {(dataService.state === "pending" ||
        dataServiceState.state === "pending") && (
        <WebAppSpinner mt="calc(var(--vh-50) - 16px)" />
      )}
      {dataService.state === "resolved" &&
        dataServiceState.state === "resolved" && (
          <DataServiceContextProvider service={dataService.value}>
            <UnloadController />
            <DataServiceStateContextProvider initial={dataServiceState.value}>
              <WebLockContextProvider>
                <StorageQuotaController />
                <ColorModeController />
                <LockController />
                <AutoLockController />
                <WebPasscodeWall>
                  <WebSearchDialogContextProvider>
                    <WebHotkeysDialogContextProvider>
                      <WebSyncObstructionAlert />
                      <Box
                        bg={bg}
                        minH="var(--vh-100)"
                        transition="background-color 0.2s"
                      >
                        <Switch>
                          <Route exact path="/">
                            <WebJournalPage />
                          </Route>
                          <Route path="/journals/:journalId/entries/new">
                            <WebCreateEntryPage />
                          </Route>
                          <Route path="/journals/:journalId/entries/:entryId">
                            <WebEditEntryPage />
                          </Route>
                          <Route path="/journals/:journalId">
                            <WebJournalPage />
                          </Route>
                          <Route>
                            <WebNotFountPage />
                          </Route>
                        </Switch>
                      </Box>
                    </WebHotkeysDialogContextProvider>
                  </WebSearchDialogContextProvider>
                </WebPasscodeWall>
              </WebLockContextProvider>
            </DataServiceStateContextProvider>
          </DataServiceContextProvider>
        )}
      {(dataService.state === "rejected" ||
        dataServiceState.state === "rejected") && <ErrorFeedback />}
    </Fragment>
  );
}

export default memo(WebApp);
