import { BlobStore } from '../blob-service';
import { Cloud, CloudEncryption, CloudEncryptionConfig, CloudLogEntry } from '../cloud';
import { ConcurrencyToken, ConcurrencyWrapper } from '../data-types/concurrency-token';
import { BlobKey } from '../data-types/entities/blob';
import { UserId } from '../data-types/entities/identity';
import { deserializeFromEnvelope, serializeToEnvelope } from '../encoding';
import { ConcurrencyError, NetworkError, StorageQuotaError, UnknownError } from '../errors';
import { KeyValuePair } from '../key-value-storage';
import { Result } from '../result';
import {
  TransactionLogCommitCommand,
  TransactionLogFreeOptions,
  TransactionLogListOptions,
  TransactionLogStore,
} from '../transaction-log';

type FetchFn = (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>;

type ApiFetchFn = <T>(input: string, init?: RequestInit | undefined) => Promise<Result<ConcurrencyError | NetworkError | UnknownError, T>>;

class BadStatusCodeError extends UnknownError {
  constructor(public readonly response: Response) {
    super(responseStatus(response));
  }
}

function createApiFetch(fetch: FetchFn, apiUrl: string, getApiToken: () => Promise<string>): ApiFetchFn {
  return async <T>(input: string, init?: RequestInit | undefined): Promise<Result<ConcurrencyError | NetworkError | BadStatusCodeError, T>> => {
    try {
      const response = await fetch(`${apiUrl}${input}`, {
        ...init,
        headers: {
          Authorization: `Bearer ${await getApiToken()}`,
          'Content-Type': 'application/json',
          ...init?.headers,
        }
      });

      if (response.status === 409) {
        return Result.error(new ConcurrencyError());
      }

      if (!response.ok) {
        return Result.error(new BadStatusCodeError(response));
      }

      const json = await response.json();

      return Result.ok(json);
    } catch {
      return Result.error(new NetworkError());
    }
  }
}

export interface RemoteCloudOptions {
  readonly getApiToken: () => Promise<string>;
  readonly mediaUrl: string;
  readonly apiUrl: string;
  readonly getUserId: () => Promise<UserId>;
}

export class RemoteCloud implements Cloud {
  readonly logStore: TransactionLogStore<CloudLogEntry>;
  readonly blobStore: BlobStore;
  readonly encryption: CloudEncryption;

  constructor(
    fetch: FetchFn,
    private options: RemoteCloudOptions,
  ) {
    const fetchOriginal = fetch;
    fetch = (...args: any[]) => (fetchOriginal as any)(...args);
    const apiFetch = createApiFetch(fetch, options.apiUrl, options.getApiToken);

    this.logStore = new RemoteTransactionLogStore(apiFetch);
    this.encryption = new RemoteCloudEncryption(apiFetch);
    this.blobStore = new RemoteBlobStore(apiFetch, fetch, this.options);
  }
}

class RemoteTransactionLogStore implements TransactionLogStore<CloudLogEntry> {
  constructor(
    private apiFetch: ApiFetchFn,
  ) { }

  async getCommitBoundary(): Promise<Result<unknown, string>> {
    const response = await this.apiFetch<string>('/me/transactions/boundary', { method: 'GET' });
    if (response.isFailure()) return response.forward();

    return Result.ok(deserializeFromEnvelope(response.value));
  }

  async commit(commands: ReadonlyArray<TransactionLogCommitCommand<CloudLogEntry>>): Promise<Result<unknown, void>> {
    const result = await this.apiFetch('/me/transactions/commit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(serializeToEnvelope(commands)),
    });

    if (result.isFailure()) return result.forward();

    return Result.ok();
  }

  async list(options: TransactionLogListOptions): Promise<Result<unknown, readonly KeyValuePair<string, CloudLogEntry>[]>> {
    const result = await this.apiFetch<string>(
      `/me/transactions?limit=${options.limit}` + (options.gt === undefined ? '' : `&gt=${options.gt}`),
      { method: 'GET' },
    );
    if (result.isFailure()) return result.forward();

    return Result.ok(deserializeFromEnvelope(result.value));
  }

  async append(items: readonly CloudLogEntry[]): Promise<Result<unknown, string[]>> {
    const result = await this.apiFetch<string>('/me/transactions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(serializeToEnvelope(items)),
    });

    if (result.isFailure()) return result.forward();

    return Result.ok(deserializeFromEnvelope(result.value));
  }

  async free(options: TransactionLogFreeOptions): Promise<Result<unknown, void>> {
    console.warn('[WARN] Remote transaction log does not support free operation');
    return Result.ok();
  }
}

class RemoteCloudEncryption implements CloudEncryption {
  constructor(
    private apiFetch: ApiFetchFn,
  ) { }

  async getEncryption(): Promise<Result<NetworkError, ConcurrencyWrapper<CloudEncryptionConfig>>> {
    const result = await this.apiFetch<string>('/me/encryption', { method: 'GET' });
    if (result.isFailure()) return Result.error(new NetworkError());

    return Result.ok(deserializeFromEnvelope(result.value));
  }

  async setEncryption(encryption: CloudEncryptionConfig, concurrencyToken: ConcurrencyToken): Promise<Result<NetworkError | ConcurrencyError, ConcurrencyWrapper<CloudEncryptionConfig>>> {
    const result = await this.apiFetch<string>(`/me/encryption?concurrency=${concurrencyToken}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(serializeToEnvelope(encryption)),
    });

    if (result.isFailure()) {
      switch (result.error.type) {
        case 'concurrency':
          return Result.error(result.error);
        case 'network':
          return Result.error(result.error);
        default:
          return Result.error(new NetworkError());
      }
    }

    return Result.ok(deserializeFromEnvelope(result.value));
  }
}

class RemoteBlobStore implements BlobStore {
  constructor(
    private apiFetch: ApiFetchFn,
    private fetch: FetchFn,
    private options: RemoteCloudOptions,
  ) { }

  async get(key: BlobKey): Promise<Result<unknown, Uint8Array | undefined>> {
    try {
      const response = await this.fetch(`${this.options.mediaUrl}/${await this.options.getUserId()}/${key}`, { method: 'GET' });

      if (response.status === 404) {
        return Result.ok(undefined);
      }

      if (!response.ok) {
        return Result.error(new UnknownError(responseStatus(response)));
      }

      const buffer = await response.arrayBuffer();

      return Result.ok(new Uint8Array(buffer));
    } catch {
      return Result.error(new NetworkError());
    }
  }

  async create(key: BlobKey, object: Uint8Array): Promise<Result<unknown, void>> {
    const uploadUrl = await this.getUploadUrl(key, object.length);
    if (uploadUrl.isFailure()) return uploadUrl.forward();

    try {
      const response = await this.fetch(uploadUrl.value, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/octet-stream',
          'x-amz-acl': 'public-read',
        },
        body: object,
      });

      if (!response.ok) {
        return Result.error(new UnknownError(responseStatus(response)));
      }

      return Result.ok();
    } catch {
      return Result.error(new NetworkError());
    }
  }

  async delete(key: BlobKey): Promise<Result<unknown, void>> {
    const result = await this.apiFetch(`/me/media/${key}`, { method: 'DELETE' });
    return result.fold(() => Result.ok(), error => Result.error(error));
  }

  private async getUploadUrl(key: BlobKey, size: number): Promise<Result<unknown, string>> {
    const result = await this.apiFetch<any>(`/me/media/upload-url?suffix=${key}&size=${size}`, { method: 'GET' });
    if (result.isFailure()) {
      if (result.error instanceof BadStatusCodeError) {
        try {
          const body = await result.error.response.json();
          if (body?.error === 'storage_quota') {
            return Result.error(new StorageQuotaError());
          }
        } catch {
          // do nothing
        }
      }

      return result.forward();
    }

    return Result.ok(result.value.uploadUrl);
  }
}

function responseStatus(response: Response) {
  return { statusCode: response.status, message: response.statusText };
}
