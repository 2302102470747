import { DeflateOptions, deflateSync, inflateSync } from 'fflate';

import { deserialize, serialize } from './encoding';
import { Brand } from './utils';

export type Deflated<TInflated> = Brand<Uint8Array, 'Deflated' & TInflated>;
export type InflatedOf<TCiphertext extends Deflated<any>> = TCiphertext extends Deflated<infer TInflated>
  ? TInflated
  : never;

export class Compression {
  constructor(
    private options?: DeflateOptions,
  ) { }

  deflate<TInflated>(inflated: TInflated): Deflated<TInflated> {
    return deflateSync(serialize(inflated), this.options) as Deflated<TInflated>;
  }

  inflate<TDeflated extends Deflated<any>>(deflated: TDeflated): InflatedOf<TDeflated> {
    return deserialize(inflateSync(deflated)) as InflatedOf<TDeflated>;
  }
}
