import './main-web.css';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import 'quill/dist/quill.core.css';
import 'focus-visible/dist/focus-visible';

import { ColorModeScript } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Global, css } from '@emotion/react';
import { BrowserRouter, MemoryRouter, Route, Switch } from 'react-router-dom';

import { AppChakraProvider } from '../shared/components/AppChakraProvider';
import { ErrorBoundary } from '../shared/components/ErrorBoundary';
import WebAuthWall from './components/WebAuthWall';
import WebScrollBox from './components/WebScrollBox';
import WebSingInCallbackPage from './pages/WebSignInCallbackPage';
import WebTestPage from './pages/WebTestPage';
import WebApp from './WebApp';
import WebElectronAuthCallbackListener from './components/WebElectronAuthCallbackListener';
import { isElectron } from '../shared/utils';
import WebMessageListPage from './pages/WebMessageListPage';

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
     outline: none;
     box-shadow: none;
   }
`;


function monitorWindowHeight() {
  function getApphHeight() {
    return window.innerHeight;
  }

  function setAppHeight(height: number) {
    const doc = document.documentElement;
    for (let i = 5; i <= 100; i += 5) {
      doc.style.setProperty(`--vh-${i}`, `${height * (i / 100)}px`);
    }
  }

  function handler() {
    setAppHeight(getApphHeight());
  }

  window.addEventListener('resize', handler);
  handler();
}

export function bootstrapWeb() {
  monitorWindowHeight();

  const Router: typeof MemoryRouter = isElectron() ? MemoryRouter : BrowserRouter;

  ReactDOM.render(
    <React.StrictMode>
      <ColorModeScript initialColorMode="light" />
      <AppChakraProvider resetCSS>
        <ErrorBoundary>
          <Global styles={GlobalStyles} />
          <Router>
            <WebElectronAuthCallbackListener />
            <WebScrollBox h="var(--vh-100)">
              <Switch>
                <Route path="/test">
                  <WebTestPage />
                </Route>
                <Route path="/messages">
                  <WebMessageListPage />
                </Route>
                <Route path="/sign-in-callback">
                  <WebSingInCallbackPage />
                </Route>
                <Route>
                  <WebAuthWall>
                    <WebApp />
                  </WebAuthWall>
                </Route>
              </Switch>
            </WebScrollBox>
          </Router>
        </ErrorBoundary>
      </AppChakraProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}
