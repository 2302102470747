import { Observable, Observer } from 'rxjs';
import { match } from 'ts-pattern';
import { applyUpdateV2, Doc as YDoc, Map as YMap, mergeUpdatesV2, Text as YText } from 'yjs';

import { Cabinet, CabinetAction as StoreAction, diffEqual, diffOverlaps, TransactionDiff } from './data-types/cabinet';
import { DeltaOperation } from './data-types/delta';
import { DeviceId } from './data-types/device';
import { Entry, EntryId } from './data-types/entities/entry';
import { Journal, JournalId } from './data-types/entities/journal';
import { ImageMedia, Media, MediaId } from './data-types/entities/media';
import { Settings } from './data-types/entities/settings';
import { getTimestamp, TimeRange, Timestamp } from './data-types/timestamp';
import { Brand, distinct, single } from './utils';

type YjsUpdateV2 = Brand<Uint8Array, 'YjsUpdateV2'>

export type TransactionBatch = readonly Transaction[];

export class Transaction {
  constructor(
    public readonly timeFrame: TimeRange,
    public readonly diff: TransactionDiff,
    public readonly update: YjsUpdateV2,
    public readonly deviceId: DeviceId,
    public readonly clientId: number,
  ) { }
}

enum EntityType {
  Journal = '0',
  Entry = '1',
  Media = '2',
  Settings = '3',
}

enum MediaProps {
  Id = '0',
  Type = '1',
  CreatedOn = '2',
  CreatedAt = '3',
  ModifiedAt = '4',

  // image props
  MimeType = '5',
  BlobKey = '6',
}

enum JournalProps {
  Id = '0',
  Name = '1',
  CreatedAt = '2',
  ModifiedAt = '3',
  Icon = '4',
  Order = '5',
  EntryStyle = '6',
  EntryTemplate = '7',
}

enum EntryProps {
  Id = '0',
  JournalId = '1',
  Timestamp = '3',
  Body = '4',
  CreatedAt = '6',
  ModifiedAt = '7',
  Bookmarked = '8',
  Cover = '9',
  Background = '10',
  Pad = '11',
  TitleFont = '12',
  TextFont = '13',
}

enum SettingsProps {
  Initialized = '0',
  Name = '2',
  SyncTheme = '3',
  Theme = '4',
  AutoDarkMode = '5',
  AutoLock = '7',
  ShowEditorHelpWeb = '8',
}

export interface CabinetCRDTOptions {
  readonly sessionId?: number;
  readonly trackModificationTime?: boolean;
  readonly deviceId: DeviceId;
}

class ChainableMap<TKey extends string, TValue> {
  constructor(
    private map: YMap<TValue>,
  ) { }

  get(key: TKey): TValue | undefined {
    return this.map.get(key);
  }

  set(key: TKey, value: TValue, condition?: boolean): ChainableMap<TKey, TValue> {
    if (condition !== false) {
      this.map.set(key, value);
    }
    return this;
  }
}

export class CabinetCRDT implements Cabinet {
  static merge(transactions: readonly Transaction[]): Transaction {
    if (transactions.length === 0) {
      throw new Error('CabinetCRDT cannot merge an empty array of transactions');
    }
    const deviceIds = distinct(transactions.map(x => x.deviceId));
    if (deviceIds.length > 1) {
      throw new Error('CabinetCRDT cannot merge transactions from different devices');
    }
    const clientIds = distinct(transactions.map(x => x.clientId));
    if (clientIds.length > 1) {
      throw new Error('CabinetCRDT cannot merge transactions with different clientId');
    }
    if (!transactions.every(x => diffEqual(x.diff, transactions[0].diff))) {
      throw new Error('CabinetCRDT cannot merge transactions with different diffs');
    }
    const deviceId = single(deviceIds);
    const clientId = single(clientIds);

    return new Transaction(
      [
        Math.min(...transactions.map(({ timeFrame: [from, to] }) => from)) as Timestamp,
        Math.max(...transactions.map(({ timeFrame: [from, to] }) => to)) as Timestamp,
      ],
      CabinetCRDT.mergeMetadata(transactions.map(x => x.diff)),
      mergeUpdatesV2(transactions.map((x) => x.update)) as YjsUpdateV2,
      deviceId,
      clientId,
    );
  }

  private static mergeMetadata(metadata: TransactionDiff[]): TransactionDiff {
    return {
      journalIds: distinct(metadata.flatMap(({ journalIds }) => journalIds)),
      entryIds: distinct(metadata.flatMap(({ entryIds }) => entryIds)),
      mediaIds: distinct(metadata.flatMap(({ mediaIds }) => mediaIds)),
      settings: metadata.some(({ settings }) => settings),
    };
  }

  private transactinos: Transaction[] = [];

  private doc = new YDoc({ gc: true });

  private accumulateUpdate = true;
  private combinedUpdateV2: Uint8Array | undefined = undefined;

  private subs: Array<{ readonly observer: Observer<TransactionDiff>, filter: Partial<TransactionDiff> }> = [];

  private latestDiff: TransactionDiff | undefined = undefined;

  // needed for tests
  private sessionIdCounter = 0;

  constructor(private options: CabinetCRDTOptions) {
    if (options?.sessionId !== undefined) {
      this.doc.clientID = options.sessionId;
    }

    this.doc.on('updateV2', (eventV2: Uint8Array) => {
      if (!this.accumulateUpdate) return;

      if (this.combinedUpdateV2 === undefined) {
        this.combinedUpdateV2 = eventV2;
      } else {
        this.combinedUpdateV2 = mergeUpdatesV2([this.combinedUpdateV2, eventV2]);
      }
    });
  }

  view(timestamp: Timestamp, diff: Partial<TransactionDiff>): Cabinet {
    const cabinet = new CabinetCRDT(this.options);
    cabinet.apply(
      this.transactinos
        .filter(x => timestamp >= x.timeFrame[1])
        .filter(x => diffOverlaps(x.diff, diff))
    );

    return cabinet;
  }

  history(diff: Partial<TransactionDiff>): TimeRange[] {
    return this.transactinos
      .filter(x => diffOverlaps(x.diff, diff))
      .map(x => x.timeFrame)
      .sort((a, b) => {
        if (a[0] < b[0]) {
          return -1;
        } else if (a[0] > b[0]) {
          return 1;
        } else if (a[1] < b[1]) {
          return -1;
        } else if (a[1] > b[1]) {
          return 1;
        } else {
          return 0;
        }
      });
  }

  entry(entryId: EntryId): Entry | undefined {
    const entryMap = this.doc.getMap<any>(EntityType.Entry).get(entryId);
    if (entryMap === undefined) {
      return undefined;
    }

    return this.mapEntry(entryMap);
  }

  journal(journalId: JournalId): Journal | undefined {
    const journalMap = this.doc.getMap<any>(EntityType.Journal).get(journalId);
    if (journalMap === undefined) {
      return undefined;
    }

    return this.mapJournal(journalMap);
  }

  on(filter: Partial<TransactionDiff>): Observable<TransactionDiff> {
    return new Observable(observer => {
      const sub = { observer, filter };
      this.subs.push(sub);

      return () => {
        this.subs = this.subs.filter(x => x !== sub);
      };
    })
  }

  settings(): Settings {
    return {
      initialized: this.doc.getMap<any>(EntityType.Settings).get(SettingsProps.Initialized) ?? false,
      name: this.doc.getMap<any>(EntityType.Settings).get(SettingsProps.Name),
      showEditorHelpWeb: this.doc.getMap<any>(EntityType.Settings).get(SettingsProps.ShowEditorHelpWeb) ?? true,

      autoLock: this.doc.getMap<any>(EntityType.Settings).get(SettingsProps.AutoLock),

      syncTheme: this.doc.getMap<any>(EntityType.Settings).get(SettingsProps.SyncTheme) ?? false,
      theme: this.doc.getMap<any>(EntityType.Settings).get(SettingsProps.Theme) ?? { type: 'light', autoDark: true },
    };
  }

  entries(): Entry[] {
    return [...this.doc.getMap<any>(EntityType.Entry).values()]
      .map((x: Map<EntryProps, any>) => this.mapEntry(x))
      .sort((a, b) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });
  }

  media(mediaId: MediaId): Media | undefined;
  media(): Media[];
  media(mediaId?: MediaId): Media[] | Media | undefined {
    if (mediaId === undefined) {
      return [...this.doc.getMap<any>(EntityType.Media).values()]
        .map((x: Map<MediaProps, any>) => this.mapMedia(x)!).filter(x => x !== undefined)
        .sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        });
    }

    return this.mapMedia(this.doc.getMap<any>(EntityType.Media).get(mediaId));
  }

  journals(): Journal[] {
    return [...this.doc.getMap<any>(EntityType.Journal).values()]
      .map((x: Map<JournalProps, any>) => this.mapJournal(x))
      .sort((a, b) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });
  }

  execute(action: StoreAction): Transaction {
    const diff = match(action)
      //  journal actions
      .with({ type: 'journal/put' }, ({ journal }) => ({ journalIds: [journal.id], entryIds: [], mediaIds: [], settings: false }) as TransactionDiff)
      .with({ type: 'journal/delete' }, ({ journalId }) => ({ journalIds: [journalId], entryIds: [], mediaIds: [], settings: false }) as TransactionDiff)
      .with({ type: 'journal/set_name' }, ({ journalId }) => ({ journalIds: [journalId], entryIds: [], mediaIds: [], settings: false }) as TransactionDiff)
      .with({ type: 'journal/set_icon' }, ({ journalId }) => ({ journalIds: [journalId], entryIds: [], mediaIds: [], settings: false }) as TransactionDiff)
      .with({ type: 'journal/set_entry_style' }, ({ journalId }) => ({ journalIds: [journalId], entryIds: [], mediaIds: [], settings: false }) as TransactionDiff)
      .with({ type: 'journal/set_entry_template' }, ({ journalId }) => ({ journalIds: [journalId], entryIds: [], mediaIds: [], settings: false }) as TransactionDiff)
      .with({ type: 'journal/set_order' }, ({ journalId }) => ({ journalIds: [journalId], entryIds: [], mediaIds: [], settings: false }) as TransactionDiff)
      //  entry actions
      .with({ type: 'entry/put' }, ({ entry }) => ({ journalIds: [], entryIds: [entry.id], mediaIds: [], settings: false }) as TransactionDiff)
      .with({ type: 'entry/delete' }, ({ entryId }) => ({ journalIds: [], entryIds: [entryId], mediaIds: [], settings: false }) as TransactionDiff)
      .with({ type: 'entry/set_bookmarked' }, ({ entryId }) => ({ journalIds: [], entryIds: [entryId], mediaIds: [], settings: false }) as TransactionDiff)
      .with({ type: 'entry/set_timestamp' }, ({ entryId }) => ({ journalIds: [], entryIds: [entryId], mediaIds: [], settings: false }) as TransactionDiff)
      .with({ type: 'entry/set_cover' }, ({ entryId }) => ({ journalIds: [], entryIds: [entryId], mediaIds: [], settings: false }) as TransactionDiff)
      .with({ type: 'entry/set_background' }, ({ entryId }) => ({ journalIds: [], entryIds: [entryId], mediaIds: [], settings: false }) as TransactionDiff)
      .with({ type: 'entry/set_pad' }, ({ entryId }) => ({ journalIds: [], entryIds: [entryId], mediaIds: [], settings: false }) as TransactionDiff)
      .with({ type: 'entry/set_title_font' }, ({ entryId }) => ({ journalIds: [], entryIds: [entryId], mediaIds: [], settings: false }) as TransactionDiff)
      .with({ type: 'entry/set_text_font' }, ({ entryId }) => ({ journalIds: [], entryIds: [entryId], mediaIds: [], settings: false }) as TransactionDiff)
      .with({ type: 'entry/edit_body' }, ({ entryId }) => ({ journalIds: [], entryIds: [entryId], mediaIds: [], settings: false }) as TransactionDiff)
      // media action
      .with({ type: 'image/put' }, ({ media }) => ({ journalIds: [], entryIds: [], mediaIds: [media.id], settings: false }) as TransactionDiff)
      .with({ type: 'image/delete' }, ({ mediaId }) => ({ journalIds: [], entryIds: [], mediaIds: [mediaId], settings: false }) as TransactionDiff)
      // settings action
      .with({ type: 'settings/init' }, () => ({ journalIds: [], entryIds: [], mediaIds: [], settings: true }) as TransactionDiff)
      .with({ type: 'settings/set_show_editor_help_web' }, () => ({ journalIds: [], entryIds: [], mediaIds: [], settings: true }) as TransactionDiff)
      .with({ type: 'settings/set_auto_lock' }, () => ({ journalIds: [], entryIds: [], mediaIds: [], settings: true }) as TransactionDiff)
      .with({ type: 'settings/set_name' }, () => ({ journalIds: [], entryIds: [], mediaIds: [], settings: true }) as TransactionDiff)
      .with({ type: 'settings/set_sync_theme' }, () => ({ journalIds: [], entryIds: [], mediaIds: [], settings: true }) as TransactionDiff)
      .with({ type: 'settings/set_theme' }, () => ({ journalIds: [], entryIds: [], mediaIds: [], settings: true }) as TransactionDiff)
      .exhaustive();

    if (this.latestDiff !== undefined && !diffEqual(this.latestDiff, diff)) {
      if (this.options.sessionId !== undefined) {
        this.sessionIdCounter += 1;
        this.doc.clientID = this.options.sessionId + this.sessionIdCounter;
      } else {
        this.doc.clientID = new YDoc().clientID;
      }
    }
    this.latestDiff = diff;

    const now = getTimestamp();
    match(action)
      //  journal actions
      .with(
        { type: 'journal/put' },
        ({ journal }) => this.doc.getMap<any>(EntityType.Journal).set(
          journal.id,
          new YMap([
            [JournalProps.Id, journal.id],
            [JournalProps.Name, journal.name],
            [JournalProps.CreatedAt, journal.createdAt],
            [JournalProps.ModifiedAt, journal.modifiedAt],
            [JournalProps.Icon, journal.icon],
            [JournalProps.Order, journal.order.toString()],
            [JournalProps.EntryStyle, journal.entryStyle],
            [JournalProps.EntryTemplate, journal.entryTemplate],
          ])
        )
      )
      .with({ type: 'journal/delete' }, ({ journalId }) => this.doc.getMap<any>(EntityType.Journal).delete(journalId))
      .with({ type: 'journal/set_name' }, ({ journalId, name }) => this.getJournalMap(journalId)?.set(JournalProps.Name, name).set(JournalProps.ModifiedAt, now, this.options.trackModificationTime))
      .with({ type: 'journal/set_icon' }, ({ journalId, icon }) => this.getJournalMap(journalId)?.set(JournalProps.Icon, icon).set(JournalProps.ModifiedAt, now, this.options.trackModificationTime))
      .with({ type: 'journal/set_entry_style' }, ({ journalId, style }) => this.getJournalMap(journalId)?.set(JournalProps.EntryStyle, style).set(JournalProps.ModifiedAt, now, this.options.trackModificationTime))
      .with({ type: 'journal/set_entry_template' }, ({ journalId, template }) => this.getJournalMap(journalId)?.set(JournalProps.EntryTemplate, template).set(JournalProps.ModifiedAt, now, this.options.trackModificationTime))
      .with({ type: 'journal/set_order' }, ({ journalId, order }) => this.getJournalMap(journalId)?.set(JournalProps.Order, order.toString()).set(JournalProps.ModifiedAt, now, this.options.trackModificationTime))
      //  entry actions
      .with(
        { type: 'entry/put' },
        ({ entry }) => this.doc.getMap<any>(EntityType.Entry).set(
          entry.id,
          new YMap([
            [EntryProps.Id, entry.id],
            [EntryProps.JournalId, entry.journalId],
            [EntryProps.Timestamp, entry.timestamp],
            [EntryProps.Body, this.createYText(entry.body)],
            [EntryProps.CreatedAt, entry.createdAt],
            [EntryProps.ModifiedAt, entry.modifiedAt],
            [EntryProps.Bookmarked, entry.bookmarked],
            [EntryProps.Cover, entry.cover],
            [EntryProps.Background, entry.background],
            [EntryProps.Pad, entry.pad],
            [EntryProps.TitleFont, entry.titleFont],
            [EntryProps.TextFont, entry.textFont],
          ])
        )
      )
      .with({ type: 'entry/delete' }, ({ entryId }) => this.doc.getMap<any>(EntityType.Entry).delete(entryId))
      .with({ type: 'entry/set_bookmarked' }, ({ entryId, value }) => this.getEntryMap(entryId)?.set(EntryProps.Bookmarked, value).set(EntryProps.ModifiedAt, now, this.options.trackModificationTime))
      .with({ type: 'entry/set_timestamp' }, ({ entryId, timestamp }) => this.getEntryMap(entryId)?.set(EntryProps.Timestamp, timestamp).set(EntryProps.ModifiedAt, now, this.options.trackModificationTime))
      .with({ type: 'entry/set_cover' }, ({ entryId, cover }) => this.getEntryMap(entryId)?.set(EntryProps.Cover, cover).set(EntryProps.ModifiedAt, now, this.options.trackModificationTime))
      .with({ type: 'entry/set_background' }, ({ entryId, background }) => this.getEntryMap(entryId)?.set(EntryProps.Background, background).set(EntryProps.ModifiedAt, now, this.options.trackModificationTime))
      .with({ type: 'entry/set_pad' }, ({ entryId, pad }) => this.getEntryMap(entryId)?.set(EntryProps.Pad, pad).set(EntryProps.ModifiedAt, now, this.options.trackModificationTime))
      .with({ type: 'entry/set_title_font' }, ({ entryId, font }) => this.getEntryMap(entryId)?.set(EntryProps.TitleFont, font).set(EntryProps.ModifiedAt, now, this.options.trackModificationTime))
      .with({ type: 'entry/set_text_font' }, ({ entryId, font }) => this.getEntryMap(entryId)?.set(EntryProps.TextFont, font).set(EntryProps.ModifiedAt, now, this.options.trackModificationTime))
      .with({ type: 'entry/edit_body' }, ({ entryId, delta }) => this.getEntryMap(entryId)?.set(EntryProps.ModifiedAt, now, this.options.trackModificationTime).get(EntryProps.Body).applyDelta(delta))
      //  media actions
      .with(
        { type: 'image/put' },
        ({ media }) => this.doc.getMap<any>(EntityType.Media).set(
          media.id,
          new YMap([
            [MediaProps.Id, media.id],
            [MediaProps.CreatedAt, media.createdAt],
            [MediaProps.ModifiedAt, media.modifiedAt],
            [MediaProps.Type, media.type],
            [MediaProps.MimeType, media.mimeType],
            [MediaProps.BlobKey, media.blobKey],
          ])
        )
      )
      .with({ type: 'image/delete' }, ({ mediaId }) => this.doc.getMap<any>(EntityType.Media).delete(mediaId))
      // settings actions
      .with({ type: 'settings/init' }, () => this.doc.getMap<any>(EntityType.Settings).set(SettingsProps.Initialized, true))
      .with({ type: 'settings/set_show_editor_help_web' }, ({ value }) => this.doc.getMap<any>(EntityType.Settings).set(SettingsProps.ShowEditorHelpWeb, value))
      .with({ type: 'settings/set_auto_lock' }, ({ value }) => this.doc.getMap<any>(EntityType.Settings).set(SettingsProps.AutoLock, value))
      .with({ type: 'settings/set_name' }, ({ name }) => this.doc.getMap<any>(EntityType.Settings).set(SettingsProps.Name, name))
      .with({ type: 'settings/set_sync_theme' }, ({ value }) => this.doc.getMap<any>(EntityType.Settings).set(SettingsProps.SyncTheme, value))
      .with({ type: 'settings/set_theme' }, ({ theme }) => this.doc.getMap<any>(EntityType.Settings).set(SettingsProps.Theme, theme))
      .exhaustive();

    const update = this.getUpdate();
    if (!update) {
      throw new Error('Update is undefined, but value was expected. Action: ' + JSON.stringify(action));
    }

    const transaction: Transaction = new Transaction(
      [getTimestamp(), getTimestamp()],
      diff,
      update,
      this.options.deviceId,
      this.doc.clientID,
    );

    this.transactinos.push(transaction);
    this.emit(transaction.diff);

    return transaction;
  }

  apply(transactions: readonly Transaction[]): void {
    try {
      this.accumulateUpdate = false;
      for (const { update } of transactions) {
        applyUpdateV2(this.doc, update);
      }
    } finally {
      this.accumulateUpdate = true;
    }
    this.transactinos.push(...transactions);
    this.emit(CabinetCRDT.mergeMetadata(transactions.map(x => x.diff)));
  }

  private emit(diff: TransactionDiff): void {
    for (const { observer, filter } of this.subs) {
      if (diffOverlaps(diff, filter)) {
        observer.next(diff);
      }
    }
  }

  private createYText(delta: readonly DeltaOperation[]): YText {
    const ytext = new YText();
    ytext.applyDelta(delta);
    return ytext;
  }

  private getMediaMap(mediaId: MediaId): ChainableMap<MediaProps, any> | undefined {
    const map = this.doc.getMap<any>(EntityType.Media).get(mediaId);
    return map === undefined ? undefined : new ChainableMap(map);
  }

  private getEntryMap(entryId: EntryId): ChainableMap<EntryProps, any> | undefined {
    const map = this.doc.getMap<any>(EntityType.Entry).get(entryId);
    return map === undefined ? undefined : new ChainableMap(map);
  }

  private getJournalMap(journalId: JournalId): ChainableMap<JournalProps, any> | undefined {
    const map = this.doc.getMap<any>(EntityType.Journal).get(journalId);
    return map === undefined ? undefined : new ChainableMap(map);
  }

  private mapJournal(x: Map<JournalProps, any>): Journal {
    return {
      id: x.get(JournalProps.Id),
      name: x.get(JournalProps.Name),
      icon: x.get(JournalProps.Icon),
      createdAt: x.get(JournalProps.CreatedAt),
      modifiedAt: x.get(JournalProps.ModifiedAt),
      order: x.get(JournalProps.Order) === undefined
        ? Number.MAX_VALUE / 3
        : parseFloat(x.get(JournalProps.Order)),
      entryStyle: x.get(JournalProps.EntryStyle) ?? {
        background: undefined,
        cover: { type: 'gray' },
        pad: undefined,
        textFont: undefined,
        titleFont: undefined,
      },
      entryTemplate: x.get(JournalProps.EntryTemplate),
    };
  }

  private mapEntry(x: Map<EntryProps, any>): Entry {
    return {
      id: x.get(EntryProps.Id),
      journalId: x.get(EntryProps.JournalId),
      timestamp: x.get(EntryProps.Timestamp),
      body: x.get(EntryProps.Body).toDelta(),
      cover: x.get(EntryProps.Cover),
      background: x.get(EntryProps.Background),
      pad: x.get(EntryProps.Pad),
      titleFont: x.get(EntryProps.TitleFont),
      textFont: x.get(EntryProps.TextFont),
      createdAt: x.get(EntryProps.CreatedAt),
      modifiedAt: x.get(EntryProps.ModifiedAt),
      bookmarked: x.get(EntryProps.Bookmarked) ?? false,
    };
  }

  private mapMedia(x?: Map<MediaProps, any>): ImageMedia | undefined {
    if (x === undefined) {
      return undefined;
    }

    return match(x.get(MediaProps.Type))
      .with('image', (): ImageMedia => ({
        id: x.get(MediaProps.Id),
        type: 'image' as const,
        createdAt: x.get(MediaProps.CreatedAt),
        modifiedAt: x.get(MediaProps.ModifiedAt),

        mimeType: x.get(MediaProps.MimeType),
        blobKey: x.get(MediaProps.BlobKey),
      }))
      .otherwise(() => undefined);
  }

  private getUpdate(): YjsUpdateV2 | undefined {
    const updateV2 = this.combinedUpdateV2;
    if (updateV2 === undefined) {
      return undefined;
    }

    this.combinedUpdateV2 = undefined;
    return updateV2 as YjsUpdateV2;
  }
}
