import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import React, { useCallback, useRef } from 'react';

import { useDataService } from '../../../shared/components/DataServiceContext';
import { withLazy } from '../../../shared/components/hoc/withLazy';
import { ImageMedia } from '../../../shared/data/data-types/entities/media';

interface Props {
  readonly image: ImageMedia;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

function WebDeleteImageDialog({ image, isOpen, onClose }: Props) {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const dataService = useDataService();

  const handleDelete = useCallback(() => {
    dataService.execute({ type: 'image/delete', mediaId: image.id });
    onClose();
  }, [dataService, image.id, onClose]);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent mx={[4, null]}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete image
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to delete this image?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="ghost" ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDelete}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default React.memo(withLazy(WebDeleteImageDialog, 200));
