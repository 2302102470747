import React, { ReactNode } from 'react';
import { BsJournalText } from 'react-icons/bs';

import { Icon } from '../data/data-types/entities/journal';
import Emoji from './Emoji';

interface Props {
  readonly icon: Icon | undefined;
}

function IconView({ icon }: Props) {
  function wrap(children: ReactNode) {
    return <div style={{ display: 'flex', alignItems: 'center', height: '1em', width: '1em' }}>{children}</div>;
  }

  if (icon?.type === 'emoji') {
    return wrap(<Emoji unicode={icon.unicode} />);
  }

  return wrap(<BsJournalText />);
}

export default React.memo(IconView);
