import { match } from 'ts-pattern';

import { BlobKey } from './data-types/entities/blob';
import { deserialize, serialize } from './encoding';
import { CiphertextEnvelope, EncryptionService } from './encryption-service';
import { AppError } from './errors';
import { KeyValueStorage, prefixKeyValueStorage, ValueStorage } from './key-value-storage';
import { Result } from './result';
import { OptimisticTransactionLog } from './transaction-log';
import { last, Locker, MIN_STRING } from './utils';

export class KeyTakenError extends AppError<'key_exists'> {
  constructor(key: BlobKey) {
    super('key_exists', `Blob with key ${key} exists`);
  }
}

export interface BlobStore {
  get(key: BlobKey): Promise<Result<unknown, Uint8Array | undefined>>;
  create(key: BlobKey, object: Uint8Array): Promise<Result<unknown, void>>;
  delete(key: BlobKey): Promise<Result<unknown, void>>;
}

interface BlobOperation {
  readonly type: 'create' | 'delete';
  readonly key: BlobKey;
}

export interface BlobServiceSyncOptions {
  readonly targetKeys: readonly BlobKey[];
}

// todo: handle no storage available error
export class BlobService {
  private cache: KeyValueBlobStore;
  private log: OptimisticTransactionLog<BlobOperation>;
  private appliedPtr: ValueStorage<string>;
  private locker: Locker<string>;

  constructor(
    storage: KeyValueStorage<string, any>,
    private encryptionService: EncryptionService,
    private target: BlobStore,
    private mode: 'proxy' | 'store',
  ) {
    this.locker = storage;
    this.cache = new KeyValueBlobStore(prefixKeyValueStorage(storage, 'cache/'));
    this.log = new OptimisticTransactionLog(prefixKeyValueStorage(storage, 'log/'));
    this.appliedPtr = new ValueStorage(prefixKeyValueStorage(storage, 'applied_ptr/'), MIN_STRING);
  }

  async get(key: BlobKey): Promise<Uint8Array | undefined> {
    const fromCache = await this.cache.get(key);
    if (fromCache.isSuccess() && fromCache.value !== undefined) return fromCache.value;

    const fromTarget = await this.target.get(key);
    if (fromTarget.isSuccess()) {
      const saveResult = await this.processTargetBlob(key, fromTarget.value);
      return saveResult.getOrDefault(undefined);
    }

    return undefined;
  }

  async dirty(): Promise<boolean> {
    const logs = await this.log.list({ gt: await this.appliedPtr.get(), limit: 1 });
    return logs.length > 0;
  }

  async create(key: BlobKey, object: Uint8Array): Promise<Result<unknown, void>> {
    const cacheResult = await this.cache.create(key, object);
    if (cacheResult.isFailure()) return cacheResult.forward();

    await this.log.append({ type: 'create', key });

    return Result.ok();
  }

  async delete(key: BlobKey): Promise<Result<unknown, void>> {
    await this.log.append({ type: 'delete', key });

    const cacheResult = await this.cache.delete(key);
    if (cacheResult.isFailure()) return cacheResult.forward();

    return Result.ok();
  }

  async sync({ targetKeys }: BlobServiceSyncOptions): Promise<Result<unknown, void>> {
    return await this.locker.lock('sync', async () => {
      const pushResult = await this.push();
      if (pushResult.isFailure()) return pushResult.forward();

      if (this.mode === 'store') {
        const pullResult = await this.pull(targetKeys);
        if (pullResult.isFailure()) return pullResult.forward();
      }

      return Result.ok();
    });
  }

  private async push(): Promise<Result<unknown, void>> {
    while (true) {
      const ops = await this.log.list({ gt: await this.appliedPtr.get(), limit: 1 });
      if (ops.length === 0) return Result.ok();

      for (const { value } of ops) {
        const result = await this.applyOperation(value);
        if (result.isFailure()) return result.forward();
      }

      const appliedPtr = last(ops).key;
      await this.appliedPtr.set(appliedPtr);
      await this.log.free({ lt: appliedPtr });
    }
  }

  private async applyOperation(operation: BlobOperation): Promise<Result<unknown, void>> {
    return await match(operation)
      .with({ type: 'create' }, async ({ key }) => {
        const buffer = await this.cache.get(key);
        if (buffer.isFailure()) return buffer.forward();
        if (buffer.value === undefined) return Result.ok();

        const envelope = await this.encryptionService.encrypt(buffer.value);
        if (envelope.isFailure()) return envelope.forward();

        const createResult = await this.target.create(key, serialize(envelope.value));
        if (createResult.isFailure()) return createResult.forward();

        if (this.mode === 'proxy') {
          const deleteResult = await this.cache.delete(key);
          return deleteResult.forward();
        }

        return Result.ok();
      })
      .with({ type: 'delete' }, async ({ key }) => {
        const deleteResult = await this.target.delete(key);
        return deleteResult.forward();
      })
      .exhaustive()
  }

  private async pull(targetKeys: readonly BlobKey[]): Promise<Result<unknown, void>> {
    const cacheKeys = await this.cache.listKeys();
    if (cacheKeys.isFailure()) return cacheKeys.forward();

    const { additions } = diff(cacheKeys.value, targetKeys);
    for (const objectKey of additions) {
      const envelopeBuff = await this.target.get(objectKey);
      if (envelopeBuff.isFailure()) return envelopeBuff.forward();

      await this.processTargetBlob(objectKey, envelopeBuff.value);
    }

    return Result.ok();
  }

  private async processTargetBlob(
    objectKey: BlobKey,
    envelopeBuff: Uint8Array | undefined,
  ): Promise<Result<unknown, Uint8Array | undefined>> {
    if (envelopeBuff !== undefined) {
      const envelope = deserialize(envelopeBuff) as CiphertextEnvelope<Uint8Array>;

      const object = await this.encryptionService.decrypt(envelope);
      if (object.isFailure()) return object.forward();

      if (this.mode === 'store') {
        const createResult = await this.cache.create(objectKey, object.value);
        if (createResult.isFailure()) return createResult.forward();
      }

      return Result.ok(object.value);
    }

    return Result.ok(undefined);
  }
}

export class KeyValueBlobStore implements BlobStore {
  constructor(
    private storage: KeyValueStorage<BlobKey, Uint8Array>,
  ) { }

  async listKeys(): Promise<Result<unknown, BlobKey[]>> {
    const items = await this.storage.list();
    return Result.ok(items.map(x => x.key));
  }

  async get(key: BlobKey): Promise<Result<unknown, Uint8Array | undefined>> {
    return Result.ok(await this.storage.get(key));
  }

  async create(key: BlobKey, object: Uint8Array): Promise<Result<unknown, void>> {
    return await this.storage.lock('write', async () => {
      const existingItem = await this.storage.get(key);
      if (existingItem !== undefined) return Result.error(new KeyTakenError(key));

      await this.storage.set(key, object);

      return Result.ok();
    });
  }

  async delete(key: BlobKey): Promise<Result<unknown, void>> {
    await this.storage.delete(key);

    return Result.ok();
  }

}

function diff<T extends string>(parent: readonly T[], child: readonly T[]): { additions: T[], deletions: T[] } {
  const parentSet = new Set(parent);
  const childSet = new Set(child);

  return {
    additions: child.filter(x => !parentSet.has(x)),
    deletions: parent.filter(x => !childSet.has(x)),
  };
}
