import { useBoolean } from "@chakra-ui/react";
import { createContext, ReactNode, useContext } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import SearchDialog from "./dialogs/WebSearchDialog";

const WebSearchDialogContext = createContext<ReturnType<typeof useBoolean> | undefined>(undefined);

interface Props {
  readonly children: ReactNode;
}

export function WebSearchDialogContextProvider({ children }: Props) {
  const [isOpen, setIsOpen] = useBoolean();

  useHotkeys('ctrl+k', e => {
    e.preventDefault();
    setIsOpen.toggle();
  });

  return (
    <WebSearchDialogContext.Provider value={[isOpen, setIsOpen]}>
      {children}
      <SearchDialog isOpen={isOpen} onClose={setIsOpen.off} />
    </WebSearchDialogContext.Provider>
  )
}

export function useWebSearchDialog() {
  const value = useContext(WebSearchDialogContext);

  if (value === undefined) {
    throw new Error('useWebSearchDialog can only be used by components in WebSearchDialogContextProvider subtree');
  }

  return value;
}
