import { config } from '../../config';
import { UserId } from '../data/data-types/entities/identity';
import { Timestamp } from '../data/data-types/timestamp';
import { AuthService } from './AuthService';

export interface User {
  readonly id: UserId;
  readonly tier: 'beta' | 'trial' | 'premium' | 'expired';
  readonly email?: string;
  readonly createdAt: Timestamp;
  readonly storageUsage: number | undefined;
}

async function apiFetch<T>(url: string, options: RequestInit & { ignoreResponse?: boolean }): Promise<T> {
  const token = await AuthService.getToken();
  return fetch(`${config.apiUrl}${url}`, {
    ...options,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...(options.headers || {})
    }
  }).then(x => options?.ignoreResponse === true ? x : x.json());
}

async function postMessage(model: { name: string, email: string, text: string }): Promise<{ success: boolean }> {
  try {
    const response = await apiFetch<any>('/messages', { method: 'POST', body: JSON.stringify(model) });
    return { success: response?.success || false };
  } catch {
    console.warn('[WARN] Cannot post message:', model);
    return { success: false };
  }
}

async function deleteAccount(): Promise<void> {
  return await apiFetch('/me', { method: 'DELETE' },);
}

async function getMe(): Promise<User> {
  return await apiFetch('/me', { method: 'GET' },);
}

export const ApiClient = {
  postMessage,
  deleteAccount,
  getMe,
}
