import { Box, chakra, Fade, useColorMode, useColorModeValue, useTheme } from '@chakra-ui/react';
import { DeltaStatic, RangeStatic, Sources } from 'quill';
import Delta from 'quill-delta';
import { forwardRef, memo } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useIsMobile, usePreventUnload } from '../../hooks';
import { DeltaAttributeMap, DeltaOperation, DeltaOps, getTags } from '../../data/data-types/delta';
import { getTimestamp } from '../../data/data-types/timestamp';
import { distinct } from '../../data/utils';
import { postpone, Subscribable, wait } from '../../utils';
import { useCabinet } from '../DataServiceStateContext';
import { useWebHotkeysDialog } from '../../../web/components/WebHotkeysDialogContext';
import VirtualElementPopover, { VirtualElementRect } from '../VirtualElementPopover';
import AppEmbedProvider from './AppEmbedProvider';
import AppMediaProvider from './AppMediaProvider';
import { AppQuill } from './AppQuill';
import { AppEmbed, AppEmbedCursor } from './formats/createAppEmbed';
import { TEXT_COLOR_STYLE_DARK_OBJECT, TEXT_COLOR_STYLE_LIGHT_OBJECT } from './formats/TextColor';
import LinkPopover from './LinkPopover';
import { ReactQuill, ReactQuillProps } from './ReactQuill';
import Toolbar from './Toolbar';

const ReactQuillWrapper = forwardRef<ReactQuill, ReactQuillProps>((props, ref) => <ReactQuill ref={ref} {...props} />)

const ChakraReactQuill = chakra(ReactQuillWrapper);

const Keyboard = AppQuill.import('modules/keyboard');
const EmbedBlot = AppQuill.import('blots/embed');

const ALLOWED_INLINE_FORMATS = [
  'text_color', 'placeholder',
  'bold', 'italic', 'strike', 'underline', 'link', 'mention',
  // 'size', 'scirpt', 'font', 'code','color', 'background',
];
const ALLOWED_BLOCK_FORMATS = [
  'entry_title',
  'header', 'list', 'blockquote', 'app_embed_cursor', 'media', 'indent',
  // 'align', 'direction',
];

// https://github.com/quilljs/quill/blob/0148738cb22d52808f35873adb620ca56b1ae061/modules/keyboard.js#L305
function makeEmbedArrowHandler(key: string, shiftKey: boolean) {
  const where = key === Keyboard.keys.LEFT ? 'prefix' : 'suffix';
  return {
    key,
    shiftKey,
    altKey: null,
    [where]: /^$/,
    handler: function (this: { quill: AppQuill }, range: any) {
      let index = range.index;
      if (key === Keyboard.keys.RIGHT) {
        index += (range.length + 1);
      }
      const [leaf,] = this.quill.getLeaf(index);
      if (!(leaf instanceof EmbedBlot)) return true;
      if (key === Keyboard.keys.LEFT) {
        if (shiftKey) {
          this.quill.setSelection(range.index - 1, range.length + 1, 'user');
        } else {
          this.quill.setSelection(range.index - 1, 0, 'user');
        }
      } else {
        if (shiftKey) {
          this.quill.setSelection(range.index, range.length + 1, 'user');
        } else {
          this.quill.setSelection(range.index + range.length + 1, 'user');
        }
      }
      return false;
    }
  };
}

function getFormatRange(delta: DeltaOps, expandFromIndex: number, formats: DeltaAttributeMap): RangeStatic | undefined {
  let found = false;
  let left = 0;
  let right = 0;

  for (const op of delta.ops ?? []) {
    const hasFormats = Object.keys(formats).every(key => formats[key] === (op.attributes ?? {})[key]);
    const partLength = typeof op.insert === 'string' ? op.insert.length : 1;

    if (hasFormats) {
      right += partLength;

      if (right >= expandFromIndex) {
        found = true;
      }
    } else if (found) {
      break;
    } else {
      right += partLength;
      left = right;
    }
  }

  if (found) {
    return { index: left, length: right - left };
  }
}

function getLineNumber(quill: AppQuill, index: number): number {
  const [line,] = quill.getLine(index);
  return quill.getLines().findIndex(x => x === line);
}

interface Props {
  readonly readOnly?: boolean;
  readonly value: readonly DeltaOperation[];
  readonly onChange?: (delta: readonly DeltaOperation[]) => void;
  readonly focusEmitter?: Subscribable;
  readonly onCaretUp?: () => void;
  readonly scrollingContainer: HTMLElement | undefined;
  readonly autoFocus?: boolean;
  readonly headingFontFamily: string;
  readonly bodyFontFamily: string;
}

function RichTextEditor({
  headingFontFamily, bodyFontFamily, autoFocus, value, onChange, focusEmitter, onCaretUp, readOnly,
  scrollingContainer
}: Props) {
  const reactQuillRef = useRef<ReactQuill>(null);
  const [quillEditor, setQuillEditor] = useState<AppQuill>();

  function getEditor() {
    if (reactQuillRef.current?.isEditorReady()) {
      return reactQuillRef.current?.getEditor();
    }
  }

  useEffect(() => {
    return focusEmitter?.subscribe(() => getEditor()?.setSelection(0, 0, 'user'));
  }, [focusEmitter]);

  const relativeContainerRef = useRef<HTMLDivElement>(null);
  const handleFocus = useCallback((selection: RangeStatic | null) => {
    const editor = getEditor();
    if (editor && selection && relativeContainerRef.current) {
      const { top, left, width, height } = editor.getBounds(selection.index, selection.length);
      const div = document.createElement('div');
      div.style.position = 'absolute';
      div.style.top = top + 'px';
      div.style.left = left + 'px';
      div.style.width = width + 1 + 'px';
      div.style.height = height + 40 + 'px';
      div.style.pointerEvents = 'none';
      relativeContainerRef.current.appendChild(div);

      wait(200)
        .then(() => div.scrollIntoView({ block: 'nearest', behavior: 'smooth' })).then(() => wait(200))
        .then(() => div.scrollIntoView({ block: 'nearest', behavior: 'smooth' })).then(() => wait(200))
        .then(() => div.scrollIntoView({ block: 'nearest', behavior: 'smooth' })).then(() => wait(200))
        .then(() => div.scrollIntoView({ block: 'nearest', behavior: 'smooth' }))
        .then(() => wait(1000))
        .finally(() => relativeContainerRef.current?.removeChild(div));
    }
  }, []);

  useEffect(() => {
    const editor = getEditor();
    if (editor && value) {
      const editorValue = editor.getContents();
      let remoteDelta = new Delta(value as any);
      if (accumulatedDeltaRef.current !== undefined) {
        remoteDelta = remoteDelta.compose(accumulatedDeltaRef.current as any);
      }
      const diff = editorValue.diff(remoteDelta as any);
      if ((diff.ops?.length ?? 0) > 0) {
        editor.updateContents(diff, 'api')
      }
    }
  }, [value]);

  const [lastUncolapsedSelection, setLastUncolapsedSelection] = useState<RangeStatic>();
  const [selection, setSelection] = useState<RangeStatic>();
  const [toolbarActiveFormats, setToolbarActiveFormats] = useState<{ [format: string]: any }>({});

  const [isToolbarVisible, setIsToolbarVisible] = useState(false);

  const calculateActiveFormats = useCallback(() => {
    if (!isToolbarVisible) {
      // ignore active format caclculation, because they are not needed
      return;
    }
    const editor = getEditor();

    if (editor && lastUncolapsedSelection) {
      setToolbarActiveFormats(editor.getFormat(lastUncolapsedSelection.index, lastUncolapsedSelection.length));
    }
  }, [lastUncolapsedSelection, isToolbarVisible]);

  const getPopoverTargetBySelection = useCallback((index: number, length: number): VirtualElementRect => {
    const editor = getEditor();
    if (editor && containerRef.current) {
      const bounds = editor.getBounds(index, length);

      const margin = 20;
      return {
        top: bounds.top - margin,
        left: bounds.left - margin,
        width: bounds.width + 2 * margin,
        height: bounds.height + 2 * margin,
      };
    }

    return { top: -1000, left: -1000, width: 1, height: 1 };
  }, []);

  const [selectedLink, setSelectedLink] = useState<{ range: RangeStatic, link: string, target: VirtualElementRect }>();

  const updateLinkPopover = useCallback(() => {
    const editor = getEditor();
    if (editor && selection && selection.length === 0) {
      let link = editor.getFormat(selection)['link'];
      let expandFromIndex = selection.index;

      if (!link && getLineNumber(editor, selection.index) === getLineNumber(editor, selection.index + 1)) {
        link = editor.getFormat(selection.index + 1)['link'];
        expandFromIndex = selection.index + 1;
      }

      if (link !== undefined) {
        const range = getFormatRange(editor.getContents(), expandFromIndex, { link });
        if (range) {
          setSelectedLink({ range, link, target: getPopoverTargetBySelection(range.index, range.length) });
        }
      } else {
        setSelectedLink(undefined);
      }
    } else {
      setSelectedLink(undefined);
    }
  }, [selection, getPopoverTargetBySelection])

  useEffect(() => updateLinkPopover(), [updateLinkPopover]);

  const accumulatedDeltaRef = useRef<DeltaStatic | undefined>();

  const [preventUnload, setPreventUnload] = useState(false);
  usePreventUnload(preventUnload);

  const [, setIsHotkeysDialogOpen] = useWebHotkeysDialog();

  useEffect(() => {
    let cancelled = false;
    let lastReported = getTimestamp();
    (async () => {
      while (!cancelled) {
        if (accumulatedDeltaRef.current === undefined) {
          lastReported = getTimestamp();
        }

        if (getTimestamp() - lastReported >= 200 && accumulatedDeltaRef.current?.ops !== undefined) {
          if (onChange) {
            onChange(accumulatedDeltaRef.current.ops);
            accumulatedDeltaRef.current = undefined;
            setPreventUnload(false);
            lastReported = getTimestamp();
          }
        }

        await wait(50);
      }
    })();

    return () => {
      cancelled = true;
    }
  }, [onChange]);

  const handleChange = useCallback((
    _value: string,
    delta: DeltaStatic,
    source: Sources,
  ) => {
    const editor = getEditor();
    if (editor && source === 'user' && delta.ops !== undefined && delta.ops.length > 0) {
      if (accumulatedDeltaRef.current === undefined) {
        accumulatedDeltaRef.current = delta;
      } else {
        accumulatedDeltaRef.current = accumulatedDeltaRef.current.compose(delta);
      }
      setPreventUnload(true);
    }
    calculateActiveFormats();
  }, [calculateActiveFormats]);

  const theme = useTheme();

  const cabinet = useCabinet();
  const otherTags = useMemo(() => distinct(cabinet.entries().flatMap(x => getTags(x.body))), [cabinet]);
  const entryTags = useMemo(() => distinct(getTags(value)), [value]);
  const tags = useMemo(() => distinct([...otherTags, ...entryTags]), [otherTags, entryTags]);

  const tagsRef = useRef(tags);
  useEffect(() => { tagsRef.current = tags; }, [tags]);

  const modules = useMemo(() => ({
    history: {
      delay: 1000,
      maxStack: 100,
      userOnly: true,
    },
    mention: {
      allowedChars: /^[^#\n]*$/,
      mentionDenotationChars: ["#"],
      minChars: 1,
      dataAttributes: ['value', 'type'],
      source: function (searchTerm: any, renderList: any) {
        if (searchTerm.length === 0) {
          renderList(tagsRef.current.map(tag => ({ value: tag, type: 'tag' })), searchTerm);
        } else {
          const matches = [];
          for (const tag of tagsRef.current) {
            if (~tag.toLowerCase().indexOf(searchTerm.toLowerCase())) {
              matches.push({ value: tag, type: 'tag' });
            }
          }

          if (matches.every(x => x.value !== searchTerm)) {
            matches.push({ value: searchTerm, type: 'tag' });
          }
          renderList(matches.slice(0, 5), searchTerm);
        }
      }
    },
    keyboard: {
      bindings: {
        // disable embed bindings from
        // https://github.com/quilljs/quill/blob/0148738cb22d52808f35873adb620ca56b1ae061/modules/keyboard.js#L298
        'embed left': makeEmbedArrowHandler(Keyboard.keys.LEFT, false),
        'embed left shift': makeEmbedArrowHandler(Keyboard.keys.LEFT, true),
        'embed right': makeEmbedArrowHandler(Keyboard.keys.RIGHT, false),
        'embed right shift': makeEmbedArrowHandler(Keyboard.keys.RIGHT, true),

        'remove react embed with delete': {
          key: 'delete',
          format: ['app_embed_cursor'],
          collapsed: true,
          handler: function (this: { quill: AppQuill }, range: RangeStatic) {
            const [cursor] = this.quill.getLine(range.index);
            const [embed] = this.quill.getLine(cursor.offset() - 1);
            if (cursor instanceof AppEmbedCursor && embed instanceof AppEmbed) {
              this.quill.setSelection(embed.offset() + embed.length() + cursor.length(), 0, 'user');
              this.quill.updateContents(new Delta().retain(embed.offset()).delete(embed.length() + cursor.length()) as any, 'user');
              return false;
            } else {
              console.warn('remove react embed with delete got inconsistent delta', this.quill.getContents(), range);

              return true;
            }
          },
        },
        'remove react embed with backspace': {
          key: 'backspace',
          format: ['app_embed_cursor'],
          collapsed: true,
          handler: function (this: { quill: AppQuill }, range: RangeStatic, context: any) {
            const [cursor] = this.quill.getLine(range.index);
            const [embed] = this.quill.getLine(cursor.offset() - 1);
            if (cursor instanceof AppEmbedCursor && embed instanceof AppEmbed) {
              this.quill.setSelection(embed.offset() - 1, 0, 'user');
              this.quill.updateContents(new Delta().retain(embed.offset()).delete(embed.length() + cursor.length()) as any, 'user');
              return false;
            } else {
              console.warn('remove react embed with backspace got inconsistent delta', this.quill.getContents(), range);

              return true;
            }
          },
        },
        'remove react embed with backspace from next line': {
          key: 'backspace',
          prefix: /^$/,
          collapsed: true,
          handler: function (this: { quill: AppQuill }, range: RangeStatic) {
            const [prevLine] = this.quill.getLine(range.index - 1);
            if (prevLine instanceof AppEmbedCursor) {
              const cursor = prevLine;
              const [embed] = this.quill.getLine(cursor.offset() - 1);
              if (embed instanceof AppEmbed) {
                const [currentLine] = this.quill.getLine(range.index);
                this.quill.setSelection(cursor.offset(), 0, 'user');
                if (currentLine.length() === 1) {
                  this.quill.updateContents(new Delta().retain(currentLine.offset()).delete(currentLine.length()) as any, 'user');
                }

                return false;
              } else {
                console.warn('remove react embed with backspace from next line got inconsistent delta', this.quill.getContents(), range);
              }
            }

            return true;
          },
        },
        'remove react embed with delete from prev line': {
          key: 'delete',
          suffix: /^$/,
          collapsed: true,
          handler: function (this: { quill: AppQuill }, range: RangeStatic) {
            const [nextLine] = this.quill.getLine(range.index + 1);
            if (nextLine instanceof AppEmbed) {
              const embed = nextLine;
              const [cursor] = this.quill.getLine(embed.offset() + embed.length());
              if (cursor instanceof AppEmbedCursor) {
                this.quill.setSelection(cursor.offset(), 0, 'user');
                const [currentLine] = this.quill.getLine(range.index);
                if (currentLine.length() === 1) {
                  this.quill.updateContents(new Delta().retain(currentLine.offset()).delete(currentLine.length()) as any, 'user');
                }

                return false;
              } else {
                console.warn('remove react embed with delete from prev line got inconsistent delta', this.quill.getContents(), range);
              }
            }

            return true;
          },
        },
        'add new line after react embed with enter': {
          key: 'enter',
          format: ['app_embed_cursor'],
          collapsed: true,
          handler: function (this: { quill: AppQuill }, range: RangeStatic) {
            const [cursor] = this.quill.getLine(range.index);
            if (cursor instanceof AppEmbedCursor) {
              const newLineOffset = cursor.offset() + cursor.length();
              this.quill.updateContents(new Delta().retain(newLineOffset).insert('\n') as any, 'user');
              wait(0).then(() => this.quill.setSelection(newLineOffset, 0, 'user'));
              return false;
            } else {
              console.warn('add new line after react embed with enter got inconsistent delta', this.quill.getContents(), range);

              return true;
            }
          },
        },
        strikeThrough: {
          key: 'S',
          shiftKey: true,
          shortKey: true,
          handler: function (this: { quill: AppQuill }, range: RangeStatic) {
            const applied = this.quill.getFormat(range)['strike'];
            this.quill.format('strike', !applied, 'user');
          },
        },
        h1: {
          key: '1',
          shortKey: true,
          altKey: true,
          handler: function (this: { quill: AppQuill }, range: RangeStatic) {
            const applied = this.quill.getFormat(range)['header'] === 1;
            this.quill.format('header', applied ? false : 1, 'user');
          },
        },
        h2: {
          key: '2',
          shortKey: true,
          altKey: true,
          handler: function (this: { quill: AppQuill }, range: RangeStatic) {
            const applied = this.quill.getFormat(range)['header'] === 2;
            this.quill.format('header', applied ? false : 2, 'user');
          },
        },
        quote: {
          key: '5',
          shortKey: true,
          altKey: true,
          handler: function (this: { quill: AppQuill }, range: RangeStatic) {
            const applied = this.quill.getFormat(range)['blockquote'];
            this.quill.format('blockquote', !applied, 'user');
          },
        },
        hotkeys: {
          key: 191,
          shortKey: true,
          handler: function () {
            setIsHotkeysDialogOpen.toggle();
            return false;
          },
        },
        'list autofill': {
          key: ' ',
          shiftKey: null,
          collapsed: true,
          format: { list: false },
          prefix: /^\s*?(1\.|-|\*|\[ ?\]|\[x\])$/,
          handler: function (this: any, range: any, context: any) {
            let length = context.prefix.length;
            let [line, offset] = this.quill.getLine(range.index);
            if (offset > length) return true;
            let value;
            switch (context.prefix.trim()) {
              case '[]': case '[ ]':
                value = 'unchecked';
                break;
              case '[x]':
                value = 'checked';
                break;
              case '-': case '*':
                value = 'bullet';
                break;
              default:
                value = 'ordered';
            }
            this.quill.insertText(range.index, ' ', 'user');
            this.quill.history.cutoff();
            let delta = new Delta().retain(range.index - offset)
              .delete(length + 1)
              .retain(line.length() - 2 - offset)
              .retain(1, { list: value });
            this.quill.updateContents(delta, 'user');
            this.quill.history.cutoff();
            this.quill.setSelection(range.index - length, 'silent');
          }
        },
        clearFormatsOnEnter: {
          key: 'enter',
          suffix: /^$/,
          handler: function (this: { quill: AppQuill }) {
            postpone(() => {
              for (const attribute of ALLOWED_INLINE_FORMATS) {
                this.quill.format(attribute, false, 'user');
              }
            });
            return true;
          },
        },
        removeHeaderOnEnter: {
          key: 'enter',
          format: ['header'],
          prefix: /^$/,
          handler: function (this: { quill: AppQuill }, range: RangeStatic) {
            postpone(() => this.quill.formatLine(range.index, 0, 'header', false, 'user'));
            return true;
          },
        },
        removeBlockquoteOnEnter: {
          key: 'enter',
          format: ['blockquote'],
          empty: true,
          handler: function (this: { quill: AppQuill }, range: RangeStatic) {
            this.quill.formatLine(range.index, range.length, 'blockquote', false, 'user');
          },
        },
        'caret up with arrow left': {
          key: Keyboard.keys.LEFT,
          collapsed: true,
          prefix: /^$/,
          handler: function (this: { quill: AppQuill }, range: RangeStatic) {
            if (range.index === 0) {
              if (onCaretUp) {
                onCaretUp();
              }
              return false;
            }
            return true;
          },
        },
        'caret up with arrow up': {
          key: Keyboard.keys.UP,
          collapsed: true,
          preffix: /^$/,
          handler: function (this: { quill: AppQuill }, range: RangeStatic) {
            if (range.index === 0) {
              if (onCaretUp) {
                onCaretUp();
              }
              return false;
            }
            return true;
          },
        },
      }
    },
  }), [onCaretUp, setIsHotkeysDialogOpen]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      const isMac = (navigator.platform ?? (navigator as any).userAgentData?.platform)?.match("Mac");
      if (e.key === 's' && (isMac ? e.metaKey : e.ctrlKey)) {
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', handler, true);

    return () => document.removeEventListener('keydown', handler, true);
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);

  const [toolbarInteractionActive, setToolbarInteractionActive] = useState(false);

  const changeToolbarVisibility = useCallback((visible: boolean) => {
    if (visible || toolbarInteractionActive) {
      calculateActiveFormats();
    }

    if (isToolbarVisible === visible) {
      return;
    }
    setIsToolbarVisible(visible);
  }, [isToolbarVisible, toolbarInteractionActive, calculateActiveFormats]);
  const handleOnToolbarIneractionStart = useCallback(() => {
    calculateActiveFormats();
    setToolbarInteractionActive(true);
  }, [calculateActiveFormats]);
  const handleOnToolbarIneractionEnd = useCallback(() => {
    setTimeout(() => getEditor()?.focus(), 0);

    setToolbarInteractionActive(false);
    setIsToolbarVisible(true);
  }, []);

  const [toolbarTarget, setToolbarTarget] = useState<VirtualElementRect>({ top: 0, left: 0, width: 0, height: 0 });

  const updateToolbarPosition = useCallback(() => {
    const editor = getEditor();
    const range = toolbarInteractionActive
      ? lastUncolapsedSelection
      : editor?.getSelection();
    if (!editor || !containerRef.current || !range) {
      return;
    }

    changeToolbarVisibility(range.length > 0);

    if (range.length > 0) {
      const target = getPopoverTargetBySelection(range.index, range.length);

      setToolbarTarget({ ...target });
    }
  }, [changeToolbarVisibility, toolbarInteractionActive, lastUncolapsedSelection, getPopoverTargetBySelection]);

  const handleBlur = useCallback(() => {
    changeToolbarVisibility(false);
  }, [changeToolbarVisibility]);

  const handleChangeSelection = useCallback((range: RangeStatic | null) => {
    updateToolbarPosition();
    setSelection(range ?? undefined);

    if (range && range.length > 0) {
      setLastUncolapsedSelection(range);
    }
  }, [updateToolbarPosition]);

  useEffect(() => calculateActiveFormats(), [lastUncolapsedSelection, calculateActiveFormats]);

  const handleFormatApply = useCallback((attributes: { [format: string]: any }) => {
    const editor = getEditor();
    if (editor && lastUncolapsedSelection) {
      editor.formatText(lastUncolapsedSelection.index, lastUncolapsedSelection.length, attributes, 'user');
    }
  }, [lastUncolapsedSelection]);

  const handleFormatLineApply = useCallback((attributes: { [format: string]: any }) => {
    const editor = getEditor();
    if (editor && lastUncolapsedSelection) {
      editor.formatLine(lastUncolapsedSelection.index, lastUncolapsedSelection.length, attributes, 'user');
    }
  }, [lastUncolapsedSelection]);

  const handleDefaultLineFormatApply = useCallback(() => {
    const editor = getEditor();
    if (editor && lastUncolapsedSelection) {
      editor.formatLine(
        lastUncolapsedSelection.index,
        lastUncolapsedSelection.length,
        ALLOWED_BLOCK_FORMATS.reduce((formats, format) => ({ ...formats, [format]: null }), {}),
        'user'
      );
    }
  }, [lastUncolapsedSelection]);

  const allowedFormats = useMemo(() => ALLOWED_BLOCK_FORMATS.concat(ALLOWED_INLINE_FORMATS), []);

  const [interactionLink, setInteractionLink] = useState<{ range: RangeStatic, link: string, target: VirtualElementRect }>();
  const handleLinkPopoverInteractionStart = useCallback(() => {
    setInteractionLink(selectedLink);
  }, [selectedLink]);

  const handleLinkPopoverInteractionEnd = useCallback(() => {
    setTimeout(() => getEditor()?.focus(), 0);

    setSelectedLink(interactionLink);
    setInteractionLink(undefined);
  }, [interactionLink]);

  const popoverLink = interactionLink ?? selectedLink;

  const handleLinkEdit = useCallback((link: string | false) => {
    const editor = getEditor();
    if (editor && popoverLink) {
      editor.formatText(popoverLink.range.index, popoverLink.range.length, 'link', link, 'user');
    }
  }, [popoverLink]);

  const handleLinkDelete = useCallback(() => {
    const editor = getEditor();
    if (editor && popoverLink) {
      editor.formatText(popoverLink.range.index, popoverLink.range.length, 'link', false, 'user');
      setSelectedLink(undefined);
    }
  }, [popoverLink]);

  const [lastPopoverLinkTarget, setLastPopoverLinkTarget] = useState(popoverLink?.target);
  useEffect(() => {
    if (popoverLink?.target) {
      setLastPopoverLinkTarget(popoverLink.target);
    }
  }, [popoverLink]);

  useEffect(() => {
    const handleResize = () => {
      updateToolbarPosition();
      updateLinkPopover();
      if (interactionLink) {
        setInteractionLink({
          ...interactionLink,
          target: getPopoverTargetBySelection(interactionLink.range.index, interactionLink.range.length),
        });
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [updateToolbarPosition, updateLinkPopover, interactionLink, getPopoverTargetBySelection]);

  const quoteBorderColor = useColorModeValue('rgba(0, 0, 0, 0.84)', 'rgba(255, 255, 255, 0.84)');
  const { colorMode } = useColorMode();

  const mentionListBg = useColorModeValue('white', 'gray.700');
  const mentionBg = useColorModeValue('gray.200', 'gray.600');
  const mentionSelectedBg = useColorModeValue('gray.200', 'gray.600');

  const isMobile = useIsMobile();

  return (
    <>
      <Box ref={relativeContainerRef} position="relative">
        <VirtualElementPopover
          left={lastPopoverLinkTarget?.left ?? -1000}
          top={lastPopoverLinkTarget?.top ?? -1000}
          height={lastPopoverLinkTarget?.height ?? 1}
          width={lastPopoverLinkTarget?.width ?? 1}
          placement="bottom"
        >
          <Fade in={!readOnly && popoverLink !== undefined} unmountOnExit>
            <LinkPopover
              onEdit={handleLinkEdit}
              onDelete={handleLinkDelete}
              link={popoverLink?.link}
              onInteractionStart={handleLinkPopoverInteractionStart}
              onInteractionEnd={handleLinkPopoverInteractionEnd}
            />
          </Fade>
        </VirtualElementPopover>
        <VirtualElementPopover
          width={toolbarTarget.width}
          height={toolbarTarget.height}
          top={toolbarTarget.top}
          left={toolbarTarget.left}
          placement={isMobile ? 'bottom' : 'top'}
        >
          <Fade in={!readOnly && (isToolbarVisible || toolbarInteractionActive)} unmountOnExit>
            <Toolbar
              activeFormats={toolbarActiveFormats}
              onFormatApply={handleFormatApply}
              onFormatLineApply={handleFormatLineApply}
              onDefaultLineFormatApply={handleDefaultLineFormatApply}
              onInteractionStart={handleOnToolbarIneractionStart}
              onInteractionEnd={handleOnToolbarIneractionEnd}
            />
          </Fade>
        </VirtualElementPopover>
      </Box>
      <Box position="relative" ref={containerRef}>
        {quillEditor && (
          <AppMediaProvider editor={quillEditor}>
            <AppEmbedProvider editor={quillEditor} />
          </AppMediaProvider>
        )}
        <ChakraReactQuill
          readOnly={readOnly}
          autoFocus={autoFocus}
          defaultValue={{ ops: value } as any}
          scrollingContainer={scrollingContainer}
          onChangeEditor={setQuillEditor}
          onChange={handleChange}
          onChangeSelection={handleChangeSelection}
          onFocus={handleFocus}
          modules={modules}
          formats={allowedFormats}
          onBlur={handleBlur}
          ref={reactQuillRef}
          sx={{
            // https://github.com/quilljs/quill/issues/1374#issuecomment-520296215
            '& .ql-clipboard': {
              position: 'fixed !important',
              opacity: '0 !important',
              zIndex: '-1000 !important',
              left: '50% !important',
              top: '50% !important',
            },
            '.ql-mention-list-container': {
              background: mentionListBg,
            },
            '.ql-mention-list-item.selected': {
              background: mentionSelectedBg,
            },
            '& .ql-editor': {
              outline: 'none',
              marginTop: 0,
              padding: '0 0 50px 0',
              overflowY: 'visible',
              fontSize: isMobile ? '18px' : '20px',
              fontFamily: bodyFontFamily,
              lineHeight: 1.6,

              '.mention': {
                background: mentionBg,
              },

              '&.caret-transparent': {
                caretColor: 'transparent',
              },

              ...(colorMode === 'light' ? TEXT_COLOR_STYLE_LIGHT_OBJECT : TEXT_COLOR_STYLE_DARK_OBJECT),

              'blockquote + blockquote': {
                marginTop: 0,
                paddingTop: '20px',
              },
              'blockquote': {
                fontStyle: 'italic',
                borderLeft: `3px solid ${quoteBorderColor}`,
                paddingLeft: '20px',
              },
              [[1, 2, 3, 4, 5, 6, 7, 8].map(i => `li.ql-indent-${i}`).join(', ')]: {
                marginLeft: '-1.5em',
              },
              'p, blockquote, ul, ol': {
                marginTop: '20px',
              },
              '.ql-app_embed_cursor': {
                position: 'relative',
                bottom: '50px',
                transform: 'scale(0)',
                display: 'block',
              },
              '.ql-app_embed_cursor + *': {
                marginTop: 0,
              },
              '.ql-app-embed': {
                marginTop: '20px',
              },
              'ul, ol': {
                paddingLeft: '30px',

                '> li': {
                  paddingLeft: 0,
                  marginTop: '10px',
                },
              },
              'ul + ul, ul + ol, ol + ul, ol + ol': {
                marginTop: 0,
              },
              'h1.ql-entry-title': {
                marginTop: 0,
                fontSize: isMobile ? '34px' : '42px',
                lineHeight: isMobile ? '40px' : '52px',
              },
              '.ql-placeholder-title': {
                position: 'relative',
                '&:before': {
                  content: '"Untitled"',
                  position: 'absolute',
                  color: 'gray',
                },
              },
              '.ql-placeholder-content': {
                position: 'relative',
                '&:before': {
                  content: '"Write something down..."',
                  position: 'absolute',
                  color: 'gray',
                },
              },
              'h1, h2, h3, h4, h5, h6': {
                fontFamily: headingFontFamily,
                fontWeight: 'bold',
              },
              'h1': {
                marginTop: '40px',
                fontSize: isMobile ? '30px' : '34px',
                lineHeight: isMobile ? '30px' : '40px',
              },
              'h2': {
                marginTop: '30px',
                fontSize: '21px',
                lineHeight: '33px',
              },
              'h3': {
                marginTop: '20px',
                fontSize: theme.fontSizes['xl'],
              },
              '&.ql-blank::before': {
                left: '2px',
                marginTop: '20px',
                color: theme.colors.gray['400'],
                fontStyle: 'normal'
              },
              'a': {
                color: '#3c73d5',
                textDecoration: 'underline',
              }
            },
            '@media print': {
              '& .ql-editor': {
                paddingBottom: 0,
              },
            },
            '@media (min-width: 800px)': {
              '& .ql-editor blockquote': {
                paddingLeft: '20px', // 3px border
                marginLeft: '-23px',
              },
            },
          }}
        />
      </Box>
    </>
  )
}

export default memo(RichTextEditor);
