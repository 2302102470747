import { createContext, memo, ReactNode, useContext } from 'react';

import { DataService } from '../../shared/data/data-service';

const DataServiceContext = createContext<DataService | undefined>(undefined);

interface DataServiceContextProviderProps {
  readonly service: DataService;
  readonly children: ReactNode;
}

export const DataServiceContextProvider = memo(({ service, children }: DataServiceContextProviderProps) => {
  return (
    <DataServiceContext.Provider value={service}>
      {children}
    </DataServiceContext.Provider>
  )
});

if (process.env.NODE_ENV === 'development') {
  DataServiceContextProvider.displayName = 'DataServiceContextProvider';
}

export function useDataService() {
  const service = useContext(DataServiceContext);

  if (service === undefined) {
    throw new Error('useDataService can only be used by components in DataServiceContexttProvider subtree');
  }

  return service;
}
