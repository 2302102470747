import { BoxProps, chakra, useColorMode, useMergeRefs } from '@chakra-ui/react';
import OverlayScrollbars, { Options as OverlayScrollbarsOptions } from 'overlayscrollbars';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { createContext, forwardRef, memo, ReactNode, useContext, useEffect, useMemo } from 'react';

import { useScrollParentAccessor } from '../../shared/hooks';
import { isTouchDevice } from '../../shared/utils';

const ChakraOverlayScrollbarsComponent = chakra(OverlayScrollbarsComponent);

const ScrollBoxContext = createContext<HTMLElement | undefined | 'default'>('default');

interface Props extends Omit<BoxProps, 'onScroll'> {
  readonly autoHide?: boolean;
  readonly children: ReactNode;
  readonly onScroll?: (this: OverlayScrollbars, args?: UIEvent) => void;
  readonly monitorWindow?: boolean;
}

const WebScrollBox = forwardRef<OverlayScrollbarsComponent, Props>(({ monitorWindow, autoHide, children, onScroll, ...boxProps }, ref) => {
  const options = useMemo((): OverlayScrollbarsOptions => ({
    nativeScrollbarsOverlaid: {
      showNativeScrollbars: isTouchDevice(),
    },
    scrollbars: {
      visibility: 'auto',
      autoHide: autoHide ? 'leave' : 'never',
      autoHideDelay: 0,
      dragScrolling: true,
      clickScrolling: true,
      touchSupport: true,
      snapHandle: false,
    },
    overflowBehavior: {
      x: 'scroll',
      y: 'scroll',
    },
    callbacks: {
      onScroll: onScroll,
    }
  }), [autoHide, onScroll]);

  const [osRef, getViewport] = useScrollParentAccessor();
  const mergedRef = useMergeRefs(ref, osRef);

  const viewport = useMemo(() => getViewport(), [getViewport]);

  useEffect(() => {
    if (monitorWindow) {
      const handler = () => {
        if (window.scrollY > 1) {
          setTimeout(() => {
            if (viewport) {
              viewport.scrollTo({ top: viewport.scrollTop + window.scrollY });
            }
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }, 300);
        }
      };

      window.addEventListener('scroll', handler);
      return () => {
        window.removeEventListener('scroll', handler);
      }
    }
  }, [monitorWindow, viewport]);

  const { colorMode } = useColorMode();

  return (
    <ScrollBoxContext.Provider value={viewport ?? undefined}>
      <ChakraOverlayScrollbarsComponent
        {...boxProps}
        ref={mergedRef}
        className={colorMode === 'light' ? 'os-theme-dark' : 'os-theme-light'}
        options={options}
      >
        {children}
      </ChakraOverlayScrollbarsComponent>
    </ScrollBoxContext.Provider>
  )
});

export default memo(WebScrollBox);

export function useScrollBox() {
  const os = useContext(ScrollBoxContext);

  if (os === 'default') {
    throw new Error('useScrollBox can only be used by components in ScrollBoxContext subtree');
  }

  return os;
}
