import { BASE_ASSETS_URL, getEmojiName, isElectron } from "../utils";

interface Props {
  readonly unicode: string;
  readonly withTitle?: boolean;
}

function Emoji({ unicode, withTitle }: Props) {
  const title = getEmojiName(unicode);

  return (
    <svg style={{
      width: '1em',
      height: '1em',
      // margin: '0 .05em 0 .1em',
      // verticalAlign: '-0.1em',
    }}>
      <use href={`${BASE_ASSETS_URL}/twitter-emoji-sprite.svg#${unicode}`}>
        {withTitle && <title>{title}</title>}
      </use>
    </svg>
  )
}

export default Emoji;
