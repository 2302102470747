import { distinct, last } from '../utils';
import { MediaId } from './entities/media';

export interface DeltaAttributeMap {
  [key: string]: any;
}

export interface DeltaOperation {
  insert?: string | object;
  delete?: number;
  retain?: number;
  attributes?: DeltaAttributeMap;
}

export interface DeltaOps {
  ops?: Array<DeltaOperation>;
}

export function getAsPlaintext(delta: readonly DeltaOperation[], maxLen: number = 1_000_000_000) {
  const parts: string[] = [];
  let totalLen = 0;
  for (const op of delta) {
    let value: string;
    if (typeof op.insert === 'string') {
      value = op.insert;
    } else if ((op.insert as any)?.mention?.value !== undefined) {
      value = (op.insert as any)?.mention?.value;
    } else {
      continue;
    }
    totalLen += value.length;
    parts.push(value);

    if (totalLen >= maxLen) {
      break;
    }
  }

  return parts.join('').slice(0, maxLen);
}

export function getTitle(delta: readonly DeltaOperation[]): string {
  const text = getAsPlaintext(delta, 200);
  return text.split('\n')[0];
}

export function withoutTitle(delta: readonly DeltaOperation[]): readonly DeltaOperation[] {
  return toParagraphs(delta).slice(1).flatMap(x => x.delta);
}

export interface Paragraph {
  readonly delta: readonly DeltaOperation[];
  readonly offset: number;
  readonly length: number;
  readonly attributes: DeltaAttributeMap;
}

export function toParagraphs(delta: readonly DeltaOperation[]): Paragraph[] {
  let paragraphDelta: DeltaOperation[] = [];
  let paragraphOffset = 0;

  const result: Paragraph[] = [];

  function flush() {
    if (paragraphDelta.length === 0) return;

    let length = 0;
    for (const op of paragraphDelta) {
      length += typeof op.insert === 'object' ? 1 : op.insert!.length;
    }

    result.push({
      delta: paragraphDelta,
      offset: paragraphOffset,
      length,
      attributes: last(paragraphDelta).attributes ?? {},
    });

    paragraphDelta = [];
    paragraphOffset += length;
  }

  for (const op of delta) {
    if (typeof op.insert === 'string') {
      let gt = -1, lte = -1;
      while ((lte = op.insert.indexOf('\n', gt + 1)) !== -1) {
        paragraphDelta.push({ insert: op.insert.slice(gt + 1, lte + 1), attributes: op.attributes });
        gt = lte;
        flush();
      }

      if (gt + 1 < op.insert.length) {
        paragraphDelta.push({ insert: op.insert.slice(gt + 1), attributes: op.attributes });
      }
    } else {
      paragraphDelta.push({ insert: op.insert, attributes: op.attributes });
    }
  }

  flush();

  return result;
}

export function getTags(delta: readonly DeltaOperation[]): string[] {
  return distinct(
    delta
      .map(x => (x.insert as any)?.mention)
      .filter(x => x?.type === 'tag')
      .map(x => x.value)
  );
}

export function getMedia(delta: readonly DeltaOperation[]): MediaId[] {
  return distinct(
    delta.flatMap(x => (x.insert as any)?.media !== undefined ? [(x.insert as any)?.media.mediaId] : [])
  );
}

export interface IntroOptions {
  readonly length: number;
  readonly term?: string;
  readonly prefixLength?: number;
}

export function getIntro(delta: readonly DeltaOperation[], { length, term, prefixLength }: IntroOptions): string {
  prefixLength = prefixLength || Math.trunc(length / 2);

  const body = getAsPlaintext(withoutTitle(delta), 100_000);
  if (body.length === 0) {
    return '';
  }
  const index = term === undefined
    ? 0
    : normalizeText(body).indexOf(normalizeText(term));
  const left = Math.max(0, index - prefixLength);

  let result = index <= prefixLength ? '' : '...';
  result += body.slice(left, left + length);
  if (left + length < body.length) {
    result += '...';
  }

  return result;
}

export function normalizeText(x: string) {
  return replaceAll(x.toUpperCase(), '\n', ' ');
}

function replaceAll(source: string, searchValue: string, replaceValue: string) {
  let result = source;
  while (result.indexOf(searchValue) !== -1) {
    result = result.replace(searchValue, replaceValue);
  }

  return result;
}
