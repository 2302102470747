import { Box, Button, useBoolean } from '@chakra-ui/react';
import { Fragment, memo, useMemo } from 'react';

import StorageUsage from '../../../shared/components/StorageUsage';
import { usePromise, useTextSecondary } from '../../../shared/hooks';
import { ApiClient } from '../../../shared/services/ApiClient';
import { AuthService } from '../../../shared/services/AuthService';
import WebDeleteAccountDialog from '../dialogs/WebDeleteAccountDialog';
import WebAppSpinner from '../WebAppSpinner';
import WebSettingsSection from './WebSettingsSection';

function WebAccountSettings() {
  const [isDeleteAccountDialogOpen, setIsDeleteAccountDialogOpen] = useBoolean();

  const user = usePromise(useMemo(() => ApiClient.getMe(), []));
  const email = useMemo(() => AuthService.getIdentitySync()?.email, []);

  const textSecondary = useTextSecondary();

  return (
    <Fragment>
      <WebSettingsSection title="Email">
        {email === undefined && (
          <div>
            <Button
              onClick={() => AuthService.startSignInGoogle()}
              variant="outline"
              size="sm"
            >
              Log in
            </Button>
          </div>
        )}
        {email !== undefined && (
          <div>
            {email}
          </div>
        )}
      </WebSettingsSection>
      <WebSettingsSection title="Media">
        {user.state === 'pending' && <WebAppSpinner size="md" />}
        {user.state === 'rejected' && 'Currently unable to display this information'}
        {user.state === 'resolved' && (
          <Fragment>
            <StorageUsage usage={user.value.storageUsage ?? 0} />
            <Box fontSize="sm" mt={2} color={textSecondary}>
              You can view and delete your media in Settings &gt; Media.
            </Box>
          </Fragment>
        )}
      </WebSettingsSection>
      <WebSettingsSection title="Delete account">
        <Box fontSize="sm" mb={2} color={textSecondary}>
          This will immediately delete all of your data including journals, media, and more. This can't be undone.
        </Box>
        <Button size="sm" colorScheme="red" variant="outline" onClick={setIsDeleteAccountDialogOpen.on}>
          Delete account
        </Button>
      </WebSettingsSection>
      <WebDeleteAccountDialog isOpen={isDeleteAccountDialogOpen} onClose={setIsDeleteAccountDialogOpen.off} />
    </Fragment>
  )
}

export default memo(WebAccountSettings);
