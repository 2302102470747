import { Box, Text } from "@chakra-ui/layout";
import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  useBoolean,
} from "@chakra-ui/react";
import { Fragment, memo, useCallback, useEffect } from "react";
import { FiEdit, FiPaperclip, FiPlus, FiTrash2 } from "react-icons/fi";
import { useHistory } from "react-router-dom";

import { JournalId } from "../../shared/data/data-types/entities/journal";
import { useRed, useTextSecondary } from "../../shared/hooks";
import DeleteJournalDialog from "./dialogs/WebDeleteJournalDialog";
import EditJournalDialog from "./dialogs/WebEditJournalDialog";
import WebExportJournalDialog from "./dialogs/WebExportJournalDialog";

interface Props {
  readonly journalId: JournalId;
  readonly children: React.ReactNode;
  readonly onEditStateChange?: (editActive: boolean) => void;
}

function WebJournalMenu({ journalId, children, onEditStateChange }: Props) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useBoolean();
  const [isEditJournalDialogOpen, setIsEditJournalDialogOpen] = useBoolean();
  const [isExportDialogOpen, setIsExportDialogOpen] = useBoolean();

  useEffect(() => {
    if (onEditStateChange) {
      onEditStateChange(
        isDeleteDialogOpen || isEditJournalDialogOpen || isExportDialogOpen
      );
    }
  }, [
    isDeleteDialogOpen,
    isEditJournalDialogOpen,
    isExportDialogOpen,
    onEditStateChange,
  ]);

  const history = useHistory();
  const handleNewEntry = useCallback(() => {
    history.push(`/journals/${journalId}/entries/new`);
  }, [history, journalId]);

  const red = useRed();
  const textSecondary = useTextSecondary();

  return (
    <Fragment>
      <Menu isLazy>
        {children}
        <Portal>
          <MenuList>
            <MenuItem
              icon={
                <Text fontSize="lg">
                  <FiPlus />
                </Text>
              }
              onClick={handleNewEntry}
            >
              New entry
            </MenuItem>
            <MenuDivider />
            <MenuItem
              icon={
                <Text fontSize="md">
                  <FiEdit />
                </Text>
              }
              onClick={setIsEditJournalDialogOpen.on}
            >
              Edit
            </MenuItem>
            <MenuItem
              icon={
                <Text fontSize="md">
                  <FiPaperclip />
                </Text>
              }
              onClick={setIsExportDialogOpen.on}
            >
              <Box fontSize="md">Export</Box>
              <Box fontSize="sm" color={textSecondary} pr={2}>
                PDF, HTML, Markdown
              </Box>
            </MenuItem>
            <MenuItem
              icon={
                <Text fontSize="md">
                  <FiTrash2 />
                </Text>
              }
              onClick={setIsDeleteDialogOpen.on}
              _hover={{ color: red }}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>

      <EditJournalDialog
        isOpen={isEditJournalDialogOpen}
        journalId={journalId}
        onClose={setIsEditJournalDialogOpen.off}
      />

      <DeleteJournalDialog
        journalId={journalId}
        isOpen={isDeleteDialogOpen}
        onClose={setIsDeleteDialogOpen.off}
      />
      <WebExportJournalDialog
        journalId={journalId}
        isOpen={isExportDialogOpen}
        onClose={setIsExportDialogOpen.off}
      />
    </Fragment>
  );
}

export default memo(WebJournalMenu);
