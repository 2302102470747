import { FormControl, FormLabel, Input, Textarea, useToast } from '@chakra-ui/react';
import React, { useRef } from 'react';

import { withLazy } from '../../../shared/components/hoc/withLazy';
import { useContactUs } from '../../../shared/hooks/useContactUs';
import WebModal from '../WebModal';

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

function WebContactUsDialog({ isOpen, onClose }: Props) {
  const toast = useToast();
  const initialRef = useRef<HTMLTextAreaElement>(null);
  const {
    email,
    handleSend,
    inProgress,
    name,
    setEmail,
    setName,
    setText,
    text,
  } = useContactUs({ onDone: onClose, toast });

  return (
    <WebModal
      minimal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
      action="Send"
      actionIsLoading={inProgress}
      actionLoadingText="Sending..."
      onAction={handleSend}
      actionDisabled={text.length === 0 || inProgress}
      title="Contact us"
    >
      <FormControl mb={4}>
        <FormLabel>Name</FormLabel>
        <Input
          placeholder="Enter your name... (Optional)"
          value={name}
          onChange={x => setName(x.target.value)}
        />
      </FormControl>

      <FormControl mb={4}>
        <FormLabel>E-mail</FormLabel>
        <Input
          placeholder="Enter your e-mail... (Optional)"
          value={email}
          type="email"
          onChange={x => setEmail(x.target.value)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Message</FormLabel>
        <Textarea
          ref={initialRef}
          placeholder="Enter your message..."
          value={text}
          onChange={x => setText(x.target.value)}
        />
      </FormControl>
    </WebModal>
  )
}

export default React.memo(withLazy(WebContactUsDialog, 200));
