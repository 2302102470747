import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useBoolean,
} from '@chakra-ui/react';
import React from 'react';
import { useRef } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { withLazy } from '../../../shared/components/hoc/withLazy';
import { useKeyDownCallback } from '../../../shared/hooks';
import { useTurnPasscodeOn } from '../../../shared/hooks/useTurnPasscodeOn';

interface Props {
  readonly action?: string;
  readonly isOpen: boolean;
  readonly onClose: (success: boolean) => void;
}

function WebTurnOnPasscodeLockDialog({ isOpen, onClose, action }: Props) {
  const {
    handlePasscodeChange,
    handleRepeatPasscodeChange,
    isRepeatPasscodeInvalid,
    passcode,
    repeatPasscode,
    confirmPasscodeInputRef,
    turnPasscodeOnLock,
   } = useTurnPasscodeOn({ onClose });

  const handleKeyDown = useKeyDownCallback({
    onEnter: turnPasscodeOnLock,
    onEscape: () => onClose(false),
  });

  const passcodeInputRef = useRef<HTMLInputElement>(null);

  const [showPasscode, setShowPasscode] = useBoolean();
  const [showRepeatPasscode, setShowRepeatPasscode] = useBoolean();

  return (
    <Modal
      isOpen={isOpen}
      initialFocusRef={passcodeInputRef}
      onClose={() => onClose(false)}
    >
      <ModalOverlay>
        <ModalContent mx={[4, null]}>
          <ModalBody pt={6}>
            <FormControl mb={2}>
              <FormLabel>Passcode</FormLabel>
              <InputGroup>
                <Input
                  ref={passcodeInputRef}
                  type={showPasscode ? 'text' : 'password'}
                  placeholder="Enter passcode..."
                  value={passcode}
                  onChange={e => handlePasscodeChange(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <InputRightElement>
                  <IconButton
                    tabIndex={-1}
                    variant="ghost"
                    onClick={setShowPasscode.toggle}
                    icon={showPasscode ? <FiEyeOff /> : <FiEye />}
                    aria-label="toggle passcode visibility"
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl isInvalid={isRepeatPasscodeInvalid}>
              <FormLabel>Repeat passcode</FormLabel>
              <InputGroup>
                <Input
                  ref={confirmPasscodeInputRef}
                  isInvalid={isRepeatPasscodeInvalid}
                  type={showRepeatPasscode ? 'text' : 'password'}
                  placeholder="Repeat passcode..."
                  value={repeatPasscode}
                  onChange={e => handleRepeatPasscodeChange(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <InputRightElement>
                  <IconButton
                    tabIndex={-1}
                    variant="ghost"
                    onClick={setShowRepeatPasscode.toggle}
                    icon={showRepeatPasscode ? <FiEyeOff /> : <FiEye />}
                    aria-label="toggle passcode visibility"
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>Passcode doesn't match</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={() => onClose(false)}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              disabled={isRepeatPasscodeInvalid || passcode === '' || repeatPasscode === ''}
              onClick={turnPasscodeOnLock}
              ml={3}
            >
              {action ?? 'Turn on'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export default React.memo(withLazy(WebTurnOnPasscodeLockDialog, 200));
