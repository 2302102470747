import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { useRef } from 'react';

import { withLazy } from '../../../shared/components/hoc/withLazy';

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

function WebUnsupportedBackupFormatDialog({ isOpen, onClose }: Props) {
  const okRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={okRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent mx={[4, null]}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Restore failed
          </AlertDialogHeader>

          <AlertDialogBody>
            Unsupported backup file format
          </AlertDialogBody >

          <AlertDialogFooter>
            <Button colorScheme="blue" ref={okRef} onClick={onClose}>
              Ok
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default React.memo(withLazy(WebUnsupportedBackupFormatDialog, 200));
