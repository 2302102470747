import {
  Box,
  Flex,
  IconButton,
  Input,
  Tooltip,
  useClipboard,
  useColorModeValue,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FiCheck, FiCopy, FiEdit2, FiTrash2, FiX } from 'react-icons/fi';

import { useKeyDownCallback } from '../../hooks';

interface Props {
  readonly link?: string;

  readonly onEdit: (link: string) => void;
  readonly onDelete: () => void;

  readonly onInteractionStart: () => void;
  readonly onInteractionEnd: () => void;
}

function LinkPopover({ link, onEdit, onDelete, onInteractionStart, onInteractionEnd }: Props) {

  const [latestLink, setLatestLink] = useState(link);
  useEffect(() => {
    if (link !== undefined) {
      setLatestLink(link);
    }
  }, [link]);

  const { onCopy } = useClipboard(latestLink ?? '');

  const [editedLink, setEditedLink] = useState('');

  const { isOpen: isLinkEditActive, onToggle: toggleIsLinkEditActive } = useDisclosure();

  const linkEditInputRef = useRef<HTMLInputElement>(null);

  const startLinkEdit = useCallback(() => {
    setEditedLink(latestLink ?? '');
    toggleIsLinkEditActive();

    onInteractionStart();

    setTimeout(() => linkEditInputRef.current?.focus(), 0);
  }, [latestLink, toggleIsLinkEditActive, onInteractionStart]);

  const saveEditedLink = useCallback(() => {
    if (editedLink.length === 0) {
      onDelete();
    } else {
      onEdit(editedLink);
    }

    onInteractionEnd();
    toggleIsLinkEditActive();
  }, [onEdit, onDelete, toggleIsLinkEditActive, editedLink, onInteractionEnd]);

  const exitLinkEdit = useCallback(() => {
    onInteractionEnd();
    toggleIsLinkEditActive();
  }, [toggleIsLinkEditActive, onInteractionEnd]);

  const handleLinkChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedLink(e.target.value);
  }, []);

  const handleLinkEditKeyDown = useKeyDownCallback(
    useMemo(() => ({
      onEnter: saveEditedLink,
      onEscape: exitLinkEdit
    }), [exitLinkEdit, saveEditedLink])
  );

  const linkEditContainerRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: linkEditContainerRef,
    handler: () => {
      if (isLinkEditActive) {
        exitLinkEdit();
      }
    },
  });

  const bg = useColorModeValue('white', 'gray.700');
  const linkColor = useColorModeValue('#3c73d5', 'blue.400');

  return (
    <Box
      bg={bg}
      boxShadow={`
        rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
        rgba(15, 15, 15, 0.10) 0px 3px 6px,
        rgba(15, 15, 15, 0.20) 0px 9px 24px
      `}
    >
      <Flex alignItems="center" position="relative">
        <Box
          color={linkColor}
          fontWeight="semibold"
          textDecoration="underline"
          px={4}
          flex="1"
        >
          <Tooltip openDelay={500} placement="top" label="Open in a new tab">
            <Box isTruncated maxW="200px">
              <a href={latestLink} target="_blank" rel="noreferrer">{latestLink}</a>
            </Box>
          </Tooltip>
        </Box>
        <div>
          <Tooltip openDelay={500} placement="top" label="Copy">
            <IconButton borderRadius="none" icon={<FiCopy />} onClick={onCopy} aria-label="copy" variant="ghost" />
          </Tooltip>
          <Tooltip openDelay={500} placement="top" label="Edit">
            <IconButton borderRadius="none" icon={<FiEdit2 />} onClick={startLinkEdit} aria-label="edit" variant="ghost" />
          </Tooltip>
          <Tooltip openDelay={500} placement="top" label="Delete">
            <IconButton borderRadius="none" icon={<FiTrash2 />} onClick={onDelete} aria-label="delete" variant="ghost" />
          </Tooltip>
        </div>

        <Box
          bg={bg}
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display={isLinkEditActive ? 'block' : 'none'}
          ref={linkEditContainerRef}
        >
          <Flex>
            <Box flex="1">
              <Input
                value={editedLink}
                bg={bg}
                onChange={handleLinkChange}
                onKeyDown={handleLinkEditKeyDown}
                variant="ghost"
                ref={linkEditInputRef}
              />
            </Box>
            <div>
              <Tooltip openDelay={500} placement="top" label="Save">
                <IconButton icon={<FiCheck />} onClick={saveEditedLink} aria-label="copy" variant="ghost" />
              </Tooltip>
              <Tooltip openDelay={500} placement="top" label="Cancel">
                <IconButton icon={<FiX />} onClick={exitLinkEdit} aria-label="edit" variant="ghost" />
              </Tooltip>
            </div>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export default memo(LinkPopover);
