import { Flex, HStack, Spacer } from '@chakra-ui/layout';
import { IconButton, MenuButton, useBoolean } from '@chakra-ui/react';
import { memo, useCallback } from 'react';
import { FiMenu, FiX, FiMoreHorizontal } from 'react-icons/fi';

import { useIsMobile } from '../../shared/hooks';
import { Icon, Journal } from '../../shared/data/data-types/entities/journal';
import { useDataService } from '../../shared/components/DataServiceContext';
import { withJournal } from '../../shared/components/hoc/withJournal';
import WebIconPickerPopover from './WebIconPickerPopover';
import JournalIcon from '../../shared/components/JournalIcon';
import WebJournalMenu from './WebJournalMenu';
import JournalNameInput from './WebJournalNameInput';

interface Props {
  readonly journal: Journal;
  readonly isSidebarOpen: boolean;
  readonly toggleSidebar: () => void;
}

function WebJournalTopBar({ journal, toggleSidebar, isSidebarOpen }: Props) {
  const [isIconPickerOpen, setIsIconPickerOpen] = useBoolean();

  const isMobile = useIsMobile();

  const dataService = useDataService();
  const handleIconChange = useCallback((icon: Icon | undefined) => {
    dataService.execute({ type: 'journal/set_icon', journalId: journal.id, icon });
    setIsIconPickerOpen.off();
  }, [dataService, journal, setIsIconPickerOpen]);

  return (
    <Flex px={4} align="center" height="56px">
      {isMobile && (
        <IconButton
          icon={isSidebarOpen ? <FiX /> : <FiMenu />}
          size="sm"
          fontSize="xl"
          variant="ghost"
          aria-label="menu"
          onClick={toggleSidebar}
          mr={2}
        />
      )}
      <HStack ml={2} spacing={0} minW={0} overflow="hidden">
        <WebIconPickerPopover
          isOpen={isIconPickerOpen}
          onClose={setIsIconPickerOpen.off}
          value={journal.icon}
          onChange={handleIconChange}
        >
          <IconButton
            onClick={setIsIconPickerOpen.toggle}
            icon={<JournalIcon journalId={journal.id} />}
            aria-label="Pick journal icon"
            fontSize="md"
            size="xs"
            variant="ghost"
          />
        </WebIconPickerPopover>
        <div>
          <div>
            <JournalNameInput fontSize={16} fontWeight="normal" journalId={journal.id} />
          </div>
        </div>
      </HStack>
      <Spacer />
      <div>
        <WebJournalMenu journalId={journal.id}>
          <MenuButton fontSize="2xl" as={IconButton} aria-label="Options" icon={<FiMoreHorizontal />} variant="ghost" />
        </WebJournalMenu>
      </div>
    </Flex>
  )
}

export default memo(withJournal(WebJournalTopBar));
