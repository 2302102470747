import { useBoolean } from '@chakra-ui/react';
import { useCallback, useState } from 'react';

import { ApiClient } from '../services/ApiClient';
import { AuthService } from '../services/AuthService';

export interface ContactUsToastOptions {
  readonly status: 'success' | 'error';
  readonly title: string;
}

interface Options {
  readonly onDone: () => void;
  readonly toast: (options: ContactUsToastOptions) => void;
}

export function useContactUs({ onDone, toast }: Options) {
  const [email, setEmail] = useState(AuthService.getIdentitySync()?.email ?? '');
  const [name, setName] = useState('');
  const [text, setText] = useState('');

  const [isTextInvalid, setIsTextInvalid] = useBoolean();

  const [inProgress, setInProgress] = useBoolean();
  const handleSend = useCallback(async () => {
    if (inProgress) return;

    if (text.trim() === '') {
      setIsTextInvalid.on();
      return;
    }

    try {
      setInProgress.on();
      const result = await ApiClient.postMessage({ name, email, text });
      if (result.success) {
        toast({ status: 'success', title: 'Message was sent successfully' });
        onDone();
      } else {
        toast({ status: 'error', title: 'Currently unable to send your message' });
      }
    } finally {
      setInProgress.off();
    }
  }, [inProgress, text, setIsTextInvalid, setInProgress, name, email, toast, onDone]);

  return {
    email,
    setEmail,
    name,
    setName,
    text, setText,
    inProgress,
    handleSend,
    isTextInvalid,
  }
}
