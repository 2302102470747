import { Box, Button, Flex, Slide, useBoolean, useColorModeValue } from '@chakra-ui/react';
import React, { Fragment, memo, useEffect, useMemo, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useParams, useRouteMatch } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Journal, JournalId } from '../../shared/data/data-types/entities/journal';
import { Timestamp } from '../../shared/data/data-types/timestamp';
import { wait } from '../../shared/data/utils';
import { EntryListOptions, useIsMobile, useJournals, useStateDelay, useTextOptional } from '../../shared/hooks';
import { isElectron } from '../../shared/utils';
import WebAddJournalDialog from '../components/dialogs/WebAddJournalDialog';
import WebAddJournalFab from '../components/WebAddJournalFab';
import WebJournalDashboard from '../components/WebJournalDashboard';
import { WebJournalEntryListContextProvider } from '../components/WebJournalEntryListContext';
import WebJournalHelpFab from '../components/WebJournalHelpFab';
import WebJournalTopBar from '../components/WebJournalTopBar';
import WebScrollBox from '../components/WebScrollBox';
import WebSidebar from '../components/WebSidebar';
import WebJournalTimelinePage from './journal/WebJournalTimelinePage';

const CONTENT_HEIGHT = 'calc(var(--vh-100) - 56px - 1px)';

function WebJournalPage() {
  const params = useParams<any>();
  const journalId = params.journalId as (JournalId | undefined);
  let journals = useJournals();

  const activeJournal: Journal | undefined = journals.find(x => x.id === journalId) ?? journals[0];

  useEffect(() => {
    if (!isElectron()) {
      if (activeJournal === undefined) {
        if (journalId !== undefined) {
          window.history.replaceState(window.history.state, '', `/`);
        }
      } else {
        if (activeJournal.id !== journalId) {
          window.history.replaceState(window.history.state, '', `/journals/${activeJournal.id}`);
        }
      }
    }
  }, [activeJournal, journalId]);

  const textOptional = useTextOptional();
  const [isAddJournalDialogOpen, setIsAddJournalDialogOpen] = useBoolean();
  const [isSidebarOpen, setIsSidebarOpen] = useBoolean();

  const closeSidebar = setIsSidebarOpen.off;
  useEffect(() => closeSidebar(), [activeJournal?.id, closeSidebar]);

  const isMobile = useIsMobile();

  const [renderMobileSidebar, setRenderMobileSidebar] = useState(false);
  useEffect(() => { wait(1).then(() => setRenderMobileSidebar(true)) }, []);

  return (
    <Flex minH="var(--vh-100)">
      {isMobile && renderMobileSidebar && (
        <Fragment>
          <Slide
            direction="left"
            in={isSidebarOpen}
            style={{ height: CONTENT_HEIGHT, top: 'auto', bottom: 0, width: '300px', zIndex: 1400 }}
          >
            <Box h="100%" transition="background-color 200ms" bg="bg-light-gray">
              <WebSidebar activeJournalId={activeJournal?.id} />
            </Box>
          </Slide>
          <Box
            position="fixed"
            h={CONTENT_HEIGHT}
            right="0"
            bottom="0"
            left="0"
            background={isSidebarOpen ? 'blackAlpha.500' : 'transparent'}
            pointerEvents={isSidebarOpen ? undefined : 'none'}
            transition="background-color 200ms"
            onClick={setIsSidebarOpen.off}
            zIndex="overlay"
          />
        </Fragment>
      )}
      {!isMobile && (
        <Box
          transition="background-color 200ms"
          bg="bg-light-gray"
          h="var(--vh-100)"
          w={SIDEBAR_WIDTH}
        >
          <WebSidebar activeJournalId={activeJournal?.id} />
        </Box>
      )}
      {isMobile ? <WebAddJournalFab journalId={activeJournal.id} /> : <WebJournalHelpFab />}
      <Box flex={1} minH="var(--vh-100)" minW={0}>
        {activeJournal && <WebJournalPageContent
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={setIsSidebarOpen.toggle}
          activeJournal={activeJournal}
          key={activeJournal.id}
        />}
        {!activeJournal && (
          <Box textAlign="center" mt="calc((var(--vh-100) - 108px) / 2)" px={4}>
            <Box fontSize="2xl" fontWeight="normal" color={textOptional} mb={6}>
              Looks like you haven't created any journals yet!
            </Box>
            <Button
              onClick={setIsAddJournalDialogOpen.on}
              leftIcon={<FiPlus />}
              size="lg"
              colorScheme="blue"
            >
              Create a new journal
            </Button>
          </Box>
        )}

        <WebAddJournalDialog isOpen={isAddJournalDialogOpen} onClose={setIsAddJournalDialogOpen.off} />
      </Box>
    </Flex>
  );
}

export default memo(WebJournalPage);

interface JournalPageInternalProps {
  readonly activeJournal: Journal;
  readonly isSidebarOpen: boolean;
  readonly toggleSidebar: () => void;
}

const SIDEBAR_WIDTH = 270;

function WebJournalPageContent({ activeJournal, toggleSidebar, isSidebarOpen }: JournalPageInternalProps) {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [sort, setSort] = useState<'asc' | 'desc'>('desc');
  const [bookmarked, setBookmarked] = useState<true | undefined>(undefined);
  const [before, setBefore] = useState<Timestamp | undefined>(undefined);
  const [after, setAfter] = useState<Timestamp | undefined>(undefined);

  const [delayedSearchText, setDelayedSearchText] = useStateDelay<string | undefined>(searchText);
  useEffect(() => setDelayedSearchText(searchText, searchText === undefined ? undefined : 500), [searchText, setDelayedSearchText]);

  const listOptions = useMemo((): EntryListOptions => ({
    bookmarked,
    before,
    after,
    sort,
    text: delayedSearchText,
    journalIds: activeJournal === undefined ? undefined : [activeJournal.id],
  }), [activeJournal, after, before, bookmarked, delayedSearchText, sort]);

  const isMobile = useIsMobile();
  const topBarBorderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <WebJournalEntryListContextProvider options={listOptions}>
      <Box borderBottom="1px solid" borderBottomColor={[topBarBorderColor, 'transparent']}>
        <WebJournalTopBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} journalId={activeJournal.id} />
      </Box>
      <WebScrollBox h={CONTENT_HEIGHT}>
        <Box pb={[20, 0]} mt={[2, 8]} px={4} maxW="100%" w={{ lg: 62 * 16 - SIDEBAR_WIDTH, xl: '900px' }} mx={{ lg: 'auto' }}>
          {!isMobile && (
            <WebJournalDashboard
              key={activeJournal.id}
              sort={sort}
              onSortChange={setSort}
              searchText={searchText}
              onSearchTextChange={setSearchText}
              journalId={activeJournal.id}
              entryListOptions={listOptions}
              bookmarked={bookmarked}
              before={before}
              after={after}
              onBookmarkedChange={setBookmarked}
              onBeforeChange={setBefore}
              onAfterChange={setAfter}
            />
          )}
          <Box mt={6}>
            <WebJournalPageRoutesMemo entryListOptions={listOptions} journalId={activeJournal.id} />
          </Box>
        </Box>
      </WebScrollBox>
    </WebJournalEntryListContextProvider>
  );
}

interface WebJournalPageRoutesProps {
  readonly journalId: JournalId;
  readonly entryListOptions: EntryListOptions;
}

function WebJournalPageRoutes({ journalId, entryListOptions }: WebJournalPageRoutesProps) {
  const { path } = useRouteMatch();

  return (
    <Switch key={journalId}>
      <Route path={`${path}/timeline`}>
        <WebJournalTimelinePage journalId={journalId} entryListOptions={entryListOptions} />
      </Route>
      <Route>
        <Redirect to={`/journals/${journalId}/timeline`} />
      </Route>
    </Switch>
  );
}

const WebJournalPageRoutesMemo = React.memo(WebJournalPageRoutes);
