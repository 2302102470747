export const config = {
  // apiUrl: process.env.REACT_APP_API_URL ?? 'http://localhost:8080',
  // apiUrl: process.env.REACT_APP_API_URL ?? 'https://api-dev.journalify.com',
  apiUrl: process.env.REACT_APP_API_URL ?? 'https://api.journalify.com',
  // mediaUrl: process.env.REACT_APP_MEDIA_URL ?? 'https://media-dev.journalify.com',
  mediaUrl: process.env.REACT_APP_MEDIA_URL ?? 'https://media.journalify.com',
  appUrl: process.env.REACT_APP_URL ?? 'journalify://',
  googleClientId: '271438004826-0auhkgej76ah3fr4ln1qnkqigaiko4h3.apps.googleusercontent.com',
  facebookClientId: '809829393745980',
  crypto: process.env.REACT_APP_CRYPTO === 'web' ? 'web' as const : 'dummy' as const,
}
