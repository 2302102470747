import { PasscodeMetadata } from "./cryptography";

export class AppError<TType extends string> extends Error {
  constructor(
    public readonly type: TType,
    message: string,
  ) {
    super(message);
  }
}

export class ConcurrencyError extends AppError<'concurrency'> {
  constructor() {
    super('concurrency', 'Concurrency error');
  }
}

export class NetworkError extends AppError<'network'> {
  constructor() {
    super('network', 'Network error');
  }
}

export class WrongPasscodeError extends AppError<'wrong_passcode'> {
  constructor() {
    super('wrong_passcode', 'wrong passcode');
  }
}

export class StorageQuotaError extends AppError<'storage_quota'> {
  constructor() {
    super('storage_quota', 'Storage quota exceeded');
  }
}

export class UnknownError extends AppError<'unknown'> {
  constructor(
    public error: unknown,
  ) {
    super('unknown', 'Unexpected error');
  }
}

export class PasscodeRequiredError extends AppError<'passcode_required'> {
  constructor(public readonly metadata: PasscodeMetadata) {
    super('passcode_required', 'Passcode required for syncronization')
  }
}

export class EncryptionKeyNotFoundError extends AppError<'encryption_key_not_found'> {
  constructor() {
    super('encryption_key_not_found', 'Encryption config is outdated. Resync in order to execute this operation');
  }
}
