import { useBoolean } from '@chakra-ui/react';
import { useCallback, useRef, useState } from 'react';

import { useDataService } from '../components/DataServiceContext';
import { usePasscode } from '../components/DataServiceStateContext';
import { wait } from '../utils';

interface Options {
  readonly onClose: () => void;
}

export function useTurnPasscodeOff({ onClose }: Options) {
  const effectivePasscode = usePasscode();
  const [confirmPasscode, setConfirmPasscode] = useState('');
  const [isConfirmPasscodeInvalid, setIsConfirmPasscodeInvlid] = useBoolean();

  const confirmPasscodeInputRef = useRef<HTMLInputElement>(null);

  const handleConfirmPasscodeChange = useCallback((value: string) => {
    setConfirmPasscode(value);
    setIsConfirmPasscodeInvlid.off();
  }, [setIsConfirmPasscodeInvlid]);

  const dataService = useDataService();
  const turnOffPrivacyLock = useCallback(() => {
    const valid = effectivePasscode?.text === confirmPasscode;
    if (valid) {
      dataService.decrypt().then(() => onClose());
    } else {
      setIsConfirmPasscodeInvlid.on();
      wait(0).then(() => confirmPasscodeInputRef.current?.focus());
    }
  }, [effectivePasscode, confirmPasscode, dataService, onClose, setIsConfirmPasscodeInvlid]);

  return {
    confirmPasscode,
    confirmPasscodeInputRef,
    handleConfirmPasscodeChange,
    turnOffPrivacyLock,
    isConfirmPasscodeInvalid,
  };
}
