import { Button, Flex, Spacer } from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { Timestamp } from '../../../shared/data/data-types/timestamp';

interface Props {
  readonly timestamp: Timestamp;
  readonly onNext: () => void;
  readonly onPrev: () => void;
  readonly hasNext: boolean;
  readonly hasPrev: boolean;
}

function WebHistoryNavigation({ timestamp, onNext, onPrev, hasNext, hasPrev }: Props) {
  return (
    <Flex alignItems="center" h="56px">
      <div>
        <Button
          h="56px"
          isDisabled={!hasPrev}
          borderRadius={0}
          onClick={onPrev}
          size="lg"
          variant="ghost"
        >
          <FiChevronLeft />
        </Button>
      </div>
      <Spacer />
      <div>
        Version edited at {format(new Date(timestamp), 'PP p')}
      </div>
      <Spacer />
      <div>
        <Button
          h="56px"
          isDisabled={!hasNext}
          borderRadius={0}
          onClick={onNext}
          size="lg"
          variant="ghost"
        >
          <FiChevronRight />
        </Button>
      </div>
    </Flex>
  )
}

export default React.memo(WebHistoryNavigation);
