import { Brand } from '../utils';

export type TimeRange = [Timestamp, Timestamp];

export type Timestamp = Brand<number, 'Timestamp'>;
export type Duration = Brand<number, 'Duration'>;

export function getTimestamp() {
  return Date.now() as Timestamp;
}

export function toTimestamp(date: Date) {
  return date.getTime() as Timestamp;
}
