import { memo, useCallback } from 'react';
import { useParams } from 'react-router';

import { JournalId } from '../../shared/data/data-types/entities/journal';
import { useIsMobile, useJournal } from '../../shared/hooks';
import { isElectron } from '../../shared/utils';
import WebEntryEditor from '../components/editor/WebEntryEditor';
import NotFoundFeedback from '../components/NotFoundFeedback';
import WebEntryHelpFab from '../components/WebEntryHelpFab';

function WebCreateEntryPage() {
  const params = useParams<any>();
  const journalId = params.journalId! as JournalId;

  const handleOnCreate = useCallback((entryId: string) => {
    if (!isElectron()) {
      // we have to use native api in order to prevent react router from changing the active route
      window.history.replaceState(window.history.state, '', `/journals/${journalId}/entries/${entryId}`);
    }
  }, [journalId]);

  const journal = useJournal(journalId);

  const isMobile = useIsMobile();

  return (
    <div>
      {journal !== undefined && (
        <WebEntryEditor onCreate={handleOnCreate} journalId={journalId} />
      )}

      {journal === undefined && (
        <NotFoundFeedback />
      )}

      {!isMobile && <WebEntryHelpFab />}
    </div>
  );
}

export default memo(WebCreateEntryPage);
