import { Box, Button, Flex, Input, Spacer } from '@chakra-ui/react';
import { memo, useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import EmojiList from '../../shared/components/EmojiList';
import { Icon } from '../../shared/data/data-types/entities/journal';
import emojis from '../../shared/emojis.json';
import { useScrollParentAccessor, useStateDelay, useTextOptional, useTextSecondary } from '../../shared/hooks';
import WebScrollBox from './WebScrollBox';

interface Props {
  readonly value: Icon | undefined;
  readonly onChange: (icon: Icon | undefined) => void;
}

function WebIconPicker({ value, onChange }: Props) {
  const [limit, setLimit] = useState(1);
  const loadMore = useCallback(() => setLimit(prev => prev + 1), []);

  const [osRef, getScrollParent] = useScrollParentAccessor();
  const hasMore = limit < emojis.length;

  const textSecondary = useTextSecondary();
  const textOptional = useTextOptional();

  const [filter, setFilter] = useState('');
  const [delayedFilter, setDelayedFilter] = useStateDelay(filter);
  useEffect(() => setDelayedFilter(filter, 500), [filter, setDelayedFilter]);
  useEffect(() => setLimit(1), [delayedFilter]);

  const handleRandom = useCallback(() => {
    const length = emojis.reduce((a, b) => a + b.emojis.length, 0);
    let left = Math.trunc(Math.random() * length);

    for (const group of emojis) {
      if (left >= group.emojis.length) {
        left -= group.emojis.length;
      } else {
        onChange({ type: 'emoji', unicode: group.emojis[left].unicode });
        break;
      }
    }
  }, [onChange]);

  const handleEmojiClick = useCallback((unicode: string) => {
    onChange({ type: 'emoji', unicode });
  }, [onChange]);

  return (
    <div>
      <Flex px={2} pt={1} alignItems="center">
        <Box p={1} fontSize="sm" fontWeight="semibold">
          Emoji
        </Box>
        <Spacer />
        <Button
          variant="ghost"
          color={textSecondary}
          size="sm"
          fontWeight="normal"
          onClick={handleRandom}
        >
          Random
        </Button>
        {value !== undefined && (
          <Button
            variant="ghost"
            color={textSecondary}
            size="sm"
            fontWeight="normal"
            onClick={() => onChange(undefined)}
          >
            Remove
          </Button>
        )}
      </Flex>
      <Box p={2}>
        <Input
          value={filter}
          onChange={e => setFilter(e.target.value)}
          pl={2}
          size="sm"
          placeholder="Filter..."
        />
      </Box>
      <WebScrollBox ref={osRef} h="300px" maxH="var(--vh-60)">
        <Box p={2}>
          <InfiniteScroll
            pageStart={0}
            threshold={200}
            loadMore={loadMore}
            useWindow={false}
            getScrollParent={getScrollParent}
            hasMore={hasMore}
          >
            <EmojiList
              filter={delayedFilter}
              limit={limit}
              onClick={handleEmojiClick}
              empty={
                <Box textAlign="center" py={16} px={2}>
                  <Box fontSize="md" fontWeight="semibold" color={textSecondary}>
                    No results
                  </Box>
                  <Box fontSize="sm" color={textOptional}>
                    Try different search terms
                  </Box>
                </Box>
              }
            />
          </InfiniteScroll>
        </Box>
      </WebScrollBox>
    </div>
  );
}

export default memo(WebIconPicker);
