import { SystemStyleObject } from '@chakra-ui/react';
import Quill from 'quill/core';

const Inline = Quill.import('blots/inline')

export class TextColor extends Inline {
  static blotName = 'text_color';
  static clasName = 'text-color';

  static create(value: any) {
    const node: HTMLElement = super.create(value);
    if (value) {
      const classSelector = `.text-color-${value}`;
      if (classSelector in TEXT_COLOR_STYLE_LIGHT_OBJECT || classSelector in TEXT_COLOR_STYLE_DARK_OBJECT) {
        node.classList.add(`text-color-${value}`);
      }
    }
    return node;
  }

  static formats(domNode: HTMLElement) {
    let formats: string | undefined = undefined;
    domNode.classList.forEach(className => {
      if (className.startsWith('text-color-')) {
        formats = className.slice("text-color-".length);
      }
    });

    return formats;
  }
}

export const TEXT_COLOR_STYLE_LIGHT_OBJECT: SystemStyleObject = {
  '.text-color-gray': { color: 'rgba(120, 119, 116, 1)' },
  '.text-color-brown': { color: 'rgba(159, 107, 83, 1)' },
  '.text-color-orange': { color: 'rgba(217, 115, 13, 1)' },
  '.text-color-yellow': { color: 'rgba(203, 145, 47, 1)' },
  '.text-color-green': { color: 'rgba(68, 131, 97, 1)' },
  '.text-color-blue': { color: 'rgba(51, 126, 169, 1)' },
  '.text-color-purple': { color: 'rgba(144, 101, 176, 1)' },
  '.text-color-pink': { color: 'rgba(193, 76, 138, 1)' },
  '.text-color-red': { color: 'rgba(212, 76, 71, 1)' },
  '.text-color-bg-gray': { background: 'rgba(241, 241, 239, 1)' },
  '.text-color-bg-brown': { background: 'rgba(244, 238, 238, 1)' },
  '.text-color-bg-orange': { background: 'rgba(251, 236, 221, 1)' },
  '.text-color-bg-yellow': { background: 'rgba(251, 243, 219, 1)' },
  '.text-color-bg-green': { background: 'rgba(237, 243, 236, 1)' },
  '.text-color-bg-blue': { background: 'rgba(231, 243, 248, 1)' },
  '.text-color-bg-purple': { background: 'rgba(244, 240, 247, 0.8)' },
  '.text-color-bg-pink': { background: 'rgba(249, 238, 243, 0.8)' },
  '.text-color-bg-red': { background: 'rgba(253, 235, 236, 1)' },
};

export const TEXT_COLOR_STYLE_DARK_OBJECT: SystemStyleObject = {
  '.text-color-gray': { color: 'rgba(159, 164, 169, 1)' },
  '.text-color-brown': { color: 'rgba(212, 150, 117, 1)' },
  '.text-color-orange': { color: 'rgba(217, 133, 56, 1)' },
  '.text-color-yellow': { color: 'rgba(201, 145, 38, 1)' },
  '.text-color-green': { color: 'rgba(113, 178, 131, 1)' },
  '.text-color-blue': { color: 'rgba(102, 170, 218, 1)' },
  '.text-color-purple': { color: 'rgba(176, 152, 217, 1)' },
  '.text-color-pink': { color: 'rgba(223, 132, 209, 1)' },
  '.text-color-red': { color: 'rgba(234, 135, 140, 1)' },
  '.text-color-bg-gray': { background: 'rgba(60, 65, 68, 1)' },
  '.text-color-bg-brown': { background: 'rgba(76, 61, 53, 1)' },
  '.text-color-bg-orange': { background: 'rgba(85, 59, 41, 1)' },
  '.text-color-bg-yellow': { background: 'rgba(79, 64, 41, 1)' },
  '.text-color-bg-green': { background: 'rgba(46, 68, 58, 1)' },
  '.text-color-bg-blue': { background: 'rgba(45, 66, 86, 1)' },
  '.text-color-bg-purple': { background: 'rgba(69, 58, 91, 1)' },
  '.text-color-bg-pink': { background: 'rgba(81, 56, 77, 1)' },
  '.text-color-bg-red': { background: 'rgba(94, 52, 54, 1)' },
};
