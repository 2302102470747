import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useBoolean,
} from '@chakra-ui/react';
import React from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { withLazy } from '../../../shared/components/hoc/withLazy';
import { useKeyDownCallback } from '../../../shared/hooks';
import { useTurnPasscodeOff } from '../../../shared/hooks/useTurnPasscodeOff';

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

function WebTurnOffPasscodeLockDialog({ isOpen, onClose }: Props) {
  const {
    confirmPasscode,
    confirmPasscodeInputRef,
    handleConfirmPasscodeChange,
    isConfirmPasscodeInvalid,
    turnOffPrivacyLock,
   } = useTurnPasscodeOff({ onClose });

  const handleKeyDown = useKeyDownCallback({
    onEnter: turnOffPrivacyLock,
    onEscape: onClose,
  });

  const [showConfirmPasscode, setShowConfirmPasscode] = useBoolean();

  return (
    <Modal
      isOpen={isOpen}
      initialFocusRef={confirmPasscodeInputRef}
      onClose={onClose}
    >
      <ModalOverlay>
        <ModalContent mx={[4, null]}>
          <ModalBody pt={6}>
            <FormControl isInvalid={isConfirmPasscodeInvalid}>
              <FormLabel>Current Passcode</FormLabel>
              <InputGroup>
                <Input
                  ref={confirmPasscodeInputRef}
                  isInvalid={isConfirmPasscodeInvalid}
                  type={showConfirmPasscode ? 'text' : 'password'}
                  placeholder="Enter current passcode..."
                  value={confirmPasscode}
                  onChange={e => handleConfirmPasscodeChange(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <InputRightElement>
                  <IconButton
                    tabIndex={-1}
                    variant="ghost"
                    onClick={setShowConfirmPasscode.toggle}
                    icon={showConfirmPasscode ? <FiEyeOff /> : <FiEye />}
                    aria-label="toggle passcode visibility"
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>Passcode doesn't match</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              disabled={isConfirmPasscodeInvalid || confirmPasscode === ''}
              onClick={turnOffPrivacyLock}
              ml={3}
            >
              Turn off
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export default React.memo(withLazy(WebTurnOffPasscodeLockDialog, 200));
