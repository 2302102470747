import { memo } from 'react';

import { JournalId } from '../../../shared/data/data-types/entities/journal';
import { EntryListOptions } from '../../../shared/hooks';
import WebEntryList from '../../components/WebEntryList';

interface Props {
  readonly journalId: JournalId;
  readonly entryListOptions: EntryListOptions;
}

function WebJournalTimelinePage({ journalId, entryListOptions }: Props) {
  return (
    <WebEntryList journalId={journalId} options={entryListOptions} />
  )
}

export default memo(WebJournalTimelinePage);
