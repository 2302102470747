import { ColorMode } from "@chakra-ui/react";
import { customAlphabet } from "nanoid";
import { DataService } from "./data/data-service";

import { Journal, JournalId } from "./data/data-types/entities/journal";
import {
  StyledBackground,
  StyledFont,
} from "./data/data-types/entities/styled";
import { bytesToBase64 } from "./data/utils";
import emojis from "./emojis.json";

export interface FormatDateOptions {
  readonly precise: boolean;
}

export function formatDate(timestamp: number, { precise }: FormatDateOptions) {
  return new Date(timestamp).toLocaleDateString("en-US", {
    weekday: precise ? undefined : "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: precise ? "numeric" : undefined,
    minute: precise ? "2-digit" : undefined,
  });
}

export function toObjectMap<T>(
  items: readonly T[],
  keySelector: (item: T) => string
): { [key: string]: T };
export function toObjectMap<T, TValue>(
  items: readonly T[],
  keySelector: (item: T) => string,
  valueSelector: (item: T) => TValue
): { [key: string]: TValue };
export function toObjectMap<T>(
  items: readonly T[],
  keySelector: (item: T) => string,
  valueSelector?: (item: T) => any
) {
  valueSelector = valueSelector ?? ((x) => x);

  const obj: { [key: string]: any } = {};
  for (const item of items) {
    obj[keySelector(item)] = valueSelector(item);
  }

  return obj;
}

export function postpone(fn: () => void) {
  Promise.resolve().then(fn);
}

export function wait(milliseconds: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

const nanoid = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyz", 20);
export function generateId() {
  return nanoid();
}

export function readBlobAsBuffer(blob: Blob) {
  return new Promise<Uint8Array>((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onload = function () {
      resolve(new Uint8Array(reader.result as ArrayBuffer));
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
}

export function buildDataUrl(mimeType: string, buffer: Uint8Array) {
  return `data:${mimeType};charset=utf-8;base64,${bytesToBase64(buffer)}`;
}

export function zeroPad(num: number, places: number) {
  const zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

export interface Subscribable<TEvent = unknown> {
  subscribe(callback: (event: TEvent) => void): () => void;
}

export class AppEmitter<TEvent = unknown> implements Subscribable<TEvent> {
  private callbacks: Array<(event: TEvent) => void> = [];

  constructor() {
    this.subscribe = this.subscribe.bind(this);
    this.emit = this.emit.bind(this);
  }

  subscribe(callback: (event: TEvent) => void): () => void {
    this.callbacks.push(callback);
    return () => {
      this.callbacks = this.callbacks.filter((x) => x !== callback);
    };
  }

  emit(event: TEvent) {
    this.callbacks.forEach((cb) => cb(event));
  }
}

export function getBackground(opacity: number, colorMode: ColorMode) {
  if (colorMode === "light") {
    return `rgba(255, 255, 255, ${opacity})`;
  } else {
    return `rgba(26, 32, 44, ${opacity})`;
  }
}

export function getBackgroundColor(
  background: StyledBackground | undefined,
  colorMode: ColorMode
) {
  if (colorMode === "light") {
    switch (background?.type) {
      case "gray":
        return "gray.300";
      case "red":
        return "red.500";
      case "orange":
        return "orange.500";
      case "yellow":
        return "yellow.500";
      case "green":
        return "green.500";
      case "teal":
        return "teal.500";
      case "blue":
        return "blue.500";
      case "cyan":
        return "cyan.500";
      case "purple":
        return "purple.500";
      case "pink":
        return "pink.500";
      default:
        return "transparent";
    }
  } else {
    switch (background?.type) {
      case "gray":
        return "gray.600";
      case "red":
        return "red.400";
      case "orange":
        return "orange.400";
      case "yellow":
        return "yellow.400";
      case "green":
        return "green.400";
      case "teal":
        return "teal.400";
      case "blue":
        return "blue.400";
      case "cyan":
        return "cyan.400";
      case "purple":
        return "purple.400";
      case "pink":
        return "pink.400";
      default:
        return "transparent";
    }
  }
}

export function getPadColor(
  background: StyledBackground | undefined,
  colorMode: ColorMode
) {
  if (colorMode === "light") {
    switch (background?.type) {
      case "gray":
        return "rgba(241, 241, 239, 1)";
      case "red":
        return "rgba(253, 235, 236, 1)";
      case "orange":
        return "rgba(251, 236, 221, 1)";
      case "yellow":
        return "rgba(251, 243, 219, 1)";
      case "green":
        return "rgba(237, 243, 236, 1)";
      case "teal":
        return "teal.50";
      case "blue":
        return "rgba(231, 243, 248, 1)";
      case "cyan":
        return "cyan.50";
      case "purple":
        return "rgba(244, 240, 247, 0.8)";
      case "pink":
        return "rgba(249, 238, 243, 0.8)";
      default:
        return "white";
    }
  } else {
    switch (background?.type) {
      case "gray":
        return "rgba(60, 65, 68, 1)";
      case "red":
        return "rgba(94, 52, 54, 1)";
      case "orange":
        return "rgba(85, 59, 41, 1)";
      case "yellow":
        return "rgba(79, 64, 41, 1)";
      case "green":
        return "rgba(46, 68, 58, 1)";
      case "teal":
        return "teal.800";
      case "blue":
        return "rgba(45, 66, 86, 1)";
      case "cyan":
        return "cyan.800";
      case "purple":
        return "rgba(69, 58, 91, 1)";
      case "pink":
        return "rgba(81, 56, 77, 1)";
      default:
        return "gray.800";
    }
  }
}

export function getFontFamily(
  font: StyledFont,
  isBody: boolean = true
): string {
  switch (font.type) {
    case "cursive":
      return `"Brush Script MT", cursive`;
    case "mono":
      return `'Courier New', monospace`;
    case "sans-serif":
      return isBody
        ? `Geneva,Arial,sans-serif`
        : `"Lucida Grande","Lucida Sans Unicode","Lucida Sans",Geneva,Arial,sans-serif`;
    case "serif":
      return `Georgia, Cambria,"Times New Roman", Times, serif`;
    case "fantasy":
      return `Impact, fantasy`;
    default:
      return `"Lucida Grande","Lucida Sans Unicode","Lucida Sans",Geneva,Arial,sans-serif`;
  }
}

const emojisMap = new Map(
  emojis.flatMap((x) => x.emojis).map((x) => [x.unicode, x])
);

export function getEmojiName(unicode: string): string | undefined {
  return emojisMap.get(unicode)?.name;
}

export function calcOrderBetween(lower: number, upper: number): number {
  return lower + (upper - lower) / 2 + (Math.random() * (upper - lower)) / 128;
}

type NonOptional<T> = T extends undefined ? never : T;

export function fallback<TValue, TResult>(
  value: TValue,
  action: (x: NonOptional<TValue>) => TResult,
  otherwise: TResult
): TResult {
  if (value === undefined) {
    return otherwise;
  }

  return action(value as any);
}

export function isTouchDevice() {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    (navigator as any).msMaxTouchPoints > 0
  );
}

export const ORDER_LOWER_BOUND = -(Number.MAX_VALUE / 3) * 1.0234;
export const ORDER_UPPER_BOUND = (Number.MAX_VALUE / 3) * 1.2345;

export function moveJournal(
  dataService: DataService,
  journals: readonly Journal[],
  journalId: JournalId,
  to: number
) {
  let below = to <= 0 ? ORDER_LOWER_BOUND : journals[to - 1].order;
  let above = to >= journals.length ? ORDER_UPPER_BOUND : journals[to].order;

  below = Number.isFinite(below) ? below : ORDER_LOWER_BOUND;
  above = Number.isFinite(above) ? above : ORDER_UPPER_BOUND;

  return dataService.execute({
    type: "journal/set_order",
    journalId,
    order: calcOrderBetween(below, above),
  });
}

export function groupBy<TKey, TValue>(
  items: readonly TValue[],
  keySelector: (x: TValue) => TKey
): Array<[TKey, TValue[]]> {
  const map = new Map<TKey, TValue[]>();

  for (const item of items) {
    if (!map.has(keySelector(item))) {
      map.set(keySelector(item), []);
    }

    map.get(keySelector(item))?.push(item);
  }

  return [...map.entries()];
}

export function isElectron() {
  // Renderer process
  if (
    typeof window !== "undefined" &&
    typeof window.process === "object" &&
    (window.process as any).type === "renderer"
  ) {
    return true;
  }

  // Main process
  if (
    typeof process !== "undefined" &&
    typeof process.versions === "object" &&
    !!(process.versions as any).electron
  ) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
  ) {
    return true;
  }

  return false;
}

export const BASE_ASSETS_URL = isElectron() ? '.' : '';
