import { memo, useEffect, useState } from 'react';
import { createContext, ReactNode, useContext } from 'react';

import { Passcode, PasscodeMetadata } from '../data/cryptography';
import { Cabinet } from '../data/data-types/cabinet';
import { EntryId } from '../data/data-types/entities/entry';
import { JournalId } from '../data/data-types/entities/journal';
import { MediaId } from '../data/data-types/entities/media';
import { DeviceSettings } from '../data/data-types/entities/settings';
import { Timestamp } from '../data/data-types/timestamp';
import { createDummyState } from '../data/utils';
import { useDataService } from './DataServiceContext';

export interface DataServiceState {
  readonly deviceSettings: DeviceSettings;
  readonly cabinet: Cabinet;
  readonly passcode: Passcode | undefined;
  readonly obstruction: PasscodeMetadata | undefined;
  readonly syncedOnce: boolean;
  readonly lastSuccessfulCabinetPull: Timestamp | undefined;
}

const DataServiceStateContext = createContext<DataServiceState | undefined>(undefined);
const CabinetContext = createContext<Cabinet | undefined>(undefined);
const LastSuccessfulCabinetPullContext = createContext<Timestamp | undefined | 'unknown'>('unknown');
export const HiddenJournalIdsContext = createContext(createDummyState<ReadonlySet<JournalId>>(new Set()));
export const HiddenEntryIdsContext = createContext(createDummyState<ReadonlySet<EntryId>>(new Set()));
export const HiddenMediaIdsContext = createContext(createDummyState<ReadonlySet<MediaId>>(new Set()));

interface Props {
  readonly initial: DataServiceState;
  readonly children: ReactNode;
}

export const DataServiceStateContextProvider = memo(({ initial, children }: Props) => {
  const [deviceSettings, setDeviceSettings] = useState(initial.deviceSettings);
  const [passcode, setPasscode] = useState(initial.passcode);
  const [obstruction, setObstruction] = useState(initial.obstruction);
  const [syncedOnce, setSyncedOnce] = useState(initial.syncedOnce);
  const hideJournalsState = useState<ReadonlySet<JournalId>>(new Set());
  const hideEntriesState = useState<ReadonlySet<EntryId>>(new Set());
  const hideMediaState = useState<ReadonlySet<MediaId>>(new Set());
  const dataService = useDataService();

  useEffect(() => {
    const subscription = dataService.deviceSettings$.subscribe(setDeviceSettings);
    return () => subscription.unsubscribe();
  }, [dataService]);

  useEffect(() => {
    const subscription = dataService.obstruction$.subscribe(setObstruction);
    return () => subscription.unsubscribe();
  }, [dataService]);

  useEffect(() => {
    const subscription = dataService.passcode$.subscribe(setPasscode);
    return () => subscription.unsubscribe();
  }, [dataService]);

  useEffect(() => {
    const subscription = dataService.syncedOnce$.subscribe(setSyncedOnce);
    return () => subscription.unsubscribe();
  }, [dataService]);

  return (
    <DataServiceStateContext.Provider
      value={{ deviceSettings, passcode, obstruction, cabinet: initial.cabinet, syncedOnce, lastSuccessfulCabinetPull: initial.lastSuccessfulCabinetPull }}
    >
      <CabinetContext.Provider value={initial.cabinet}>
        <LastSuccessfulCabinetPullContextProvider initial={initial.lastSuccessfulCabinetPull}>
          <HiddenJournalIdsContext.Provider value={hideJournalsState}>
            <HiddenEntryIdsContext.Provider value={hideEntriesState}>
              <HiddenMediaIdsContext.Provider value={hideMediaState}>
                {children}
              </HiddenMediaIdsContext.Provider>
            </HiddenEntryIdsContext.Provider>
          </HiddenJournalIdsContext.Provider>
        </LastSuccessfulCabinetPullContextProvider>
      </CabinetContext.Provider>
    </DataServiceStateContext.Provider>
  )
});

interface LastSuccessfulCabinetPullContextProviderProps {
  readonly initial: Timestamp | undefined;
  readonly children: ReactNode;
}

function LastSuccessfulCabinetPullContextProvider({ initial, children }: LastSuccessfulCabinetPullContextProviderProps) {
  const [lastSuccessfulCabinetPull, setLastSuccessfulCabinetPull] = useState(initial);

  const dataService = useDataService();
  useEffect(() => {
    const subscription = dataService.lastSuccessfulCabinetPull$.subscribe(setLastSuccessfulCabinetPull);
    return () => subscription.unsubscribe();
  }, [dataService]);

  return (
    <LastSuccessfulCabinetPullContext.Provider value={lastSuccessfulCabinetPull}>
      {children}
    </LastSuccessfulCabinetPullContext.Provider>
  )
}

if (process.env.NODE_ENV === 'development') {
  DataServiceStateContextProvider.displayName = 'DataServiceStateContextProvider';
}

export function useDeviceSettings() {
  const value = useContext(DataServiceStateContext);

  if (value === undefined) {
    throw new Error('useDeviceSettings can only be used by components in DataServiceStateContext subtree');
  }

  return value.deviceSettings;
}

export function usePasscode() {
  const value = useContext(DataServiceStateContext);

  if (value === undefined) {
    throw new Error('usePasscode can only be used by components in DataServiceStateContext subtree');
  }

  return value.passcode;
}

export function useObstruction() {
  const value = useContext(DataServiceStateContext);

  if (value === undefined) {
    throw new Error('useObstruction can only be used by components in DataServiceStateContext subtree');
  }

  return value.obstruction;
}

export function useSyncedOnce() {
  const value = useContext(DataServiceStateContext);

  if (value === undefined) {
    throw new Error('useSyncedOnce can only be used by components in DataServiceStateContext subtree');
  }

  return value.syncedOnce;
}

export function useLastSuccessfulCabinetPull() {
  const value = useContext(LastSuccessfulCabinetPullContext);

  if (value === 'unknown') {
    throw new Error('useLastSuccessfulCabinetPull can only be used by components in DataServiceStateContext subtree');
  }

  return value;
}

export function useCabinet() {
  const value = useContext(CabinetContext);

  if (value === undefined) {
    throw new Error('useCabinet can only be used by components in DataServiceStateContext subtree');
  }

  return value;
}

export function useHiddenJournalIds() {
  return useContext(HiddenJournalIdsContext);
}

export function useHiddenEntryIds() {
  return useContext(HiddenEntryIdsContext);
}

export function useHiddenMediaIds() {
  return useContext(HiddenMediaIdsContext);
}
