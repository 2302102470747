import { AspectRatio, Box, Button, HStack } from '@chakra-ui/react';
import { memo } from 'react';
import { FiHome } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { ReactComponent as NotFoundSvg } from '../../assets/undraw_page_not_found_re_e9o6.svg';

function NotFoundFeedback() {
  return (
    <Box textAlign="center">
      <Box width="220px" mt="100px" mx="auto">
        <AspectRatio ratio={1}>
          <NotFoundSvg />
        </AspectRatio>
      </Box>
      <div>
        The page you are looking for doesn't exist.
      </div>
      <div>
        <Link to="/">
          <Button size="md" mt={6} variant="ghost">
            <HStack>
              <FiHome />
              <div>
                Go home
              </div>
            </HStack>
          </Button>
        </Link>
      </div>
    </Box>
  );
}

export default memo(NotFoundFeedback);
