import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { useCallback, useRef } from 'react';

import { EntryId } from '../../../shared/data/data-types/entities/entry';
import { useDataService } from '../../../shared/components/DataServiceContext';
import { withLazy } from '../../../shared/components/hoc/withLazy';

interface Props {
  readonly entryId: EntryId;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onDelete?: () => void;
}

function WebDeleteEntryDialog({ entryId, isOpen, onClose, onDelete }: Props) {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const dataService = useDataService();

  const handleDelete = useCallback(() => {
    dataService.execute({ type: 'entry/delete', entryId });
    onClose();
    if (onDelete) {
      onDelete();
    }
  }, [onClose, entryId, onDelete, dataService]);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent mx={[4, null]}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete entry
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to delete the entry?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="ghost" ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDelete}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default React.memo(withLazy(WebDeleteEntryDialog, 200));
