import { Button, Box, useBoolean } from '@chakra-ui/react';
import { memo, useCallback } from 'react';
import { BsPalette } from 'react-icons/bs';
import { StyledBackground, StyledEntity, StyledFont } from '../../shared/data/data-types/entities/styled';
import WebStylePicker from './WebStylePicker';

interface Props {
  readonly value: StyledEntity;
  readonly onChange: (styledEntity: StyledEntity) => void;
}

function WebJournalEntryStylePickerButton({ value, onChange }: Props) {
  const [isStylePickerOpen, setIsStylePickerOpen] = useBoolean();

  const handleCoverChange = useCallback((cover: StyledBackground | undefined) => onChange({ ...value, cover }), [onChange, value]);
  const handleBackgroundChange = useCallback((background: StyledBackground | undefined) => onChange({ ...value, background }), [onChange, value]);
  const handlePadChange = useCallback((pad: StyledBackground | undefined) => onChange({ ...value, pad }), [onChange, value]);
  const handleTitleFontChange = useCallback((titleFont: StyledFont | undefined) => onChange({ ...value, titleFont }), [onChange, value]);
  const handleTextFontChange = useCallback((textFont: StyledFont | undefined) => onChange({ ...value, textFont }), [onChange, value]);

  const nonEmptyStyles: string[] = [];
  if (value.background !== undefined) {
    nonEmptyStyles.push(`background: ${value.background.type}`);
  }
  if (value.cover !== undefined) {
    nonEmptyStyles.push(`cover: ${value.cover.type}`);
  }
  if (value.pad !== undefined) {
    nonEmptyStyles.push(`pad: ${value.pad.type}`);
  }
  if (value.titleFont !== undefined) {
    nonEmptyStyles.push(`heading font: ${value.titleFont.type}`);
  }
  if (value.textFont !== undefined) {
    nonEmptyStyles.push(`body font: ${value.textFont.type}`);
  }
  const selectStyleText = nonEmptyStyles.length > 0
    ? nonEmptyStyles.join('; ')
    : 'Customize entry style...'

  return (
    <WebStylePicker
      isOpen={isStylePickerOpen}
      onClose={setIsStylePickerOpen.off}

      value={value}

      onCoverChange={handleCoverChange}
      onBackgroundChange={handleBackgroundChange}
      onPadChange={handlePadChange}
      onTitleFontChange={handleTitleFontChange}
      onTextFontChange={handleTextFontChange}
    >
      <Button
        leftIcon={<BsPalette />}
        aria-label="Icon picker"
        onClick={setIsStylePickerOpen.toggle}
        variant="outline"
        fontWeight="normal"
        w="100%"
      >
        <Box py={2} color={true ? 'text-optional' : 'text-secondary'} textAlign="left" w="100%" overflow="hidden" textOverflow="ellipsis">
          {selectStyleText}
        </Box>
      </Button>
    </WebStylePicker>
  );
}

export default memo(WebJournalEntryStylePickerButton);
