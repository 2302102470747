import { useBoolean } from '@chakra-ui/react';
import { createContext, ReactNode, useCallback, useContext } from 'react';

import { useDataService } from '../../shared/components/DataServiceContext';
import { usePasscode } from '../../shared/components/DataServiceStateContext';
import WebTurnOnPasscodeLockDialog from './dialogs/WebTurnOnPasscodeLockDialog';

const WebLockContext = createContext<() => void>(() => { });

interface Props {
  readonly children: ReactNode;
}

export function WebLockContextProvider({ children }: Props) {
  const effectivePin = usePasscode();
  const [isTurnOnPrivacyLockDialogOpen, setIsTurnOnPrivacyLockDialogOpen] = useBoolean();
  const dataService = useDataService();

  const handleLock = useCallback(() => {
    if (effectivePin === undefined) {
      setIsTurnOnPrivacyLockDialogOpen.on();
    } else {
      dataService.lock();
    }
  }, [dataService, effectivePin, setIsTurnOnPrivacyLockDialogOpen]);

  const handleClose = useCallback((success: boolean) => {
    setIsTurnOnPrivacyLockDialogOpen.off();
    if (success) {
      dataService.lock();
    }
  }, [dataService, setIsTurnOnPrivacyLockDialogOpen]);

  return (
    <WebLockContext.Provider value={handleLock}>
      {children}
      <WebTurnOnPasscodeLockDialog action="Lock" isOpen={isTurnOnPrivacyLockDialogOpen} onClose={handleClose} />
    </WebLockContext.Provider>
  );
}

export function useWebLock() {
  return useContext(WebLockContext);
}
