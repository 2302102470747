import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import React, { useCallback, useRef, useState } from "react";

import { useDataService } from "../../../shared/components/DataServiceContext";
import { withJournal } from "../../../shared/components/hoc/withJournal";
import { withLazy } from "../../../shared/components/hoc/withLazy";
import { Journal } from "../../../shared/data/data-types/entities/journal";
import { deepEqual } from "../../../shared/data/utils";
import { useKeyDownCallback } from "../../../shared/hooks";
import WebIconPickerButton from "../WebIconPickerButton";
import WebJournalEntryStylePickerButton from "../WebJournalEntryStylePickerButton";
import WebModal from "../WebModal";
import WebTextTemplatePickerButton from "../WebTextTemplatePickerButton";

interface Props {
  readonly journal: Journal;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

function WebEditJournalDialog({ journal, isOpen, onClose }: Props) {
  const initialRef = useRef<HTMLInputElement>(null);

  const [name, setJournalName] = useState(journal.name);
  const [icon, setIcon] = useState(journal.icon);
  const [entryStyle, setEntryStyle] = useState(journal.entryStyle);
  const [entryTemplate, setEntryTemplate] = useState(journal.entryTemplate);
  const dataService = useDataService();

  const handleSave = useCallback(async () => {
    if (journal.name !== name) {
      dataService.execute({
        type: "journal/set_name",
        journalId: journal.id,
        name,
      });
    }
    if (journal.icon !== icon) {
      dataService.execute({
        type: "journal/set_icon",
        journalId: journal.id,
        icon,
      });
    }
    if (!deepEqual(journal.entryStyle, entryStyle)) {
      dataService.execute({
        type: "journal/set_entry_style",
        journalId: journal.id,
        style: entryStyle,
      });
    }
    if (!deepEqual(journal.entryTemplate, entryTemplate)) {
      dataService.execute({
        type: "journal/set_entry_template",
        journalId: journal.id,
        template: entryTemplate,
      });
    }

    onClose();
  }, [
    journal.name,
    journal.icon,
    journal.entryStyle,
    journal.entryTemplate,
    journal.id,
    name,
    icon,
    entryStyle,
    entryTemplate,
    onClose,
    dataService,
  ]);

  const handleKeyDown = useKeyDownCallback({
    onEnter: useCallback(() => {
      if (name.length > 0) {
        handleSave();
      }
    }, [handleSave, name]),
  });

  return (
    <WebModal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
      title="Edit journal"
      onAction={handleSave}
      action="Save"
    >
      <FormControl>
        <FormLabel>Name</FormLabel>
        <Input
          ref={initialRef}
          value={name}
          placeholder="Enter journal name..."
          onKeyDown={handleKeyDown}
          onChange={(x) => setJournalName(x.target.value)}
        />
      </FormControl>

      <FormControl mt={3}>
        <FormLabel>Icon</FormLabel>
        <WebIconPickerButton value={icon} onChange={setIcon} />
      </FormControl>

      <FormControl mt={3}>
        <FormLabel>Entry style</FormLabel>
        <WebJournalEntryStylePickerButton
          value={entryStyle}
          onChange={setEntryStyle}
        />
      </FormControl>

      <FormControl mt={3}>
        <FormLabel>Entry template</FormLabel>
        <WebTextTemplatePickerButton
          value={entryTemplate}
          onChange={setEntryTemplate}
        />
      </FormControl>
    </WebModal>
  );
}

export default React.memo(withLazy(withJournal(WebEditJournalDialog), 200));
