import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  useBoolean,
  useColorMode,
} from '@chakra-ui/react';
import React, { ReactNode, useCallback } from 'react';
import { FiChevronDown } from 'react-icons/fi';

import StylePickerBackgroundIcon from '../../shared/components/StylePickerBackgroundIcon';
import { COLOR_BACKGROUNDS, FONTS, getBackgroundName, getFontName } from '../../shared/constants';
import { StyledBackground, StyledEntity, StyledFont } from '../../shared/data/data-types/entities/styled';
import { useKeyDownCallback, useTextSecondary } from '../../shared/hooks';
import { getBackgroundColor, getFontFamily, getPadColor } from '../../shared/utils';
import WebScrollableMenu from './WebScrollableMenu';

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;

  readonly value: StyledEntity;

  readonly onCoverChange: (cover: StyledBackground | undefined) => void;
  readonly onBackgroundChange: (background: StyledBackground | undefined) => void;
  readonly onPadChange: (pad: StyledBackground | undefined) => void;
  readonly onTitleFontChange: (font: StyledFont | undefined) => void;
  readonly onTextFontChange: (font: StyledFont | undefined) => void;

  readonly children: ReactNode;
}

function WebStylePicker({
  value, children, isOpen, onClose, onCoverChange, onBackgroundChange, onPadChange, onTitleFontChange, onTextFontChange
}: Props
) {
  const legendColor = useTextSecondary();
  const { colorMode } = useColorMode();

  const handleKeyDown = useKeyDownCallback({
    onEscape: useCallback((e: React.KeyboardEvent<unknown>) => {
      if (isOpen) {
        e.stopPropagation();
        e.preventDefault();

        onClose();
      }
    }, [isOpen, onClose]),
  });

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        {children}
      </PopoverTrigger>
      <PopoverContent onKeyDown={handleKeyDown} borderRadius="sm">
        <PopoverBody boxShadow="md" p={4}>
          <SimpleGrid alignItems="center" columns={2} spacing={2}>
            <Box color={legendColor}>Cover</Box>
            <Flex justifyContent="end">
              <WebStylePickerBackgroundMenu
                allowDefault="None"
                getColor={x => getBackgroundColor(x, colorMode)}
                value={value.cover}
                onChange={onCoverChange}
              />
            </Flex>
            <Box color={legendColor}>Background</Box>
            <Flex justifyContent="end">
              <WebStylePickerBackgroundMenu
                allowDefault
                getColor={x => getBackgroundColor(x, colorMode)}
                value={value.background}
                onChange={onBackgroundChange}
              />
            </Flex>
            <Box color={legendColor}>Pad</Box>
            <Flex justifyContent="end">
              <WebStylePickerBackgroundMenu
                allowDefault
                getColor={x => getPadColor(x, colorMode)}
                value={value.pad}
                onChange={onPadChange}
                isTextPreview
              />
            </Flex>
            <Box color={legendColor}>Heading font</Box>
            <Flex justifyContent="end">
              <WebStylePickerFontMenu value={value.titleFont} onChange={onTitleFontChange} />
            </Flex>
            <Box color={legendColor}>Body font</Box>
            <Flex justifyContent="end">
              <WebStylePickerFontMenu value={value.textFont} onChange={onTextFontChange} />
            </Flex>
          </SimpleGrid>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default React.memo(WebStylePicker);

interface WebStylePickerBackgroundMenuProps {
  readonly isTextPreview?: boolean;
  readonly allowDefault?: boolean | string;
  readonly value: StyledBackground | undefined;
  readonly onChange: (background: StyledBackground | undefined) => void;
  readonly getColor: (background: StyledBackground | undefined) => string;
}

function WebStylePickerBackgroundMenu({ isTextPreview, value, onChange, getColor, allowDefault }: WebStylePickerBackgroundMenuProps) {
  const [isMenuOpen, setIsMenuOpen] = useBoolean();

  const backgrounds: Array<StyledBackground | undefined> = [];
  if (allowDefault) {
    backgrounds.push(undefined);
  }

  backgrounds.push(...COLOR_BACKGROUNDS);

  const defaultText = typeof allowDefault === 'boolean' ? undefined : allowDefault;

  return (
    <WebScrollableMenu
      placement="bottom-start"
      isOpen={isMenuOpen}
      onClose={setIsMenuOpen.off}
      w="220px"
      trigger={
        <Button
          mr={-2} px={2}
          fontWeight="normal"
          onClick={setIsMenuOpen.toggle}
          fontSize="md"
          size="sm"
          variant="ghost"
          leftIcon={<StylePickerBackgroundIcon bg={getColor(value)} ignoreDefault isTextPreview={isTextPreview} />}
          rightIcon={<FiChevronDown />}
        >
          {getBackgroundName(value, defaultText)}
        </Button>
      }
    >
      {backgrounds.map(bg => (
        <Button
          leftIcon={<StylePickerBackgroundIcon bg={getColor(bg)} isTextPreview={isTextPreview} />}
          key={bg?.type ?? 'default'}
          variant="ghost"
          onClick={() => { onChange(bg); setIsMenuOpen.off() }}
        >
          {getBackgroundName(bg, defaultText)}
        </Button>
      ))}
    </WebScrollableMenu>
  )
}

interface WebStylePickerFontMenuProps {
  readonly value: StyledFont | undefined;
  readonly onChange: (font: StyledFont | undefined) => void;
}

function WebStylePickerFontMenu({ value, onChange }: WebStylePickerFontMenuProps) {
  const [isMenuOpen, setIsMenuOpen] = useBoolean();

  return (
    <WebScrollableMenu
      placement="bottom-start"
      isOpen={isMenuOpen}
      onClose={setIsMenuOpen.off}
      w="220px"
      trigger={
        <Button
          mr={-2} px={2}
          fontWeight="normal"
          onClick={setIsMenuOpen.toggle}
          fontSize="md"
          size="sm"
          fontFamily={value === undefined ? undefined : getFontFamily(value)}
          variant="ghost"
          rightIcon={<FiChevronDown />}
        >
          {getFontName(value)}
        </Button>
      }
    >
      {FONTS.map(font => (
        <Button
          key={font?.type ?? 'default'}
          variant="ghost"
          fontFamily={font === undefined ? undefined : getFontFamily(font)}
          onClick={() => { onChange(font); setIsMenuOpen.off() }}
        >
          {getFontName(font)}
        </Button>
      ))}
    </WebScrollableMenu>
  )
}
