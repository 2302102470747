import {
  Box,
  Button,
  Flex,
  MenuButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { Fragment, memo, useCallback } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { useHistory } from "react-router-dom";

import { withJournal } from "../../shared/components/hoc/withJournal";
import JournalIcon from "../../shared/components/JournalIcon";
import {
  Journal,
  JournalId,
} from "../../shared/data/data-types/entities/journal";
import WebJournalMenu from "./WebJournalMenu";

interface Props {
  readonly journal: Journal;
  readonly isActive: boolean;
  readonly inDrag: boolean;
  readonly onEditStateChange?: (editActive: boolean) => void;
}

function WebJournalListItem({
  journal,
  isActive,
  inDrag,
  onEditStateChange,
}: Props) {
  const history = useHistory();
  const handleClick = useCallback(
    (e: { preventDefault(): void }) => {
      if (inDrag) {
        e.preventDefault();
      } else {
        history.push(`/journals/${journal.id}/timeline`);
      }
    },
    [history, inDrag, journal.id]
  );

  const activeBg = useColorModeValue("rgba(0, 0, 0, 0.05)", "#3C4658"); // gray.650
  const textColor = useColorModeValue("gray.600", "gray.300");

  return (
    <Fragment>
      <Box bg="bg-light-gray" transition="background-color 0.2s">
        <Flex
          alignItems="center"
          pr={1}
          transition="background-color 0.2s"
          bg={isActive ? activeBg : undefined}
          _hover={{
            backgroundColor: activeBg,
          }}
          maxW="100%"
          color={textColor}
          sx={{
            "&:hover > .more-button .more-button-icon": {
              opacity: "1 !important",
            },
            "& > .more-button:focus-visible .more-button-icon": {
              opacity: "1 !important",
            },
          }}
        >
          <div>
            <Button
              tabIndex={-1}
              variant="unstyled"
              w="100%"
              fontWeight="normal"
              textAlign="left"
              onClick={handleClick}
            >
              <Box pl={7} py={2}>
                <JournalIcon journalId={journal.id} />
              </Box>
            </Button>
          </div>
          <Box flex={1} minW={0}>
            <Button
              variant="unstyled"
              w="100%"
              fontWeight="normal"
              textAlign="left"
              onClick={handleClick}
            >
              <Box isTruncated pl={3} py={2}>
                {journal.name || "Untitled"}
              </Box>
            </Button>
          </Box>
          <WebJournalListItemMenu
            onEditStateChange={onEditStateChange}
            inDrag={inDrag}
            journalId={journal.id}
          />
        </Flex>
      </Box>
    </Fragment>
  );
}

export default memo(withJournal(WebJournalListItem));

interface JournalListItemMenuProps {
  readonly journalId: JournalId;
  readonly inDrag: boolean;
  readonly onEditStateChange?: (editActive: boolean) => void;
}

const WebJournalListItemMenu = memo(
  ({ journalId, inDrag, onEditStateChange }: JournalListItemMenuProps) => {
    const textColor = useColorModeValue("gray.600", "gray.300");

    const handleClick = useCallback(
      (e: { preventDefault(): void }) => {
        if (inDrag) {
          e.preventDefault();
        }
      },
      [inDrag]
    );

    return (
      <WebJournalMenu
        onEditStateChange={onEditStateChange}
        journalId={journalId}
      >
        <MenuButton
          py={1}
          pl={2}
          pr={4}
          pb={0.5}
          color={textColor}
          onClick={handleClick}
          _hover={{ color: "text-primary" }}
          className="more-button"
          fontSize="lg"
        >
          <Box
            aria-label="more actions"
            className="more-button-icon"
            opacity={[0.5, 0]}
          >
            <FiMoreHorizontal />
          </Box>
        </MenuButton>
      </WebJournalMenu>
    );
  }
);
