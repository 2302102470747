import Quill from 'quill/core';
import { createPortal } from 'react-dom';

import { generateId } from '../../../utils';

const BlockEmbed = Quill.import('blots/block/embed');
const Block = Quill.import('blots/block');

export type AppEmbedInstance = ReturnType<typeof createAppEmbed> extends { new(...args: any[]): infer T } ? T : never;

export class AppEmbed extends BlockEmbed {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(...args: any[]) {
    super(...args);
  }
}

export function createAppEmbed<TValue>(
  type: string,
  render: (options: {
    readOnly: boolean;
    value: TValue;
    isFocused: boolean;
    onFocus: () => void;
  }) => JSX.Element,
) {
  return class extends AppEmbed {
    static blotName = type;
    static tagName = 'div';
    static className = `ql-app-embed-${type}`;

    static create(value: any) {
      const node: HTMLElement = super.create(value);
      const id = generateId();
      node.setAttribute('data-id', id);
      node.setAttribute('data-app-embed-value', JSON.stringify(value ?? {}));
      node.setAttribute('contenteditable', 'false');
      node.classList.add('ql-app-embed');
      return node;
    }

    static value(domNode: HTMLElement) {
      const valueJson = domNode.getAttribute('data-app-embed-value');
      return valueJson === null ? {} : JSON.parse(valueJson);
    }

    constructor(domNode: HTMLElement, value: TValue) {
      super(domNode);

      this.id = domNode.getAttribute('data-id');
      this.componentData = value;
    }

    attach() {
      super.attach();
      this.scroll.emitter.emit('blot-mount', this);

      if (!this.scroll.reactBlots) {
        this.scroll.reactBlots = [];
      }

      this.scroll.reactBlots.push(this);
    }

    renderPortal(isFocused: boolean) {
      const { options } = Quill.find(this.scroll.domNode.parentNode);
      return createPortal(
        render({
          readOnly: !!options.readOnly,
          value: this.componentData,
          isFocused,
          onFocus: () => this.onFocus(),
        }),
        this.domNode
      );
    }

    onFocus() {
      this.scroll.emitter.emit('blot-focus', this);
    }

    detach() {
      super.detach();
      this.scroll.emitter.emit('blot-unmount', this);

      this.scroll.reactBlots = this.scroll.reactBlots?.filter((x: any) => x !== this);
    }
  }
}

export class AppEmbedCursor extends Block {
  static blotName = 'app_embed_cursor';
  static tagName = 'span';
  static className = `ql-app_embed_cursor`;
}
