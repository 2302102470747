import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Spacer,
  Switch,
  Tooltip,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { memo, useCallback, useRef } from 'react';
import { FaFilter, FaSortAmountDown } from 'react-icons/fa';
import { FiChevronDown, FiFilter, FiSearch, FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { JournalId } from '../../shared/data/data-types/entities/journal';
import { Timestamp, toTimestamp } from '../../shared/data/data-types/timestamp';
import { EntryListOptions, useBackground, useKeyDownCallback, useTextPrimary, useTextSecondary } from '../../shared/hooks';
import { wait } from '../../shared/utils';
import WebDatePicker from './WebDatePicker';
import { useEntryList } from './WebJournalEntryListContext';
import JournalNameInput from './WebJournalNameInput';

interface Props {
  readonly journalId: JournalId;

  readonly bookmarked: true | undefined;
  readonly onBookmarkedChange: (bookmarked: true | undefined) => void;
  readonly before: Timestamp | undefined;
  readonly onBeforeChange: (before: Timestamp | undefined) => void;
  readonly after: Timestamp | undefined;
  readonly onAfterChange: (after: Timestamp | undefined) => void;

  readonly entryListOptions: EntryListOptions;
  readonly searchText: string | undefined;
  readonly onSearchTextChange: (searchText: string | undefined) => void;

  readonly sort: 'asc' | 'desc';
  readonly onSortChange: (sort: 'asc' | 'desc') => void;
}

function WebJournalDashboard({
  journalId, searchText, onSearchTextChange, sort, onSortChange, entryListOptions: options,
  before, after, bookmarked, onBookmarkedChange, onBeforeChange, onAfterChange,
}: Props) {
  const history = useHistory();

  const handleSearchKeyDown = useKeyDownCallback({
    onEscape: useCallback(() => {
      onSearchTextChange(undefined);
    }, [onSearchTextChange]),
  });

  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleStartSearch = useCallback(() => {
    onSearchTextChange('');
    wait(0).then(() => searchInputRef.current?.focus());
  }, [onSearchTextChange]);

  const pageBg = useBackground();
  const textPrimary = useTextPrimary();
  const textSecondary = useTextSecondary();

  const entries = useEntryList();
  const filtered = (options.text ?? '') !== '';

  const hasFilters = (bookmarked ?? before ?? after) !== undefined;

  return (
    <Box py={[2, 4]} display={!filtered && !hasFilters && entries.length === 0 ? 'none' : undefined}>
      <JournalNameInput fontSize={55} fontWeight="bold" journalId={journalId} />
      <Flex mt={2}>
        <Spacer />
        <ButtonGroup mr={2} spacing={1} size="md" variant="ghost">
          <Popover>
            <PopoverTrigger>
              <Button
                leftIcon={hasFilters ? <Box fontSize="sm"><FaFilter /></Box> : <FiFilter />}
                color={hasFilters ? 'blue.400' : textSecondary}
                aria-label="filter"
                variant="ghost"
              >
                Filter
              </Button>
            </PopoverTrigger>
            <PopoverContent px={4} pb={3} pt={2}>
              <SimpleGrid alignItems="center" columns={2} spacing={2}>
                <Box py={2}>
                  Bookmarked
                </Box>
                <Flex justifyContent="end">
                  <Switch mr={2} isChecked={bookmarked === true} onChange={() => onBookmarkedChange(bookmarked === true ? undefined : true)} />
                </Flex>
                <div>Before</div>
                <Flex justifyContent="end">
                  <WebDateFilter value={before} onChange={onBeforeChange} />
                </Flex>
                <div>After</div>
                <Flex justifyContent="end">
                  <WebDateFilter value={after} onChange={onAfterChange} />
                </Flex>
              </SimpleGrid>
            </PopoverContent>
          </Popover>
          <Menu>
            <MenuButton
              as={Button}
              leftIcon={<FaSortAmountDown />}
              color={textSecondary}
              aria-label="sort"
              variant="ghost"
            >
              Sort
            </MenuButton>
            <MenuList color={textPrimary}>
              <MenuOptionGroup value={sort} type="radio" onChange={(e) => onSortChange(e as 'asc' | 'desc')}>
                <MenuItemOption value="desc">Latest first</MenuItemOption>
                <MenuItemOption value="asc">Oldest first</MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
          {searchText === undefined && (
            <Button color={textSecondary} onClick={handleStartSearch} leftIcon={<FiSearch />}>
              Search
            </Button>
          )}
          {searchText !== undefined && (
            <div>
              <InputGroup>
                <InputLeftElement h="100%" pointerEvents="none">
                  <Box h="100%" display="flex" alignItems="center" pt={0.5} color={textSecondary}>
                    <FiSearch />
                  </Box>
                </InputLeftElement>
                <Input
                  fontSize="md"
                  ref={searchInputRef}
                  variant="ghost"
                  bg={pageBg}
                  placeholder="Type to search..."
                  onChange={e => onSearchTextChange(e.target.value)}
                  onKeyDown={handleSearchKeyDown}
                  onBlur={() => {
                    if (searchText === '') {
                      onSearchTextChange(undefined);
                    }
                  }}
                />
                <InputRightElement h="100%">
                  <Box h="100%" display="flex" alignItems="center" pt={0.5} color={textSecondary}>
                    {searchText !== '' && (
                      <Tooltip openDelay={500} placement="top" label="Clear">
                        <IconButton
                          variant="ghost"
                          size="sm"
                          fontSize="md"
                          aria-label="close"
                          onClick={() => onSearchTextChange(undefined)}
                          icon={<FiX />}
                        />
                      </Tooltip>
                    )}
                  </Box>
                </InputRightElement>
              </InputGroup>
            </div>
          )}
        </ButtonGroup>

        <Button
          size="md"
          variant="solid"
          colorScheme="blue"
          onClick={() => history.push(`/journals/${journalId}/entries/new`)}
          mr="1px"
        >
          New entry
        </Button>
      </Flex>
    </Box >
  )
}

export default memo(WebJournalDashboard);

interface WebDateFilterProps {
  readonly value: Timestamp | undefined;
  readonly onChange: (timestamp: Timestamp | undefined) => void;
}

function WebDateFilter({ value, onChange }: WebDateFilterProps) {
  if (value === undefined) {
    return (
      <WebDatePicker value={value} onChange={x => onChange(toTimestamp(x))}>
        <Button px={2} fontSize="md" size="sm" fontWeight="normal" variant="ghost" rightIcon={<FiChevronDown />}>
          <Box color="text-secondary">Select date...</Box>
        </Button>
      </WebDatePicker>
    );
  }

  return (
    <HStack>
      <div>
        {format(new Date(value), 'PP')}
      </div>
      <IconButton
        fontSize="md"
        size="sm"
        variant="ghost"
        icon={<FiX />}
        aria-label="clear"
        onClick={() => onChange(undefined)}
      />
    </HStack>
  );
}
