import { Box, IconButton } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

import { JournalId } from '../../shared/data/data-types/entities/journal';

interface Props {
  readonly journalId: JournalId;
}

function WebAddJournalFab({ journalId }: Props) {
  return (
    <IconButton
      as={Link}
      zIndex="sticky"
      icon={<Box mb="5px">+</Box>}
      aria-label="Add journal"
      to={`/journals/${journalId}/entries/new`}
      colorScheme="blue"
      position="fixed"
      right="25px"
      bottom="25px"
      borderRadius="full"
      size="lg"
      variant="solid"
      fontSize="3xl"
      fontWeight="normal"
      boxShadow="md"
      // display="block"
      // w="20px"
      // h="20px"
    />
  );
}

export default React.memo(WebAddJournalFab);
