import { Cloud, CloudEncryption, CloudEncryptionConfig, CloudLogEntry } from '../cloud';
import { Aggregate } from '../aggregation';
import { KeyValueBlobStore } from '../blob-service';
import { ConcurrencyToken, ConcurrencyWrapper } from '../data-types/concurrency-token';
import { BlobKey } from '../data-types/entities/blob';
import { ConcurrencyError, NetworkError } from '../errors';
import { InMemoryKeyValueStorage, KeyValuePair } from '../key-value-storage';
import { Result } from '../result';
import {
  KeyValueTransactionLogStore,
  TransactionLogCommitCommand,
  TransactionLogFreeOptions,
  TransactionLogListOptions,
} from '../transaction-log';
import { generateId } from '../utils';

function generateConcurrencyToken() {
  return generateId() as ConcurrencyToken;
}

export class InMemoryCloud implements Cloud {
  public readonly logStore: ControlledKeyValueTransactionLogStore<CloudLogEntry>;
  public readonly blobStore: ControlledBlobStore;
  public readonly encryption: CloudEncryption;

  public encryptionToken: ConcurrencyToken = generateConcurrencyToken();

  private status: 'online' | 'offline' = 'online';

  constructor(
    private encryptionValue: CloudEncryptionConfig,
    private pageSize: number,
    private aggregate: (items: readonly CloudLogEntry[]) => ReadonlyArray<Aggregate<CloudLogEntry>>,
    private optimizeWindow: number,
  ) {
    this.logStore = new ControlledKeyValueTransactionLogStore(new InMemoryKeyValueStorage(), this.pageSize);
    this.blobStore = new ControlledBlobStore(new InMemoryKeyValueStorage());
    this.encryption = this;
  }

  online() {
    this.status = 'online';
    this.logStore.online();
    this.blobStore.online();
  }

  offline() {
    this.status = 'offline';
    this.logStore.offline();
    this.blobStore.offline();
  }

  async getEncryption(): Promise<Result<NetworkError, ConcurrencyWrapper<CloudEncryptionConfig>>> {
    if (this.status === 'offline') {
      return Result.error(new NetworkError());
    }
    return Result.ok({ value: this.encryptionValue, concurrencyToken: this.encryptionToken });
  }

  async setEncryption(encryption: CloudEncryptionConfig, concurrencyToken: ConcurrencyToken): Promise<Result<ConcurrencyError | NetworkError, ConcurrencyWrapper<CloudEncryptionConfig>>> {
    if (this.status === 'offline') {
      return Result.error(new NetworkError());
    }

    if (concurrencyToken !== this.encryptionToken) {
      return Result.error(new ConcurrencyError());
    }

    this.encryptionValue = encryption;
    this.encryptionToken = generateConcurrencyToken();

    return Result.ok({ value: this.encryptionValue, concurrencyToken: this.encryptionToken });
  }
}

class ControlledBlobStore extends KeyValueBlobStore {
  private status: 'online' | 'offline' = 'online';

  online() {
    this.status = 'online';
  }

  offline() {
    this.status = 'offline';
  }

  async listKeys(): Promise<Result<unknown, BlobKey[]>> {
    if (this.status === 'offline') {
      return Result.error(new NetworkError());
    }

    return super.listKeys();
  }

  async get(key: BlobKey): Promise<Result<unknown, Uint8Array | undefined>> {
    if (this.status === 'offline') {
      return Result.error(new NetworkError());
    }

    return super.get(key);
  }

  async create(key: BlobKey, object: Uint8Array): Promise<Result<unknown, void>> {
    if (this.status === 'offline') {
      return Result.error(new NetworkError());
    }

    return super.create(key, object);
  }

  async delete(key: BlobKey): Promise<Result<unknown, void>> {
    if (this.status === 'offline') {
      return Result.error(new NetworkError());
    }

    return super.delete(key);
  }
}

class ControlledKeyValueTransactionLogStore<T> extends KeyValueTransactionLogStore<T> {
  async getCommitBoundary(): Promise<Result<unknown, string>> {
    if (this.status === 'offline') {
      return Result.error(new NetworkError());
    }

    return await super.getCommitBoundary();
  }

  async commit(commands: ReadonlyArray<TransactionLogCommitCommand<T>>): Promise<Result<NetworkError | ConcurrencyError, void>> {
    if (this.status === 'offline') {
      return Result.error(new NetworkError());
    }

    return await super.commit(commands);
  }

  async list(options: TransactionLogListOptions): Promise<Result<unknown, readonly KeyValuePair<string, T>[]>> {
    if (this.status === 'offline') {
      return Result.error(new NetworkError());
    }

    return await super.list(options);
  }

  async append(entries: readonly T[]): Promise<Result<unknown, string[]>> {
    if (this.status === 'offline') {
      return Result.error(new NetworkError());
    }

    return await super.append(entries);
  }

  async free(options: TransactionLogFreeOptions): Promise<Result<unknown, void>> {
    if (this.status === 'offline') {
      return Result.error(new NetworkError());
    }

    return await super.free(options);
  }

  private status: 'online' | 'offline' = 'online';

  online() {
    this.status = 'online';
  }

  offline() {
    this.status = 'offline';
  }
}
