import { Box, Button } from '@chakra-ui/react';
import { memo } from 'react';

function ErrorFeedback() {
  return (
    <Box textAlign="center" py={8}>
      <Box fontSize="lg" mb={2} fontWeight="semibold">
        Something went wrong
      </Box>
      <Button colorScheme="blue" onClick={() => window.location.reload()}>
        Reload page
      </Button>
    </Box>
  );
}

export default memo(ErrorFeedback);
