import { Box } from '@chakra-ui/react';
import { Fragment, memo } from 'react';

import TextColorPreview from './TextColorPreview';

interface Props {
  readonly bg: string;
  readonly ignoreDefault?: boolean;
  readonly isTextPreview?: boolean;
}

function StylePickerBackgroundIcon({ bg, ignoreDefault, isTextPreview }: Props) {
  if (bg === undefined && ignoreDefault) {
    return <Fragment />;
  }

  if (isTextPreview) {
    return <TextColorPreview fontSize="md" bg={bg} />;
  } else {
    return <Box w="15px" h="15px" borderRadius="sm" bg={bg} />;
  }
}

export default memo(StylePickerBackgroundIcon);
