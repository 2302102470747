import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { Fragment, memo, ReactNode, useMemo } from "react";

import emojis from "../emojis.json";
import { BASE_ASSETS_URL } from "../utils";

interface EmojiListProps {
  readonly filter: string;
  readonly limit: number;
  readonly onClick: (unicode: string) => void;
  readonly empty: ReactNode;
}

function EmojiList({ filter, limit, onClick, empty }: EmojiListProps) {
  const filteredEmojis = useMemo(
    () =>
      emojis
        .map((group) => ({
          ...group,
          emojis: group.emojis.filter(
            (emoji) =>
              emoji.name.toUpperCase().indexOf(filter.toUpperCase()) !== -1
          ),
        }))
        .filter((group) => group.emojis.length > 0),
    [filter]
  );

  const btnHoverBg = useColorModeValue("gray.100", "whiteAlpha.200");
  const btnActiveBg = useColorModeValue("gray.200", "whiteAlpha.300");

  return (
    <Fragment>
      {filteredEmojis.slice(0, limit).map((group, groupIndex) => (
        <Box key={group.unicode}>
          {filteredEmojis.length > 1 && (
            <Box
              fontSize="xs"
              mt={groupIndex === 0 ? 0 : 3}
              mb={2}
              fontWeight="semibold"
              textTransform="uppercase"
              color="text-optional"
            >
              {group.name}
            </Box>
          )}
          <Flex
            wrap="wrap"
            sx={{
              svg: {
                fontSize: "2xl",
                width: "32px",
                height: "32px",
                padding: "4px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "md",
                appearance: "none",
                verticalAlign: "middle",
                userSelect: "none",
                outlineOffset: "2px",
                lineHeight: "1.2",
                outline: "2px solid transparent",
                position: "relative",
                transition: "background-color 0.2s",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: btnHoverBg,
                  cursor: "pointer",
                },
                "&:active": {
                  backgroundColor: btnActiveBg,
                },
                "&:focus": {
                  boxShadow: "outline",
                  zIndex: 1,
                },
              },
            }}
          >
            {group.emojis.map((emoji) => (
              <svg key={emoji.unicode} onClick={() => onClick(emoji.unicode)}>
                <use href={`${BASE_ASSETS_URL}/twitter-emoji-sprite.svg#${emoji.unicode}`} />
              </svg>
            ))}
          </Flex>
        </Box>
      ))}
      {filteredEmojis.length === 0 && empty}
    </Fragment>
  );
}

export default memo(EmojiList);
