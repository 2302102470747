import { chakra, useColorModeValue } from '@chakra-ui/react';
import React, { KeyboardEventHandler } from 'react';
import AutosizeInput from 'react-input-autosize';
import { useBackground } from '../../shared/hooks';

const ChakraAutosizeInput = chakra(AutosizeInput);

interface Props {
  readonly value: string;
  readonly onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readonly fontSize: number;
  readonly fontWeight: 'bold' | 'normal';
  readonly onKeyDown?: KeyboardEventHandler<HTMLInputElement> | undefined;
}

const WebInlineInput = React.forwardRef<HTMLInputElement, Props>(({ value, onChange, fontSize, fontWeight, onKeyDown }, ref) => {
  const hoverBg = useColorModeValue('gray.100', 'gray.600');
  const bg = useBackground();

  return (
    <ChakraAutosizeInput
      ref={ref}
      value={value}
      style={{ fontSize, fontWeight }}
      inputStyle={{ fontSize, fontWeight }}
      onKeyDown={onKeyDown}
      onChange={onChange}
      placeholder="Untitled"
      sx={{
        'input': {
          outline: 'none',
          padding: '0 5px',
          borderRadius: '3px',
          background: bg,
          transition: 'background-color 0.2s',
        },
        'input:hover, input:focus': {
          backgroundColor: hoverBg,
        },
      }}
    />
  )
});

export default React.memo(WebInlineInput);
