import { DataService } from '../data/data-service';
import { Backup } from '../data/data-types/entities/backup';
import { Entry } from '../data/data-types/entities/entry';
import { JournalId } from '../data/data-types/entities/journal';
import { ExportOptions } from './export';

export namespace ExportProxy {
  export async function exportBackup(backup: Backup): Promise<void> {
    const exporter = await import('./export');
    await exporter.exportBackup(backup);
  }

  export async function exportJournal(dataService: DataService, journalId: JournalId, options: ExportOptions) {
    const exporter = await import('./export');
    await exporter.exportJournal(dataService, journalId, options);
  }

  export async function exportEntry(dataService: DataService, entry: Entry, options: ExportOptions) {
    const exporter = await import('./export');
    await exporter.exportEntry(dataService, entry, options);
  }
}
