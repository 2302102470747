import { Box } from "@chakra-ui/react";
import { memo, useMemo } from "react";

import { usePromise } from "../../shared/hooks";
import { config } from "../../config";

function WebMessageListPage() {
  const messagesPromise = useMemo(async () => {
    const pass =
      localStorage.getItem("MESSAGES_PASS") ??
      (localStorage.MESSAGES_PASS = prompt("Enter password:"));
    const response = await fetch(`${config.apiUrl}/messages?pass=${pass}`);
    return await response.json();
  }, []);
  const messages = usePromise(messagesPromise);

  if (messages.state === "pending") {
    return <>Loading...</>;
  }

  if (messages.state === "rejected") {
    return <>Something went wrong</>;
  }

  return (
    <>
      {messages.value.map((message: any) => (
        <Box maxW="600px" mx="auto" p={8} key={message.id} mb={4}>
          <div>
            {message.name}
            {"<"}
            {message.email} {">"}
          </div>
          <pre style={{ whiteSpace: "pre-wrap" }}>{message.text}</pre>
        </Box>
      ))}
    </>
  );
}

export default memo(WebMessageListPage);
