import { useBoolean } from '@chakra-ui/react';
import { useCallback, useRef, useState } from 'react';

import { useDataService } from '../components/DataServiceContext';
import { usePasscode } from '../components/DataServiceStateContext';
import { PasscodeId } from '../data/cryptography';
import { getTimestamp } from '../data/data-types/timestamp';
import { generateId, wait } from '../utils';

interface Options {
  readonly onClose: () => void;
}

export function useChangePasscode({ onClose }: Options) {
  const effectivePasscode = usePasscode();

  const [oldPasscode, setOldPasscode] = useState('');
  const [newPasscode, setNewPasscode] = useState('');
  const [repeatPasscode, setRepeatPasscode] = useState('');
  const [isRepeatPasscodeInvalid, setIsRepeatPasscodeInvlid] = useBoolean();
  const [isOldPasscodeInvalid, setIsOldPasscodeInvlid] = useBoolean();

  const oldPasscodeInputRef = useRef<HTMLInputElement>(null);
  const repeatPasscodeInputRef = useRef<HTMLInputElement>(null);

  const handleOldPasscodeChange = useCallback((value: string) => {
    setOldPasscode(value);
    setIsOldPasscodeInvlid.off();
  }, [setIsOldPasscodeInvlid]);

  const handleNewPasscodeChange = useCallback((value: string) => {
    setNewPasscode(value);
    setIsNewPasscodeInvalid(false);
    setIsRepeatPasscodeInvlid.off();
  }, [setIsRepeatPasscodeInvlid]);

  const handleRepeatPasscodeChange = useCallback((value: string) => {
    setRepeatPasscode(value);
    setIsRepeatPasscodeInvlid.off();
    setIsNewPasscodeInvalid(false);
  }, [setIsRepeatPasscodeInvlid]);

  const [isNewPasscodeInvalid, setIsNewPasscodeInvalid] = useState(false);

  const dataService = useDataService();
  const changePasscode = useCallback(() => {
    if (newPasscode === '') {
      setIsNewPasscodeInvalid(true);
      return;
    }

    const repeatIsValid = newPasscode === repeatPasscode;
    const oldIsValid = effectivePasscode?.text === oldPasscode;

    if (!oldIsValid) {
      setIsOldPasscodeInvlid.on();
    }

    if (!repeatIsValid) {
      setIsRepeatPasscodeInvlid.on();
    }

    if (!oldIsValid) {
      wait(0).then(() => oldPasscodeInputRef.current?.focus());
    } else if (!repeatIsValid) {
      wait(0).then(() => repeatPasscodeInputRef.current?.focus());
    }

    if (repeatIsValid && oldIsValid) {
      dataService.encrypt({
        text: newPasscode,
        metadata: {
          id: generateId() as PasscodeId,
          timestamp: getTimestamp(),
          hint: undefined,
          location: undefined,
        },
      }).then(() => onClose());
    }
  }, [newPasscode, repeatPasscode, effectivePasscode?.text, oldPasscode, setIsOldPasscodeInvlid, setIsRepeatPasscodeInvlid, dataService, onClose]);

  return {
    isOldPasscodeInvalid,
    isRepeatPasscodeInvalid,
    oldPasscode,
    repeatPasscode,
    newPasscode,
    handleOldPasscodeChange,
    handleNewPasscodeChange,
    handleRepeatPasscodeChange,
    changePasscode,
    oldPasscodeInputRef,
    repeatPasscodeInputRef,
    isNewPasscodeInvalid,
  };
}
