import { Box, Button } from '@chakra-ui/react';
import { Fragment, memo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';

import { useQuery } from '../../shared/hooks';
import { AuthService } from '../../shared/services/AuthService';
import { isElectron } from '../../shared/utils';
import WebConsent from '../components/WebConsent';
import WebSocialLogin from '../components/WebSocialLogin';

function WebSingInCallbackPage() {
  const query = useQuery();
  const token = query.get('token');
  const error = query.get('error');

  useEffect(() => {
    if (!error || error === 'rejected') {
      if (token) {
        AuthService.setToken(token);
      }

      if (isElectron()) {
        window.location.reload();
      } else {
        window.location.href = '/';
      }
    }
  }, [error, token]);

  if (!error) {
    return <Fragment />;
  }

  const errorMessage = match(error)
    .with('no_email', () => ({ title: 'Email not found', description: 'Please try different sign-in method' }))
    .otherwise(() => ({ title: 'Something went wrong', description: 'An unexpected error has occurred. Please try again later.' }));

  return (
    <Box
      boxShadow={[null, 'md']}
      mt="var(--vh-10)"
      px={[4, 20]}
      py={10}
      textAlign="center"
      maxW="700px"
      mx="auto"
    >
      <Box fontSize="2xl">
        {errorMessage.title}
      </Box>
      <Box mb={[16, 8]} fontSize="md" color="text-secondary">
        {errorMessage.description}
      </Box>
      <WebSocialLogin action="Continue" />
      <Box mt={10}>
        <Button as={Link} to="/" variant="link" colorScheme="blue">
          Back to home
        </Button>
      </Box>
      <Box mt={20} px={[6, 0]} color="text-secondary" fontSize="sm">
        <WebConsent action="Continue" />
      </Box>
    </Box>
  );
}

export default memo(WebSingInCallbackPage);
