import { Flex, Image, Skeleton, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { FiImage } from 'react-icons/fi';

import { ImageMedia } from '../../shared/data/data-types/entities/media';
import { useDataService } from '../../shared/components/DataServiceContext';

interface Props {
  readonly media: ImageMedia;
}

type ImageState =
  | { readonly state: 'no_data' }
  | { readonly state: 'waiting' }
  | { readonly state: 'error' }
  | { readonly state: 'ready', readonly buffer: Uint8Array | undefined };

function WebAppFigure({ media }: Props) {
  const [image, setImage] = useState<ImageState>({ state: 'no_data' });
  const dataService = useDataService();

  useEffect(() => {
    (async () => {
      if (image.state === 'no_data') {
        setImage({ state: 'waiting' });
        try {
          const buffer = await dataService.blob(media.blobKey);
          setImage({ state: 'ready', buffer });
        } catch (err) {
          console.warn(`Error while loading image ${media.id}:`, err);
          setImage({ state: 'error' });
        }
      }
    })();
  }, [media, image, dataService]);

  const imageBlob = useMemo(
    () => image.state === 'ready' && image.buffer !== undefined
      ? new Blob([image.buffer], { type: media.mimeType })
      : undefined,
    [image, media]
  );

  const [dataUrl, setDataUrl] = useState<string>();
  useEffect(() => {
    if (imageBlob) {
      const url = URL.createObjectURL(imageBlob);
      setDataUrl(url);

      return () => {
        URL.revokeObjectURL(url);
      }
    }
  }, [imageBlob]);

  const errorImageColor = useColorModeValue('gray.400', 'gray.500');

  return (
    <>
      {dataUrl && (<Image
        objectFit="cover"
        h="100%"
        w="100%"
        mx="auto"
        src={dataUrl}
        alt="decrypted"
      />)}
      {image.state === 'waiting' && (
        <Skeleton h="100%" w="100%" />
      )}
      {image.state === 'error' && (
        <Flex height="100%" direction="column" justify="center" align="center" color={errorImageColor} fontSize="6xl">
          <FiImage />
        </Flex>
      )}
    </>
  )
}

export default React.memo(WebAppFigure);
