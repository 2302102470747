import createRBTree, { Tree } from 'functional-red-black-tree';
import { Observable, Subject } from 'rxjs';

import { InMemoryLocker, Lazy, Locker, maxString, MIN_STRING } from './utils';

export interface KeyValuePair<TKey extends string, TValue> {
  readonly key: TKey;
  readonly value: TValue;
}

export interface ListKeyValueStorageOptions {
  readonly lt?: string;
  readonly lte?: string;
  readonly gt?: string;
  readonly gte?: string;
  readonly prefix?: string;
  readonly limit?: number;
}

export interface KeyValueStorage<TKey extends string, TValue> extends Locker<string> {
  readonly message$: Observable<TValue>;
  list(options?: ListKeyValueStorageOptions): Promise<readonly KeyValuePair<TKey, TValue>[]>;
  get(key: TKey): Promise<TValue | undefined>;
  set(key: TKey, value: TValue): Promise<void>;
  delete(key: TKey): Promise<void>;

  post(message: TValue): void;
}

export class InMemoryKeyValueStorage<TKey extends string, TValue> implements KeyValueStorage<TKey, TValue> {
  private tree: Tree<TKey, { value: TValue }>;
  private locker = new InMemoryLocker();
  private messageSubject = new Subject<TValue>();

  constructor() {
    this.tree = createRBTree<TKey, { value: TValue }>();
  }

  get message$(): Observable<TValue> {
    return this.messageSubject.asObservable();
  }

  async list(options?: ListKeyValueStorageOptions): Promise<readonly KeyValuePair<TKey, TValue>[]> {
    const gte = maxString(
      MIN_STRING,
      ...[
        ...(options?.gt !== undefined ? [options.gt] : []),
        ...(options?.gte !== undefined ? [options.gte] : []),
        ...(options?.prefix !== undefined ? [options.prefix] : []),
      ]
    );

    const result: Array<KeyValuePair<TKey, TValue>> = [];
    this.tree.forEach(
      (key, { value }) => {
        if (options?.gte !== undefined && key < options.gte) return;
        if (options?.gt !== undefined && key <= options.gt) return;
        if (options?.prefix !== undefined && key < options.prefix) return;

        if (options?.prefix !== undefined && !key.startsWith(options.prefix)) return false;
        if (options?.lt !== undefined && key >= options.lt) return false;
        if (options?.lte !== undefined && key > options.lte) return false;
        if (options?.limit !== undefined && result.length === options.limit) return false;

        result.push({ key, value });
      },
      gte as TKey,
    );

    return result;
  }

  async get(key: TKey): Promise<TValue | undefined> {
    return this.tree.get(key)?.value;
  }

  async set(key: TKey, value: TValue): Promise<void> {
    const item = this.tree.get(key);
    if (item) {
      item.value = value;
    } else {
      this.tree = this.tree.insert(key, { value });
    }
  }

  async delete(key: TKey): Promise<void> {
    this.tree = this.tree.remove(key);
  }

  async lock<T>(key: string, fn: () => Promise<T>): Promise<T> {
    return await this.locker.lock(key, fn);
  }

  post(message: TValue): void {
    this.messageSubject.next(message);
  }
}

export interface Mapper<TSource, TResult> {
  map(value: TSource): TResult;
  reverse(value: TResult): TSource;
}

export function prefixKeyValueStorage<TKey extends string, TValue>(
  storage: KeyValueStorage<string, TValue>,
  prefix: string,
): KeyValueStorage<TKey, TValue> {
  return new class implements KeyValueStorage<TKey, TValue> {
    get message$(): Observable<TValue> {
      return storage.message$;
    }

    async list(options?: ListKeyValueStorageOptions): Promise<readonly KeyValuePair<TKey, TValue>[]> {
      const items = await storage.list({
        ...options,
        lt: options?.lt !== undefined ? prefix + options.lt : undefined,
        lte: options?.lte !== undefined ? prefix + options.lte : undefined,
        gt: options?.gt !== undefined ? prefix + options.gt : undefined,
        gte: options?.gte !== undefined ? prefix + options.gte : undefined,
        prefix: prefix + (options?.prefix ?? ''),
      });
      return items.map(({ key, value }) => ({ key: key.slice(prefix.length) as TKey, value }));
    }

    get(key: TKey): Promise<TValue | undefined> {
      return storage.get(prefix + key);
    }

    set(key: TKey, value: TValue): Promise<void> {
      return storage.set(prefix + key, value);
    }

    delete(key: TKey): Promise<void> {
      return storage.delete(prefix + key);
    }

    lock<T>(key: string, fn: () => Promise<T>): Promise<T> {
      return storage.lock(prefix + key, fn);
    }

    post(message: TValue): void {
      storage.post(message);
    }
  }();
}

export function cacheKeyValueStorage<TKey extends string, TValue>(
  storage: KeyValueStorage<TKey, TValue>
): KeyValueStorage<TKey, TValue> {
  return new class implements KeyValueStorage<TKey, TValue> {
    private cache: Lazy<InMemoryKeyValueStorage<TKey, TValue>>;

    constructor() {
      this.cache = new Lazy(async () => {
        const items = await storage.list();
        const result = new InMemoryKeyValueStorage<TKey, TValue>();
        for (const { key, value } of items) {
          await result.set(key, value);
        }
        return result;
      });
    }

    get message$(): Observable<TValue> {
      return storage.message$;
    }

    async list(options?: ListKeyValueStorageOptions): Promise<readonly KeyValuePair<TKey, TValue>[]> {
      return (await this.cache.get()).list(options);
    }

    async get(key: TKey): Promise<TValue | undefined> {
      return (await this.cache.get()).get(key);
    }

    async set(key: TKey, value: TValue): Promise<void> {
      await storage.set(key, value);
      await (await this.cache.get()).set(key, value);
    }

    async delete(key: TKey): Promise<void> {
      await storage.delete(key);
      await (await this.cache.get()).delete(key);
    }

    async lock<T>(key: string, fn: () => Promise<T>): Promise<T> {
      return await storage.lock(key, fn);
    }

    post(message: TValue): void {
      storage.post(message);
    }
  }();
}

const VALUE_STORAGE_KEY = 'value';

export class ValueStorage<T> {
  constructor(
    private storage: KeyValueStorage<string, T>,
    private initialValue: T,
  ) { }

  async get(): Promise<T> {
    return await this.storage.get(VALUE_STORAGE_KEY) ?? this.initialValue;
  }

  async set(value: T): Promise<void> {
    await this.storage.set(VALUE_STORAGE_KEY, value);
  }
}
