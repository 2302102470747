import { FC, Fragment } from 'react';

import { useEntry } from '../../../shared/hooks';
import { Entry, EntryId } from '../../../shared/data/data-types/entities/entry';

export function withEntry<Props extends { readonly entry: Entry }>(Component: FC<Props>) {
  return function WithEntry({ entryId, ...props }: Omit<Props, 'entry'> & { readonly entryId: EntryId }) {
    const entry = useEntry(entryId);

    if (entry) {
      return <Component {...props as any} entry={entry} />;
    }

    return <Fragment />;
  }
}
