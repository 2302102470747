import {
  Box,
  BoxProps,
  chakra,
  Flex,
  HStack,
  IconButton,
  MenuButton,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  useBoolean,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { Fragment, useCallback, useMemo } from 'react';
import { FiCalendar, FiMoreHorizontal } from 'react-icons/fi';
import { IoBookmarkSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import { useBackground, useButtonBackground, useIsMobile, useTextPrimary, useTextSecondary } from '../../shared/hooks';
import { getIntro, getTags, getTitle } from '../../shared/data/data-types/delta';
import { Entry } from '../../shared/data/data-types/entities/entry';
import { getBackgroundColor } from '../../shared/utils';
import WebAppFigure from './WebAppFigure';
import { useDataService } from '../../shared/components/DataServiceContext';
import DeleteEntryDialog from './dialogs/WebDeleteEntryDialog';
import ExportEntryDialog from './dialogs/WebExportEntryDialog';
import WebEntryMenu from './WebEntryMenu';
import HighlightTerm from '../../shared/components/HighlightTerm';
import { withEntry } from '../../shared/components/hoc/withEntry';
import { useCabinet } from '../../shared/components/DataServiceStateContext';
import { ImageMedia, MediaId } from '../../shared/data/data-types/entities/media';

const ChakraBookmark = chakra(IoBookmarkSharp);

interface Props extends BoxProps {
  readonly entry: Entry;
  readonly isFirst: boolean;
  readonly isLast: boolean;
  readonly hideLeftDate?: boolean;
  readonly highlightTerm?: string;
}

function WebEntryListItem({ entry, highlightTerm, isLast, isFirst, hideLeftDate, ...boxProps }: Props) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useBoolean();
  const [isExportDialogOpen, setIsExportDialogOpen] = useBoolean();

  const intro = useMemo(
    () => getIntro(entry.body, { length: 300, prefixLength: 30, term: highlightTerm }),
    [entry.body, highlightTerm],
  );

  const textSecondary = useTextSecondary();
  const textPrimary = useTextPrimary();

  const { colorMode } = useColorMode();

  const entryDate = useMemo(() => new Date(entry.timestamp), [entry.timestamp]);

  const cabinet = useCabinet();
  const entryImageId = entry.body
    .map(x => (x.insert as any)?.media?.mediaId as MediaId)
    .filter(x => !!x)
    .find(x => cabinet.media(x)?.type === 'image');
  const entryImage = !!entryImageId
    ? cabinet.media(entryImageId) as ImageMedia
    : undefined;

  const isMobile = useIsMobile();
  const entryTags = useMemo(() => getTags(entry.body).slice(0, isMobile ? 2 : 5), [entry, isMobile]);

  const pageBg = useBackground();
  const cardDefaultBg = useColorModeValue('transparent', '#242C3A'); // gray.750
  const cardHoverBg = useColorModeValue('gray.50', 'gray.700');
  const buttonBg = useButtonBackground();
  const timelineBg = useColorModeValue('gray.300', 'gray.600');
  const optionsButtonBg = useColorModeValue('whiteAlpha.700', 'blackAlpha.700');

  const dataService = useDataService();
  const handleBookmarkedChange = useCallback((value: boolean) => {
    dataService.execute({ type: 'entry/set_bookmarked', value, entryId: entry.id });
  }, [dataService, entry.id]);

  const title = useMemo(() => getTitle(entry.body) || format(new Date(entry.timestamp), 'PPPP'), [entry]);

  const bookmarkColor = useColorModeValue('gray.700', 'white');

  const renderMenuButton = useCallback((onClick: () => void) => (
    <MenuButton
      color={entryImage ? textPrimary : textSecondary}
      as={IconButton}
      position="absolute"
      bg={entryImage ? optionsButtonBg : undefined}
      top={3}
      right={3}
      size="sm"
      icon={<FiMoreHorizontal />}
      variant="ghost"
      fontSize="xl"
      onClick={onClick}
    />
  ), [entryImage, optionsButtonBg, textPrimary, textSecondary]);

  return (
    <Fragment>
      <Flex direction={['column', 'row']}>
        {!isMobile && (
          <Box
            width="50px"
            mr="30px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Box
              pt={4}
              transition="background 0.2s"
              backgroundColor={isFirst ? pageBg : timelineBg}
              w="1px"
              mx="auto"
            />
            {(!hideLeftDate || isLast) && (
              <Tooltip placement="top" label={format(entryDate, 'p, PPPP')}>
                <Flex
                  direction={['row', 'column']}
                  py={2}
                  w="100%"
                  textAlign="center"
                  fontSize={['md', 'xl']}
                  fontWeight={['normal', 'bold']}
                  alignItems="center"
                  color={['text-primary', 'text-primary']}
                  mb={[1, 0]}
                >
                  <Box display={['block', 'none']} mr={1}>
                    <FiCalendar />
                  </Box>
                  <Box mr={[1, 0]}>
                    {format(entryDate, 'do')}
                  </Box>
                  <Box color={[null, 'text-secondary']}>
                    {isMobile ? format(entryDate, 'MMMM') : format(entryDate, 'MMM')}
                  </Box>
                </Flex>
              </Tooltip>
            )}

            <Box
              display={['none', 'block']}
              flex={1}
              transition="background 0.2s"
              backgroundColor={isLast ? pageBg : timelineBg}
              w="1px" mx="auto"
            />
          </Box>
        )}
        <Box
          {...boxProps}
          flex={1}
          minW={0}
          position="relative"
        >
          {entry.bookmarked && (
            <ChakraBookmark
              fontSize="xl"
              position="absolute"
              transform="scaleX(1.3)"
              top="-2px"
              left="29px"
              color={bookmarkColor}
            />
          )}
          <Link style={{ display: 'block' }} to={`/journals/${entry.journalId}/entries/${entry.id}`}>
            <Flex
              borderLeft="5px solid"
              borderLeftColor={getBackgroundColor(entry.cover ?? entry.background, colorMode)}
              background={cardDefaultBg}
              _hover={{ backgroundColor: cardHoverBg }}
              transition="background 0.2s"
              boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06), 0 -7px 4px -5px rgba(0, 0, 0, 0.06)"
              borderRadius="sm"
            >
              <Box
                flex={1}
                minW={0}
                p={[4, 6]}
              >
                <Text fontWeight={['semibold', 'bold']} fontSize={['lg', '2xl']} pr={6}>
                  <HighlightTerm value={title} term={highlightTerm} />
                </Text>
                {!isMobile && (
                  <Text display={['none', 'block']} mt={1} isTruncated color={textSecondary} fontSize="md">
                    <HighlightTerm value={intro} term={highlightTerm} />
                  </Text>
                )}
                {(entryTags.length > 0 || isMobile) && (
                  <Flex mb={[-1, 0]} wrap="wrap" mt={[2, 4]} fontSize="xs" alignItems="center">
                    {isMobile && (
                      <HStack mb={[1, 0]} spacing={1}>
                        <FiCalendar />
                        <Box pr={2}>
                          {format(entryDate, 'MMMM d')}
                        </Box>
                      </HStack>
                    )}
                    {entryTags.map(name => (
                      <Tag
                        size="sm"
                        key={name}
                        borderRadius="sm"
                        variant="subtle"
                        backgroundColor={buttonBg}
                        mr={[1, 2]}
                        mb={[1, 0]}
                      >
                        <TagLabel>{name}</TagLabel>
                      </Tag>
                    ))}
                  </Flex>
                )}
              </Box>
              {entryImage && (
                <Box w="120px" position="relative">
                  <Box position="absolute" left="0" right="0" top="0" bottom="0">
                    <WebAppFigure media={entryImage} />
                  </Box>
                </Box>
              )}
            </Flex>
          </Link>

          <WebEntryMenu
            edit
            entryId={entry.id}
            journalId={entry.journalId}
            onBookmarkedChange={handleBookmarkedChange}
            button={renderMenuButton}
          />
        </Box>
      </Flex>

      <ExportEntryDialog entry={entry} isOpen={isExportDialogOpen} onClose={setIsExportDialogOpen.off} />
      <DeleteEntryDialog entryId={entry.id} isOpen={isDeleteDialogOpen} onClose={setIsDeleteDialogOpen.off} />
    </Fragment>
  );
}

export default React.memo(withEntry(WebEntryListItem));
