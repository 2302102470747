import { Box, Menu, MenuItem, MenuList, Text, useBoolean } from '@chakra-ui/react';
import { Fragment, memo, ReactNode, useCallback, useRef } from 'react';
import { FiEdit, FiPaperclip, FiPrinter, FiTrash2 } from 'react-icons/fi';
import { MdHistory } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { useDelayed, useEntry, useIsMobile, useRed, useTextSecondary } from '../../shared/hooks';
import { EntryId } from '../../shared/data/data-types/entities/entry';
import { JournalId } from '../../shared/data/data-types/entities/journal';
import WebBookmarkIcon from './WebBookmarkIcon';
import DeleteEntryDialog from './dialogs/WebDeleteEntryDialog';
import EntryHistoryDialog from './dialogs/WebEntryHistoryDialog';
import ExportEntryDialog from './dialogs/WebExportEntryDialog';

interface Props {
  readonly onDelete?: () => void;
  readonly entryId: EntryId | undefined;
  readonly journalId: JournalId;
  readonly edit?: boolean;
  readonly onPrint?: () => void;
  readonly onBookmarkedChange: (value: boolean) => void;
  readonly button: (onClick: () => void) => ReactNode;
}

function WebEntryMenu(props: Props) {
  const [isMenuOpen, setIsMenuOpen] = useBoolean();
  const isMenuMounted = useRef(isMenuOpen);
  isMenuMounted.current = isMenuOpen || isMenuMounted.current;

  return (
    <Menu isOpen={isMenuOpen} onClose={setIsMenuOpen.off} isLazy lazyBehavior="keepMounted">
      {props.button(setIsMenuOpen.toggle)}
      <MenuList>
        {isMenuMounted.current && <WebEntryMenuItems {...props} />}
      </MenuList>
    </Menu>
  )
}

export default memo(WebEntryMenu);

function WebEntryMenuItems({ edit, entryId, journalId, onPrint, onBookmarkedChange, onDelete }: Props) {
  const entry = useEntry(entryId ?? ('unknown' as EntryId));

  const textSecondary = useTextSecondary();
  const red = useRed();

  const bookmarked = entry?.bookmarked === true;
  const bookmarkedWithDelay = useDelayed(bookmarked, 200);

  const history = useHistory();
  const handleEdit = useCallback(() => history.push(`/journals/${journalId}/entries/${entryId}`), [history, journalId, entryId]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useBoolean();
  const [isExportDialogOpen, setIsExportDialogOpen] = useBoolean();
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useBoolean();
  const isMobile = useIsMobile();

  return (
    <Fragment>
      {edit && (
        <MenuItem
          icon={<Text fontSize="sm"><FiEdit /></Text>}
          onClick={handleEdit}
        >
          Edit
        </MenuItem>
      )}
      <MenuItem
        icon={<Text fontSize="sm"><WebBookmarkIcon value={bookmarkedWithDelay} /></Text>}
        onClick={() => onBookmarkedChange(!bookmarked)}
      >
        {bookmarkedWithDelay ? 'Remove bookmark' : 'Bookmark'}
      </MenuItem>
      {onPrint && !isMobile && (
        <MenuItem icon={<Text fontSize="sm"><FiPrinter /></Text>} onClick={onPrint} command="Ctrl+P">Print</MenuItem>
      )}
      <MenuItem
        icon={<Text fontSize="md"><FiPaperclip /></Text>}
        onClick={setIsExportDialogOpen.on}
      >
        <Box fontSize="md">
          Export
        </Box>
        <Box fontSize="sm" color={textSecondary} pr={2}>
          PDF, HTML, Markdown
        </Box>
      </MenuItem>
      {!isMobile && (
        <MenuItem
          icon={<Text fontSize="lg"><MdHistory /></Text>}
          onClick={setIsHistoryDialogOpen.on}
        >
          History
        </MenuItem>
      )}
      {
        entryId && (
          <MenuItem
            icon={<Text fontSize="sm"><FiTrash2 /></Text>}
            _hover={{ color: red }}
            onClick={setIsDeleteDialogOpen.on}
          >
            Delete
          </MenuItem>
        )
      }

      {entry && (
        <Fragment>
          <DeleteEntryDialog
            entryId={entry.id}
            isOpen={isDeleteDialogOpen}
            onClose={setIsDeleteDialogOpen.off}
            onDelete={onDelete}
          />
          <ExportEntryDialog entry={entry} isOpen={isExportDialogOpen} onClose={setIsExportDialogOpen.off} />
          <EntryHistoryDialog entryId={entry.id} journalId={entry.journalId} isOpen={isHistoryDialogOpen} onClose={setIsHistoryDialogOpen.off} />
        </Fragment>
      )}
    </Fragment>
  );
}
