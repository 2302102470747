import { FC, Fragment } from 'react';
import { useDelayed } from '../../../shared/hooks';

export function withLazy<Props>(Component: FC<Props>, unmountDelay?: number) {
  return function WithLazy(props: Props & { readonly isOpen: boolean }) {
    const delayedIsOpen = useDelayed(props.isOpen, unmountDelay);

    if (props.isOpen || delayedIsOpen) {
      return <Component {...props} />;
    }

    return <Fragment />;
  }
}
