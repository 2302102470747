import { FC, Fragment } from 'react';

import { useJournal } from '../../../shared/hooks';
import { Journal, JournalId } from '../../../shared/data/data-types/entities/journal';

export function withJournal<Props extends { readonly journal: Journal }>(Component: FC<Props>) {
  return function WithJournal({ journalId, ...props }: Omit<Props, 'journal'> & { readonly journalId: JournalId }) {
    const journal = useJournal(journalId);

    if (journal) {
      return <Component {...props as any} journal={journal} />;
    }

    return <Fragment />;
  }
}
