import React from 'react';
import { createContext, ReactNode, useContext } from 'react';

export type EntryMediaCache = {
  readonly [mediaId: string]: {
    readonly value: {
      readonly buffer: Uint8Array;
      readonly mimeType: string;
    };
    readonly uploading: boolean | undefined;
  };
}

const EntryMediaCacheContext = createContext<EntryMediaCache>({});

interface Props {
  readonly cache: EntryMediaCache;
  readonly children: ReactNode;
}

export const EntryMediaCacheContexttProvider = React.memo(({ cache, children }: Props) => {
  return (
    <EntryMediaCacheContext.Provider value={cache}>
      {children}
    </EntryMediaCacheContext.Provider>
  )
});

export function useEntryMediaCache(mediaId: string) {
  const media = useContext(EntryMediaCacheContext);

  return media[mediaId];
}


