import { createContext, Fragment, memo, useCallback, useContext, useMemo } from 'react';

import { useDataService } from '../../shared/components/DataServiceContext';
import { Journal, JournalId } from '../../shared/data/data-types/entities/journal';
import { hashString } from '../../shared/data/utils';
import { useJournals } from '../../shared/hooks';
import { isTouchDevice, moveJournal } from '../../shared/utils';
import DnDList from './WebDndList';
import JournalListItem from './WebJournalListItem';

interface Props {
  readonly activeJournalId: JournalId | undefined;
}

const ActiveJournalIdContext = createContext<JournalId | undefined>(undefined);

function WebJournalList({ activeJournalId }: Props) {
  const journals = useJournals();

  const dataService = useDataService();
  const setJournals = useCallback((target: readonly Journal[]) => {
    if (target.length !== journals.length) {
      console.warn(`[WARN] Unexpected target length: ${target.length}, source: ${journals.length}`);
      return;
    }

    if (target.length < 2) return;

    // const sourceIndexMap = new Map(journals.map(({ id }, index) => [id, index]));
    const targetIndexMap = new Map(target.map(({ id }, index) => [id, index]));

    let lowerBound = -1;
    for (let i = 0; i < journals.length; i += 1) {
      if (i !== targetIndexMap.get(journals[i].id)) {
        lowerBound = i;
        break;
      }
    }

    let upperBound = target.length;
    for (let i = journals.length - 1; i >= 0; i -= 1) {
      if (i !== targetIndexMap.get(journals[i].id)) {
        upperBound = i;
        break;
      }
    }

    if (lowerBound === -1 || upperBound === target.length) return;

    let journalId: JournalId;
    let insertAt: number;
    if (targetIndexMap.get(journals[lowerBound].id) === lowerBound + 1) {
      journalId = journals[upperBound].id;
      insertAt = lowerBound;
    } else {
      journalId = journals[lowerBound].id;
      insertAt = upperBound + 1;
    }

    moveJournal(dataService, journals, journalId, insertAt);
  }, [journals, dataService]);

  const listHash = useMemo(() => journals.reduce((hash, { id }) => hash ^ hashString(id), 0), [journals]);

  if (isTouchDevice()) {
    return (
      <Fragment>
        {journals.map(journal => <JournalListItem
          inDrag={false}
          isActive={journal.id === activeJournalId}
          journalId={journal.id}
          key={journal.id}
        />)}
      </Fragment>
    )
  }

  return (
    <ul style={{ listStyle: 'none', margin: 0 }}>
      <ActiveJournalIdContext.Provider value={activeJournalId}>
        <DnDList
          key={listHash}
          items={journals}
          setList={setJournals}
          itemComponent={WebDnDItem}
          setSwapThreshold={(size: any) => size * .75}
          setOverflowThreshold={() => 50}
        />
      </ActiveJournalIdContext.Provider>
    </ul>
  )
}

export default memo(WebJournalList);

function WebDnDItem(props: any) {
  const dnd = props.dnd

  let classes = 'el';
  if (props.itemInDrag) {
    classes += ' active';
  }

  const activeJournalId = useContext(ActiveJournalIdContext);

  return (
    <li
      style={{ ...dnd.item.styles, ...dnd.handler.styles }}
      className={dnd.item.classes}
      ref={dnd.item.ref}
      {...dnd.handler.listeners}
    >
      <div className={classes}>
        <JournalListItem
          key={props.item.id}
          journalId={props.item.id}
          isActive={activeJournalId === props.item.id}
          inDrag={props.itemInDrag}
          onEditStateChange={props.onEditStateChange}
        />
      </div>
    </li>
  );
}
