import './main.css';

if (process.env.REACT_APP_TARGET === 'web') {
  require('./web/bootstrapWeb').bootstrapWeb();
} else if (process.env.REACT_APP_TARGET === 'mobile') {
  require('./mobile/bootstrapMobile').bootstrapMobile();
} else {
  console.warn(`[WARN] Unknown target: ${process.env.REACT_APP_TARGET}. Fallback to 'mobile'`);
  require('./mobile/bootstrapMobile').bootstrapMobile();
}
