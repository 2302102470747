import { useCallback } from "react";
import { useDataService } from "../components/DataServiceContext";
import { useDeviceSettings } from "../components/DataServiceStateContext";
import { ColorTheme } from "../data/data-types/entities/settings";
import { useSettings } from "../hooks";

export function useThemeSettings() {
  const dataService = useDataService();

  const selectTheme = useCallback((theme: ColorTheme) => {
    dataService.execute({ type: 'settings/set_theme', theme });
    dataService.setDeviceSettings(prev => ({ ...prev, theme }));
  }, [dataService]);

  const settings = useSettings();
  const deviceSettings = useDeviceSettings();
  const effectiveTheme = settings.syncTheme ? settings.theme : deviceSettings.theme;

  const toggleSyncTheme = useCallback(() => {
    dataService.execute({ type: 'settings/set_theme', theme: effectiveTheme });
    dataService.execute({ type: 'settings/set_sync_theme', value: !settings.syncTheme });
  }, [dataService, settings.syncTheme, effectiveTheme]);

  return { effectiveTheme, syncTheme: settings.syncTheme, toggleSyncTheme, selectTheme };
}
