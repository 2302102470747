import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { Fragment, memo } from 'react';

import { useIsMobile } from '../../shared/hooks';
import WebMobileDialogHeader from './WebMobileDialogHeader';

interface Props extends ModalProps {
  readonly minimal?: boolean;
  readonly title: string;
  readonly action: string | [string, string];
  readonly onAction: () => void;
  readonly actionIsLoading?: boolean;
  readonly actionLoadingText?: string;
  readonly actionDisabled?: boolean;
}

function WebMobal({ title, action, actionDisabled, minimal, actionIsLoading, actionLoadingText, onAction, children, ...modalProps }: Props) {
  const isMobile = useIsMobile();

  modalProps = { ...modalProps, ...(isMobile ? { size: 'full' } : {}) };
  const { onClose } = modalProps;

  return (
    <Modal {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        {!isMobile && !minimal && (
          <Fragment>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
          </Fragment>
        )}
        <ModalBody p={[0, null]} pb={[null, 6]}>
          {isMobile && (
            <WebMobileDialogHeader
              title={title}
              actionDisabled={actionDisabled}
              action={Array.isArray(action) ? action[0] : action}
              onAction={onAction}
              onCancel={onClose}
            />
          )}
          <Box p={[4, 6]}>
            {children}
          </Box>
        </ModalBody>

        {!isMobile && (
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>Cancel</Button>
            <Button
              disabled={actionDisabled}
              onClick={onAction}
              colorScheme="blue"
              isLoading={actionIsLoading}
              loadingText={actionLoadingText}
            >
              {Array.isArray(action) ? action[1] : action}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}

export default memo(WebMobal);
