import { Ciphertext, Cryptography, EncryptionKey, EncryptionKeyId, PlaintextOf } from '../cryptography';
import { deserialize, serialize } from '../encoding';
import { Result } from '../result';
import { generateId } from '../utils';

export class DummyCryptography implements Cryptography {
  async encrypt<TPlaintext>(key: EncryptionKey, plaintext: TPlaintext): Promise<Ciphertext<TPlaintext>> {
    return serialize({
      secret: key.secret,
      plaintext,
    }) as unknown as Ciphertext<TPlaintext>;
  }

  async decrypt<TChiphertext extends Ciphertext<any>>(
    key: EncryptionKey,
    ciphertext: TChiphertext,
  ): Promise<Result<any, PlaintextOf<TChiphertext>>> {
    const { plaintext, secret } = deserialize(ciphertext) as any;
    if (secret !== key.secret) {
      return Result.error(new Error(`Can't decrypt with key ${key.id}`));
    }

    return Result.ok(plaintext);
  }

  async createKey(): Promise<EncryptionKey> {
    return {
      id: generateId() as EncryptionKeyId,
      secret: generateId(),
    };
  }
}
