import { Box, Button, Flex, Spacer } from '@chakra-ui/react';
import { Fragment, memo, ReactNode, useMemo, useState } from 'react';
import { useQuery } from '../../shared/hooks';

import { AuthService } from '../../shared/services/AuthService';
import WebConsent from './WebConsent';
import WebSocialLogin from './WebSocialLogin';

interface Props {
  readonly children: ReactNode;
}

function WebAuthWall({ children }: Props) {
  const identity = useMemo(() => AuthService.getIdentitySync(), []);

  const initialAction = useQuery().get('action');

  const [action, setAction] = useState<'Log in' | 'Sign up'>(initialAction?.toLowerCase() === 'sign up' ? 'Sign up' : 'Log in');

  if (identity?.email !== undefined) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }

  return (
    <Box
      w={['100%', '700px']}
      mx="auto"
      px={[4, 40]} py={[12, 20]}
      mt="var(--vh-10)"
      boxShadow={[null, 'md']}
      borderRadius="md"
      textAlign="center"
    >
      <Flex mb={[16, 8]} alignItems="center">
        <Spacer />
        <Box pr={4} fontSize="4xl" fontWeight="bold">
          Journalify
        </Box>
        <Box pl={4} minW="120px" textAlign="left" fontSize="3xl" color="gray.600" borderLeft="2px solid" borderLeftColor="gray.300">
          {action}
        </Box>
        <Spacer />
      </Flex>
      <WebSocialLogin action={action} />
      <Box mt={10}>
        {action === 'Sign up' && (
          <Fragment>
            Already have an account? <Button colorScheme="blue" onClick={() => setAction('Log in')} variant="link">Log in</Button>
          </Fragment>
        )}
        {action === 'Log in' && (
          <Fragment>
            No account? <Button colorScheme="blue" onClick={() => setAction('Sign up')} variant="link">Create one</Button>
          </Fragment>
        )}
      </Box>
      <Box mt={20} px={[6, 0]} color="text-secondary" fontSize="sm">
        <WebConsent action={action} />
      </Box>
    </Box>
  );
}

export default memo(WebAuthWall);
