import { Box, BoxProps, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { memo } from 'react';

import { TEXT_COLOR_STYLE_DARK_OBJECT, TEXT_COLOR_STYLE_LIGHT_OBJECT } from './editor/formats/TextColor';

interface Props extends BoxProps { }

function TextColorPreview({ ...boxProps }: Props) {
  const { colorMode } = useColorMode();
  const borderColor = useColorModeValue('#ddd', '#555');

  return (
    <Box sx={colorMode === 'light' ? TEXT_COLOR_STYLE_LIGHT_OBJECT : TEXT_COLOR_STYLE_DARK_OBJECT}>
      <Box
        border={`1px solid ${borderColor}`}
        fontWeight="normal"
        lineHeight="19px"
        px={1.5} py={1}
        fontFamily="Arial, sans-serif"
        borderRadius="md"
        {...boxProps}
      >
        A
      </Box>
    </Box>
  )
}

export default memo(TextColorPreview);
