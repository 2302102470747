import { Link } from '@chakra-ui/react';
import React, { Fragment } from 'react';

interface Props {
  readonly action: string;
}

function WebConsent({ action }: Props) {
  return (
    <Fragment>
      Click "{action}" to agree to Journalify's <Link textDecor="underline" href="https://www.journalify.com/terms" target="_blank">Terms of Service</Link> and acknowledge that Journalify's <Link textDecor="underline" href="https://www.journalify.com/privacy" target="_blank">Privacy Policy</Link> applies to you.
    </Fragment>
  );
}

export default React.memo(WebConsent);
