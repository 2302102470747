import { useCallback, useMemo } from 'react';

import { useDataService } from '../components/DataServiceContext';
import { useCabinet } from '../components/DataServiceStateContext';
import { EntryId } from '../data/data-types/entities/entry';
import { Timestamp } from '../data/data-types/timestamp';
import { last } from '../data/utils';

export function useEntryHistory(entryId: EntryId) {
  const cabinet = useCabinet();
  const history = useMemo(
    () => {
      const items = cabinet.history({ entryIds: [entryId] }).reverse();
      if (items.length < 1) {
        return items;
      }
      const result = [items[0]];
      for (let i = 1; i < items.length; i += 1) {
        if (last(result)[1] - items[i][1] >= 60 * 1000) {
          result.push(items[i]);
        }
      }

      return result;
    },
    [cabinet, entryId],
  );

  const dataService = useDataService();
  const restore = useCallback((timestamp: Timestamp) => {
    const entry = cabinet.view(timestamp, { entryIds: [entryId] }).entry(entryId);
    if (entry === undefined) {
      console.warn(`[WARN] Cannot restore entry ${entryId} ${timestamp}`);
      alert('Can\'t restore this version');
      return;
    }
    dataService.execute({ type: 'entry/put', entry });
  }, [cabinet, dataService, entryId]);

  return useMemo(() => ({ history, restore }), [history, restore]);
}
