import { Box, Button, useBoolean } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { useCallback } from "react";
import { FiFileText } from "react-icons/fi";
import { match } from "ts-pattern";

import { EntryTemplate } from "../../shared/data/data-types/entities/journal";
import { useTextOptional, useTextSecondary } from "../../shared/hooks";
import WebTextTemplatePickerPopover from "./WebTextTemplatePickerPopover";

interface Props {
  readonly value: EntryTemplate | undefined;
  readonly onChange: (template: EntryTemplate | undefined) => void;
}

function WebTextTemplatePickerButton({ value, onChange }: Props) {
  const [isTemplatePickerOpen, setIsTemplatePickerOpen] = useBoolean();

  const handleTemplateChange = useCallback(
    (newTemplate: EntryTemplate | undefined) => {
      onChange(newTemplate);
    },
    [onChange]
  );

  const textOptional = useTextOptional();
  const textSecondary = useTextSecondary();

  const selectTemplateText = match(value ?? { type: "unknown" as const })
    .with({ type: "text" }, ({ text }) => text)
    .otherwise(() => "Select entry template...");

  if (value && value.type !== "text") {
    return <Fragment />;
  }

  return (
    <WebTextTemplatePickerPopover
      isOpen={isTemplatePickerOpen}
      onClose={setIsTemplatePickerOpen.off}
      value={value}
      onChange={handleTemplateChange}
    >
      <Button
        leftIcon={<FiFileText />}
        aria-label="Template picker"
        onClick={setIsTemplatePickerOpen.toggle}
        variant="outline"
        fontWeight="normal"
        w="100%"
      >
        <Box
          color={value === undefined ? textOptional : textSecondary}
          textAlign="left"
          w="100%"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {selectTemplateText}
        </Box>
      </Button>
    </WebTextTemplatePickerPopover>
  );
}

export default React.memo(WebTextTemplatePickerButton);
