import { Component } from 'react';

import ErrorFeedback from './ErrorFeedback';

interface State {
  readonly hasError: boolean;
}

export class ErrorBoundary extends Component<object, State> {
  constructor(props: object) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorFeedback />
      );
    }

    return this.props.children;
  }
}
