import { Button } from '@chakra-ui/react';
import { Fragment, memo } from 'react';

import { AuthService } from '../../shared/services/AuthService';
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';
import { ReactComponent as GoogleIcon } from '../../assets/google.svg';

interface Props {
  readonly action: string;
}

function WebSocialLogin({ action }: Props) {
  return (
    <Fragment>
      <Button
        leftIcon={<GoogleIcon style={{ width: '1em', height: '1em' }} />}
        onClick={() => AuthService.startSignInGoogle()}
        variant="outline" w="100%"
        mb={3}
      >
        {action} with Google
      </Button>
      <Button
        leftIcon={<FacebookIcon style={{ width: '1.2em', height: '1.2em' }} />}
        onClick={() => AuthService.startSignInFacebook()}
        variant="outline" w="100%"
        mb={3}
      >
        {action} with Facebook
      </Button>
    </Fragment>
  );
}

export default memo(WebSocialLogin);
