import { useToast } from '@chakra-ui/react';
import { Fragment, memo, useEffect, useState } from 'react';
import { distinctUntilChanged } from 'rxjs';

import { useDataService } from './DataServiceContext';

function StorageQuotaController() {
  const dataService = useDataService();
  const toast = useToast();

  const [quotaExceeded, setQuotaExceeded] = useState(false);

  useEffect(() => {
    const subscription = dataService.storageQuotaExceeded$
      .pipe(distinctUntilChanged())
      .subscribe(setQuotaExceeded);

    return () => {
      subscription.unsubscribe();
    }
  }, [dataService, toast]);

  useEffect(() => {
    if (quotaExceeded) {
      const toastId = toast({
        status: 'error',
        title: 'Media storage quota is exceeded',
        description: 'You can review your current limit in the settings',
        isClosable: true,
        variant: 'subtle',
        duration: null,
      });

      return () => {
        if (toastId) {
          toast.close(toastId);
        }
      };
    }
  }, [quotaExceeded, toast]);

  return <Fragment />;
}

export default memo(StorageQuotaController);
