import { concatMap, distinctUntilChanged, from, Observable, Subject } from 'rxjs';
import { match } from 'ts-pattern';

import { CloudEncryption, CloudEncryptionConfig } from './cloud';
import {
  Ciphertext,
  Cryptography,
  EncryptionKey,
  EncryptionKeyId,
  Passcode,
  PasscodeId,
  PasscodeMetadata,
} from './cryptography';
import { ConcurrencyError, EncryptionKeyNotFoundError, NetworkError, PasscodeRequiredError } from './errors';
import { KeyValuePair, KeyValueStorage, prefixKeyValueStorage, ValueStorage } from './key-value-storage';
import { Result } from './result';
import { OptimisticTransactionLog } from './transaction-log';
import { deepEqual, last, MIN_STRING, single } from './utils';

export type CiphertextEnvelope<T> = [EncryptionKeyId, Ciphertext<T>];

export interface EncryptionConfig {
  readonly effective: EncryptionKeyId;
  readonly reference: readonly EncryptionKey[];
  readonly passcode: Passcode | undefined;
  readonly latestOpKey: string | undefined;
}

type Operation =
  | { readonly type: 'set_passcode', readonly passcode: Passcode }
  | { readonly type: 'remove_passcode' };

export class EncryptionService {
  private config: ValueStorage<EncryptionConfig | undefined>;
  private pendingConfig: ValueStorage<CloudEncryptionConfig | undefined>;

  private pendingChangeLogPointer: ValueStorage<string | undefined>;
  private log: OptimisticTransactionLog<Operation>;
  private passcodes: KeyValueStorage<PasscodeId, Passcode>;

  private changeSubject = new Subject<void>();

  constructor(
    private storage: KeyValueStorage<string, any>,
    private cryptography: Cryptography,
    private vault: CloudEncryption,
  ) {
    this.config = new ValueStorage<EncryptionConfig | undefined>(
      prefixKeyValueStorage(storage, 'config/'),
      undefined
    );
    this.pendingConfig = new ValueStorage<CloudEncryptionConfig | undefined>(
      prefixKeyValueStorage(storage, 'pending_config/'),
      undefined
    );
    this.log = new OptimisticTransactionLog(prefixKeyValueStorage(storage, 'log/'));
    this.passcodes = prefixKeyValueStorage(storage, 'passcodes/');
    this.pendingChangeLogPointer = new ValueStorage<string | undefined>(
      prefixKeyValueStorage(storage, 'pending_change_log_pointer/'),
      undefined,
    );
  }

  get passcode$(): Observable<Passcode | undefined> {
    return this.changeSubject.pipe(
      concatMap(() => from(this.getPasscode())),
      distinctUntilChanged(deepEqual),
    );
  }

  get obstruction$(): Observable<PasscodeMetadata | undefined> {
    return this.changeSubject.pipe(
      concatMap(() => from(this.getObstruction())),
      distinctUntilChanged(deepEqual),
    );
  }

  async dirty(): Promise<boolean> {
    const config = await this.config.get();
    if (config === undefined) {
      return false;
    }

    return config.latestOpKey !== await this.pendingChangeLogPointer.get();
  }

  async encrypt<T>(plaintext: T): Promise<Result<EncryptionKeyNotFoundError, CiphertextEnvelope<T>>> {
    const config = await this.config.get();
    if (config === undefined) {
      return Result.error(new EncryptionKeyNotFoundError());
    }

    if (config.latestOpKey !== await this.pendingChangeLogPointer.get()) {
      return Result.error(new EncryptionKeyNotFoundError());
    }

    const encryptionKey = config.reference.find(x => x.id === config.effective);
    if (encryptionKey === undefined) throw new Error('Effective encryption key not found');

    const ciphertext = await this.cryptography.encrypt(encryptionKey, plaintext);
    return Result.ok([encryptionKey.id, ciphertext]);
  }

  async decrypt<T>(envelope: CiphertextEnvelope<T>): Promise<Result<EncryptionKeyNotFoundError, T>> {
    const config = await this.config.get();
    if (config === undefined) return Result.error(new EncryptionKeyNotFoundError());

    const [encryptionKeyId, ciphertext] = envelope;
    const encryptionKey = config.reference.find(x => x.id === encryptionKeyId);
    if (encryptionKey === undefined) return Result.error(new EncryptionKeyNotFoundError());

    const plaintext = await this.cryptography.decrypt(encryptionKey, ciphertext);
    return Result.ok(plaintext.getOrThrow());
  }

  async hasPasscode(): Promise<boolean> {
    const config = await this.config.get();
    return config?.passcode !== undefined;
  }

  async getObstruction(): Promise<PasscodeMetadata | undefined> {
    const pendingConfig = await this.pendingConfig.get();
    if (pendingConfig === undefined || pendingConfig.type === 'decrypted' || await this.passcodes.get(pendingConfig.encryptedWith.id) !== undefined) {
      return undefined;
    }

    return pendingConfig.encryptedWith;
  }

  async providePasscode(passcodeText: string): Promise<Result<PasscodeRequiredError, void>> {
    try {

      const pendingConfig = await this.pendingConfig.get();
      if (pendingConfig === undefined) {
        return Result.ok();
      }

      if (pendingConfig.type === 'decrypted') {
        return Result.ok();
      }

      const passcode: Passcode = { text: passcodeText, metadata: pendingConfig.encryptedWith };
      const encryptionKey = await this.cryptography.createKey(passcode);
      const result = await this.cryptography.decrypt(encryptionKey, pendingConfig.config);
      if (result.isFailure()) return Result.error(new PasscodeRequiredError(passcode.metadata));

      await this.passcodes.set(passcode.metadata.id, passcode);
      return await this.storage.lock('write', () => this.processPendingConfig());
    } finally {
      this.emitChange();
    }
  }

  async getPasscode(): Promise<Passcode | undefined> {
    const config = await this.config.get();
    return config?.passcode;
  }

  async setPasscode(passcode: Passcode): Promise<void> {
    try {
      return await this.storage.lock('write', async () => {
        const operation: Operation = { type: 'set_passcode', passcode };
        const operationKeys = await this.log.append(operation);

        await this.applyOperation({ key: single(operationKeys), value: operation });
      });
    } finally {
      this.emitChange();
    }
  }

  async removePasscode(): Promise<void> {
    try {
      return await this.storage.lock('write', async () => {
        const operation: Operation = { type: 'remove_passcode' };
        const operationKeys = await this.log.append(operation);

        await this.applyOperation({ key: single(operationKeys), value: operation });
      });
    } finally {
      this.emitChange();
    }
  }

  async sync(): Promise<Result<PasscodeRequiredError | NetworkError, void>> {
    try {
      const cloudEncryption = await this.vault.getEncryption();
      if (cloudEncryption.isFailure()) return cloudEncryption.forward();

      const processResult = await this.storage.lock('write', async () => {
        await this.pendingConfig.set(cloudEncryption.value.value);
        return await this.processPendingConfig();
      });
      if (processResult.isFailure()) return processResult.forward();

      const latestConfig = (await this.config.get())!;
      if (latestConfig.latestOpKey !== await this.pendingChangeLogPointer.get()) {
        const newCloudEncryption = await this.buildCloudEncryption(latestConfig);
        const setEncryptionResult = await this.vault.setEncryption(newCloudEncryption, cloudEncryption.value.concurrencyToken);
        if (setEncryptionResult.isFailure()) {
          if (setEncryptionResult.error instanceof ConcurrencyError) {
            return await this.sync();
          }

          return Result.error(setEncryptionResult.error);
        }

        await this.log.free({ lt: latestConfig.latestOpKey ?? MIN_STRING });
        await this.pendingChangeLogPointer.set(latestConfig.latestOpKey);
      }

      return Result.ok();
    } finally {
      this.emitChange();
    }
  }

  private async processPendingConfig(): Promise<Result<PasscodeRequiredError, void>> {
    const cloudEncryption = await this.pendingConfig.get();
    if (cloudEncryption === undefined) {
      return Result.ok();
    }

    const baseConfig = await match(cloudEncryption)
      .with({ type: 'encrypted' }, async ({ encryptedWith, config }) => {
        const passcode = await this.passcodes.get(encryptedWith.id);
        if (passcode === undefined) {
          return Result.error<PasscodeRequiredError, EncryptionConfig>(
            new PasscodeRequiredError(encryptedWith)
          );
        }

        const encryptionKey = await this.cryptography.createKey(passcode);
        const decryptResult = await this.cryptography.decrypt(encryptionKey, config);
        if (decryptResult.isFailure()) {
          return Result.error<PasscodeRequiredError, EncryptionConfig>(
            new PasscodeRequiredError(encryptedWith)
          );
        }

        return Result.ok<PasscodeRequiredError, EncryptionConfig>({
          ...decryptResult.value,
          latestOpKey: await this.pendingChangeLogPointer.get(),
        });
      })
      .with({ type: 'decrypted' }, async ({ config }) => Result.ok<PasscodeRequiredError, EncryptionConfig>({
        ...config,
        latestOpKey: await this.pendingChangeLogPointer.get(),
      }))
      .exhaustive();

    if (baseConfig.isFailure()) return Result.error(baseConfig.error);

    await this.config.set(baseConfig.value);
    await this.pendingConfig.set(undefined);

    let lastOpKey = baseConfig.value.latestOpKey;
    while (true) {
      const operations = await this.log.list({ gt: lastOpKey, limit: 100_000 });
      if (operations.length === 0) break;

      for (const operation of operations) {
        await this.applyOperation(operation);
      }

      lastOpKey = last(operations).key;
    }

    return Result.ok();
  }

  private async buildCloudEncryption(
    combinedConfig: EncryptionConfig,
  ): Promise<CloudEncryptionConfig> {
    if (combinedConfig.passcode === undefined) {
      return {
        type: 'decrypted',
        config: combinedConfig,
      };
    }
    return {
      type: 'encrypted',
      encryptedWith: combinedConfig.passcode.metadata,
      config: await this.cryptography.encrypt(
        await this.cryptography.createKey(combinedConfig.passcode),
        combinedConfig,
      ),
    };
  }

  private async applyOperation(operation: KeyValuePair<string, Operation>): Promise<void> {
    const oldConfig = await this.config.get();
    const newConfig: EncryptionConfig | undefined = await match(operation.value)
      .with({ type: 'set_passcode' }, async ({ passcode }): Promise<EncryptionConfig> => {
        if (oldConfig?.passcode !== undefined) {
          // just change passcode, no need for a new encryption key
          return { ...oldConfig, passcode, latestOpKey: operation.key };
        }

        const encryptionKey = await this.cryptography.createKey();
        return {
          effective: encryptionKey.id,
          reference: [
            encryptionKey,
            ...(oldConfig?.reference ?? []).filter(x => x.id !== encryptionKey.id),
          ],
          passcode,
          latestOpKey: operation.key,
        }
      })
      .with({ type: 'remove_passcode' }, () => {
        if (oldConfig === undefined) {
          return undefined;
        }

        return {
          ...oldConfig,
          passcode: undefined,
          latestOpKey: operation.key,
        };
      })
      .exhaustive();

    await this.config.set(newConfig === undefined ? undefined : { ...newConfig, latestOpKey: operation.key });

    if (operation.value.type === 'set_passcode') {
      await this.passcodes.set(operation.value.passcode.metadata.id, operation.value.passcode);
    }
  }

  private emitChange(): void {
    this.storage.post({ type: 'encryption_change' });
    this.changeSubject.next();
  }
}
