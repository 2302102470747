import { Box, IconButton, useBoolean } from '@chakra-ui/react';
import { RangeStatic } from 'quill';
import { memo } from 'react';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { FiCamera } from 'react-icons/fi';

import { createBlobKey } from '../../data/data-types/entities/blob';
import { Media, MediaId } from '../../data/data-types/entities/media';
import { getTimestamp } from '../../data/data-types/timestamp';
import { useBackground, useStateDelay } from '../../hooks';
import { generateId, readBlobAsBuffer, wait } from '../../utils';
import { useDataService } from '../DataServiceContext';
import FileUploaderWrapper from '../FileUploadButton';
import { AppQuill } from './AppQuill';
import { EntryMediaCache, EntryMediaCacheContexttProvider } from './EntryMediaCacheContext';
import { AppEmbedCursor } from './formats/createAppEmbed';
import { MediaAppEmbedValue } from './formats/MediaAppEmbed';

interface Props {
  readonly editor: AppQuill;
  readonly children: ReactNode;
}

const MEDIA_BAR_ANIMATION_DURATION = 200;

function AppMediaProvider({ editor, children }: Props) {
  const [mediaBarOffsetTop, setMediaBarOffsetTop] = useState(0);
  const [isMediaBarVisible, setIsMediaBarVisible] = useBoolean();
  const [isMediaBarInteractive, setIsMediaBarInteractive] = useStateDelay(false);
  const [mediaBarSelection, setMediaBarSelection] = useState<RangeStatic>({ index: 0, length: 0 });

  useEffect(() => {
    // https://github.com/quilljs/quill/issues/3240
    // https://issuetracker.google.com/issues/177757645
    try {
      const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
      if (isAndroid && !(editor as any).applyGoogleKeyboardWorkaround) {
        (editor as any).applyGoogleKeyboardWorkaround = true;
        editor.on('editor-change', (eventName: any, ...args: any[]) => {
          if (eventName === 'text-change') {
            // args[0] will be delta
            const ops = args[0].ops;
            const oldSelection = editor.getSelection();
            const oldPos = oldSelection?.index;
            const oldSelectionLength = oldSelection ? oldSelection.length : 0;

            if (ops[0].retain === undefined ||
              !ops[1] ||
              !ops[1].insert ||
              !ops[1].insert ||
              ops[1].insert !== '\n' ||
              oldSelectionLength > 0) {
              return;
            }

            setTimeout(() => {
              const newPos = editor.getSelection()?.index;
              if (newPos !== null && newPos !== undefined && newPos === oldPos) {
                console.log(`Change selection bad position on enter. old: ${oldPos}, new: ${newPos}`);
                editor.setSelection(newPos + 1, 0);
              }
            }, 30);
          }
        });
      }
    } catch {
      console.warn('[WARN] Error occured with gboard workaround');
    }
  }, [editor]);

  const hideMediaBar = useCallback(() => {
    setIsMediaBarVisible.off();
    setIsMediaBarInteractive(false, MEDIA_BAR_ANIMATION_DURATION);
  }, [setIsMediaBarVisible, setIsMediaBarInteractive]);

  const showMediaBar = useCallback(() => {
    setIsMediaBarVisible.on();
    setIsMediaBarInteractive(true);
  }, [setIsMediaBarVisible, setIsMediaBarInteractive]);

  useEffect(() => {
    const handleChange = () => {
      const selection = editor.getSelection();
      if (!selection || selection.length > 0) {
        hideMediaBar();
        return;
      }

      const [line] = editor.getLine(selection.index);
      if (selection.index === 0 || line.length() > 1 || line instanceof AppEmbedCursor) {
        hideMediaBar();
      } else {
        showMediaBar();
        setMediaBarOffsetTop(editor.getBounds(selection.index, 0).top);
        setMediaBarSelection(selection);
      }
    };

    editor.on('editor-change', handleChange);

    return () => {
      editor.off('editor-change', handleChange);
    };
  }, [editor, hideMediaBar, showMediaBar]);

  const [entryMediaCache, setEntryMediaCache] = useState<EntryMediaCache>({});

  const dataService = useDataService();

  const handleAddImage = useCallback(async (file?: File, index?: number) => {
    if (!file) return;

    const mediaId = generateId() as MediaId;

    const objectValue = await readBlobAsBuffer(file);
    const mimeType = file.type;


    setEntryMediaCache(prev => ({
      ...prev,
      [mediaId]: {
        value: { buffer: objectValue, mimeType },
        uploading: true
      }
    }));

    const media: Media = {
      id: mediaId,
      type: 'image',
      createdAt: getTimestamp(),
      modifiedAt: getTimestamp(),
      blobKey: createBlobKey(),
      mimeType,
    };
    dataService.execute({ type: 'image/create', media, blob: objectValue }).then(() => {
      setEntryMediaCache(prev => ({
        ...prev,
        [mediaId]: {
          value: { buffer: objectValue, mimeType },
          uploading: false,
        },
      }));
    });

    const embedValue: MediaAppEmbedValue = { mediaId: media.id };
    editor.insertEmbed(index ?? mediaBarSelection.index, 'media', embedValue, 'user');
  }, [dataService, editor, mediaBarSelection.index]);

  useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      const clipboard = e.clipboardData || (window as any).clipboardData;

      // IE 11 is .files other browsers are .items
      if (clipboard && (clipboard.items || clipboard.files)) {
        let items = clipboard.items || clipboard.files;

        for (let i = 0; i < items.length; i++) {
          if ((items[i].type as string)?.startsWith('image/')) {
            let file = items[i].getAsFile ? items[i].getAsFile() : items[i];

            if (file) {
              e.preventDefault();
              wait(0).then(() => handleAddImage(file, editor.getSelection()?.index));
            }
          }
        }
      }
    };
    const handleDrop = async (e: DragEvent) => {
      if (
        e.dataTransfer &&
        e.dataTransfer.files &&
        e.dataTransfer.files.length
      ) {
        if (document.caretRangeFromPoint) {
          const selection = document.getSelection();
          const range = document.caretRangeFromPoint(e.clientX, e.clientY);
          if (selection && range) {
            selection.setBaseAndExtent(
              range.startContainer,
              range.startOffset,
              range.startContainer,
              range.startOffset
            );
          }
        } else if (typeof (document as any).caretPositionFromPoint === 'function') {
          const selection = document.getSelection();
          const range = (document as any).caretPositionFromPoint(e.clientX, e.clientY);
          if (selection && range) {
            selection.setBaseAndExtent(
              range.offsetNode,
              range.offset,
              range.offsetNode,
              range.offset
            );
          }
        } else {
          return;
        }

        e.stopPropagation();
        e.preventDefault();

        const files = e.dataTransfer?.files ?? [];
        for (let i = 0; i < files.length; i += 1) {
          wait(0).then(() => handleAddImage(files[i], editor.getSelection()?.index));
        }
      }
    };

    editor.root.addEventListener('paste', handlePaste);
    editor.root.addEventListener('drop', handleDrop);

    return () => {
      editor.root.removeEventListener('paste', handlePaste);
      editor.root.removeEventListener('drop', handleDrop);
    };
  }, [editor, handleAddImage]);

  const bg = useBackground();

  return (
    <EntryMediaCacheContexttProvider cache={entryMediaCache}>
      <Box
        opacity={isMediaBarVisible ? 1 : 0}
        position="absolute"
        zIndex={isMediaBarInteractive ? 1 : -1000}
        transition="opacity 0.1s"
        top={`${mediaBarOffsetTop - 9}px`}
        left={[null, '-50px']}
        right={['0', null]}
        pointerEvents="none"
      >
        <FileUploaderWrapper onSelected={handleAddImage} accept="image/*">
          <IconButton
            tabIndex={-1}
            variant="ghost"
            icon={<Box fontSize="2xl"><FiCamera /></Box>}
            aria-label="add photo"
            bg={bg}
          />
        </FileUploaderWrapper>
      </Box>
      {children}
    </EntryMediaCacheContexttProvider>
  )
}

export default memo(AppMediaProvider);
