import { Fragment, memo, useMemo } from 'react';
import { concatMap, from } from 'rxjs';

import { useDataService } from './components/DataServiceContext';
import { useObservable, usePreventUnload } from './hooks';

function UnloadController() {
  const service = useDataService();
  const isDirty = useObservable(
    useMemo(() => service
      .dirty$({})
      .pipe(
        concatMap(dirty => from(service.obstruction().then(obstruction => {
          return dirty && obstruction === undefined;
        })))
      ),
      [service],
    ),
    false,
  );

  usePreventUnload(isDirty);

  return (
    <Fragment />
  );
}

export default memo(UnloadController);
