import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import memoize from 'fast-memoize';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { FaBold, FaListOl, FaListUl } from 'react-icons/fa';
import { FiArrowUpRight, FiCheck, FiChevronDown, FiItalic, FiType, FiUnderline, FiX } from 'react-icons/fi';
import { IoMdQuote } from 'react-icons/io';
import { IoText } from 'react-icons/io5';
import { RiStrikethrough } from 'react-icons/ri';

import TextColorPreview from '../TextColorPreview';
import { useIsMobile, useKeyDownCallback } from '../../hooks';
import WebScrollBox from '../../../web/components/WebScrollBox';

interface Props {
  readonly onInteractionStart: () => void;
  readonly onInteractionEnd: () => void;

  readonly activeFormats: { readonly [format: string]: any };

  readonly onFormatApply: (attributes: { [fornat: string]: any }) => void;
  readonly onFormatLineApply: (attributes: { [fornat: string]: any }) => void;
  readonly onDefaultLineFormatApply: () => void;
}

interface TextColorStyleGroup {
  readonly name: string;
  readonly styles: readonly TextColorStyle[];
}

interface TextColorStyle {
  readonly name: string;
  readonly text_color: string | null;
}

const TEXT_COLOR_STYLES: readonly TextColorStyleGroup[] = [
  {
    name: 'COLOR', styles: [
      { name: 'Default', text_color: null },
      { name: 'Gray', text_color: 'gray' },
      { name: 'Brown', text_color: 'brown' },
      { name: 'Orange', text_color: 'orange' },
      { name: 'Yellow', text_color: 'yellow' },
      { name: 'Green', text_color: 'green' },
      { name: 'Blue', text_color: 'blue' },
      { name: 'Purple', text_color: 'purple' },
      { name: 'Pink', text_color: 'pink' },
      { name: 'Red', text_color: 'red' },
    ]
  },
  {
    name: 'BACKGROUND', styles: [
      { name: 'Default background', text_color: null },
      { name: 'Gray background', text_color: 'bg-gray' },
      { name: 'Brown background', text_color: 'bg-brown' },
      { name: 'Orange background', text_color: 'bg-orange' },
      { name: 'Yellow background', text_color: 'bg-yellow' },
      { name: 'Green background', text_color: 'bg-green' },
      { name: 'Blue background', text_color: 'bg-blue' },
      { name: 'Purple background', text_color: 'bg-purple' },
      { name: 'Pink background', text_color: 'bg-pink' },
      { name: 'Red background', text_color: 'bg-red' },
    ]
  },
];

function Toolbar({
  onInteractionStart, onInteractionEnd, onFormatApply, onFormatLineApply, onDefaultLineFormatApply, activeFormats,
}: Props) {
  const [isLinkEditActive, setIsLinkEditActive] = useState(false);

  const [linkValue, setLinkValue] = useState('');
  const handleLinkChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkValue(e.target.value);
  }, []);

  const exitLinkEdit = useCallback((save?: boolean) => {
    onInteractionEnd();

    if (save) {
      onFormatApply({ link: linkValue });
    }

    setIsLinkEditActive(false);
    setLinkValue('');
  }, [onInteractionEnd, linkValue, onFormatApply]);

  const linkEditInputRef = useRef<HTMLInputElement>(null);

  const startLinkEdit = useCallback(() => {
    if (!!activeFormats['link']) {
      onFormatApply({ link: null });
      return;
    }

    setIsLinkEditActive(true);
    onInteractionStart();
    setTimeout(() => linkEditInputRef.current?.focus(), 0);
  }, [onInteractionStart, activeFormats, onFormatApply]);

  const handleLinkEditKeyDown = useKeyDownCallback(
    useMemo(() => ({
      onEnter: () => exitLinkEdit(true),
      onEscape: () => exitLinkEdit(),
    }), [exitLinkEdit])
  );

  const handleFormatApply = useMemo(
    () => memoize((attributes: { [fornat: string]: any }) => () => onFormatApply(attributes)),
    [onFormatApply]
  );

  const handleFormatLineApply = useMemo(
    () => memoize((attributes: { [fornat: string]: any }) => () => onFormatLineApply(attributes)),
    [onFormatLineApply]
  );

  const activeLineFormatName = useMemo(() => {
    if (activeFormats['blockquote']) {
      return 'Quote';
    }

    if (activeFormats['header'] === 1) {
      return 'Heading 1';
    }

    if (activeFormats['header'] === 2) {
      return 'Heading 2';
    }

    if (!!activeFormats['header']) {
      return 'Heading';
    }

    if (activeFormats['list'] === 'bullet') {
      return 'Bullet list';
    }

    if (activeFormats['list'] === 'ordered') {
      return 'Numbered list';
    }

    if (activeFormats['list'] === 'checked') {
      return 'Checklist';
    }

    return 'Normal text';
  }, [activeFormats]);

  const linkEditContainerRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: linkEditContainerRef,
    handler: () => {
      if (isLinkEditActive) {
        exitLinkEdit();
      }
    },
  });

  const {
    isOpen: isTextColorPopoverVisible,
    onToggle: toggleTextColorPopover,
    onClose: closeTextColorPopover,
  } = useDisclosure();

  const [textColorStyles, setTextColorStyles] = useState<readonly TextColorStyleGroup[]>(TEXT_COLOR_STYLES);

  const handleTextColorChange = useCallback((style: TextColorStyle) => {
    onFormatApply({ text_color: style.text_color });
    closeTextColorPopover();
    setTextColorStyles([{ name: 'LAST USED', styles: [style] }, ...TEXT_COLOR_STYLES]);
  }, [onFormatApply, closeTextColorPopover]);

  const bg = useColorModeValue('white', 'gray.700');
  const menuGroupColor = useColorModeValue('gray.500', 'gray.400');
  const isMobile = useIsMobile();

  return (
    <Box
      bg={bg}
      boxShadow={`
        rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
        rgba(15, 15, 15, 0.10) 0px 3px 6px,
        rgba(15, 15, 15, 0.20) 0px 9px 24px
      `}
      d="flex"
      borderRadius="sm"
      position="relative"
      sx={{
        '&': isMobile
          ? {
            maxW: `${window.innerWidth - 20}px`,
            overflowX: 'scroll',
            overflowY: 'visible',
            marginLeft: '10px',
            marginRight: '10px',
          }
          : {
            overflowX: 'visible',
            overflowY: 'visible',
          },

        '& button:focus': {
          boxShadow: 'none',
        },
        '& > *': {
          // flex: 'none'
        },
      }}
    >
      <Menu isLazy>
        <Tooltip openDelay={500} placement="top" label="Turn into">
          <MenuButton
            as={Button}
            size="md"
            borderRadius={0}
            variant="ghost"
            rightIcon={<FiChevronDown />}
          >
            <Text pb="3px">{activeLineFormatName}</Text>
          </MenuButton>
        </Tooltip>
        <Portal>
          <MenuList>
            <Box px={4} py={2} userSelect="none" fontWeight="semibold" fontSize="xs" color={menuGroupColor}>TURN INTO</Box>
            <MenuItem onClick={onDefaultLineFormatApply} icon={<Text fontSize="lg"><IoText /></Text>}>Normal text</MenuItem>
            <MenuItem onClick={handleFormatLineApply({ blockquote: true })} icon={<Text fontSize="md"><IoMdQuote /></Text>}>Quote</MenuItem>
            <MenuItem onClick={handleFormatLineApply({ header: 1 })} icon={<Text fontSize="lg"><FiType /></Text>}>Heading 1</MenuItem>
            <MenuItem onClick={handleFormatLineApply({ header: 2 })} icon={<Text fontSize="md"><FiType /></Text>}>Heading 2</MenuItem>
            <MenuItem onClick={handleFormatLineApply({ list: 'bullet' })} icon={<FaListUl />}>Bulleted list</MenuItem>
            <MenuItem onClick={handleFormatLineApply({ list: 'ordered' })} icon={<FaListOl />}>Numbered list</MenuItem>
          </MenuList>
        </Portal>
      </Menu>

      <Tooltip openDelay={500}
        placement="top"
        label="Add link"
      >
        <Button
          borderLeft="1px solid rgb(55, 53, 47, 0.15)"
          size="md"
          borderRadius={0}
          variant="ghost"
          color={!!activeFormats['link'] ? 'blue.400' : undefined}
          onClick={startLinkEdit}
          leftIcon={<Text fontSize="xl"><FiArrowUpRight /></Text>}
        >
          <Text pb="3px">Link</Text>
        </Button>
      </Tooltip>

      <Tooltip openDelay={500}
        placement="top"
        label={<div><Text>Bold</Text><Text color="gray.500">Ctrl+B</Text></div>}
      >
        <IconButton
          borderLeft="1px solid rgb(55, 53, 47, 0.15)"
          borderRadius={0}
          variant="ghost"
          size="md"
          color={!!activeFormats['bold'] ? 'blue.400' : undefined}
          onClick={handleFormatApply({ bold: !activeFormats['bold'] })}
          aria-label="bold"
          icon={<FaBold />}
        />
      </Tooltip>

      <Tooltip openDelay={500}
        placement="top"
        label={<div><Text>Italicize</Text><Text color="gray.500">Ctrl+I</Text></div>}
      >
        <IconButton
          borderLeft="1px solid rgb(55, 53, 47, 0.15)"
          borderRadius={0}
          variant="ghost"
          size="md"
          color={!!activeFormats['italic'] ? 'blue.400' : undefined}
          onClick={handleFormatApply({ italic: !activeFormats['italic'] })}
          aria-label="italic"
          icon={<FiItalic />}
        />
      </Tooltip>

      <Tooltip openDelay={500}
        placement="top"
        label={<div><Text>Underline</Text><Text color="gray.500">Ctrl+U</Text></div>}
      >
        <IconButton
          borderLeft="1px solid rgb(55, 53, 47, 0.15)"
          borderRadius={0}
          variant="ghost"
          size="md"
          display={['none', 'inline-flex']}
          color={!!activeFormats['underline'] ? 'blue.400' : undefined}
          onClick={handleFormatApply({ underline: !activeFormats['underline'] })}
          aria-label="underline"
          icon={<FiUnderline />}
        />
      </Tooltip>

      <Tooltip openDelay={500}
        placement="top"
        label={<div><Text>Strike-through</Text><Text color="gray.500">Ctrl+Shift+S</Text></div>}
      >
        <IconButton
          borderLeft="1px solid rgb(55, 53, 47, 0.15)"
          borderRadius={0}
          size="md"
          display={['none', 'inline-flex']}
          color={!!activeFormats['strike'] ? 'blue.400' : undefined}
          onClick={handleFormatApply({ strike: !activeFormats['strike'] })}
          variant="ghost"
          aria-label="strike-through"
          icon={<Text fontSize="lg"><RiStrikethrough /></Text>}
        />
      </Tooltip>

      <Popover
        isOpen={isTextColorPopoverVisible}
        onClose={closeTextColorPopover}
        isLazy
      >
        <PopoverTrigger>
          <Box display="inline-block" borderLeft="1px solid rgb(55, 53, 47, 0.15)">
            <Tooltip openDelay={500} placement="top" label="Text color">
              <Button
                borderRadius={0}
                variant="ghost"
                pl={3}
                size="md"
                onClick={toggleTextColorPopover}
                rightIcon={<FiChevronDown />}
              >
                <Text px={2} py={0.5} fontSize="lg" color={activeFormats['color']} background={activeFormats['background']}>A</Text>
              </Button>
            </Tooltip>
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent boxShadow="none" _focus={{ boxShadow: 'none' }}>
            <PopoverBody px={0} py={2} sx={{
              '& button': {
                justifyContent: 'start',
                fontWeight: 'normal',
                width: '100%',
                borderRadius: 0,
                '&:focus': { boxShadow: 'none' },
              },
              '&:focus': {

              }
            }}>
              <WebScrollBox maxH="var(--vh-50)">
                {
                  textColorStyles.map(({ name, styles }) => (
                    <Box key={name}>
                      <Box px={4} py={2} userSelect="none" fontWeight="semibold" fontSize="xs" color={menuGroupColor}>{name}</Box>
                      {styles.map((style) => (
                        <Button key={style.name} onClick={() => handleTextColorChange(style)} variant="ghost">
                          <TextColorPreview
                            mr={2}
                            className={`text-color-${style.text_color}`}
                          />
                          {style.name}
                        </Button>
                      ))}
                    </Box>
                  ))
                }
              </WebScrollBox>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>

      <Box
        position="absolute"
        left="0"
        right="0"
        top="0"
        bottom="0"
        bg={bg}
        display={isLinkEditActive ? 'block' : 'none'}
      >
        <Flex ref={linkEditContainerRef}>
          <Input
            flex={1}
            placeholder="Paste or type a link..."
            variant="ghost"
            bg={bg}
            value={linkValue}
            onKeyDown={handleLinkEditKeyDown}
            onChange={handleLinkChange}
            ref={linkEditInputRef}
          />

          <Tooltip openDelay={500} placement="top" label="Save">
            <IconButton
              variant="ghost"
              size="md"
              aria-label="save"
              onClick={() => exitLinkEdit(true)}
              icon={<FiCheck />}
            />
          </Tooltip>
          <Tooltip openDelay={500} placement="top" label="Cancel">
            <IconButton
              variant="ghost"
              size="md"
              aria-label="close"
              onClick={() => exitLinkEdit()}
              icon={<FiX />}
            />
          </Tooltip>
        </Flex>
      </Box>
    </Box>
  );
}

export default memo(Toolbar);
