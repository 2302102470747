import { useBoolean } from '@chakra-ui/react';
import { useCallback, useRef, useState } from 'react';

import { useDataService } from '../components/DataServiceContext';
import { PasscodeId } from '../data/cryptography';
import { getTimestamp } from '../data/data-types/timestamp';
import { generateId, wait } from '../data/utils';

interface Options {
  readonly onClose: (success: boolean) => void;
}

export function useTurnPasscodeOn({ onClose }: Options) {
  const [passcode, setPasscode] = useState('');
  const [repeatPasscode, setRepeatPasscode] = useState('');
  const [isRepeatPasscodeInvalid, setIsRepeatPasscodeInvlid] = useBoolean();

  const confirmPasscodeInputRef = useRef<HTMLInputElement>(null);

  const handlePasscodeChange = useCallback((value: string) => {
    setPasscode(value);
    setIsPasscodeInvalid(false);
    setIsRepeatPasscodeInvlid.off();
  }, [setIsRepeatPasscodeInvlid]);

  const handleRepeatPasscodeChange = useCallback((value: string) => {
    setRepeatPasscode(value);
    setIsPasscodeInvalid(false);
    setIsRepeatPasscodeInvlid.off();
  }, [setIsRepeatPasscodeInvlid]);

  const [isPasscodeInvalid, setIsPasscodeInvalid] = useState(false);

  const dataService = useDataService();
  const turnPasscodeOnLock = useCallback(() => {
    if (passcode === '') {
      setIsPasscodeInvalid(true);
      return;
    }

    const valid = passcode === repeatPasscode;
    if (valid) {
      dataService.encrypt({
        text: passcode,
        metadata: {
          id: generateId() as PasscodeId,
          timestamp: getTimestamp(),
          hint: undefined,
          location: undefined,
        },
      }).then(() => onClose(true));
    } else {
      setIsRepeatPasscodeInvlid.on();
      wait(0).then(() => confirmPasscodeInputRef.current?.focus());
    }
  }, [passcode, repeatPasscode, dataService, onClose, setIsRepeatPasscodeInvlid]);

  return {
    passcode,
    repeatPasscode,
    isRepeatPasscodeInvalid,
    confirmPasscodeInputRef,
    handlePasscodeChange,
    handleRepeatPasscodeChange,
    turnPasscodeOnLock,
    isPasscodeInvalid,
  }
}
