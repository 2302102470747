import { ChakraProvider, extendTheme, theme as defaultTheme, useColorMode } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { createContext, Fragment, ReactNode, useContext, useEffect, useState } from 'react';

function getTheme(colorMode: 'light' | 'dark', withStyles: boolean) {
  const colors = defaultTheme.colors;

  let appTheme = extendTheme({
    breakpoints: createBreakpoints({
      // sm: '30em',
      md: '48em',
      lg: '62em',
      xl: '80em',
      '2xl': '96em',
    } as any),
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false,
    },
    colors: {
      'text-primary': colorMode === 'light' ? 'black' : 'white',
      'text-secondary': colorMode === 'light' ? colors.gray['500'] : colors.gray['400'],
      'text-optional': colorMode === 'light' ? colors.gray['400'] : colors.gray['500'],
      'text-red': colorMode === 'light' ? 'red' : colors.red['300'],

      'bg-main': colorMode === 'light' ? 'white' : colors.gray['800'],
      'bg-light-gray': colorMode === 'light' ? colors.gray['50'] : '#242C3A',
      'bg-layer-2': colorMode === 'light' ? 'white' : '#242C3A',
      'bg-gray': colorMode === 'light' ? colors.gray['100'] : colors.gray['700'],
      'bg-highlight': colorMode === 'light' ? colors.gray['200'] : '#3C4658', // gray.650
      'bg-btn': colorMode === 'light' ? colors.gray['100'] : colors.whiteAlpha['200'],
    },
  });

  if (!withStyles) {
    appTheme = {
      ...appTheme,
      styles: undefined,
    };
  }

  return appTheme;
}

const useTheme = () => useState<any>();
const ThemeContext = createContext<ReturnType<typeof useTheme>>([{}, () => { }]);

interface Props {
  readonly resetCSS: boolean;
  readonly children: ReactNode;
}

export function AppChakraProvider({ children, resetCSS }: Props) {
  const [{ theme }, setTheme] = useState(() => ({ colorMode: 'light', theme: getTheme('light', resetCSS) }));

  return (
    <ThemeContext.Provider value={[theme, setTheme]}>
      <ChakraProvider resetCSS={resetCSS} theme={theme}>
        <ThemeController resetCSS={resetCSS} />
        {children}
      </ChakraProvider>
    </ThemeContext.Provider>
  );
}

function ThemeController({ resetCSS }: { resetCSS: boolean }) {
  const [{ colorMode: themeColorMode }, setTheme] = useContext(ThemeContext);

  const { colorMode } = useColorMode();

  useEffect(() => {
    if (themeColorMode !== colorMode) {
      setTheme({ colorMode, theme: getTheme(colorMode, resetCSS) });
    }
  }, [colorMode, resetCSS, setTheme, themeColorMode]);

  return <Fragment />;
}
