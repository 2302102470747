import { Box, Flex, FormControl, FormLabel, Select, Switch } from '@chakra-ui/react';
import { memo } from 'react';
import { THEME_OPTIONS } from '../../../shared/constants';

import { useThemeSettings } from '../../../shared/hooks/useThemeSettings';
import WebSettingsSection from './WebSettingsSection';

function WebThemeSettings() {
  const { selectTheme, syncTheme, effectiveTheme, toggleSyncTheme } = useThemeSettings();

  return (
    <div>
      <WebSettingsSection>
        <Flex wrap="wrap" w={[null, '420px']}>
          <Select
            value={THEME_OPTIONS.find(x => x.value.type === effectiveTheme.type && x.value.autoDark === effectiveTheme.autoDark)?.title}
            onChange={e => selectTheme(THEME_OPTIONS.find(x => x.title === e.target.value)?.value ?? { type: 'light', autoDark: false })}
            size="sm"
            maxW="300px"
          >
            {THEME_OPTIONS.map(x => <option key={x.title} value={x.title}>{x.title}</option>)}
          </Select>
        </Flex>

        <Box fontSize="sm" mt={2} color="text-secondary">
          Customize how Journalify looks on your device.
        </Box>
      </WebSettingsSection>

      <WebSettingsSection>
        <FormControl display="flex" alignItems="center" mb={3}>
          <Switch
            id="sync-theme"
            isChecked={syncTheme}
            onChange={toggleSyncTheme}
          />
          <FormLabel fontWeight="normal" htmlFor="sync-theme" ml={3} mb="3px" cursor="pointer">
            Sync theme between devices
          </FormLabel>
        </FormControl>
      </WebSettingsSection>
    </div>
  )
}

export default memo(WebThemeSettings);
