import Delta from 'quill-delta';
import { memo } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { toObjectMap, wait } from '../../utils';
import { AppQuill } from './AppQuill';
import { AppEmbed, AppEmbedCursor, AppEmbedInstance } from './formats/createAppEmbed';
import { EntryTitle } from './formats/EntryTitle';

interface Props {
  readonly editor: AppQuill;
}

interface EmbedBloState {
  readonly blot: AppEmbedInstance,
  readonly isFocused: boolean
}

interface EmbedBlotMap {
  readonly [blotId: string]: EmbedBloState;
};

function AppEmbedProvider({ editor }: Props) {
  const [embedBlots, setEmbedBlots] = useState<EmbedBlotMap>({});

  const onMount = useCallback((...blots: AppEmbedInstance[]) => {
    setEmbedBlots(prevEmbedBlots => blots.reduce(
      (memo, blot) => {
        memo[blot.id] = { blot, isFocused: false };
        return memo;
      },
      { ...prevEmbedBlots }
    ));
  }, []);

  const setEmbedFocus = useCallback((blot: AppEmbed | undefined) => {
    setEmbedBlots(prevEmbedBlots => {
      const result: { [blotId: string]: EmbedBloState } = {};
      for (const blotId of Object.keys(prevEmbedBlots)) {
        result[blotId] = { ...prevEmbedBlots[blotId], isFocused: prevEmbedBlots[blotId].blot === blot };
      }
      return result;
    });

    const lines = editor.getLines();
    const lineNumber = lines.findIndex(x => x === blot);
    if (lineNumber !== -1) {
      const nextLine = lines[lineNumber + 1];
      if (nextLine instanceof AppEmbedCursor) {
        wait(1).then(() => editor.setSelection(nextLine.offset(), 0, 'user'));
      }
    }
  }, [editor]);

  const onUnmount = useCallback((unmountedBlot: AppEmbedInstance) => {
    setEmbedBlots(prevEmbedBlots => {
      const { [unmountedBlot.id]: blot, ...newEmbedBlots } = prevEmbedBlots;
      return newEmbedBlots;
    });
  }, []);

  const selectionFocusChange = useCallback(() => {
    const selection = editor.getSelection(false);
    if (!selection || selection.length > 0) {
      setEmbedFocus(undefined);
      return;
    }

    const [line] = editor.getLine(selection.index);
    const [prevLine] = editor.getLine(selection.index - 1);
    if (line instanceof AppEmbedCursor && prevLine instanceof AppEmbed) {
      setEmbedFocus(prevLine);
    } else {
      setEmbedFocus(undefined);
    }
  }, [editor, setEmbedFocus]);

  const normalizeTextInProgressRef = useRef(false);
  const normalizeText = useCallback(() => {
    if (normalizeTextInProgressRef.current) return;

    normalizeTextInProgressRef.current = true;
    try {
      const newLines = editor.getLines();
      if (newLines.length === 1) {
        editor.updateContents(new Delta().retain(newLines[0].length()).insert('\n', { placeholder: 'content' }) as any, 'user');
      } else if (newLines.length === 2 && newLines[1].length() === 1) {
        editor.formatLine(newLines[1].offset(), 1, 'placeholder', 'content', 'user');
      } else {
        editor.formatLine(newLines[1].offset(), 1_000_000_000, 'placeholder', false, 'user');
      }

      const lines = editor.getLines();
      for (let lineNumber = lines.length; lineNumber >= 0; lineNumber -= 1) {
        const prevLine = lines[lineNumber - 1];
        const line = lines[lineNumber];
        const nextLine = lines[lineNumber + 1];
        if (line instanceof AppEmbed) {
          if (!(nextLine instanceof AppEmbedCursor)) {
            editor.updateContents(new Delta().retain(line.offset() + line.length()).insert('\n', { app_embed_cursor: true }) as any, 'user');
          }
        } else if (line instanceof AppEmbedCursor) {
          if (!(prevLine instanceof AppEmbed)) {
            editor.updateContents(new Delta().retain(line.offset()).delete(line.length()) as any, 'user')
          } else if (line.length() !== 1) {
            editor.updateContents(new Delta().retain(line.offset()).delete(line.length() - 1) as any, 'user');
          }
        } else if (line instanceof EntryTitle) {
          if (lineNumber !== 0) {
            editor.formatLine(line.offset(), 1, { entry_title: null, placeholder: null }, 'user');
          }
        }
      }

      const [firstLine] = editor.getLine(0);
      if (firstLine instanceof AppEmbed) {
        editor.updateContents(new Delta().insert('\n', { entry_title: true, placeholder: 'title' }) as any, 'user');
      } else {
        let formats: any = {};
        for (const op of editor.getContents().ops ?? []) {
          formats = { ...formats, ...op.attributes };

          if (typeof op.insert === 'string' && op.insert.indexOf('\n') !== -1) break;
        }
        editor.formatText(0, firstLine.length(), 'entry_title', true, 'user');
        if (formats.placeholder !== undefined && firstLine.length() > 1) {
          editor.formatText(0, firstLine.length(), 'placeholder', false, 'user');
        }

        if (formats.placeholder !== 'title' && firstLine.length() === 1) {
          editor.formatText(0, firstLine.length(), 'placeholder', 'title', 'user');
        }

        for (const format of Object.keys(formats)) {
          if (format !== 'placeholder' && format !== 'entry_title') {
            editor.formatText(0, firstLine.length(), format, false, 'user');
          }
        }
      }
    }
    finally {
      normalizeTextInProgressRef.current = false;
    }
  }, [editor]);

  (window as any).editor = editor;

  const normalizeSelectionInProgressRef = useRef(false);
  const normalizeSelection = useCallback(() => {
    if (normalizeSelectionInProgressRef.current) return;
    normalizeSelectionInProgressRef.current = true;

    try {
      const selection = editor.getSelection(false);
      if (!selection) return;

      let newSelection = selection;

      const [startLine] = editor.getLine(selection.index);
      if (startLine instanceof AppEmbed) {
        newSelection = {
          index: newSelection.index + 1,
          length: Math.max(0, newSelection.length - 1)
        };
      }

      if (newSelection.length > 0) {
        const [endLine] = editor.getLine(newSelection.index + newSelection.length);
        if (endLine instanceof AppEmbed) {
          newSelection = { index: newSelection.index, length: newSelection.length + 1 };
        }
      }

      if (newSelection.index !== selection.index || newSelection.length !== selection.length) {
        editor.setSelection(newSelection, 'user');
      }
    } finally {
      normalizeSelectionInProgressRef.current = false;
    }
  }, [editor]);

  useEffect(() => {
    const reactBlots: AppEmbedInstance[] = (editor.scroll as any).reactBlots ?? [];

    setEmbedBlots(toObjectMap(reactBlots.map(blot => ({ blot, isFocused: false })) ?? [], x => x.blot.id));

    editor.clipboard.addMatcher('.ql-app_embed_cursor', (node, delta) => {
      return delta.compose(new Delta().retain(delta.length(), { app_embed_cursor: true }) as any);
    });

    return () => setEmbedBlots({})
  }, [editor]);

  useEffect(() => {
    const { emitter } = (editor.scroll as any);

    editor.on('text-change', normalizeText);
    editor.on('selection-change', normalizeSelection);
    editor.on('editor-change', selectionFocusChange);

    emitter.on('blot-mount', onMount);
    emitter.on('blot-focus', setEmbedFocus);
    emitter.on('blot-unmount', onUnmount);

    return () => {
      editor.off('text-change', normalizeText);
      editor.off('selection-change', normalizeSelection);
      editor.off('editor-change', selectionFocusChange);

      emitter.off('blot-mount', onMount);
      emitter.off('blot-focus', setEmbedFocus);
      emitter.off('blot-unmount', onUnmount);
    }
  }, [editor, onMount, onUnmount, normalizeText, normalizeSelection, setEmbedFocus, selectionFocusChange]);

  const copyInProgressRef = useRef(false);
  useEffect(() => {
    const createCopyOrCutHandler = (actionType: 'copy' | 'cut') => async (e: ClipboardEvent) => {
      if (copyInProgressRef.current) return;
      copyInProgressRef.current = true;

      try {
        const focusedBlot = Object.values(embedBlots).find(x => x.isFocused)?.blot;
        if (focusedBlot) {
          const selection = editor.getSelection();
          if (!selection) return;

          e.preventDefault();

          editor.setSelection(focusedBlot.offset() - 1, focusedBlot.length() + 1, 'silent');
          // await wait(0);

          document.execCommand(actionType);
          editor.setSelection(selection, 'silent');
        }
      } finally {
        copyInProgressRef.current = false;
      }
    };
    const handleCopy = createCopyOrCutHandler('copy');
    const handleCut = createCopyOrCutHandler('cut');
    editor.root.addEventListener('copy', handleCopy);
    editor.root.addEventListener('cut', handleCut);

    return () => {
      editor.root.removeEventListener('copy', handleCopy);
      editor.root.removeEventListener('cut', handleCut);
    }
  }, [editor, embedBlots]);

  return (
    <>
      {Object.values(embedBlots).map(({ blot, isFocused }) => blot.renderPortal(isFocused))}
    </>
  );
}

export default memo(AppEmbedProvider);
