import { Button, ButtonProps, useColorModeValue } from '@chakra-ui/react';
import { memo, forwardRef } from 'react';

const WebHelpFabButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const bg = useColorModeValue('white', '#242C3A'); // gray.750

  return (
    <Button
      ref={ref}
      bg={bg}
      zIndex="sticky"
      position="fixed"
      right="25px"
      bottom="25px"
      borderRadius="full"
      variant="outline"
      fontSize="xl"
      fontWeight="normal"
      boxShadow="md"
      {...props}
    >
      ?
    </Button>
  );
});

export default memo(WebHelpFabButton);
