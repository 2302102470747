import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Switch,
  useBoolean,
} from '@chakra-ui/react';
import React from 'react';
import { useCallback, useRef } from 'react';

import { useDataService } from '../../../shared/components/DataServiceContext';
import { withLazy } from '../../../shared/components/hoc/withLazy';
import { Timestamp } from '../../../shared/data/data-types/timestamp';
import { ExportProxy } from '../../../shared/export/exportProxy';

interface Props {
  readonly timestamp: Timestamp;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

function WebExportBackupDialog({ timestamp, isOpen, onClose }: Props) {
  const [includeAssets, setIncludeAssets] = useBoolean(true);
  const dataService = useDataService();

  const exportButtonRef = useRef(null);

  const [inProgress, setInProgress] = useBoolean();
  const handleExport = useCallback(async () => {
    if (inProgress) return;

    try {
      setInProgress.on();
      const backup = await dataService.createBackup(timestamp, includeAssets);
      await ExportProxy.exportBackup(backup);
    } finally {
      setInProgress.off();
      onClose();
    }
  }, [inProgress, setInProgress, dataService, timestamp, includeAssets, onClose]);

  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={exportButtonRef}
    >
      <ModalOverlay>
        <ModalContent mx={[4, null]}>
          <ModalHeader fontSize="lg" fontWeight="bold">
            Backup
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <SimpleGrid mb={3} alignItems="center" columns={2} spacing={4}>
              <div>
                Include images
              </div>
              <Flex justifyContent="end">
                <Switch mr={4} isChecked={includeAssets} onChange={setIncludeAssets.toggle} />
              </Flex>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              isLoading={inProgress}
              loadingText="Preparing backup..."
              onClick={handleExport}
              ref={exportButtonRef}
              ml={3}
            >
              Download
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export default React.memo(withLazy(WebExportBackupDialog, 200));
