import { FiImage } from 'react-icons/fi';
import {
  Box,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { BsPalette } from 'react-icons/bs';
import { FiLock, FiUser } from 'react-icons/fi';
import { MdOutlineBackup } from 'react-icons/md';

import { withLazy } from '../../../shared/components/hoc/withLazy';
import { useBackground, useGrayBg, useIsMobile } from '../../../shared/hooks';
import WebAccountSettings from '../settings/WebAccountSettings';
import WebBackupSettings from '../settings/WebBackupSettings';
import WebPasscodeSettings from '../settings/WebPasscodeSettings';
import WebThemeSettings from '../settings/WebThemeSettings';
import WebMobileDialogHeader from '../WebMobileDialogHeader';
import WebScrollBox from '../WebScrollBox';
import WebMediaSettings from '../settings/WebMediaSettings';

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

interface SettingsTab {
  readonly title: string;
  readonly icon: React.ReactNode;
  readonly component: React.ReactNode;
}

const TABS: readonly SettingsTab[] = [
  { title: 'Account', icon: <FiUser />, component: <WebAccountSettings /> },
  { title: 'Passcode', icon: <FiLock />, component: <WebPasscodeSettings /> },
  { title: 'Theme', icon: <BsPalette />, component: <WebThemeSettings /> },
  { title: 'Media', icon: <FiImage />, component: <WebMediaSettings /> },
  { title: 'Backups', icon: <MdOutlineBackup />, component: <WebBackupSettings /> },
];

function WebSettingsDialog({ isOpen, onClose }: Props) {
  const grayBg = useGrayBg();
  const hoverBg = useColorModeValue('gray.100', 'gray.700');
  const bg = useBackground();

  const [activeTab, setActiveTab] = useState(TABS[0]);

  useHotkeys('escape', onClose, [onClose]);

  const isMobile = useIsMobile();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isMobile ? 'full' : '4xl'}
      motionPreset={isMobile ? 'slideInBottom' : 'scale'}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent my={[null, 'var(--vh-5)']} borderRadius={[0, 'md']} bg={bg} transition="background-color 200ms">
        {!isMobile && <ModalCloseButton />}
        <ModalBody p={0}>
          {!isMobile && (
            <Flex h="var(--vh-90)">
              <Flex
                h="100%"
                direction="column"
                borderLeftRadius="md"
                w="200px"
                bg={grayBg}
                transition="background-color 200ms"
                pt={4}
              >
                <Box px={4} fontSize="lg" fontWeight="bold" pb={4}>
                  Settings
                </Box>
                <WebScrollBox maxH="100%" flex="1" minH={0}>
                  {TABS.map((tab) => (
                    <HStack
                      key={tab.title}
                      px={4}
                      py={2}
                      bg={tab === activeTab ? hoverBg : undefined}
                      _hover={{ backgroundColor: hoverBg }}
                      transition="background-color 200ms"
                      cursor="pointer"
                      alignItems="center"
                      fontSize="md"
                      onClick={() => setActiveTab(tab)}
                    >
                      {tab.icon}
                      <div>
                        {tab.title}
                      </div>
                    </HStack>
                  ))}
                </WebScrollBox>
              </Flex>
              <Flex direction="column" flex={1} h="100%" minW={0} borderRightRadius="md">
                <Box fontSize="lg" fontWeight="bold" p={4}>
                  {activeTab.title}
                </Box>
                <WebScrollBox maxH="100%" flex="1" minH={0}>
                  <Box px={4} pb={4}>
                    {activeTab.component}
                  </Box>
                </WebScrollBox>
              </Flex>
            </Flex>
          )}
          {isMobile && (
            <Flex direction="column" h="var(--vh-100)">
              <WebMobileDialogHeader title="Settings" action="Done" onAction={onClose} />

              <WebScrollBox flex="1" minH={0} p={4}>
                {TABS.map(tab => (
                  <Box pb={12} key={tab.title}>
                    <Box mb={4} fontSize="md" fontWeight="semibold">{tab.title}</Box>
                    {tab.component}
                  </Box>
                ))}
              </WebScrollBox>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(withLazy(WebSettingsDialog, 200));
