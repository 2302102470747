import { decode, encode, ExtensionCodec } from '@msgpack/msgpack';

import { Transaction } from './cabinet-crdt';
import { base64ToBytes, bytesToBase64 } from './utils';

export function bytesCount(value: any): number {
  return serialize(value).length;
}

const extensionCodec = new ExtensionCodec();
const TRANSACTION_EXT_TYPE = 0;
extensionCodec.register({
  type: TRANSACTION_EXT_TYPE,
  encode: (object: unknown): Uint8Array | null => {
    if (object instanceof Transaction) {
      return encode([
        object.timeFrame,
        [object.diff.journalIds, object.diff.entryIds, object.diff.mediaIds, object.diff.settings],
        object.update,
        object.deviceId,
        object.clientId,
      ]);
    } else {
      return null;
    }
  },
  decode: (data: Uint8Array) => {
    const array = decode(data) as Array<any>;
    return new Transaction(
      array[0],
      { journalIds: array[1][0], entryIds: array[1][1], mediaIds: array[1][2], settings: array[1][3] },
      array[2],
      array[3],
      array[4],
    );
  },
});

export function serialize(value: any): Uint8Array {
  return encode(value, { ignoreUndefined: true, extensionCodec });
}

export function deserialize(buffer: Uint8Array): unknown {
  return decode(buffer, { extensionCodec });
}

export function serializeToEnvelope(x: any) {
  return { payload: bytesToBase64(serialize(x)) };
}

export function deserializeFromEnvelope(x: any): any {
  return deserialize(base64ToBytes(x.payload));
}
