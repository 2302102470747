import Quill from "quill/core";

const Block = Quill.import('blots/block')

export class EntryTitle extends Block { }
EntryTitle.blotName = 'entry_title';
EntryTitle.tagName = 'H1';
EntryTitle.className = 'ql-entry-title';


