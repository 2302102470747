import {
  Box,
  Button,
  ButtonProps,
  Flex,
  HStack,
  IconButton,
  Link,
  Text,
  Tooltip,
  useBoolean,
  useColorModeValue,
} from '@chakra-ui/react';
import { Fragment, memo, useCallback, useMemo } from 'react';
import { BiMessageDetail } from 'react-icons/bi';
import { FiHelpCircle, FiLock, FiLogIn, FiLogOut, FiPlus, FiSearch, FiSettings } from 'react-icons/fi';

import { useDataService } from '../../shared/components/DataServiceContext';
import { usePasscode } from '../../shared/components/DataServiceStateContext';
import { JournalId } from '../../shared/data/data-types/entities/journal';
import { useIsMobile } from '../../shared/hooks';
import { AuthService } from '../../shared/services/AuthService';
import AddJournalDialog from './dialogs/WebAddJournalDialog';
import ContactUsDialog from './dialogs/WebContactUsDialog';
import SettingsDialog from './dialogs/WebSettingsDialog';
import WebTurnOnPasscodeLockDialog from './dialogs/WebTurnOnPasscodeLockDialog';
import WebJournalList from './WebJournalList';
import WebScrollBox from './WebScrollBox';
import { useWebSearchDialog } from './WebSearchDialogContext';


interface Props {
  readonly activeJournalId: JournalId | undefined;
}

function WebSidebar({ activeJournalId }: Props) {
  const [isAddJournalDialogOpen, setIsAddJournalDialogOpen] = useBoolean();
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useBoolean();
  const [isContactUsDialogOpen, setIsContactUsDialogOpen] = useBoolean();
  const [, setIsSearchDialogOpen] = useWebSearchDialog();

  const identity = useMemo(() => AuthService.getIdentitySync(), []);
  const isTemporary = useMemo(() => AuthService.isTemporary(), []);

  const groupNameColor = useColorModeValue('gray.500', 'gray.400');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  const isMobile = useIsMobile();
  const JournalListWrapper = isMobile ? Box : WebScrollBox;
  const SidebarWrapper = isMobile ? WebScrollBox : Box;

  return (
    <SidebarWrapper h="100%">
      <Box pb={[10, 0]} display={['block', 'flex']} py={4} flexDirection={[null, 'column']} maxH={[null, '100%']}>
        <Box flex="none">
          <Box isTruncated pr={5} pl={5} mb={3} fontSize="sm" fontWeight="semibold" color={groupNameColor}>
            {!!identity?.email ? identity.email.toUpperCase() : (
              <HStack>
                <Text>GUEST</Text>
                <Tooltip label="Your journals are only available on this device. To avoid losing your journal, please log in. All your data will be transferred to your new account.">
                  <IconButton
                    size="xs"
                    cursor="default"
                    icon={<Text fontSize="sm"><FiHelpCircle /></Text>}
                    variant="ghost"
                    aria-label="Help"
                  />
                </Tooltip>
              </HStack>
            )}
          </Box>

          {isTemporary && <WebSidebarButton icon={<FiLogIn />} title="Log in" onClick={AuthService.startSignInGoogle} />}
          <WebSidebarButton icon={<FiSearch />} title="Search" onClick={setIsSearchDialogOpen.toggle} />
          <WebLockButton />
          <WebSidebarButton icon={<FiSettings />} title="Settings" onClick={setIsSettingsDialogOpen.on} />
          {!isTemporary && <WebSidebarButton icon={<FiLogOut />} title="Log out" onClick={() => AuthService.logOut(true)} />}
        </Box>
        <JournalListWrapper mt={activeJournalId === undefined ? 0 : 5} maxH={[null, '100%']} flex="1" minH={0}>
          <WebJournalList activeJournalId={activeJournalId} />
        </JournalListWrapper>
        <Box flex="none">
          <WebSidebarButton mt={5} icon={<FiPlus />} title="New journal" onClick={setIsAddJournalDialogOpen.toggle} />
          {isMobile && (
            <Fragment>
              <WebSidebarButton
                mt={5}
                icon={<Box fontSize="md" position="relative" top="2px"><BiMessageDetail /></Box>}
                title="Send us a message"
                onClick={setIsContactUsDialogOpen.toggle}
              />
              <Link target="_blank" href="https://www.journalify.com/terms" color={textColor} pl={14} py={2} d="block">
                Terms of service
              </Link>
              <Link target="_blank" href="https://www.journalify.com/privacy" color={textColor} pl={14} py={2} d="block">
                Privacy policy
              </Link>
              <ContactUsDialog isOpen={isContactUsDialogOpen} onClose={setIsContactUsDialogOpen.off} />
            </Fragment>
          )}
        </Box>
      </Box>
      <AddJournalDialog isOpen={isAddJournalDialogOpen} onClose={setIsAddJournalDialogOpen.off} />
      <SettingsDialog isOpen={isSettingsDialogOpen} onClose={setIsSettingsDialogOpen.off} />
    </SidebarWrapper>
  );
}

export default memo(WebSidebar);

interface WebSidebarButtonProps extends ButtonProps {
  readonly disabled?: boolean;
  readonly icon: JSX.Element;
  readonly title: string;
  readonly onClick?: () => void;
}

function WebSidebarButton({ icon, title, onClick, disabled, ...rest }: WebSidebarButtonProps) {
  const activeBg = useColorModeValue('rgba(0, 0, 0, 0.05)', '#3C4658'); // gray.650
  const textColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Button variant="unstyled" d="block" w="100%" fontWeight="normal" cursor={disabled ? undefined : 'pointer'} onClick={onClick} {...rest}>
      <Box
        transition="background-color 0.2s"
        color={textColor}
        pl={7}
        pr={5}
        py={2}
        _hover={{
          backgroundColor: disabled ? undefined : activeBg
        }}
      >
        <Flex align="center">
          <Box mr={3}>
            {icon}
          </Box>
          <Box minW={0} isTruncated>
            {title}
          </Box>
        </Flex>
      </Box>
    </Button>
  )
}

function WebLockButton() {
  const effectivePin = usePasscode();
  const [isTurnOnPrivacyLockDialogOpen, setIsTurnOnPrivacyLockDialogOpen] = useBoolean();
  const dataService = useDataService();

  const handleLock = useCallback(() => {
    if (effectivePin === undefined) {
      setIsTurnOnPrivacyLockDialogOpen.on();
    } else {
      dataService.lock();
    }
  }, [dataService, effectivePin, setIsTurnOnPrivacyLockDialogOpen]);

  const handleClose = useCallback((success: boolean) => {
    setIsTurnOnPrivacyLockDialogOpen.off();
    if (success) {
      dataService.lock();
    }
  }, [dataService, setIsTurnOnPrivacyLockDialogOpen]);

  return (
    <>
      <WebSidebarButton icon={<FiLock />} title="Lock" onClick={handleLock} />
      <WebTurnOnPasscodeLockDialog action="Lock" isOpen={isTurnOnPrivacyLockDialogOpen} onClose={handleClose} />
    </>
  );
}
