import { Box } from '@chakra-ui/layout';
import { memo } from 'react';
import { useParams } from 'react-router';

import { EntryId } from '../../shared/data/data-types/entities/entry';
import { JournalId } from '../../shared/data/data-types/entities/journal';
import { useEntry, useIsMobile, useJournal } from '../../shared/hooks';
import WebEntryEditor from '../components/editor/WebEntryEditor';
import NotFoundFeedback from '../components/NotFoundFeedback';
import WebEntryHelpFab from '../components/WebEntryHelpFab';

function WebEditEntryPage() {
  const params = useParams<any>();
  const entryId = params.entryId! as EntryId;
  const journalId = params.journalId! as JournalId;

  const entry = useEntry(entryId);
  const journal = useJournal(journalId);

  const found = journal !== undefined && entry !== undefined;

  const isMobile = useIsMobile();

  return (
    <Box key={entryId}>
      {found && (
        <WebEntryEditor journalId={journalId} entryId={entry.id} />
      )}

      {!found && (
        <NotFoundFeedback />
      )}

      {!isMobile && <WebEntryHelpFab />}
    </Box >
  );
}

export default memo(WebEditEntryPage);
