import { Flex, Progress, Spacer } from '@chakra-ui/react';
import { Fragment, memo } from 'react';

interface StorageUsageProps {
  readonly usage: number | undefined;
}

const GB = 1024 * 1024 * 1024;
const STORAGE_QUOTA = 1 * GB;

function StorageUsage({ usage }: StorageUsageProps) {
  const storageUsage = usage ?? 0;
  const progress = Math.trunc(storageUsage / STORAGE_QUOTA * 100);
  let color: string;
  if (usage === undefined) {
    color = 'blue';
  } else if (progress < 60) {
    color = 'green';
  } else if (progress < 80) {
    color = 'yellow';
  } else if (progress < 100) {
    color = 'orange';
  } else {
    color = 'red';
  }

  const usedMb = Math.trunc(storageUsage / GB * 1000);

  return (
    <Fragment>
      <Flex fontWeight="semibold" fontSize="sm" mb={2}>
        <div>
          Storage
        </div>
        <Spacer />
        {usage !== undefined && (
          <div>
            {usedMb}/1000 MB ({progress}%)
          </div>
        )}
      </Flex>
      <Progress borderRadius="sm" isIndeterminate={usage === undefined} value={progress} colorScheme={color} />
    </Fragment>
  );
}

export default memo(StorageUsage);
