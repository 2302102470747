import { config } from '../config';
import { RemoteCloud } from './data/cloud/remote-cloud';
import { DummyCryptography } from './data/cryptography/dummy-cryptography';
import { WebCryptography } from './data/cryptography/web-cryptography';
import { DataService } from './data/data-service';
import { KeyValueStorage } from './data/key-value-storage';
import { Lazy } from './data/utils';
import { AuthService } from './services/AuthService';

const SALT = 'v98j39N(Nnif023(ninwode2';

export function createDataService(storage: (name: string) => KeyValueStorage<string, any>) {
  const cloud = new RemoteCloud(fetch, {
    apiUrl: config.apiUrl,
    getApiToken: AuthService.getToken,
    getUserId: async () => await AuthService.getIdentity().then(x => x.sub),
    mediaUrl: config.mediaUrl,
  });

  const dataService = new Lazy(async () => {
    const identity = await AuthService.getIdentity();
    console.log('userId', identity.sub);

    const dataService = new DataService(
      cloud,
      {
        main: storage(`users/${identity.sub}/main`),
        blob: storage(`users/${identity.sub}/blob`),
      },
      config.crypto === 'web'
        ? new WebCryptography(crypto, new TextEncoder(), SALT)
        : new DummyCryptography(),
      { blobServiceMode: 'proxy' },
    );

    await dataService.init();
    return dataService;
  });

  return [
    dataService.get(),
    Promise.all([
      dataService.get().then(x => x.initCabinet(AuthService.getIdentitySync()).then(() => x.cabinet())),
      dataService.get().then(x => x.getDeviceSettings()),
      dataService.get().then(x => x.passcode()),
      dataService.get().then(x => x.obstruction()),
      dataService.get().then(x => x.isSyncedOnce()),
      dataService.get().then(x => x.getLastSuccessfulCabinetPull()),
    ]).then(([cabinet, deviceSettings, passcode, obstruction, syncedOnce, lastSuccessfulCabinetPull]) => ({ cabinet, deviceSettings, passcode, obstruction, syncedOnce, lastSuccessfulCabinetPull }))
  ] as const;
}
