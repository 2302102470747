import { Box, useColorModeValue } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { Fragment } from 'react';

import { normalizeText } from '../data/data-types/delta';

interface Props {
  readonly value: string;
  readonly term?: string;
}

function HighlightTerm({ value, term }: Props) {
  const parts = useMemo(() => {
    if (!term) {
      return [['normal', value]] as const;
    }

    const result: Array<['normal', string] | ['strong', string]> = [];
    let rest = value;
    const normalizedTerm = normalizeText(term)
    while (normalizeText(rest).indexOf(normalizedTerm) !== -1) {
      const pos = normalizeText(rest).indexOf(normalizedTerm);
      result.push(['normal', rest.slice(0, pos)]);
      result.push(['strong', rest.slice(pos, pos + term.length)]);
      rest = rest.slice(pos + term.length);
    }
    result.push(['normal', rest]);

    return result;
  }, [value, term]);

  const highlightBg = useColorModeValue('yellow.100', 'yellow.700');

  return (
    <Fragment>
      {parts.map(([type, text], index) => (
        <Fragment key={index}>
          {
            type === 'normal'
              ? <span>{text}</span>
              : <Box as="span" bg={highlightBg}>{text}</Box>
          }
        </Fragment>
      ))}
    </Fragment>
  )
}

export default React.memo(HighlightTerm);
