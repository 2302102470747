import { Box, Flex, Spacer } from '@chakra-ui/react';
import React, { ReactNode, useRef } from 'react';

export interface FileUploaderWrapperProps {
  readonly children?: ReactNode;
  readonly accept?: string;
  readonly onSelected?: (file: File) => void;
  readonly fullWidth?: boolean;
}

function FileUploaderWrapper({ children, accept, onSelected, fullWidth }: FileUploaderWrapperProps) {
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.value = '';
    }
    hiddenFileInputRef.current?.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = (event.target.files ?? [undefined])[0];
    if (onSelected && fileUploaded) {
      onSelected(fileUploaded);
    }
  };

  return (
    <>
      <Flex className='find-me' pointerEvents="none">
        <Box minW={0} flex={fullWidth ? 1 : undefined} onClick={handleClick} pointerEvents="auto">
          {children}
        </Box>
        {!fullWidth && <Spacer pointerEvents="none" />}
      </Flex>
      <input
        type="file"
        accept={accept}
        ref={hiddenFileInputRef}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </>
  );
}

export default React.memo(FileUploaderWrapper);
