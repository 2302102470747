import { Box, Button, Checkbox, Flex, Input, SlideFade, Spacer, Text, Tooltip, useBoolean } from '@chakra-ui/react';
import { memo, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useKeyDownCallback, useSettings, useTextSecondary } from '../../shared/hooks';
import { wait } from '../../shared/data/utils';
import { AuthService } from '../../shared/services/AuthService';
import { useDataService } from '../../shared/components/DataServiceContext';
import { useDeviceSettings, useObstruction, usePasscode, useSyncedOnce } from '../../shared/components/DataServiceStateContext';

interface Props {
  readonly children: React.ReactNode;
}

function WebPasscodeWall({ children }: Props) {
  const deviceSettings = useDeviceSettings();
  const dataService = useDataService();
  const effectivePasscode = usePasscode();
  const settings = useSettings();

  const syncedOnce = useSyncedOnce();
  const obstruction = useObstruction();

  const [unlocked, setUnlocked] = useState(
    (
      (deviceSettings.rememberPasscode && settings.autoLock === undefined) ||
      effectivePasscode === undefined
    ) &&
    (syncedOnce || obstruction === undefined)
  );

  useEffect(() => {
    dataService.lock$.subscribe(() => setUnlocked(false));
  }, [dataService]);

  useEffect(() => {
    if (effectivePasscode === undefined && (syncedOnce || obstruction === undefined)) {
      setUnlocked(true);
    }
  }, [effectivePasscode, obstruction, syncedOnce]);

  const [passcodeText, setPasscodeText] = useState('');
  const [isPasscodeInvalid, setIsPasscodeInvalid] = useBoolean();

  const passcodeInputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPasscodeText(e.target.value);
    setIsPasscodeInvalid.off();
  }, [setIsPasscodeInvalid]);

  const [remember, setRemember] = useBoolean();

  const handleSubmit = useCallback(async () => {
    const unblocks = obstruction !== undefined && (await dataService.unblock(passcodeText)).isSuccess();
    if (effectivePasscode?.text === passcodeText || unblocks) {
      if (unblocks) {
        dataService.unblock(passcodeText);
      }
      dataService.setDeviceSettings(prev => ({ ...prev, rememberPasscode: remember }))
      setPasscodeText('');
      setRemember.off();
      setUnlocked(true);
    } else {
      setIsPasscodeInvalid.on();
      wait(0).then(() => passcodeInputRef.current?.focus());
    }
  }, [obstruction, dataService, passcodeText, effectivePasscode?.text, setIsPasscodeInvalid, setRemember, remember]);

  const handleKeyDown = useKeyDownCallback({
    onEnter: handleSubmit,
  });

  const textSecondary = useTextSecondary();
  const identity = useMemo(() => AuthService.getIdentitySync(), []);

  return unlocked
    ? <Fragment>{children}</Fragment>
    : (
      <Box
        textAlign="center"
        marginTop="var(--vh-20)"
        maxWidth={[null, '450px']}
        p={4}
        mx="auto"
      >
        <Flex justifyItems="center">
          <Spacer />
          <div>
            <Text fontSize={['4xl', '5xl']} fontWeight="bold">Unlock Journalify</Text>
          </div>
          <Spacer />
        </Flex>

        <Text mb={6} fontSize="lg" color="gray.500">
          {/* Your journals are protected by passcode
          Unlock your journals by entering the passcode */}
          Enter the passcode to access your journals
        </Text>

        <Box textAlign="left" mb={1}>
          <Text fontSize="sm" color="gray.400">Passcode</Text>
        </Box>
        <Input
          flex="1"
          ref={passcodeInputRef}
          type="password"
          size="lg"
          placeholder="Enter passcode..."
          value={passcodeText}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          variant="filled"
          autoFocus
          mb={2}
        />
        <Flex color={textSecondary} textAlign="left" mb={4} opacity={settings.autoLock === undefined ? undefined : 0.5}>
          <Tooltip label={settings.autoLock === undefined ? undefined : 'Not avaliable with auto-lock'}>
            <div>
              <Checkbox
                disabled={settings.autoLock !== undefined}
                isChecked={remember && settings.autoLock === undefined}
                onChange={setRemember.toggle}
              >
                Remember on this device
              </Checkbox>
            </div>
          </Tooltip>
          <Spacer />
        </Flex>
        <Button disabled={isPasscodeInvalid} w="100%" onClick={handleSubmit} size="md" colorScheme="blue">
          Unlock
        </Button>

        <Box mt={4} color={textSecondary}>
          {identity?.email ?? 'Guest'}
          <Button
            ml={2}
            colorScheme="blue"
            onClick={() => AuthService.logOut(true)}
            variant="link"
            fontWeight="semibold"
          >
            Log out
          </Button>
        </Box>

        <SlideFade in={isPasscodeInvalid}>
          <Box mt={4}>
            <Text fontSize="xl" fontWeight="bold">Incorrect passcode</Text>
            <Text color="gray.500">
              Please make sure Caps Lock is turned off and try again.
            </Text>
          </Box>
        </SlideFade>
      </Box>
    )
}

export default memo(WebPasscodeWall);
