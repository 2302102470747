import { Box, Link, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Portal, useBoolean } from '@chakra-ui/react';
import { memo } from 'react';
import { BiMessageDetail } from 'react-icons/bi';
import { BsCommand } from 'react-icons/bs';

import { useTextOptional } from '../../shared/hooks';
import ContactUsDialog from './dialogs/WebContactUsDialog';
import WebHelpFabButton from './WebHelpFabButton';
import { useWebHotkeysDialog } from './WebHotkeysDialogContext';

function WebJournalHelpFab() {
  const [isContactUsDialogOpen, setIsContactUsDialogOpen] = useBoolean();

  const textOptional = useTextOptional();

  const [, setIsHotkeysDialogOpen] = useWebHotkeysDialog();

  return (
    <Portal>
      <Menu>
        <MenuButton as={WebHelpFabButton} />
        <MenuList>
          <MenuItem
            icon={<Box fontSize="lg" position="relative" top="2px"><BiMessageDetail /></Box>}
            onClick={setIsContactUsDialogOpen.on}
          >
            Send us a message
          </MenuItem>
          <MenuItem
            icon={<Box fontSize="lg"><BsCommand /></Box>}
            onClick={setIsHotkeysDialogOpen.on}
          >
            Keyboard shortcuts
          </MenuItem>
          <MenuDivider />
          <MenuItem
            as={Link}
            target="_blank"
            href="https://www.journalify.com/terms"
            fontSize="sm"
            color={textOptional}
          >
            Terms of service
          </MenuItem>
          <MenuItem
            as={Link}
            target="_blank"
            href="https://www.journalify.com/privacy"
            fontSize="sm"
            color={textOptional}
          >
            Privacy policy
          </MenuItem>
        </MenuList>

        <ContactUsDialog isOpen={isContactUsDialogOpen} onClose={setIsContactUsDialogOpen.off} />
      </Menu>
    </Portal>
  );
}

export default memo(WebJournalHelpFab);
