import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Textarea,
} from "@chakra-ui/react";
import { memo, ReactNode, useCallback } from "react";

import { EntryTextTemplate } from "../../shared/data/data-types/entities/journal";
import { useKeyDownCallback, useTextSecondary } from "../../shared/hooks";

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;

  readonly value: EntryTextTemplate | undefined;
  readonly onChange: (template: EntryTextTemplate | undefined) => void;

  readonly children: ReactNode;
}

function WebTextTemplatePickerPopover({
  isOpen,
  onClose,
  value,
  onChange,
  children,
}: Props) {
  const handleKeyDown = useKeyDownCallback({
    onEscape: useCallback(
      (e: React.KeyboardEvent<unknown>) => {
        if (isOpen) {
          e.stopPropagation();
          e.preventDefault();

          onClose();
        }
      },
      [isOpen, onClose]
    ),
  });

  const handleTemplateChange = useCallback(
    (e: { target: { value: string } } | undefined) => {
      if (!e) return;

      if (e.target.value.length === 0) {
        onChange(undefined);
      } else {
        onChange({ type: "text", text: e.target.value });
      }
    },
    [onChange]
  );

  const textSecondary = useTextSecondary();

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        onKeyDown={handleKeyDown}
        boxShadow="md"
        borderRadius="sm"
        px={0}
        w="375px"
        maxW="90vw"
      >
        <Flex px={2} pt={1} alignItems="center">
          <Box p={1} fontSize="sm" fontWeight="semibold" lineHeight="6">
            Template
          </Box>
          <Spacer />
          {value !== undefined && (
            <Button
              variant="ghost"
              color={textSecondary}
              size="sm"
              fontWeight="normal"
              onClick={() => {
                onChange(undefined);
                onClose();
              }}
            >
              Remove
            </Button>
          )}
        </Flex>
        <Box p={2}>
          <Textarea rows={10} value={value?.text ?? ""} onChange={handleTemplateChange} />
        </Box>
      </PopoverContent>
    </Popover>
  );
}

export default memo(WebTextTemplatePickerPopover);
