import { Fragment, memo, useEffect } from "react";
import { useHistory } from "react-router";
import { config } from "../../config";
import { isElectron } from "../../shared/utils";

function WebElectronAuthCallbackListener() {
  const history = useHistory();
  useEffect(() => {
    if (isElectron()) {
      (window as any).electron.onOpenUrl((url: string) => {
        if (url.startsWith(`${config.appUrl}/sign-in-callback`)) {
          history.push(url.replace(config.appUrl, ""));
        }
      });
    }
  }, [history]);

  return <Fragment />;
}

export default memo(WebElectronAuthCallbackListener);
