import { Box, BoxProps, Spinner } from '@chakra-ui/react';
import React from 'react';

interface Props extends BoxProps {
  readonly size?: 'md' | 'lg';
}

function WebAppSpinner({ size, ...boxProps }: Props) {
  return (
    <Box textAlign="center" {...boxProps}>
      <Spinner speed="0.8s" thickness="4px" color="gray.500" size={size ?? 'lg'} />
    </Box>
  );
}

export default React.memo(WebAppSpinner);
