import { Box, Button } from '@chakra-ui/react';
import { Fragment, memo, useEffect, useMemo } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { EntryId } from '../../shared/data/data-types/entities/entry';
import { JournalId } from '../../shared/data/data-types/entities/journal';
import { EntryListOptions, usePagination, useTextOptional, useTextSecondary } from '../../shared/hooks';
import { groupBy } from '../../shared/utils';
import EntryListItem from './WebEntryListItem';
import WebInfiniteScrollBox from './WebInfiniteScrollBox';
import { useEntryList } from './WebJournalEntryListContext';

interface Props {
  readonly journalId: JournalId;
  readonly options: EntryListOptions;
}

interface YearItems {
  readonly year: number;
  readonly months: readonly MonthItems[];
}

interface MonthItems {
  readonly month: number;
  readonly days: readonly DayItems[];
}

interface DayItems {
  readonly day: number;
  readonly entryIds: readonly EntryId[];
}

function reverseComparator<T>(compareFn: (a: T, b: T) => number, reverse: boolean) {
  if (reverse) {
    return (a: T, b: T) => -compareFn(a, b);
  }

  return compareFn;
}

function WebEntryList({ journalId, options }: Props) {
  const entries = useEntryList();
  const entryIds = useMemo(() => entries.map(x => x.id), [entries]);
  const { items: entriesSlice, loadMore, hasMore, reset } = usePagination(entries);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(), [options]);

  const filtered = (options.text ?? '') !== '' || (options.after ?? options.before ?? options.bookmarked) !== undefined;

  const textSecondary = useTextSecondary();
  const textOptional = useTextOptional();

  const grouped = useMemo((): YearItems[] => {
    return groupBy(entriesSlice, x => new Date(x.timestamp).getFullYear())
      .sort(reverseComparator((a, b) => b[0] - a[0], options.sort === 'asc'))
      .map(([year, yearEntries]) => ({
        year,
        months: groupBy(yearEntries, x => new Date(x.timestamp).getMonth())
          .sort(reverseComparator((a, b) => b[0] - a[0], options.sort === 'asc'))
          .map(([month, monthEntries]) => ({
            month,
            days: groupBy(monthEntries, x => new Date(x.timestamp).getDate())
              .sort(reverseComparator((a, b) => b[0] - a[0], options.sort === 'asc'))
              .map(([day, dayEntries]) => ({
                day,
                entryIds: dayEntries.sort(reverseComparator((a, b) => b.timestamp - a.timestamp, options.sort === 'asc')).map(x => x.id),
              })),
          })),
      }));
  }, [entriesSlice, options.sort]);

  return (
    <div>
      <WebInfiniteScrollBox
        pageStart={0}
        threshold={500}
        loadMore={loadMore}
        useWindow={false}
        hasMore={hasMore}
      >
        {grouped.map(({ year, months }, yearIndex) => (
          <div key={year}>
            {months.map(({ month, days }, monthIndex) => (
              <div key={month}>
                {days.map(({ day, entryIds }, dayIndex) => (
                  <Box key={day}>
                    {entryIds.map((entryId, entryIndex) => (
                      <div key={entryId}>
                        <EntryListItem
                          key={entryId}
                          isFirst={yearIndex === 0 && monthIndex === 0 && dayIndex === 0 && entryIndex === 0}
                          isLast={
                            yearIndex === grouped.length - 1 &&
                            monthIndex === grouped[yearIndex].months.length - 1 &&
                            dayIndex === grouped[yearIndex].months[monthIndex].days.length - 1 &&
                            entryIndex === grouped[yearIndex].months[monthIndex].days[dayIndex].entryIds.length - 1
                          }
                          hideLeftDate={entryIndex > 0}
                          entryId={entryId}
                          highlightTerm={options.text}
                          pb={[4, 8]}
                          mb={entryIndex === grouped[yearIndex].months[monthIndex].days[dayIndex].entryIds.length - 1 && entryIndex > 0 ? [0, 6] : 0}
                        />
                      </div>
                    ))}
                  </Box>
                ))}
              </div>
            ))}
          </div>
        ))}
      </WebInfiniteScrollBox>
      {(entryIds.length) === 0 && (
        <Fragment>
          {!filtered && (
            <Box textAlign="center" mt="calc((var(--vh-100) - 108px) / 2 - 56px)" px={4}>
              <Box fontSize="6xl" fontWeight="bold" mb={0}>
                {/* Personal */}
              </Box>
              <Box fontSize="2xl" fontWeight="normal" color={textOptional} mb={6}>
                Looks like you haven't written any entries yet!
              </Box>
              <Button
                leftIcon={<FiPlus />}
                size="lg"
                colorScheme="blue"
                as={Link}
                to={`/journals/${journalId}/entries/new`}
              >
                Write an entry
              </Button>
            </Box>
          )}
          {filtered && (
            <Box textAlign="center" py={16} px={2}>
              <Box fontSize="xl" fontWeight="semibold" color={textSecondary}>
                No results
              </Box>
              <Box fontSize="md" color={textOptional}>
                Try different search terms
              </Box>
            </Box>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default memo(WebEntryList);
