import { Box } from '@chakra-ui/react';
import { createPopper, Instance } from '@popperjs/core';
import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

export interface VirtualElementRect {
  readonly left: number;
  readonly top: number;
  readonly width: number;
  readonly height: number;
}

interface Props extends VirtualElementRect {
  readonly placement: 'top' | 'bottom';
  readonly children: React.ReactNode;
}

function VirtualElementPopover(
  { left, top, width, height, placement, children }: Props
) {
  const popperRef = useRef<Instance>();
  useEffect(() => () => popperRef.current?.destroy(), []);

  const setupPopper = useCallback((toolbarContainerElement: HTMLDivElement, toolbarAnchorElement: HTMLDivElement) => {
    if (popperRef.current) {
      return;
    }

    const instance = createPopper(toolbarAnchorElement, toolbarContainerElement, { placement });
    popperRef.current = instance;
    instance.update();
  }, [placement]);

  useLayoutEffect(() => {
    popperRef.current?.update();
  }, [left, top, width, height]);

  const anchorRef = useRef<HTMLDivElement>();
  const containerRef = useRef<HTMLDivElement>();

  const setAnchor = useCallback((anchorElement: HTMLDivElement) => {
    anchorRef.current = anchorElement;
    if (containerRef.current) {
      setupPopper(containerRef.current, anchorRef.current);
    }
  }, [setupPopper]);

  const setContainer = useCallback((containerElement: HTMLDivElement) => {
    containerRef.current = containerElement;
    if (anchorRef.current) {
      setupPopper(containerRef.current, anchorRef.current);
    }
  }, [setupPopper]);

  return (
    <Box className="virtual_popper">
      <Box
        ref={setAnchor}
        position="absolute"
        zIndex="-1000"
        w={width}
        h={height}
        top={top}
        left={left}
      />
      <Box zIndex="popover" ref={setContainer}>
        {children}
      </Box>
    </Box>
  );
}

export default React.memo(VirtualElementPopover);
