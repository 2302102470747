import { BehaviorSubject, concatMap, distinctUntilChanged, filter, from, map, Observable, Subject } from 'rxjs';
import { match } from 'ts-pattern';

import { Aggregate, aggregate } from './aggregation';
import { BlobService } from './blob-service';
import { TransactionBatch } from './cabinet-crdt';
import { CabinetService, CabinetServiceOptions } from './cabinet-service';
import { Cloud, CloudLogEntry } from './cloud';
import { Compression } from './compression';
import { Cryptography, Passcode, PasscodeMetadata } from './cryptography';
import {
  Cabinet,
  EntryCabinetAction,
  JournalCabinetAction,
  MediaCabinetAction,
  SettingsCabinetAction,
} from './data-types/cabinet';
import { Backup, BackupAsset } from './data-types/entities/backup';
import { BlobKey } from './data-types/entities/blob';
import { EntryId } from './data-types/entities/entry';
import { Identity } from './data-types/entities/identity';
import { JournalId } from './data-types/entities/journal';
import { ImageMedia, MediaId } from './data-types/entities/media';
import { DeviceSettings } from './data-types/entities/settings';
import { getTimestamp, Timestamp } from './data-types/timestamp';
import { EncryptionService } from './encryption-service';
import { EncryptionKeyNotFoundError, StorageQuotaError } from './errors';
import { KeyValueStorage, prefixKeyValueStorage, ValueStorage } from './key-value-storage';
import { Result } from './result';
import { TransactionLog } from './transaction-log';
import { deepEqual, generateId, wait } from './utils';

type ImageDataAction =
  | { readonly type: 'image/create', readonly media: ImageMedia, readonly blob: Uint8Array }
  | { readonly type: 'image/delete', readonly mediaId: MediaId };

export type DataAction =
  | EntryCabinetAction
  | JournalCabinetAction
  | SettingsCabinetAction
  | MediaCabinetAction
  // modified actions
  | ImageDataAction;

interface DataServiceOptions {
  readonly pushInterval: number;
  readonly pullInterval: number;
  readonly aggregateWindow: number;
  readonly pageSize: number;
  readonly blobServiceMode: 'proxy' | 'store';

  readonly _cabinetServiceOptions?: CabinetServiceOptions;
}

export interface DataServiceDirtyOptions {
  readonly encryption?: false;
  readonly cabinet?: false;
  readonly blob?: false;
}

export class DataService {
  private encryptionService: EncryptionService;
  private cabinetService: CabinetService;
  private blobService: BlobService;

  private compression: Compression;

  private operationSubject = new BehaviorSubject<void>(undefined);
  private storageQuotaExceededSubject = new BehaviorSubject<boolean>(false);
  private syncedOnceSubject = new Subject<boolean>();
  private lastSuccessfulCabinetPullSubject = new BehaviorSubject<Timestamp | undefined>(undefined);
  private syncInProgress = false;
  private backgroundSyncInProgress = false;

  private options: DataServiceOptions;

  private fresh: ValueStorage<boolean>;
  private syncedOnce: ValueStorage<boolean>;
  private deviceSettings: ValueStorage<DeviceSettings>;
  private deviceSettingsSubject = new Subject<void>();

  private mainStorage: KeyValueStorage<string, any>;

  private lockSubject = new Subject<void>();

  constructor(
    cloud: Cloud,
    storage: {
      readonly main: KeyValueStorage<string, any>,
      readonly blob: KeyValueStorage<string, any>,
    },
    cryptography: Cryptography,
    partialOptions?: Partial<DataServiceOptions>,
  ) {
    this.mainStorage = storage.main;
    this.options = {
      aggregateWindow: 50,
      pageSize: 100,
      pullInterval: 60000,
      pushInterval: 1000,
      blobServiceMode: 'proxy',
      ...partialOptions,
    };
    this.compression = new Compression({ level: 2 });

    this.encryptionService = new EncryptionService(
      prefixKeyValueStorage(storage.main, 'encryption/'),
      cryptography,
      cloud.encryption,
    );
    this.cabinetService = new CabinetService(
      prefixKeyValueStorage(storage.main, 'cabinet/'),
      this.encryptionService,
      new TransactionLog(cloud.logStore, {
        refinement: {
          aggregate: cloudEntries => this.aggregateCloudLogs(cloudEntries),
          aggregateWindow: this.options.aggregateWindow,
        }
      }),
      this.options?._cabinetServiceOptions ?? {
        compressionLevel: 2,
        optimize: true,
        abyssOptimizeWindow: this.options.aggregateWindow,
        abyssPageSize: this.options.pageSize,
        targetPageSize: this.options.pageSize,
        optimizeEveryNSyncs: 100,
      },
    );
    this.blobService = new BlobService(
      prefixKeyValueStorage(storage.blob, 'blob/'),
      this.encryptionService,
      cloud.blobStore,
      this.options.blobServiceMode,
    );

    this.fresh = new ValueStorage(prefixKeyValueStorage(storage.main, 'fresh/'), true);
    this.syncedOnce = new ValueStorage(prefixKeyValueStorage(storage.main, 'synced_once/'), false);
    this.deviceSettings = new ValueStorage<DeviceSettings>(
      prefixKeyValueStorage(storage.main, 'local_settings/'),
      {
        theme: { type: 'light', autoDark: true },

        autoLock: undefined,
        rememberPasscode: false,
        unlockedAt: 0 as Timestamp,
      },
    );

    this.mainStorage.message$
      .pipe(filter(x => x.deviceSettings !== undefined))
      .subscribe(() => this.deviceSettingsSubject.next());

    this.mainStorage.message$
      .pipe(filter(x => x.type === 'lock'))
      .subscribe(() => this.lockSubject.next());
  }

  get syncedOnce$(): Observable<boolean> {
    return this.syncedOnceSubject.pipe(distinctUntilChanged());
  }

  get lastSuccessfulCabinetPull$(): Observable<Timestamp> {
    return this.lastSuccessfulCabinetPullSubject.pipe(
      filter(x => x !== undefined),
      map(x => x!),
      distinctUntilChanged(),
    );
  }

  get storageQuotaExceeded$(): Observable<boolean> {
    return this.storageQuotaExceededSubject.pipe(distinctUntilChanged());
  }

  get passcode$(): Observable<Passcode | undefined> {
    return this.encryptionService.passcode$;
  }

  get obstruction$(): Observable<PasscodeMetadata | undefined> {
    return this.encryptionService.obstruction$;
  }

  get deviceSettings$(): Observable<DeviceSettings> {
    return this.deviceSettingsSubject.pipe(
      concatMap(() => from(this.getDeviceSettings())),
      distinctUntilChanged(deepEqual),
    );
  }

  get lock$(): Observable<void> {
    return this.lockSubject.asObservable();
  }

  dirty$(options?: DataServiceDirtyOptions): Observable<boolean> {
    return this.operationSubject.pipe(
      concatMap(() => from(this.dirty(options))),
      distinctUntilChanged(),
    );
  }

  // todo: ignore blob servie if sync for media is not enabled
  async dirty(options?: DataServiceDirtyOptions): Promise<boolean> {
    return (options?.encryption !== false && await this.encryptionService.dirty())
      || (options?.cabinet !== false && await this.cabinetService.dirty())
      || (
        options?.blob !== false &&
        !this.storageQuotaExceededSubject.value &&
        await this.blobService.dirty()
      );
  }

  async isSyncedOnce(): Promise<boolean> {
    return this.syncedOnce.get();
  }

  async getLastSuccessfulCabinetPull() {
    return this.lastSuccessfulCabinetPullSubject.value;
  }

  async cabinet(): Promise<Cabinet> {
    return await this.cabinetService.getCabinet();
  }

  async blob(blobKey: BlobKey): Promise<Uint8Array | undefined> {
    return await this.blobService.get(blobKey);
  }

  async isEncrypted(): Promise<boolean> {
    return await this.encryptionService.hasPasscode();
  }

  async obstruction(): Promise<PasscodeMetadata | undefined> {
    return await this.encryptionService.getObstruction();
  }

  async passcode(): Promise<Passcode | undefined> {
    return await this.encryptionService.getPasscode();
  }

  async encrypt(passcode: Passcode): Promise<void> {
    await this.encryptionService.setPasscode(passcode);
    this.operationSubject.next();
  }

  async decrypt(): Promise<void> {
    await this.encryptionService.removePasscode();
    this.operationSubject.next();
  }

  async unblock(passcodeText: string): Promise<Result<void, void>> {
    const result = await this.encryptionService.providePasscode(passcodeText);
    this.operationSubject.next();
    return result.empty();
  }

  async lock(broadcast = true): Promise<void> {
    await this.setDeviceSettings(prev => ({ ...prev, rememberPasscode: false }));
    this.lockSubject.next();
    if (broadcast) {
      this.mainStorage.post({ type: 'lock' });
    }
  }

  async initCabinet(identity: Identity | undefined): Promise<void> {
    const cabinet = await this.cabinet();
    if (
      !cabinet.settings().initialized &&
      (identity === undefined || (getTimestamp() - identity.createdAt) < 60 * 1000)
    ) {
      await this.execute({
        type: 'journal/put',
        journal: {
          id: generateId() as JournalId,
          createdAt: getTimestamp(),
          modifiedAt: getTimestamp(),
          name: 'Personal',
          icon: undefined,
          order: 0,
          entryStyle: {
            background: undefined,
            cover: { type: 'gray' },
            pad: undefined,
            textFont: undefined,
            titleFont: undefined,
          },
          entryTemplate: undefined,
        },
      });
      await this.execute({ type: 'settings/init' });
    }
  }

  async init(): Promise<void> {
    if (await this.fresh.get()) {
      await this.sync('cabinet-only');
      await this.fresh.set(false);
    }
  }

  async execute(action: DataAction): Promise<void> {
    await match(action)
      .with({ type: 'image/create' }, async ({ media, blob }) => {
        await this.blobService.create(media.blobKey, blob);
        await this.cabinetService.execute({ type: 'image/put', media });
      })
      .with({ type: 'image/delete' }, async ({ mediaId }) => {
        const media = (await this.cabinetService.getCabinet()).media(mediaId);
        if (media?.type !== 'image') {
          return;
        }
        await this.cabinetService.execute({ type: 'image/delete', mediaId: media.id });
        await this.blobService.delete(media.blobKey);
      })
      .otherwise((cabinetAction) => this.cabinetService.execute(cabinetAction as Exclude<DataAction, ImageDataAction>));

    this.operationSubject.next();
  }

  async getDeviceSettings(): Promise<DeviceSettings> {
    return await this.deviceSettings.get();
  }

  async setDeviceSettings(updater: (prev: DeviceSettings) => DeviceSettings): Promise<void> {
    this.mainStorage.lock('device_settings', async () => {
      const prev = await this.deviceSettings.get();
      const newSettings = updater(prev)
      await this.deviceSettings.set(newSettings);
      this.mainStorage.post({ deviceSettings: newSettings });
      this.deviceSettingsSubject.next();
    });
  }

  async createBackup(timestamp: Timestamp, includeAssets: boolean): Promise<Backup> {
    const cabinet = (await this.cabinet()).view(timestamp, {});
    const journals = cabinet.journals();
    const entries = cabinet.entries();
    const entryMedia = entries
      .flatMap(({ body }) => body.map(x => (x.insert as any)?.media?.mediaId as MediaId))
      .filter(x => x !== undefined);
    const media = cabinet.media().filter(x => entryMedia.includes(x.id));
    const assets: BackupAsset[] = [];

    if (includeAssets) {
      for (const asset of media) {
        switch (asset.type) {
          case 'image':
            const blob = await this.blob(asset.blobKey);
            if (blob) {
              assets.push({ key: asset.blobKey, value: blob });
            }
            break;
        }
      }
    }

    return { timestamp, journals, entries, media, assets };
  }

  async restoreBackup(backup: Backup) {
    const journalIdMapping = new Map<JournalId, JournalId>();
    for (const journal of backup.journals) {
      const newJournalId = generateId() as JournalId;
      journalIdMapping.set(journal.id, newJournalId);

      this.execute({
        type: 'journal/put',
        journal: {
          ...journal,
          id: newJournalId,
          name: `${journal.name}`,
        }
      });
    }

    const entryMediaIds = backup.entries
      .flatMap(({ body }) => body.map(x => (x.insert as any)?.media?.mediaId as MediaId))
      .filter(x => x !== undefined);

    const mediaIdMapping = new Map<MediaId, MediaId>();
    for (const mediaItem of backup.media.filter(x => entryMediaIds.includes(x.id))) {
      const newMediaId = generateId() as MediaId;
      mediaIdMapping.set(mediaItem.id, newMediaId);

      switch (mediaItem.type) {
        case 'image':
          this.cabinetService.execute({ type: 'image/put', media: { ...mediaItem, id: newMediaId } });
          break;
      }
    }

    for (const entry of backup.entries) {
      const journalId = journalIdMapping.get(entry.journalId);
      if (journalId === undefined) continue;

      let body = entry.body.flatMap(op => {
        const mediaId = (op.insert as any)?.media?.mediaId;
        if (mediaId !== undefined) {
          const newMediaId = mediaIdMapping.get(mediaId);
          return newMediaId === undefined ? [] : [{ insert: { media: { mediaId: newMediaId } } }];
        } else {
          return [op];
        }
      });
      if (typeof (entry as any).title === 'string') {
        body = [{ insert: (entry as any).title }, { insert: '\n', attributes: { entry_title: true } }, ...body];
      }

      this.execute({
        type: 'entry/put',
        entry: {
          ...entry,
          id: generateId() as EntryId,
          journalId,
          body,
        }
      })
    }

    for (const asset of backup.assets) {
      this.blobService.create(asset.key, asset.value);
    }

    this.operationSubject.next();
  }

  async sync(type: 'full' | 'cabinet-only' = 'full'): Promise<Result<void, void>> {
    if (this.syncInProgress) {
      return Result.error();
    }

    try {
      this.syncInProgress = true;

      const syncStart = getTimestamp();

      const results = [
        await this.encryptionService.sync(),
        await this.cabinetService.sync(),
        type === 'full'
          ? await this.blobService.sync({ targetKeys: await this.getTargetBlobKeys() })
          : Result.ok(),
      ];
      const result: Result<void, void> = results.every(x => x.isSuccess()) ? Result.ok() : Result.error();

      if (result.isSuccess()) {
        this.lastSuccessfulCabinetPullSubject.next(syncStart);
      }

      this.operationSubject.next();

      return result;
    } finally {
      this.syncInProgress = false;
    }
  }

  async startBackgroundSync(): Promise<void> {
    if (this.backgroundSyncInProgress) {
      return;
    }

    try {
      this.backgroundSyncInProgress = true;
      await Promise.all([
        this.startCabinetBackgroundSync(),
        this.startBlobBackgroundSync(),
      ]);
    } finally {
      this.backgroundSyncInProgress = false;
    }
  }

  private async startCabinetBackgroundSync(): Promise<never> {
    let lastCabinetPush = -1 as Timestamp;
    let lastCabinetPull = -1 as Timestamp;
    let lastEncryptionPush = -1 as Timestamp;
    let lastEncryptionPull = -1 as Timestamp;

    while (true) {
      if (await this.encryptionService.getObstruction() === undefined) {
        const now = getTimestamp();
        if (!await this.cabinetService.dirty()) {
          lastCabinetPush = now;
        }
        if (!await this.encryptionService.dirty()) {
          lastEncryptionPush = now;
        }

        if (now - lastEncryptionPull >= this.options.pullInterval || now - lastEncryptionPush >= this.options.pushInterval) {
          lastEncryptionPush = lastEncryptionPull = now;

          await this.encryptionService.sync();
          this.operationSubject.next();
        }

        if (now - lastCabinetPull >= this.options.pullInterval || now - lastCabinetPush >= this.options.pushInterval) {
          lastCabinetPush = lastCabinetPull = now;

          const result = await this.cabinetService.sync();
          this.operationSubject.next();
          if (result.isSuccess()) {
            await this.syncedOnce.set(true);
            this.syncedOnceSubject.next(true);
            this.lastSuccessfulCabinetPullSubject.next(lastCabinetPull);
          } else {
            if (result.error instanceof EncryptionKeyNotFoundError) {
              lastEncryptionPush = lastEncryptionPull = getTimestamp();

              await this.encryptionService.sync();
              this.operationSubject.next();

              if ((await this.encryptionService.getObstruction()) !== undefined) {
                await wait(2000);
              }
            }
          }
        }
      }

      await wait(200);
    }
  }

  private async startBlobBackgroundSync(): Promise<never> {
    while (true) {
      if (await this.encryptionService.getObstruction() === undefined) {
        const result = await this.blobService.sync({ targetKeys: await this.getTargetBlobKeys() });
        if (result.isFailure()) {
          if (result.error instanceof StorageQuotaError) {
            this.storageQuotaExceededSubject.next(true);

            await wait(60_000);
          }
        } else {
          this.storageQuotaExceededSubject.next(false);
        }

        this.operationSubject.next();
      }

      // blob service doesn't send api requests if there are no changes to sync
      // so, no reason for a long delay
      await wait(1000);
    }
  }

  private async getTargetBlobKeys(): Promise<BlobKey[]> {
    const cabinet = await this.cabinetService.getCabinet();
    const media = cabinet.media();

    return media
      .map(x => match(x)
        .with({ type: 'image' }, ({ blobKey }) => [blobKey])
        .otherwise(() => [])
      )
      .flatMap(x => x);
  }

  private async aggregateCloudLogs(cloudEntries: readonly CloudLogEntry[]): Promise<Result<unknown, ReadonlyArray<Aggregate<CloudLogEntry>>>> {
    const transactionBatchResults = await Promise.all(
      cloudEntries.map(x => this.encryptionService.decrypt(x))
    );
    const transactions: TransactionBatch[] = [];
    for (const transactionResult of transactionBatchResults) {
      if (transactionResult.isFailure()) return transactionResult.forward();

      transactions.push(this.compression.inflate(transactionResult.value));
    }

    const batches = aggregate(transactions);

    const deflatedBatches = batches.map(x => ({
      value: this.compression.deflate(x.value),
      soruce: x.source,
    }));
    const encryptedBatches = await Promise.all(
      deflatedBatches.map(async x => ({
        value: await this.encryptionService.encrypt(x.value),
        source: x.soruce,
      })),
    );

    const result: Aggregate<CloudLogEntry>[] = [];
    for (const encryptedAggregate of encryptedBatches) {
      if (encryptedAggregate.value.isFailure()) return encryptedAggregate.value.forward();

      result.push({
        value: encryptedAggregate.value.value,
        source: encryptedAggregate.source,
      })
    }

    return Result.ok(result);
  }
}
