import * as base64 from 'byte-base64';
import { customAlphabet } from 'nanoid';

export type Brand<TBase, TBrand extends string> = TBase & { __brand__: TBrand };

const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz_', 20);
export function generateId() {
  return nanoid();
}

export function distinct<T>(values: readonly T[]): T[] {
  return [...new Set(values).values()];
}

export function toMap<TKey, TValue>(items: TValue[], keySelector: (item: TValue, index: number) => TKey): Map<TKey, TValue> {
  return new Map(items.map((item, index) => [keySelector(item, index), item]));
}

export function bytesToBase64(bytes: Uint8Array) {
  return base64.bytesToBase64(bytes);
};

export function base64ToBytes(chars: string): Uint8Array {
  return base64.base64ToBytes(chars);
};

export function padWith0(num: number, places: number) {
  const zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
}

export interface Locker<TKey> {
  lock<TResult>(key: TKey, fn: () => Promise<TResult>): Promise<TResult>;
}

export class InMemoryLocker<TKey> implements Locker<TKey> {
  private fnQueueMap: Map<any, Array<() => Promise<any>>> = new Map();
  async lock<TResult>(key: TKey, fn: () => Promise<TResult>): Promise<TResult> {
    const execute = async () => {
      try {
        return await fn();
      } finally {
        const blockedFn = (this.fnQueueMap.get(key) ?? []).pop();
        if (blockedFn !== undefined) {
          blockedFn();
        } else {
          this.fnQueueMap.delete(key);
        }
      }
    }

    const fnQueue = this.fnQueueMap.get(key);
    if (fnQueue === undefined) {
      this.fnQueueMap.set(key, []);
      return await execute();
    } else {
      return new Promise((resolve, reject) => {
        fnQueue.push(async () => {
          try {
            resolve(await execute());
          } catch (err) {
            reject(err);
          }
        });
      });
    }
  }
}

export class Lazy<T> {
  private value: T | undefined = undefined;
  private ready = false;
  private locker = new InMemoryLocker();

  constructor(
    private getter: () => Promise<T>
  ) { }

  async get(): Promise<T> {
    return await this.locker.lock(this, async () => {
      if (!this.ready) {
        this.value = await this.getter();
        this.ready = true;
      }

      return this.value!;
    });
  }
}

export function unreachable(_: never): never {
  throw new Error("Didn't expect to get here");
}

export function maxString(first: string, ...other: string[]): string {
  let result = first;
  for (const str of other) {
    if (result < str) {
      result = str;
    }
  }

  return result;
}

export const MIN_STRING = '';

export function defer() {
  return new Promise(resolve => setTimeout(resolve, 0));
}

export function lastOrDefault<T>(array: readonly T[]): T | undefined {
  return array[array.length - 1];
}

export function last<T>(array: readonly T[]): T {
  if (array.length === 0) {
    throw new Error('Last element is not defined for an empty array');
  }

  return lastOrDefault(array)!;
}

export function single<T>(array: readonly T[]): T {
  if (array.length !== 1) {
    throw new Error(`Expected array of length one, but got array with ${array.length} items`);
  }

  return array[0];
}

export function wait(milliseconds: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function waitWithRandom(milliseconds: number): Promise<void> {
  return new Promise(resolve => setTimeout(
    resolve,
    milliseconds + Math.trunc((Math.random() * milliseconds - milliseconds / 2) / 4)
  ));
}

type DeferredState<T> =
  | { readonly type: 'resolved', readonly value: T }
  | { readonly type: 'pending' }
  | { readonly type: 'rejected', readonly error: unknown };

type PromiseResult<T> = T extends PromiseLike<infer K> ? PromiseResult<K> : T;

export class Deferred<T> {
  private state: DeferredState<T> = { type: 'pending' };

  private _resolve!: (value: T) => void;
  private _reject!: (error: unknown) => void;

  public readonly promise: Promise<T>;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  resolve(value: T) {
    if (this.state.type === 'pending') {
      this.state = { type: 'resolved', value };
      this._resolve(value);
    }
  }

  reject(error: unknown) {
    if (this.state.type === 'pending') {
      this.state = { type: 'rejected', error };
      this._reject(error);
    }
  }

  then<R>(fn: (value: T) => R): Promise<PromiseResult<R>> {
    if (this.state.type === 'resolved') {
      return fn(this.state.value) as any;
    } else {
      return this.promise.then(fn) as any;
    }
  }
}

export function hashString(value: string): number {
  let hash = 0;
  if (value.length === 0) {
    return hash;
  }
  for (let i = 0; i < value.length; i++) {
    hash = ((hash << 5) - hash) + value.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
}

export function createDummyState<T>(value: T) {
  return [
    value,
    (_valueOrSetter: ((prev: T) => T) | T): void => {
      throw new Error('Dummy state doesn\'t support state updates')
    },
  ] as const
}

export function deepEqual(a: any, b: any) {
  if (a === b) {
    return true;
  } else if ((typeof a === 'object' && a !== null) && (typeof b === 'object' && b !== null)) {
    if (Object.keys(a).length !== Object.keys(b).length) {
      return false;
    }

    for (const prop in a) {
      if (b.hasOwnProperty(prop)) {
        if (!deepEqual(a[prop], b[prop])) {
          return false;
        }
      } else {
        return false;
      }
    }

    return true;
  } else {
    return false;
  }
}
