import { match } from "ts-pattern";
import { StyledBackground, StyledFont } from "./data/data-types/entities/styled";
import { Duration } from "./data/data-types/timestamp";

export const EXPORT_FORMATS = ['pdf', 'html', 'markdown'] as const;

export type ExportFormat = 'pdf' | 'html' | 'markdown';

export function getExportFormatName(format: ExportFormat) {
  return match(format)
    .with('pdf', () => 'PDF')
    .with('html', () => 'HTML')
    .with('markdown', () => 'Markdown')
    .exhaustive();
}

export const COLOR_BACKGROUNDS: ReadonlyArray<StyledBackground> = [
  { type: 'gray' },
  { type: 'red' },
  { type: 'orange' },
  { type: 'yellow' },
  { type: 'green' },
  { type: 'teal' },
  { type: 'blue' },
  { type: 'cyan' },
  { type: 'purple' },
  { type: 'pink' },
];

export function getBackgroundName(background: StyledBackground | undefined, defaultText?: string) {
  if (background === undefined) {
    return typeof defaultText === 'string' ? defaultText : 'Default';
  }

  return match(background.type)
    .with('gray', () => 'Gray')
    .with('red', () => 'Red')
    .with('orange', () => 'Orange')
    .with('yellow', () => 'Yellow')
    .with('green', () => 'Green')
    .with('teal', () => 'Teal')
    .with('blue', () => 'Blue')
    .with('cyan', () => 'Cyan')
    .with('purple', () => 'Purple')
    .with('pink', () => 'Pink')
    .otherwise(() => 'Uknown');
}

export const FONTS: ReadonlyArray<StyledFont | undefined> = [
  undefined,
  { type: 'serif' },
  { type: 'sans-serif' },
  { type: 'cursive' },
  { type: 'mono' },
  { type: 'fantasy' },
];

export function getFontName(font: StyledFont | undefined) {
  if (font === undefined) {
    return 'Default';
  }

  return match(font.type)
    .with('mono', () => 'Monospace')
    .with('cursive', () => 'Cursive')
    .with('serif', () => 'Serif')
    .with('sans-serif', () => 'Sans-serif')
    .with('fantasy', () => 'Fantasy')
    .otherwise(() => 'Uknown');
}

export const AUTO_LOCK_OPTIONS = [
  { title: 'Disabled', value: 'disabled', duration: undefined },
  { title: 'If away for 1 minute', value: '1_min', duration: 60_000 as Duration },
  { title: 'If away for 5 minutes', value: '5_min', duration: 5 * 60_000 as Duration },
  { title: 'If away for 1 hour', value: '1_hour', duration: 60 * 60_000 as Duration },
  { title: 'If away for 5 hours', value: '5_hours', duration: 5 * 60 * 60_000 as Duration },
] as const;

export const THEME_OPTIONS = [
  { title: 'Use system setting', value: { type: 'light', autoDark: true } },
  { title: 'Light', value: { type: 'light', autoDark: false } },
  { title: 'Dark', value: { type: 'dark', autoDark: false } },
] as const;
