import React, { useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { useWebLock } from '../../web/components/WebLockContext';

function LockController() {
  const lock = useWebLock();
  const handle = useCallback((e: { preventDefault(): void }) => {
    e.preventDefault();
    lock();
  }, [lock]);

  useHotkeys('ctrl+l', handle, [handle]);

  return <React.Fragment />;
}

export default React.memo(LockController);
