import {
  Ciphertext,
  Cryptography,
  EncryptionKey,
  EncryptionKeyId,
  InvalidEncryptionKeyError,
  Passcode,
  PlaintextOf,
} from '../cryptography';
import { deserialize, serialize } from '../encoding';
import { Result } from '../result';
import { generateId } from '../utils';

enum Algorithm { AES_GCM }
type IV = Uint8Array;

type Envelope = [Algorithm, IV, Uint8Array];

export class WebCryptography implements Cryptography {
  constructor(
    private crypto: Crypto,
    private encoder: TextEncoder,
    private salt: string,
  ) { }

  async encrypt<TPlaintext>(key: EncryptionKey, plaintext: TPlaintext): Promise<Ciphertext<TPlaintext>> {
    const cryptoKey = await this.deriveKey(key.secret, this.salt);
    return await this.encryptAny(plaintext, cryptoKey) as Ciphertext<TPlaintext>;
  }

  async decrypt<TChiphertext extends Ciphertext<any>>(
    key: EncryptionKey,
    ciphertext: TChiphertext,
  ): Promise<Result<InvalidEncryptionKeyError, PlaintextOf<TChiphertext>>> {
    const cryptoKey = await this.deriveKey(key.secret, this.salt);
    try {
      return Result.ok(await this.decryptAny(ciphertext, cryptoKey));
    } catch (err) {
      return Result.error(new InvalidEncryptionKeyError());
    }
  }

  async createKey(passcode?: Passcode): Promise<EncryptionKey> {
    return {
      id: generateId() as EncryptionKeyId,
      secret: passcode?.text ?? generateId(),
    };
  }

  private async deriveKey(secret: string, salt: string): Promise<CryptoKey> {
    const secretBuffer = this.encoder.encode(secret).buffer
    const saltBuffer = this.encoder.encode(salt).buffer
    const keyMaterial = await this.crypto.subtle.importKey(
      'raw',
      secretBuffer,
      'PBKDF2',
      false,
      ['deriveKey']
    );

    const cryptoKey = await this.crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: saltBuffer,
        iterations: 100000,
        hash: 'SHA-256'
      },
      keyMaterial,
      { name: 'AES-GCM', length: 256 },
      true,
      ['encrypt', 'decrypt']
    );

    return cryptoKey;
  }

  private async encryptAny(data: any, key: CryptoKey): Promise<Uint8Array> {
    return await this.encryptBuffer(serialize(data), key)
  }

  private async decryptAny(data: Uint8Array, key: CryptoKey): Promise<any> {
    const decryptedValue = await this.decryptBuffer(data, key);
    return deserialize(decryptedValue);
  }

  private async encryptBuffer(data: Uint8Array, key: CryptoKey): Promise<Uint8Array> {
    const iv = this.crypto.getRandomValues(new Uint8Array(12))
    const cipher = new Uint8Array(await this.crypto.subtle.encrypt({ name: 'AES-GCM', iv }, key, data));
    const envelope: Envelope = [Algorithm.AES_GCM, iv, cipher];
    return serialize(envelope);
  }

  private async decryptBuffer(envelope: Uint8Array, key: CryptoKey): Promise<Uint8Array> {
    const [alg, iv, cipher] = deserialize(envelope) as Envelope;
    if (alg !== Algorithm.AES_GCM) {
      throw new Error('Unknown encryption algorithm');
    }
    const data = await this.crypto.subtle.decrypt({ name: 'AES-GCM', iv }, key, cipher);
    return new Uint8Array(data);
  }
}
