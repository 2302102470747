import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { FiSearch } from 'react-icons/fi';
import { MdKeyboardReturn } from 'react-icons/md';
import { RiArrowUpDownLine } from 'react-icons/ri';
import InfiniteScroll from 'react-infinite-scroller';
import { Link, useHistory } from 'react-router-dom';

import HighlightTerm from '../../../shared/components/HighlightTerm';
import { withLazy } from '../../../shared/components/hoc/withLazy';
import JournalIcon from '../../../shared/components/JournalIcon';
import { getAsPlaintext, getIntro, getTitle, normalizeText } from '../../../shared/data/data-types/delta';
import { Entry } from '../../../shared/data/data-types/entities/entry';
import { Journal } from '../../../shared/data/data-types/entities/journal';
import {
  useEntries,
  useHighlight,
  useIsMobile,
  useJournals,
  useKeyDownCallback,
  useScrollParentAccessor,
  useStateDelay,
  useTextOptional,
  useTextSecondary,
} from '../../../shared/hooks';
import WebScrollBox from '../WebScrollBox';

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

interface Filter {
  readonly text: string;
}

type ResultItem =
  | { readonly type: 'journal', readonly journal: Journal }
  | { readonly type: 'entry', readonly entry: Entry };

function WebSearchDialog({ isOpen, onClose }: Props) {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const textSecondary = useTextSecondary();
  const textOptional = useTextOptional();

  const journals = useJournals();
  const entries = useEntries();

  const [filter, setFilter] = useStateDelay<Filter>({ text: '' });

  const [text, setText] = useState('');
  useEffect(() => setFilter({ text: text.trim() }, 500), [setFilter, text]);

  const PAGE_SIZE = 20;
  const [displayLength, setDisplayLength] = useState(PAGE_SIZE);

  const [result, setResult] = useState<readonly ResultItem[]>([]);
  const [active, setActive] = useState(-1);
  useEffect(() => {
    const newResult: ResultItem[] = [];

    newResult.push(
      ...journals
        .filter(journal => normalizeText(journal.name).includes(normalizeText(filter.text)))
        .map(journal => ({ type: 'journal' as const, journal }))
    );

    newResult.push(
      ...entries
        .filter(entry => normalizeText(getAsPlaintext(entry.body, 100_000)).includes(normalizeText(filter.text)))
        .map(entry => ({ type: 'entry' as const, entry }))
    );

    setDisplayLength(PAGE_SIZE);
    setActive(-1);
    setResult(newResult);
  }, [entries, filter, journals]);

  const loadMore = useCallback(() => {
    setDisplayLength(prev => prev + PAGE_SIZE);
  }, []);

  const hasMore = displayLength < result.length;

  const handleNext = useCallback((e: { preventDefault(): void }) => {
    e.preventDefault();
    setActive(x => x + 1 === result.length ? x : x + 1);
  }, [result.length]);
  const handlePrev = useCallback((e: { preventDefault(): void }) => {
    e.preventDefault();
    setActive(x => x - 1 < 0 ? 0 : x - 1);
  }, []);

  const history = useHistory();
  const handleEnter = useCallback((e: { preventDefault(): void }) => {
    e.preventDefault();

    if (active === -1) return;

    const resultItem = result[active];

    if (resultItem.type === 'entry') {
      history.push(`/journals/${resultItem.entry.journalId}/entries/${resultItem.entry.id}`);
    } else {
      history.push(`/journals/${resultItem.journal.id}/timeline`);
    }

    onClose();
  }, [active, history, result, onClose]);

  const handleKeyDown = useKeyDownCallback({
    onDown: handleNext,
    onUp: handlePrev,
    onEnter: handleEnter,
  });

  useHotkeys('down', handleNext, [handleNext]);
  useHotkeys('up', handlePrev, [handlePrev]);
  useHotkeys('enter', handleEnter, [handleEnter]);

  const [osRef, getScrollParent] = useScrollParentAccessor();

  const highlightColor = useHighlight();

  const isMobile = useIsMobile();

  return (
    <Modal
      isOpen={isOpen}
      initialFocusRef={searchInputRef}
      size={isMobile ? 'full' : 'xl'}
      onClose={onClose}
      motionPreset={isMobile ? 'slideInBottom' : 'scale'}
    >
      <ModalOverlay>
        <ModalContent my={[null, 'var(--vh-10)']} h={['var(--vh-100)', 'var(--vh-60)']} borderRadius={[0, 'md']}>
          <ModalBody d="flex" flexDir="column" p={0} maxH="100%">
            <Flex
              borderBottom="1px solid"
              borderBottomColor={highlightColor}
              flex="none"
              alignItems="center"
              bg="bg-main"
              py={[1, 0]}
              borderTopRadius={[0, 'md']}
            >
              <InputGroup ml={[4, 0]} flex={1}>
                <InputLeftElement h="100%" pointerEvents="none">
                  <Box h="100%" display="flex" alignItems="center" fontSize="lg" p={1} color={textSecondary}>
                    <FiSearch />
                  </Box>
                </InputLeftElement>
                <Input
                  size="lg"
                  ref={searchInputRef}
                  placeholder="Search"
                  value={text}
                  onKeyDown={handleKeyDown}
                  borderRadius={['lg', 0]}
                  variant="unstyled"
                  bg={['bg-gray', 'none']}
                  py={[1, 3]}
                  my={[2, 0]}
                  onChange={e => setText(e.target.value)}
                />
              </InputGroup>
              {isMobile && (
                <Button colorScheme="blue" fontWeight="normal" variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
              )}
            </Flex>
            <WebScrollBox monitorWindow flex="1" ref={osRef}>
              <Box bg={['bg-gray', 'transparent']} pb={['var(--vh-50)', 0]} onMouseLeave={() => setActive(-1)}>
                <InfiniteScroll
                  pageStart={0}
                  threshold={400}
                  loadMore={loadMore}
                  useWindow={false}
                  getScrollParent={getScrollParent}
                  hasMore={hasMore}
                >
                  <Box pt={1} bg="bg-main">
                    {result.slice(0, displayLength).map((item, index) => (
                      <Box
                        position="relative"
                        key={item.type === 'journal' ? item.journal.id : item.entry.id}
                        onMouseMove={() => setActive(index)}
                        _hover={{ bg: highlightColor }}
                        bg={index === active && !isMobile ? highlightColor : undefined}
                        px={1}
                        borderRadius="sm"
                        borderBottom="1px solid"
                        borderBottomColor={['bg-highlight', 'transparent']}
                        pr={12}
                      >
                        {item.type === 'journal'
                          ? <JournalResultItemMemo
                            isActive={index === active}
                            onClose={onClose}
                            journal={item.journal}
                            term={filter.text}
                          />
                          : <EntryResultItemMemo
                            isActive={index === active}
                            onClose={onClose}
                            entry={item.entry}
                            term={filter.text}
                          />
                        }
                        {active === index && !isMobile && (
                          <Box
                            color={textOptional}
                            position="absolute"
                            right="20px"
                            top="0"
                            bottom="0"
                            d="flex"
                            alignItems="center"
                            fontSize="lg"
                          >
                            <MdKeyboardReturn />
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                  {result.length === 0 && (
                    <Fragment>
                      {filter.text !== '' && (
                        <Box textAlign="center" py={8} px={2}>
                          <Box fontWeight="semibold" color={textSecondary}>
                            No results
                          </Box>
                          <Box fontSize="sm" color={textOptional}>
                            Try different search terms
                          </Box>
                        </Box>
                      )}
                      {filter.text === '' && (
                        <Box textAlign="center" py={8} px={2}>
                          <Box fontWeight="semibold" color={textSecondary}>
                            No data
                          </Box>
                          <Box fontSize="sm" color={textOptional}>
                            There is nothing to search for
                          </Box>
                        </Box>
                      )}
                    </Fragment>
                  )}
                </InfiniteScroll>
              </Box>
            </WebScrollBox>
            {!isMobile && (
              <Box flex="none" bg="bg-main" borderBottomRadius={[0, 'md']}>
                {(filter.text === '' || result.length > 0) && (
                  <HStack
                    borderTop="1px solid"
                    borderTopColor={highlightColor}
                    spacing={6}
                    fontSize="xs"
                    color={textOptional}
                    py={1}
                    px={4}
                  >
                    {filter.text === '' && (
                      <Fragment>
                        <HStack spacing={1}>
                          <RiArrowUpDownLine />
                          <div>
                            Select
                          </div>
                        </HStack>
                        <HStack spacing={1}>
                          <MdKeyboardReturn />
                          <div>
                            Open
                          </div>
                        </HStack>
                      </Fragment>
                    )}
                    {filter.text !== '' && (
                      <div>
                        {result.length === 1 && (<Fragment><strong>1</strong> result</Fragment>)}
                        {result.length > 1 && (<Fragment><strong>{result.length}</strong> results</Fragment>)}
                      </div>
                    )}
                  </HStack>
                )}
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export default React.memo(withLazy(WebSearchDialog, 200));

interface JournalResultItemProps {
  readonly isActive: boolean;
  readonly journal: Journal;
  readonly term: string;
  readonly onClose: () => void;
}

function JournalResultItem({ isActive, journal, term, onClose }: JournalResultItemProps) {
  const linkRef = useRef<any>(null);
  useEffect(() => {
    if (isActive) {
      (linkRef.current as HTMLElement | null)?.scrollIntoView({ block: 'nearest' });
    }
  }, [isActive]);

  return (
    <HStack ref={linkRef} as={Link} to={`/journals/${journal.id}/timeline`} onClick={onClose} py={2} px={4}>
      <div>
        <JournalIcon journalId={journal.id} />
      </div>
      <Box fontWeight="semibold">
        <HighlightTerm value={journal.name} term={term} />
      </Box>
    </HStack>
  )
}

const JournalResultItemMemo = React.memo(JournalResultItem);

interface EntryResultItemProps {
  readonly isActive: boolean;
  readonly entry: Entry;
  readonly term: string;
  readonly onClose: () => void;
}

function EntryResultItem({ isActive, entry, term, onClose }: EntryResultItemProps) {
  const bodyPart = useMemo(() => getIntro(entry.body, { length: 60, term }), [entry, term]);

  const textSecondary = useTextSecondary();

  const linkRef = useRef<any>(null);
  useEffect(() => {
    if (isActive) {
      (linkRef.current as HTMLElement | null)?.scrollIntoView({ block: 'nearest' });
    }
  }, [isActive]);

  return (
    <Box
      ref={linkRef}
      as={Link}
      to={`/journals/${entry.journalId}/entries/${entry.id}`}
      display="block"
      onClick={onClose}
      py={2}
      px={4}
    >
      <div>
        <Box as="span" fontWeight="semibold">
          <HighlightTerm value={getTitle(entry.body)?.trim() || 'Untitled'} term={term} />
        </Box>
        &nbsp;·&nbsp;
        <Box as="span" color={textSecondary} fontSize="sm">
          {format(new Date(entry.timestamp), 'PP')}
        </Box>
      </div>
      {bodyPart !== '' && (
        <Box fontSize="xs" color={textSecondary}>
          <HighlightTerm value={bodyPart} term={term} />
        </Box>
      )}
    </Box>
  )
}

const EntryResultItemMemo = React.memo(EntryResultItem);
