import Delta from 'quill-delta';
import { useCallback, useMemo, useState } from 'react';
import { match } from 'ts-pattern';

import { useDataService } from '../components/DataServiceContext';
import { DeltaOperation } from '../data/data-types/delta';
import { EntryContentItem, EntryId } from '../data/data-types/entities/entry';
import { JournalId } from '../data/data-types/entities/journal';
import { StyledBackground, StyledEntity, StyledFont } from '../data/data-types/entities/styled';
import { getTimestamp, Timestamp } from '../data/data-types/timestamp';
import { useEntry, useJournal, useValue } from '../hooks';
import { fallback, generateId } from '../utils';

interface EntryEditorOptions {
  readonly journalId: JournalId;
  readonly entryId?: EntryId;
  readonly timestamp?: Timestamp;
  readonly onCreate?: (entryId: EntryId) => void;
}

export interface EntryEditableModel extends StyledEntity {
  readonly body: readonly DeltaOperation[];
  readonly timestamp: Timestamp;
}

export function useEntryEditor({
  journalId,
  entryId,
  timestamp,
  onCreate,
}: EntryEditorOptions) {
  const dataService = useDataService();
  const [existingEntryId, setExistingEntryId] = useState(entryId);
  const entry = useEntry(
    existingEntryId ?? (generateId() as EntryId),
    timestamp
  );

  const journal = useJournal(journalId);

  if (!journal) {
    throw new Error(
      `Can't create/edit an entry without an existing journal: ${journalId}`
    );
  }

  const entryBody =
    entry?.body ??
    match(journal.entryTemplate)
      .with({ type: "text" }, ({ text }) => textTemplateToDelta(text))
      .otherwise(() => [
        {
          insert: "\n",
          attributes: { entry_title: true, placeholder: "title" },
        },
        { insert: "\n", attributes: { placeholder: "content" } },
      ]);

  const existingEntryIdRef = useValue(existingEntryId);
  const getOrCreateEntryId = useCallback(
    (delta?: readonly DeltaOperation[]) => {
      if (existingEntryIdRef.current === undefined) {
        const newEntryId = generateId() as EntryId;

        dataService.execute({
          type: "entry/put",
          entry: {
            id: newEntryId,
            body: new Delta(entryBody as any).compose({
              ops: [...(delta ?? [])],
            } as any).ops as any,
            timestamp: getTimestamp(),
            createdAt: getTimestamp(),
            journalId: journalId,
            modifiedAt: getTimestamp(),
            bookmarked: false,
            background: journal.entryStyle.background,
            cover: journal.entryStyle.cover,
            pad: journal.entryStyle.pad,
            textFont: journal.entryStyle.textFont,
            titleFont: journal.entryStyle.titleFont,
          },
        });

        setExistingEntryId(newEntryId);
        existingEntryIdRef.current = newEntryId;
        if (onCreate) {
          onCreate(newEntryId);
        }

        return newEntryId;
      }

      return existingEntryIdRef.current;
    },
    [
      dataService,
      entryBody,
      existingEntryIdRef,
      journal.entryStyle.background,
      journal.entryStyle.cover,
      journal.entryStyle.pad,
      journal.entryStyle.textFont,
      journal.entryStyle.titleFont,
      journalId,
      onCreate,
    ]
  );

  const handleBodyChange = useCallback(
    (delta: readonly DeltaOperation[]) => {
      const exists = existingEntryIdRef.current !== undefined;
      dataService.execute({
        type: "entry/edit_body",
        entryId: getOrCreateEntryId(delta),
        delta: exists ? delta : [],
      });
    },
    [dataService, existingEntryIdRef, getOrCreateEntryId]
  );

  const handleTimestampChange = useCallback(
    (timestamp: Timestamp) => {
      dataService.execute({
        type: "entry/set_timestamp",
        entryId: getOrCreateEntryId(),
        timestamp,
      });
    },
    [dataService, getOrCreateEntryId]
  );

  const handleCoverChange = useCallback(
    (cover: StyledBackground | undefined) => {
      dataService.execute({
        type: "entry/set_cover",
        entryId: getOrCreateEntryId(),
        cover,
      });
    },
    [dataService, getOrCreateEntryId]
  );

  const handleBackgroundChange = useCallback(
    (background: StyledBackground | undefined) => {
      dataService.execute({
        type: "entry/set_background",
        entryId: getOrCreateEntryId(),
        background,
      });
    },
    [dataService, getOrCreateEntryId]
  );

  const handlePadChange = useCallback(
    (pad: StyledBackground | undefined) => {
      dataService.execute({
        type: "entry/set_pad",
        entryId: getOrCreateEntryId(),
        pad,
      });
    },
    [dataService, getOrCreateEntryId]
  );

  const handleTitleFontChange = useCallback(
    (font: StyledFont | undefined) => {
      dataService.execute({
        type: "entry/set_title_font",
        entryId: getOrCreateEntryId(),
        font,
      });
    },
    [dataService, getOrCreateEntryId]
  );

  const handleTextFontChange = useCallback(
    (font: StyledFont | undefined) => {
      dataService.execute({
        type: "entry/set_text_font",
        entryId: getOrCreateEntryId(),
        font,
      });
    },
    [dataService, getOrCreateEntryId]
  );

  const handleBookmarkedChange = useCallback(
    (value: boolean) => {
      dataService.execute({
        type: "entry/set_bookmarked",
        entryId: getOrCreateEntryId(),
        value,
      });
    },
    [dataService, getOrCreateEntryId]
  );

  const editableModel = useMemo<EntryEditableModel>(
    () => ({
      body: entryBody,
      timestamp: entry?.timestamp ?? getTimestamp(),
      cover: fallback(entry, (x) => x.cover, journal.entryStyle.cover),
      background: fallback(
        entry,
        (x) => x.background,
        journal.entryStyle.background
      ),
      pad: fallback(entry, (x) => x.pad, journal.entryStyle.pad),
      textFont: fallback(entry, (x) => x.textFont, journal.entryStyle.textFont),
      titleFont: fallback(
        entry,
        (x) => x.titleFont,
        journal.entryStyle.titleFont
      ),
    }),
    [
      entry,
      entryBody,
      journal.entryStyle.background,
      journal.entryStyle.cover,
      journal.entryStyle.pad,
      journal.entryStyle.textFont,
      journal.entryStyle.titleFont,
    ]
  );

  return {
    editableModel,
    existingEntryId,
    autoFocus: existingEntryId === undefined && journal.entryTemplate === undefined,

    handleBodyChange,
    handleTimestampChange,
    handleCoverChange,
    handleBackgroundChange,
    handlePadChange,
    handleTitleFontChange,
    handleTextFontChange,
    handleBookmarkedChange,
  };
}

function textTemplateToDelta(text: string) {
  const lines = text.split("\n");
  if (lines.length < 2) {
    lines.push("");
  }

  const result: EntryContentItem[] = [];
  for (let i = 0; i < lines.length; i += 1) {
    const line = lines[i];
    if (i === 0) {
      if (line.length > 0) {
        result.push({ insert: line });
        result.push({ insert: "\n", attributes: { entry_title: true } });
      } else {
        result.push({
          insert: "\n",
          attributes: { entry_title: true, placeholder: "title" },
        });
      }
    } else if (i === 1) {
      if (line.length > 0 || lines.length > 2) {
        result.push({ insert: line + "\n" });
      } else {
        result.push({
          insert: "\n",
          attributes: { placeholder: "content" },
        });
      }
    } else {
      result.push({ insert: line + "\n" });
    }
  }

  return result;
}
