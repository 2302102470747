import { config } from '../../config';
import { Identity } from '../data/data-types/entities/identity';
import { isElectron } from '../utils';

const TOKEN_KEY = "journalify_token";

interface CreateTempUserResponse {
  readonly user: Identity;
  readonly token: string;
}

async function createTempUser(): Promise<CreateTempUserResponse> {
  return await fetch(`${config.apiUrl}/users/temp`, { method: "POST" }).then(
    (x) => x.json()
  );
}

let createTempUserPromise: Promise<CreateTempUserResponse> | undefined =
  undefined;

async function getToken(): Promise<string> {
  const storedToken = localStorage.getItem(TOKEN_KEY);
  if (storedToken) {
    return storedToken;
  }

  const { token } = createTempUserPromise
    ? await createTempUserPromise
    : await (createTempUserPromise = createTempUser());
  setToken(token);

  createTempUserPromise = undefined;

  return token;
}

async function getIdentity(): Promise<Identity> {
  const token = await getToken();
  return parseToken(token);
}

function parseToken(token: string): Identity {
  return JSON.parse(atob(token.split(".")[1]));
}

function isTemporary(): boolean {
  const storedToken = localStorage.getItem(TOKEN_KEY);
  if (storedToken) {
    return parseToken(storedToken).email === undefined;
  }

  return true;
}

function getIdentitySync(): Identity | undefined {
  const storedToken = localStorage.getItem(TOKEN_KEY);
  if (storedToken) {
    return parseToken(storedToken);
  }

  return undefined;
}

function setToken(token: string): void {
  localStorage.setItem(TOKEN_KEY, token);
}

async function getGoogleAuthUrl() {
  const rootUrl = "https://accounts.google.com/o/oauth2/v2/auth";
  const options = {
    redirect_uri: `${config.apiUrl}/callback/google`,
    client_id: config.googleClientId,
    access_type: "offline",
    response_type: "code",
    prompt: "consent",
    scope: ["https://www.googleapis.com/auth/userinfo.email"].join(" "),
    state: await getSignInState(),
  };

  return `${rootUrl}?${new URLSearchParams(options).toString()}`;
}

async function startSignInGoogle() {
  startSignIn(await getGoogleAuthUrl());
}

async function getFacebookAuthUrl() {
  const rootUrl = "https://www.facebook.com/v13.0/dialog/oauth";
  const options = {
    redirect_uri: `${config.apiUrl}/callback/facebook`,
    client_id: config.facebookClientId,
    auth_type: "rerequest",
    scope: "email public_profile",
    state: await getSignInState(),
  };

  return `${rootUrl}?${new URLSearchParams(options).toString()}`;
}

async function startSignInFacebook() {
  startSignIn(await getFacebookAuthUrl());
}

async function getSignInState() {
  return JSON.stringify({
    token: await getToken(),
    redirectUrl: `${config.appUrl}/sign-in-callback`,
  });
}

function startSignIn(url: string) {
  if (isElectron()) {
    (window as any).electron.openExternal(url);
  } else {
    window.location.href = url;
  }
}

function logOut(redirect: boolean) {
  localStorage.removeItem(TOKEN_KEY);
  if (redirect) {
    if (isElectron()) {
      window.location.reload();
    } else {
      window.location.href = "/";
    }
  }
}

export const AuthService = {
  getToken,
  setToken,
  startSignInGoogle,
  startSignInFacebook,
  getGoogleAuthUrl,
  getFacebookAuthUrl,
  getIdentity,
  isTemporary,
  logOut,
  getIdentitySync,
};
