import { Box, useBreakpointValue, useColorMode, useColorModeValue } from '@chakra-ui/react';
import OverlayScrollbars from 'overlayscrollbars';
import React, { Fragment, memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { getTitle } from '../../../shared/data/data-types/delta';
import { EntryId } from '../../../shared/data/data-types/entities/entry';
import { JournalId } from '../../../shared/data/data-types/entities/journal';
import { Timestamp } from '../../../shared/data/data-types/timestamp';
import { useIsMobile, useScollBoxContainer } from '../../../shared/hooks';
import { useEntryEditor } from '../../../shared/hooks/useEntryEditor';
import { getBackground, getBackgroundColor, getPadColor } from '../../../shared/utils';
import WebScrollBox from '../WebScrollBox';
import WebEditEntryForm from './WebEditEntryForm';
import WebEntryTopBar from './WebEntryTopBar';

interface Props {
  readonly readOnly?: boolean;
  readonly entryId?: EntryId;
  readonly journalId: JournalId;
  readonly onCreate?: (entryId: string) => void;
  readonly timestamp?: Timestamp;
  readonly h?: string;
  readonly topBar?: ReactNode;
};

function WebEntryEditor({ h, readOnly, timestamp, entryId, journalId, onCreate, topBar }: Props) {
  h = h ?? 'var(--vh-100)';

  const [osRef, scrollingContainer] = useScollBoxContainer();

  const {
    editableModel,
    existingEntryId,
    autoFocus,

    handleBackgroundChange,
    handleBodyChange,
    handleBookmarkedChange,
    handleCoverChange,
    handlePadChange,
    handleTextFontChange,
    handleTimestampChange,
    handleTitleFontChange,
  } = useEntryEditor({ entryId, journalId, onCreate, timestamp });

  const coverRef = useRef<HTMLDivElement>(null);
  const topBarRef = useRef<HTMLDivElement>(null);
  const topBarBorderRef = useRef<HTMLDivElement>(null);

  const topBarHeight = 57;

  const isMobile = useIsMobile();
  const PARALAX_TO_MAX = isMobile ? 100 : 250;
  const PARALAX_FROM_MAX = isMobile ? PARALAX_TO_MAX * 1.5 : PARALAX_TO_MAX * 2;

  const padShift = isMobile ? 0 : 100;

  const OPACITY_TO_MAX = 1;
  const OPACITY_FROM_MAX = PARALAX_TO_MAX - padShift;

  const topBarTitle = useMemo(() => getTitle(editableModel.body), [editableModel.body]);
  const [showTitle, setShowTitle] = useState(false);
  const { colorMode } = useColorMode();
  const lastScrollTopRef = useRef(0);

  const updateScrollAnimation = useCallback(() => {
    const scrollTop = lastScrollTopRef.current ?? 0;

    if (isMobile) {
      if (coverRef.current) {
        coverRef.current.style.transform = `translateY(0px)`;
      }
    } else {
      const translateValue = scrollTop < PARALAX_FROM_MAX
        ? scrollTop / PARALAX_FROM_MAX * PARALAX_TO_MAX
        : PARALAX_TO_MAX
      if (coverRef.current) {
        coverRef.current.style.transform = `translateY(-${translateValue}px)`;
      }
    }

    const opacityValue = scrollTop < (OPACITY_FROM_MAX - topBarHeight)
      ? scrollTop / (OPACITY_FROM_MAX - topBarHeight) * OPACITY_TO_MAX
      : OPACITY_TO_MAX;
    if (topBarRef.current) {
      topBarRef.current.style.backgroundColor = getBackground(opacityValue, colorMode);
    }
    if (topBarBorderRef.current) {
      topBarBorderRef.current.style.opacity = opacityValue.toString();
    }

    setShowTitle(opacityValue === OPACITY_TO_MAX);
  }, [OPACITY_FROM_MAX, PARALAX_FROM_MAX, PARALAX_TO_MAX, colorMode, isMobile]);

  useEffect(() => { requestAnimationFrame(updateScrollAnimation); }, [updateScrollAnimation]);

  const handleScroll = useCallback(function (this: OverlayScrollbars, args?: UIEvent) {
    const target = args?.target;
    if (!(target instanceof HTMLElement)) return;

    lastScrollTopRef.current = target.scrollTop;

    requestAnimationFrame(updateScrollAnimation);
  }, [updateScrollAnimation]);

  const padPx = useBreakpointValue(timestamp === undefined ? [18, 100] : [18, 30])!;
  const padPt = useBreakpointValue(timestamp === undefined ? [10, 50] : [10, 10])!;

  const topBarBorderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Box bg={getBackgroundColor(editableModel.background, colorMode)} transition="background-color 0.2s">
      <Box
        ref={coverRef}
        transition="background-color 0.2s"
        h={`${PARALAX_TO_MAX}px`}
        mb={`-${PARALAX_TO_MAX}px`}
        bg={getBackgroundColor(editableModel.cover, colorMode)}
        boxShadow={editableModel.cover === undefined ? undefined : 'sm'}
      />
      <Box
        ref={topBarRef}
        position="relative"
        sx={{ '@media print': { display: 'none' } }}
      >
        {timestamp === undefined && (
          <Fragment>
            <WebEntryTopBar
              title={topBarTitle}
              showTitle={showTitle}
              onBookmarkedChange={handleBookmarkedChange}
              entryId={existingEntryId}
              journalId={journalId}
            />
            <Box ref={topBarBorderRef} h="1px" bg={isMobile ? topBarBorderColor : 'transparent'} />
          </Fragment>
        )}
        {topBar}
      </Box>
      <WebScrollBox monitorWindow onScroll={handleScroll} h={`calc(${h} - ${topBarHeight}px)`} ref={osRef}>
        <Box width={{ md: 700 + padPx * 2 }} mx={[0, 'auto']} pb="var(--vh-60)">
          <Box
            bg={getPadColor(editableModel.pad, colorMode)}
            boxShadow={(editableModel.pad ?? editableModel.background) === undefined ? undefined : 'md'}
            transition="background-color 0.2s, top 0.2s"
            position="relative"
            top={(editableModel.cover ?? editableModel.background) === undefined ? '0' : `${OPACITY_FROM_MAX - topBarHeight + 1}px`}
            px={padPx + 'px'}
            pt={padPt + 'px'}
            pb="10px"
            borderRadius="sm"
          >
            {scrollingContainer && (
              <WebEditEntryForm
                readOnly={readOnly}

                scrollingContainer={scrollingContainer}
                autoFocus={autoFocus}
                entry={editableModel}
                onBodyChange={handleBodyChange}

                onCoverChange={handleCoverChange}
                onBackgroundChange={handleBackgroundChange}
                onPadChange={handlePadChange}
                onTitleFontChange={handleTitleFontChange}
                onTextFontChange={handleTextFontChange}

                onBookmarkedChange={handleBookmarkedChange}
                onTimestampChange={handleTimestampChange}
              />
            )}
          </Box>
        </Box>
      </WebScrollBox>
    </Box>
  );
}

export default memo(WebEntryEditor);
