import { Box, Button, Flex, Spacer, useBoolean } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { memo, useState } from 'react';

import FileUploadButton from '../../../shared/components/FileUploadButton';
import { getTimestamp, Timestamp } from '../../../shared/data/data-types/timestamp';
import { useGrayBg } from '../../../shared/hooks';
import { useBackupSettings } from '../../../shared/hooks/useBackupSettings';
import WebExportBackupDialog from '../dialogs/WebExportBackupDialog';
import WebUnsupportedBackupFormatDialog from '../dialogs/WebUnsupportedBackupFormatDialog';
import WebSettingsSection from './WebSettingsSection';

function WebBackupSettings() {
  const [backupDate, setBackupDate] = useState<Date | undefined>(undefined);

  const [isUnsupportedBackupFormatDialogOpen, setIsUnsupportedBackupFormatDialogOpen] = useBoolean();
  const {
    dates,
    now,
    restoreBackup,
    isRestoreInProgress,
  } = useBackupSettings({ onUnsupportedBackupFormat: setIsUnsupportedBackupFormatDialogOpen.on });

  const grayBg = useGrayBg();

  return (
    <div>
      <Box mb={2} fontSize="sm">
        You can easily restore your backups anytime by clicking <strong>Restore backup</strong> below.
      </Box>
      <WebSettingsSection>
        <FileUploadButton onSelected={restoreBackup} accept="application/*">
          <Button
            isLoading={isRestoreInProgress}
            loadingText="Restoring the backup..."
            tabIndex={-1}
            size="sm"
            variant="outline"
          >
            Restore backup
          </Button>
        </FileUploadButton>
      </WebSettingsSection>
      <WebSettingsSection mt={10} title="Backups">
        {[now].concat(dates).map(date => (
          <Flex alignItems="center" key={date.getTime()} px={2} ml={-2} py={2} _hover={{ backgroundColor: grayBg }} transition="background-color 0.2s">
            <div>
              {format(date, 'yyyy, MMMM dd')}
            </div>
            <Spacer />
            <Button
              disabled={backupDate !== undefined}
              size="sm"
              fontSize="md"
              fontWeight="normal"
              variant="link"
              colorScheme="blue"
              onClick={() => setBackupDate(date)}
            >
              Download
            </Button>
          </Flex>
        ))}
      </WebSettingsSection>

      <WebExportBackupDialog
        isOpen={backupDate !== undefined}
        onClose={() => setBackupDate(undefined)}
        timestamp={(backupDate?.getTime() ?? getTimestamp()) as Timestamp}
      />
      <WebUnsupportedBackupFormatDialog
        isOpen={isUnsupportedBackupFormatDialogOpen}
        onClose={setIsUnsupportedBackupFormatDialogOpen.off}
      />
    </div>
  )
}

export default memo(WebBackupSettings);
