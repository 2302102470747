import { createContext, memo, ReactNode, useContext } from "react";
import { Entry } from "../../shared/data/data-types/entities/entry";
import { EntryListOptions, useEntries } from "../../shared/hooks";

const WebJournalEntryListContext = createContext<readonly Entry[] | undefined>(undefined);

interface Props {
  readonly options: EntryListOptions;
  readonly children: ReactNode;
}

export const WebJournalEntryListContextProvider = memo(({ options, children }: Props) => {
  const entries = useEntries(options);

  return (
    <WebJournalEntryListContext.Provider value={entries}>
      {children}
    </WebJournalEntryListContext.Provider>
  )
});

export function useEntryList() {
  const entries = useContext(WebJournalEntryListContext);

  if (entries === undefined) {
    throw new Error('useEntryList can only be used by components in WebJournalEntryListContext subtree');
  }

  return entries;
}
