import { Box, Button, HStack, Tooltip, useBoolean } from '@chakra-ui/react';
import { format } from 'date-fns';
import { memo, useEffect, useMemo, useRef } from 'react';
import { useCallback } from 'react';
import { BsPalette } from 'react-icons/bs';
import { FiCalendar } from 'react-icons/fi';

import RichTextEditor from '../../../shared/components/editor/RichTextEditor';
import { DeltaOperation, getTitle } from '../../../shared/data/data-types/delta';
import { StyledBackground, StyledFont } from '../../../shared/data/data-types/entities/styled';
import { Timestamp } from '../../../shared/data/data-types/timestamp';
import {
  useButtonBackground,
  useIsMobile,
  useKeyDownCallback,
  useTextPrimary,
  useTextSecondary,
} from '../../../shared/hooks';
import { EntryEditableModel } from '../../../shared/hooks/useEntryEditor';
import { AppEmitter, getFontFamily, wait } from '../../../shared/utils';
import WebDatePicker from '../WebDatePicker';
import WebStylePicker from '../WebStylePicker';

interface Props {
  readonly readOnly?: boolean;
  readonly autoFocus?: boolean;
  readonly entry: EntryEditableModel;
  readonly scrollingContainer: HTMLElement | undefined;

  readonly onBodyChange: (delta: readonly DeltaOperation[]) => void;
  readonly onTimestampChange: (timestamp: Timestamp) => void;

  readonly onCoverChange: (cover: StyledBackground | undefined) => void;
  readonly onBackgroundChange: (background: StyledBackground | undefined) => void;
  readonly onPadChange: (pad: StyledBackground | undefined) => void;
  readonly onTitleFontChange: (font: StyledFont | undefined) => void;
  readonly onTextFontChange: (font: StyledFont | undefined) => void;

  readonly onBookmarkedChange: (value: boolean) => void;
}

function WebEditEntryForm({
  readOnly, scrollingContainer, entry, autoFocus, onBodyChange, onCoverChange,
  onBackgroundChange, onPadChange, onTitleFontChange, onTextFontChange, onTimestampChange,
}: Props) {
  const [isStylePickerOpen, setIsStylePickerOpen] = useBoolean();

  const handleBodyChange = useCallback((delta: any) => {
    onBodyChange(delta);
  }, [onBodyChange]);

  const handleDateChange = useCallback((newDate: Date) => {
    onTimestampChange(newDate.getTime() as Timestamp);
  }, [onTimestampChange]);

  const focusEmitter = useMemo(() => new AppEmitter(), []);

  const datePickerButtonRef = useRef<HTMLButtonElement>(null);

  const hadnelDatePickerButtonKeyDown = useKeyDownCallback({
    onRight: useCallback((e: React.KeyboardEvent<HTMLButtonElement>) => {
      e.preventDefault();
      focusEmitter.emit(undefined);
    }, [focusEmitter]),
    onDown: useCallback((e: React.KeyboardEvent<HTMLButtonElement>) => {
      e.preventDefault();
      focusEmitter.emit(undefined);
    }, [focusEmitter]),
  });

  const handleCaretUp = useCallback(() => {
    wait(0).then(() => datePickerButtonRef.current?.focus());
  }, []);

  const entryDate = useMemo(() => new Date(entry.timestamp), [entry.timestamp]);

  const textPrimary = useTextPrimary();
  const textSecondary = useTextSecondary();
  const buttonBg = useButtonBackground();

  const hasTitle = !!getTitle(entry.body);

  const controlsRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useIsMobile();

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (controlsRef.current) {
      if (isMobile || e.clientY < controlsRef.current.getBoundingClientRect().bottom + 70 || isStylePickerOpen) {
        controlsRef.current.style.opacity = '1';
      } else {
        controlsRef.current.style.opacity = '0';
      }
    }
  }, [isMobile, isStylePickerOpen]);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    }
  }, [handleMouseMove]);


  return (
    <Box bg={entry.background}>
      <div>
        <HStack pointerEvents="all" wrap="wrap" spacing={1} ml={-2} mb={2} alignItems="center">
          <WebDatePicker
            readOnly={readOnly}
            value={entryDate}
            onChange={handleDateChange}
          >
            <div>
              <Tooltip isDisabled={readOnly} placement="top" openDelay={500} label="Change entry date">
                <Button
                  leftIcon={<Box fontSize={['llg', 'xl']}><FiCalendar /></Box>}
                  px={2}
                  ref={datePickerButtonRef}
                  onKeyDown={hadnelDatePickerButtonKeyDown}
                  size="md"
                  fontSize={['md', 'lg']}
                  borderRadius="md"
                  color={hasTitle ? textPrimary : textSecondary}
                  fontWeight="normal"
                  variant="ghost"
                  cursor={readOnly === true ? 'auto' : undefined}
                  _hover={readOnly === true ? { background: 'transparent' } : undefined}
                  _active={readOnly === true ? { background: 'transparent' } : undefined}
                  _focus={readOnly === true ? { background: 'transparent' } : undefined}
                >
                  {format(entryDate, 'MMMM d, yyyy')}
                </Button>
              </Tooltip>
            </div>
          </WebDatePicker>
          {!readOnly && (
            <Box ref={controlsRef} transition="opacity 0.2s">
              <WebStylePicker
                isOpen={isStylePickerOpen}
                onClose={setIsStylePickerOpen.off}
                value={entry}

                onCoverChange={onCoverChange}
                onBackgroundChange={onBackgroundChange}
                onPadChange={onPadChange}
                onTitleFontChange={onTitleFontChange}
                onTextFontChange={onTextFontChange}
              >
                <Button
                  onClick={setIsStylePickerOpen.on}
                  leftIcon={<Box fontSize={['lg', 'xl']}><BsPalette /></Box>}
                  variant="ghost"
                  size="sm"
                  fontSize="md"
                  borderRadius="md"
                  fontWeight="normal"
                  transition="opacity background 0.2s"
                  color={hasTitle ? textPrimary : textSecondary}
                  opacity={0.5}
                  _hover={{
                    opacity: 1,
                    background: buttonBg,
                  }}
                  sx={{ '@media print': { display: 'none' } }}
                >
                  {isMobile ? 'Style' : 'Change style'}
                </Button>
              </WebStylePicker>
            </Box>
          )}
        </HStack>
      </div>

      <RichTextEditor
        readOnly={readOnly}
        scrollingContainer={scrollingContainer}
        value={entry.body}
        autoFocus={autoFocus}
        focusEmitter={focusEmitter}
        onCaretUp={handleCaretUp}
        onChange={handleBodyChange}
        headingFontFamily={getFontFamily(entry.titleFont ?? { type: 'sans-serif' }, false)}
        bodyFontFamily={getFontFamily(entry.textFont ?? { type: 'serif' })}
      />
    </Box>
  )
}

export default memo(WebEditEntryForm);
