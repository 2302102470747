import { useBoolean, useToast } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';

import { useDataService } from '../components/DataServiceContext';
import { getTimestamp } from '../data/data-types/timestamp';
import { ImportProxy } from '../import/importProxy';
import { AuthService } from '../services/AuthService';

interface Options {
  readonly onUnsupportedBackupFormat: () => void;
}

export function useBackupSettings({ onUnsupportedBackupFormat }: Options) {
  const identity = useMemo(() => AuthService.getIdentitySync(), []);

  const now = useMemo(() => new Date(), []);
  const dates = useMemo(
    () => [...Array(200)]
      .map((_, index) => {
        const d = new Date(getTimestamp() - (index > 60 ? (index + 1) * 7 : (index + 1)) * 24 * 60 * 60 * 1000);
        d.setHours(23);
        d.setMinutes(59);
        d.setSeconds(59);
        d.setMilliseconds(0);

        return d;
      })
      .filter(x => identity !== undefined && x.getTime() > identity.createdAt),
    [identity]
  );

  const toast = useToast()

  const dataService = useDataService();
  const [isRestoreInProgress, setIsRestoreInProgress] = useBoolean();
  const restoreBackup = useCallback(async (backupFile: Blob) => {
    if (isRestoreInProgress) return;
    try {
      setIsRestoreInProgress.on();
      const backup = await ImportProxy.readBackup(backupFile);
      if (backup === undefined) {
        onUnsupportedBackupFormat();
        return;
      }
      dataService.restoreBackup(backup);

      toast({
        title: 'Restore completed',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      console.warn('[WARN] Error during restoring a backup', error);
      onUnsupportedBackupFormat();
    } finally {
      setIsRestoreInProgress.off();
    }
  }, [dataService, isRestoreInProgress, onUnsupportedBackupFormat, setIsRestoreInProgress, toast]);

  return {
    now,
    dates,
    restoreBackup,
    isRestoreInProgress,
  };
}

