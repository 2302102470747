import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Switch,
  useBoolean,
} from '@chakra-ui/react';
import React from 'react';
import { useCallback, useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';

import { useDataService } from '../../../shared/components/DataServiceContext';
import { withLazy } from '../../../shared/components/hoc/withLazy';
import { JournalId } from '../../../shared/data/data-types/entities/journal';
import { ExportProxy } from '../../../shared/export/exportProxy';
import { EXPORT_FORMATS, getExportFormatName } from '../../../shared/constants';

interface Props {
  readonly journalId: JournalId;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

function WebExportJournalDialog({ journalId, isOpen, onClose }: Props) {
  const [exportFormat, setExportFormat] = useState<'html' | 'pdf' | 'markdown'>('pdf');
  const [includeAssets, setIncludeAssets] = useBoolean(true);
  const dataService = useDataService();

  const exportButtonRef = useRef(null);

  const handleExport = useCallback(() => {
    ExportProxy.exportJournal(dataService, journalId, { includeAssets, format: exportFormat });

    onClose();
  }, [onClose, dataService, journalId, exportFormat, includeAssets]);

  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={exportButtonRef}
    >
      <ModalOverlay>
        <ModalContent mx={[4, null]}>
          <ModalHeader fontSize="lg" fontWeight="bold">
            Export journal
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody mb={2}>
            <SimpleGrid alignItems="center" columns={2} spacing={4}>
              <div>Export format</div>
              <Flex justifyContent="end">
                <Menu>
                  <MenuButton as={Button} variant="ghost" rightIcon={<FiChevronDown />}>
                    {getExportFormatName(exportFormat)}
                  </MenuButton>
                  <MenuList>
                    {EXPORT_FORMATS.map(format => (
                      <MenuItem key={format} onClick={() => setExportFormat(format)}>{getExportFormatName(format)}</MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
              <div>
                Include images
              </div>
              <Flex justifyContent="end">
                <Switch mr={4} isChecked={includeAssets} onChange={setIncludeAssets.toggle} />
              </Flex>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleExport}
              ref={exportButtonRef}
              ml={3}
            >
              Export
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export default React.memo(withLazy(WebExportJournalDialog, 200));
