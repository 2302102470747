import { useBoolean } from '@chakra-ui/react';
import { createContext, ReactNode, useContext } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import HotkeysDialog from './dialogs/WebHotkeysDialog';

const WebHotkeysDialogContext = createContext<ReturnType<typeof useBoolean> | undefined>(undefined);

interface Props {
  readonly children: ReactNode;
}

export function WebHotkeysDialogContextProvider({ children }: Props) {
  const [isOpen, setIsOpen] = useBoolean();

  useHotkeys('ctrl+/', setIsOpen.toggle);

  return (
    <WebHotkeysDialogContext.Provider value={[isOpen, setIsOpen]}>
      {children}
      <HotkeysDialog isOpen={isOpen} onClose={setIsOpen.off} />
    </WebHotkeysDialogContext.Provider>
  )
}

export function useWebHotkeysDialog() {
  const value = useContext(WebHotkeysDialogContext);

  if (value === undefined) {
    throw new Error('useHotkeysDialog can only be used by components in HotkeysDialogContextProvider subtree');
  }

  return value;
}
