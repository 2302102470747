import { chakra, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { FiBookmark } from 'react-icons/fi';

interface Props {
  readonly value: boolean;
  readonly withYellow?: boolean;
  readonly fontSize?: 'md' | 'lg' | 'xl';
}

const ChakraFiBookmark = chakra(FiBookmark);

function WebBookmarkIcon({ fontSize, value, withYellow }: Props) {
  const yellowColor = useColorModeValue('yellow.400', 'yellow.500');
  const textColor = useColorModeValue('blackAlpha.700', 'whiteAlpha.700');
  const color = withYellow ? yellowColor : textColor;
  return <ChakraFiBookmark fontSize={fontSize} color={value ? color : undefined} fill={value ? color : undefined} />;
}

export default React.memo(WebBookmarkIcon);
