import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { memo, ReactNode, useCallback } from 'react';

import { withLazy } from '../../shared/components/hoc/withLazy';
import { Icon } from '../../shared/data/data-types/entities/journal';
import { useKeyDownCallback } from '../../shared/hooks';
import WebIconPicker from './WebIconPicker';

const WebIconPickerLazy = withLazy(WebIconPicker, 200);

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;

  readonly value: Icon | undefined;
  readonly onChange: (icon: Icon | undefined) => void;

  readonly children: ReactNode;
}

function WebIconPickerPopover({ isOpen, onClose, value, onChange, children }: Props) {
  const handleKeyDown = useKeyDownCallback({
    onEscape: useCallback((e: React.KeyboardEvent<unknown>) => {
      if (isOpen) {
        e.stopPropagation();
        e.preventDefault();

        onClose();
      }
    }, [isOpen, onClose]),
  });

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        {children}
      </PopoverTrigger>
      <PopoverContent onKeyDown={handleKeyDown} boxShadow="md" borderRadius="sm" p={0} w="375px" maxW="90vw">
        <WebIconPickerLazy isOpen={isOpen} value={value} onChange={onChange} />
      </PopoverContent>
    </Popover>
  );
}

export default memo(WebIconPickerPopover);
