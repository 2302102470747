import { Box, BoxProps } from '@chakra-ui/react';
import { memo } from 'react';

interface Props extends BoxProps {
  readonly title?: string;
  readonly children: React.ReactNode;
}

function WebSettingsSection({ title, children, ...boxProps }: Props) {
  return (
    <Box mb={6} {...boxProps}>
      {title && (
        <Box fontSize={['sm', 'md']} fontWeight={['semibold', 'bold']} mb={2}>
          {title}
        </Box>
      )}
      <div>
        {children}
      </div>
    </Box>
  )
}

export default memo(WebSettingsSection);
