import {
  Box,
  Kbd,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
} from '@chakra-ui/react';
import React from 'react';

import { withLazy } from '../../../shared/components/hoc/withLazy';

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

function WebHotkeysDialog({ isOpen, onClose }: Props) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent mx={[4, null]}>
        <ModalHeader>
          Keyboard shortcuts
        </ModalHeader>
        <ModalBody pt={2} pb={6}>
          <ModalCloseButton />

          <Box as="h2" fontWeight="bold" fontSize="lg" mb={2}>
            Everywhere
          </Box>
          <SimpleGrid columns={2} spacingX={2} spacingY={2}>
            <div>Show this modal</div>
            <div>
              <Kbd>Ctrl</Kbd> + <Kbd>/</Kbd>
            </div>
            <div>Lock</div>
            <div>
              <Kbd>Ctrl</Kbd> + <Kbd>L</Kbd>
            </div>
            <div>Search</div>
            <div>
              <Kbd>Ctrl</Kbd> + <Kbd>K</Kbd>
            </div>
          </SimpleGrid>

          <Box as="h2" fontWeight="bold" fontSize="lg" mb={2} mt={6}>
            Editing
          </Box>
          <SimpleGrid columns={2} spacingX={2} spacingY={2}>
            <div>Undo</div>
            <div>
              <Kbd>Ctrl</Kbd> + <Kbd>Z</Kbd>
            </div>
            <div>Redo</div>
            <div>
              <Kbd>Ctrl</Kbd> + <Kbd>Shift</Kbd> + <Kbd>Z</Kbd>
            </div>
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(withLazy(WebHotkeysDialog, 200));
