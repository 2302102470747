import React, { Fragment, useEffect } from "react";
import { useSettings } from "../hooks";
import { useDataService } from "./DataServiceContext";

function AutoLockController() {
  const { autoLock } = useSettings();
  const dataService = useDataService();

  useEffect(() => {
    if (autoLock === undefined) return;

    let timeout: any;

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);

    resetTimer();

    function resetTimer() {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        dataService.lock(false);
      }, autoLock);
    }

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };
  }, [autoLock, dataService]);

  return <Fragment />;
}

export default React.memo(AutoLockController);
