import { Flex, Box, Button } from '@chakra-ui/react';
import { memo } from 'react';

interface Props {
  readonly title: string;
  readonly action: string;
  readonly actionDisabled?: boolean;
  readonly onAction: () => void;
  readonly onCancel?: () => void;
}

function WebMobileDialogHeader({ title, onAction, onCancel, actionDisabled, action }: Props) {
  return (
    <Flex
      flex="none"
      p={1}
      alignItems="center"
      borderBottom="1px solid"
      borderColor="bg-highlight"
      bg="bg-layer-2"
    >
      <Box flex="1" textAlign="left">
        {onCancel && (
          <Button onClick={onCancel} colorScheme="blue" fontWeight="normal" variant="ghost">
            Cancel
          </Button>
        )}
      </Box>
      <Box flex="none" fontWeight="semibold" textAlign="center" fontSize="md">
        {title}
      </Box>
      <Box flex="1" textAlign="right">
        <Button disabled={actionDisabled} onClick={onAction} colorScheme="blue" fontWeight="semibold" variant="ghost">
          {action}
        </Button>
      </Box>
    </Flex>
  );
}

export default memo(WebMobileDialogHeader);
